export const CalendarOpen = ({
  color = 'currentColor',
  size = '24',
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill={'none'}
      {...otherProps}
    >
      <path
        d="M23.75 3.75H22.5V1.25H20V3.75H10V1.25H7.5V3.75H6.25C5.58696 3.75 4.95107 4.01339 4.48223 4.48223C4.01339 4.95107 3.75 5.58696 3.75 6.25V23.75C3.75 24.413 4.01339 25.0489 4.48223 25.5178C4.95107 25.9866 5.58696 26.25 6.25 26.25H23.75C25.1375 26.25 26.25 25.1375 26.25 23.75V6.25C26.25 5.58696 25.9866 4.95107 25.5178 4.48223C25.0489 4.01339 24.413 3.75 23.75 3.75ZM23.75 23.75H6.25V11.25H23.75V23.75ZM23.75 8.75H6.25V6.25H23.75V8.75Z"
        fill={color}
      />
    </svg>
  )
}
