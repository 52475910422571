import type { DependencyList } from 'react'
import { useCallback, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyArgs = any[]

/**
 * Hook to throttle a callback function.
 *
 * @param fn function that will be passed to useCallback and throttled
 * @param delay time in milliseconds to throttle the callback
 * @param dependencies list of dependencies to pass to useCallback
 * @returns throttled callback
 */
export const useThrottledCallback = (
  fn: (...args: AnyArgs) => void,
  delay: number,
  dependencies: DependencyList
) => {
  const ref = useRef({ lastTime: 0 })

  return useCallback((...args: AnyArgs) => {
    const now = Date.now()

    if (now - ref.current.lastTime >= delay) {
      fn(...args)
      ref.current.lastTime = now
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

/**
 * Hook to debounce a callback function.
 *
 * @param fn function that will be passed to useCallback and debounced
 * @param delay time in milliseconds to debounce the callback
 * @param dependencies list of dependencies to pass to useCallback
 * @returns debounced callback
 */
// eslint-disable-next-line import/no-unused-modules
export const useDebouncedCallback = (
  fn: (...args: AnyArgs) => void,
  delay: number,
  dependencies: DependencyList
) => {
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined)

  return useCallback((...args: AnyArgs) => {
    if (timeout.current) clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      fn(...args)
    }, delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
