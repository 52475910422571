import { Controller, useForm } from 'react-hook-form'
import { Dialog } from 'components/dialogs/Dialog'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Trans, useTranslation } from 'react-i18next'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { formatInt } from 'util/number-input'
import { useDialogs } from 'hooks/dialogs'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import { toast } from 'react-hot-toast'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useMemo } from 'react'
import classNames from 'classnames'

type FormValues = {
  sectionRequestAssignmentCount: string
  sectionRequestStudentCount: string
}

export const InvoiceRequestDialog = ({
  createSectionInvoiceRequest,
  onCreate,
  onSkip,
}: {
  createSectionInvoiceRequest: (data: {
    sectionRequestAssignmentCount: number
    sectionRequestStudentCount: number
  }) => Promise<void>
  onCreate?: () => void
  onSkip?: () => void
}) => {
  const { popDialog } = useDialogs()
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      sectionRequestStudentCount: '',
      sectionRequestAssignmentCount: '',
    },
  })

  const studentCount = watch('sectionRequestStudentCount')
  const assignmentCount = watch('sectionRequestAssignmentCount')

  const estimatedCostDollars = useMemo(() => {
    const studentCountNum = parseInt(studentCount) || 0
    const assignmentCountNum = parseInt(assignmentCount) || 0

    // todo(ashold12): should we replace this with cost x someConstant (100 tokens?)
    const estimatedCost = studentCountNum * assignmentCountNum * 10
    if (estimatedCost <= 0) return ''
    return estimatedCost.toLocaleString() // Format the number with commas
  }, [studentCount, assignmentCount])

  const onSubmit = async (data: FormValues) => {
    // We can safely cast data to FormValues because we know the form is valid.
    const values = data as FormValues

    // Parse the strings to numbers
    const sectionRequestStudentCount = parseInt(
      values.sectionRequestStudentCount
    )
    const sectionRequestAssignmentCount = parseInt(
      values.sectionRequestAssignmentCount
    )

    const [, err] = await noTryAsync(() =>
      createSectionInvoiceRequest({
        sectionRequestStudentCount,
        sectionRequestAssignmentCount,
      })
    )

    if (err) {
      console.error(err)
      toast.error(t('instructor_class.invoice_request_failed'))
      return
    }

    onCreate?.()
    popDialog()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        innerClassName="flex h-full flex-col justify-start"
        ignoreBackdropClick
      >
        <DialogCloseButton />
        <div className="mb-3 space-y-1">
          <div className="text-headline-large">
            {t('instructor_class.invoice_class')}
          </div>
          <div className="text-body-medium text-on-surface-var">
            {t('instructor_class.invoice_class_description')}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Controller
            control={control}
            name="sectionRequestStudentCount"
            rules={{
              required: t('instructor_class.student_count_required'),
              min: {
                value: 1,
                message: t('instructor_class.student_count_min'),
              },
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                type="number"
                kind="secondary"
                error={fieldState.error}
                label={t(
                  'instructor_class.section_request_student_count_label'
                )}
                {...field}
                onChange={(e) => {
                  formatInt(e)
                  field.onChange(e)
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="sectionRequestAssignmentCount"
            rules={{
              required: t('instructor_class.assignment_count_required'),
              min: {
                value: 1,
                message: t('instructor_class.assignment_count_min'),
              },
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                type="number"
                kind="secondary"
                error={fieldState.error}
                label={t(
                  'instructor_class.section_request_assignment_count_label'
                )}
                {...field}
                onChange={(e) => {
                  formatInt(e)
                  field.onChange(e)
                }}
              />
            )}
          />
          <strong
            className={classNames('text-body-medium px-3', {
              invisible: estimatedCostDollars === '',
            })}
          >
            <Trans
              i18nKey="instructor_class.invoice_class_estimated_cost"
              values={{ dollars: estimatedCostDollars }}
              components={{ bold: <span className="font-bold" /> }}
            />
          </strong>
        </div>
        <div className="flex-1" />
        <div className="mt-2 space-y-3">
          <BreakoutButton
            size="large"
            type="submit"
            kind="primary"
            fullWidth
            // todo(ashold12): may want to allow submission on empty form
            disabled={isSubmitting || estimatedCostDollars === ''}
          >
            {t('instructor_class.submit_invoice_request')}
          </BreakoutButton>

          <BreakoutButton
            size="large"
            kind="secondary"
            fullWidth
            onClick={() => {
              popDialog()
              onSkip?.()
            }}
          >
            {t('instructor_class.dont_submit_invoice_request')}
          </BreakoutButton>
        </div>
      </Dialog>
    </form>
  )
}
