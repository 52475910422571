import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function Spinner({
  size,
  thickness,
  className,
}: {
  size?: number
  thickness?: number
  className?: string
}) {
  const circleStyle = useMemo(() => {
    const thicknessOrDefault = 8 * (8 / (thickness || 8))
    let circleStyle
    if (size) {
      circleStyle = {
        width: `${size}rem`,
        height: `${size}rem`,
        borderWidth: `${size / thicknessOrDefault}rem`,
      }
    }
    return circleStyle
  }, [size, thickness])

  return (
    <span
      className={classNames('spinner', className)}
      style={circleStyle}
    ></span>
  )
}

export function SpinnerWithLogo({ className }: { className?: string }) {
  const { t } = useTranslation()
  return (
    <div className="relative flex flex-col items-center justify-center">
      <Spinner size={6} thickness={6} className={className} />
      <img
        src="/assets/images/logo.png"
        width={64}
        className="absolute"
        alt={t('breakout_learning')}
      />
    </div>
  )
}

export function FullPageSpinner({ className }: { className?: string }) {
  return (
    <div className="fixed flex h-screen w-screen flex-col items-center justify-center">
      <SpinnerWithLogo className={className} />
    </div>
  )
}
