export function PlusIcon({
  color = 'currentColor',
  size = 24,
  pathClassName,
  pathStrokeWidth = 2,
  ...otherProps
}: {
  size?: number | string
  pathClassName?: string
  pathStrokeWidth?: number
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      {...otherProps}
    >
      <path
        d="M12 20L12 12M12 12L12 4M12 12L20 12M12 12L4 12"
        stroke={color}
        className={pathClassName}
        strokeWidth={pathStrokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
