import { type OrganizationIntegration } from '@breakoutlearning/firebase-repository/models/OrganizationIntegration'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { PlusIcon } from 'components/icons/Plus'
import { useOrganizationCubit } from 'hooks/cubits/organization'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CreateOrEditOrganizationIntegrationDialog } from './CreateOrEditOrganizationIntegration'

export const OrganizationIntegrationsView = observer(
  function OrganizationIntegrationsView() {
    const cubit = useOrganizationCubit()
    const { t } = useTranslation()
    const { showDialog } = useDialogs()

    const integrations = cubit.organizationIntegrations

    return (
      <div className="h-full min-h-0 rounded-3xl bg-surface p-6">
        <div className="h-full overflow-auto rounded-3xl bg-core-tertiary">
          <BreakoutTable className="white max-h-full">
            <thead>
              <tr className="sticky top-0 z-[10]">
                <th>
                  <div className="justify-between pt-3">
                    <strong>{t('organization.name')}</strong>
                  </div>
                </th>
                <th className="flex justify-end">
                  <BreakoutButton
                    size="small"
                    kind="tertiary"
                    icon={<PlusIcon size={10} />}
                    onClick={() => {
                      showDialog(() => (
                        <CreateOrEditOrganizationIntegrationDialog
                          cubit={cubit}
                          organizationIntegration={null}
                        />
                      ))
                    }}
                  >
                    Add Integration
                  </BreakoutButton>
                </th>
              </tr>
            </thead>
            <tbody className="max-h-full overflow-auto">
              {integrations.models.map((integration) => (
                <Row
                  key={integration.id}
                  integration={integration}
                  cubit={cubit}
                />
              ))}
            </tbody>
          </BreakoutTable>
        </div>
      </div>
    )
  }
)

const Row = observer(function ClassRow({
  integration,
  cubit,
}: {
  integration: OrganizationIntegration
  cubit: ReturnType<typeof useOrganizationCubit>
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  return (
    <tr>
      <td>{integration.data.integrationName}</td>

      <td>
        <div className="flex   justify-end gap-2">
          <BreakoutButton
            kind="secondary"
            className="!min-w-[62px]"
            onClick={() => {
              showDialog(() => (
                <CreateOrEditOrganizationIntegrationDialog
                  cubit={cubit}
                  organizationIntegration={integration}
                />
              ))
            }}
            size="medium"
          >
            {t('organization.integration.view')}
          </BreakoutButton>
        </div>
      </td>
    </tr>
  )
})
