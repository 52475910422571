import type { RouteType } from 'config/routes'
import type { QueryParams } from 'mobx-router'
import type { AnimationController } from 'pages/layout/TwoColumn'
import { TwoColumnAnimatedPane } from 'pages/layout/TwoColumn'
import { InstructorLibraryPage } from './library/InstructorLibraryPage'
import { InstructorTeachingPlanPage } from './teaching_plan/InstructorTeachingPlanPage'
import { InstructorSlideDeckPageWrapper } from './slide_deck/InstructorSlideDeckPageWrapper'

// This function controls animated transitions between subpages
function calculateAnimationController(
  params: QueryParams,
  route: RouteType | undefined,
  previousController?: AnimationController
) {
  const routeTitle = route?.title
  let key: string | null = null
  let component: JSX.Element | null = null
  let mode = 'stack'
  const slideDeckId = params?.slideDeckId
  const teachingPlanId = params?.teachingPlanId
  const catalogId = params?.catalogId
  if (routeTitle === 'instructorSlideDeck' && typeof slideDeckId === 'string') {
    key = `instructorSlideDeck`
    component = <InstructorSlideDeckPageWrapper slideDeckId={slideDeckId} />
    mode = 'slideIn'
  } else if (
    routeTitle === 'instructorTeachingPlan' &&
    typeof teachingPlanId === 'string' &&
    typeof catalogId === 'string'
  ) {
    key = `instructorTeachingPlan`
    component = (
      <InstructorTeachingPlanPage
        catalogId={catalogId}
        teachingPlanId={teachingPlanId}
      />
    )
    if (previousController?.key === 'instructorSlideDeck') {
      mode = 'slideOut'
    } else {
      mode = 'slideIn'
    }
    mode = 'slideIn'
  } else if (routeTitle === 'instructorLibrary' || routeTitle === 'home') {
    key = 'instructorLibrary'
    component = <InstructorLibraryPage />
    mode = 'slideOut'
  } else {
    mode = 'leave'
    component = null
  }

  return {
    key,
    component,
    mode,
  }
}

export const InstructorLibraryEntryPage = () => {
  return (
    <TwoColumnAnimatedPane calculateController={calculateAnimationController} />
  )
}
