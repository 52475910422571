import { observer } from 'mobx-react-lite'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { RoomsDialog } from './dialogs/RoomsDialog'
import { useTranslation } from 'react-i18next'
import { CircleAlert } from 'components/icons/CircleAlert'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { DateTime } from 'luxon'
import { RoomStateForm } from './dialogs/RoomStateFormDialog'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutUserAvatarStack } from 'components/breakout/BreakoutUserAvatar'
import React, { useCallback } from 'react'
import { useBreakoutUser } from 'hooks/profile'

const ExistingRoom = observer(function StudentAssignmentStep1() {
  const cubit = useStudentAssignmentCubit()
  const { showDialog, popDialog } = useDialogs()
  const { t } = useTranslation()
  const currentUser = useBreakoutUser()

  // Admins can always see the room dialog
  const canChangeRoom =
    currentUser.isAdmin ||
    cubit.currentUserIsInSection ||
    cubit.currentUserIsInstructor ||
    currentUser.isTaToInstructor(cubit.section.data.instructorUserId)

  const openRoomDialog = useCallback(() => {
    // never show the dialog if the user is not in the room
    if (!canChangeRoom) return
    showDialog(() => <RoomsDialog cubit={cubit} />)
  }, [cubit, showDialog, canChangeRoom])

  return (
    <div className="mb-2 w-full py-5 text-center">
      <AutomaticRandomGroupingHeader />
      <div className="flex w-full flex-grow-0 flex-row justify-center justify-items-center">
        <div
          data-testid="student-assignment-schedule-area"
          className="flex w-1/2 flex-grow cursor-pointer justify-items-end"
        >
          <div className="flex-grow"></div>
          <div
            className="flex-grow-0 cursor-pointer border-r border-border-grey px-2 py-5 text-center sm:px-9"
            onClick={openRoomDialog}
          >
            <h3 className="text-body-small mb-3 flex-grow text-grey-text">
              {t('student_assignment.group_details')}
            </h3>
            <p className="text-title-large mb-2">
              {cubit.roomState.roomStateName}
            </p>

            <div className="flex flex-row justify-center -space-x-3 text-xs">
              <BreakoutUserAvatarStack
                radius={14}
                spacing={-3}
                users={cubit.roomState.users}
              />
            </div>
          </div>
        </div>
        <div className="flex w-1/2 flex-grow justify-items-start">
          <div className="flex-grow-0  px-2 py-5 text-center sm:px-9">
            <h3 className="text-body-small mb-3 text-grey-text">
              {t('student_assignment.scheduled_date')}
            </h3>
            {cubit.roomState.scheduledAtDate && (
              <div
                className="cursor-pointer"
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openRoomDialog()
                  }
                }}
                onClick={openRoomDialog}
              >
                <p
                  className="text-title-large mb-2"
                  data-testid="student-assignment-scheduled"
                >
                  {cubit.roomState.scheduledAtDate.toFormat('LLL d, yyyy')}
                </p>
                <p className="text-xs">
                  {cubit.roomState.scheduledAtDate.toLocaleString(
                    DateTime.TIME_SIMPLE
                  )}
                </p>
              </div>
            )}
            {!cubit.roomState.scheduledAtDate && canChangeRoom && (
              <BreakoutButton
                data-testid="schedule-now-button"
                size="medium"
                kind="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  showDialog(() => (
                    <RoomStateForm
                      onSave={() => {
                        popDialog()
                      }}
                      cubit={cubit}
                    />
                  ))
                }}
              >
                {t('student_assignment.schedule_now')}
              </BreakoutButton>
            )}
          </div>
          <div className="flex-grow"></div>
        </div>
      </div>
    </div>
  )
})

const AutomaticRandomGroupingHeader = observer(
  function StudentAssignmentStep1() {
    const cubit = useStudentAssignmentCubit()
    const { t } = useTranslation()
    if (
      cubit.assignment.groupingType !== AssignmentGroupingType.automaticRandom
    ) {
      return null
    }
    return (
      <div className="mb-2 flex justify-center">
        <div className="text-body-medium flex items-center rounded-full bg-core-secondary px-4 py-2 text-core-on-secondary">
          <span className="mr-1">
            <CircleAlert size={15} />
          </span>
          {t('student_assignment.uses_automatic_randomized_grouping')}
        </div>
      </div>
    )
  }
)

const NeedsARoom = observer(function StudentAssignmentStep1() {
  const cubit = useStudentAssignmentCubit()
  const { showDialog } = useDialogs()
  const { t } = useTranslation()

  const currentUser = useBreakoutUser()

  // Admins can always see the room dialog
  const canChangeRoom =
    currentUser.isAdmin ||
    cubit.currentUserIsInSection ||
    cubit.currentUserIsInstructor

  if (
    cubit.assignment.groupingType === AssignmentGroupingType.automaticRandom
  ) {
    return (
      <div className="mb-2 w-full py-5 text-center">
        <AutomaticRandomGroupingHeader />
        <div className="text-body-medium text-grey-text">
          {t('student_assignment.step_1')}
        </div>
        <h3 className="text-title-large mt-3">
          {t('student_assignment.group_tbd')}
        </h3>

        <div className="min-h-[80px] overflow-hidden">
          <p className="text-body-medium mx-auto mt-1 max-w-[23rem]">
            {t('student_assignment.group_tbd_description')}
          </p>
          <BreakoutButton
            data-testid="join-group-button"
            onClick={() => showDialog(() => <RoomsDialog cubit={cubit} />)}
            className="text-label-large w-50 mt-4 inline-block rounded-2xl bg-core-secondary px-8 py-2 text-core-on-secondary outline-none"
          >
            {t('student_assignment.manually_join_group')}
          </BreakoutButton>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="text-body-medium mt-4 text-grey-text">
          {t('student_assignment.step_1')}
        </div>
        <h3 className="text-title-large mt-3">
          {t('student_assignment.join_or_create_group')}
        </h3>

        <div className="min-h-[80px] overflow-hidden ">
          <p className="text-body-medium mx-auto mt-1 max-w-[23rem]">
            {t('student_assignment.join_or_create_group_description')}
          </p>
          {canChangeRoom && (
            <BreakoutButton
              kind="primary"
              size="large"
              data-testid="join-group-button"
              onClick={() => showDialog(() => <RoomsDialog cubit={cubit} />)}
              className="text-label-large mb-1 mt-4 inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-core-on-primary"
            >
              {t('student_assignment.join_group')}
            </BreakoutButton>
          )}
        </div>
      </div>
    )
  }
})

export const StudentAssignmentStep1 = observer(function StudentAssignmentStep1(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  const cubit = useStudentAssignmentCubit()
  return (
    <div {...props}>
      {cubit.roomStateId && <ExistingRoom />}
      {!cubit.roomStateId && <NeedsARoom />}
    </div>
  )
})
