import { BookClose } from 'components/icons/BookClose'
import { Money } from 'components/icons/Money'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { Search } from 'components/icons/Search'
import { SendIcon } from 'components/icons/Send'
import { Actions, Header, Headline } from 'pages/layout/TwoColumn'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SectionInvitationDialog } from './SectionInvitationDialog'
import {
  InstructorSectionView,
  OrganizationInvoiceStatus,
  SectionRequestState,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { EndClassDialog } from '../dialogs/EndClassDialog'
import { useBreakoutUser } from 'hooks/profile'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { InvoicedClassDialog } from '../dialogs/InvoicedClassDialog'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import type { MenuButtonProps } from 'components/breakout/FloatingIconActionButton'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { MoreHorizontalFill } from 'components/icons/MoreHorizontalFill'
import { XSmallIcon } from 'components/icons/XSmall'
import classNames from 'classnames'
import { PencilIcon } from 'components/icons/Pencil'
import { CreateOrEditSectionDialog } from '../classes/CreateOrEditSectionDialog'
import { InvoiceRequestDialog } from '../InvoiceRequestDialog'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { CheckIcon } from 'components/icons/Check'
import { toast } from 'react-hot-toast'

const ModeSwitchButton = observer(function ModeSwitchButton() {
  const { t } = useTranslation()
  const cubit = useInstructorSectionCubit()

  const selectedView = cubit.selectedView

  const section = cubit.section
  const assignmentCount =
    section && cubit.assignmentCount !== undefined
      ? `(${cubit.assignmentCount})`
      : ''
  const studentCount = section ? `(${cubit.userCount})` : ''

  const isStudents = selectedView === InstructorSectionView.students

  const tooltipContent = isStudents
    ? `${t('instructor_class.cases')} ${assignmentCount}`
    : `${t('instructor_class.students')} ${studentCount}`

  return (
    <BreakoutTooltip ariaHidden content={tooltipContent}>
      <div>
        <BreakoutButton
          aria-live="assertive"
          title={tooltipContent}
          kind="secondary"
          size="large"
          icon={isStudents ? <BookClose /> : <PeopleGroup />}
          onClick={() => {
            if (isStudents) {
              cubit.changeSelectedView(InstructorSectionView.cases)
            } else {
              cubit.changeSelectedView(InstructorSectionView.students)
            }
          }}
        />
      </div>
    </BreakoutTooltip>
  )
})

export const InstructorClassPageHeader = observer(
  function InstructorClassPageHeader() {
    const cubit = useInstructorSectionCubit()
    const { t } = useTranslation()
    const store = useRootStore()
    const { showDialog } = useDialogs()
    const user = useBreakoutUser()

    const section = cubit.section

    const sectionHasAssignments =
      section.isLoaded && cubit.sectionAssignmentsSorted.length > 0

    // assume completed to limit options if not loaded
    const sectionCompleted =
      !section.hasData || section.data.sectionState === SectionState.completed

    const sectionInvoiced = section.data.invoiced ?? false

    // if user doesn't own the section they can't edit it
    const readOnly =
      section.data.instructorUserId !== user.uid &&
      section.data.shareable === true

    const invoiceStateDisabled =
      cubit.activeSectionRequest &&
      [SectionRequestState.pending, SectionRequestState.approved].includes(
        cubit.activeSectionRequest.data.sectionRequestState
      )

    const disableRequestToInvoice =
      invoiceStateDisabled || sectionCompleted || readOnly

    const hasApprovedInvoiceRequest =
      cubit.activeSectionRequest?.data.sectionRequestState ===
      SectionRequestState.approved
    return (
      <Header data-testid="instructor-class-header">
        <Headline
          className="text-display-medium font-display min-w-[35%] cursor-pointer"
          aria-label={[
            section.data.className,
            t('instructor_class.back_to_class_list'),
          ].join(', ')}
          onClick={() => store.navigateTo('instructorClasses')}
        >
          <div className="flex w-full flex-row items-center ">
            <div className="flex-1 pr-1">
              <ChevronLeft aria-hidden size={24} />
            </div>
            <BreakoutTooltip content={section.data.className}>
              <p
                className="w-full flex-auto truncate"
                data-testid="instructor-class-header-section-name"
              >
                {section.hasData ? section.data.className : 'Loading...'}
              </p>
            </BreakoutTooltip>
          </div>
          <div className="flex items-center">
            <BreakoutTooltip content={section.data.sectionName}>
              <strong
                className="text-body-medium  ml-6 truncate pr-2"
                title={section.data.sectionName}
              >
                {section.data.sectionName}
              </strong>
            </BreakoutTooltip>
            {hasApprovedInvoiceRequest && (
              <BreakoutPill
                label={t('instructor_class.invoiced_section')}
                icon={<CheckIcon size={15} className="text-core-success" />}
              />
            )}
          </div>

          <ImpersonationInfo />
        </Headline>
        <Actions>
          {section && !sectionCompleted && !readOnly && (
            <BreakoutButton
              kind="secondary"
              size="large"
              tooltip={t('instructor_class.invite_students_tooltip')}
              onClick={() => {
                showDialog(() => <SectionInvitationDialog cubit={cubit} />)
              }}
            >
              <SendIcon className="mr-1 inline" size={14} />
              {t('instructor_class.invite_students')}
            </BreakoutButton>
          )}
          {!sectionCompleted &&
            sectionHasAssignments &&
            cubit.sectionAssignmentsSorted.length > 0 && (
              <BreakoutButton
                size="large"
                onClick={() => {
                  store.navigateTo('instructorLibrary', undefined, {
                    sectionId: section.id,
                  })
                }}
              >
                <Search className="mr-1 inline" size={14} />
                {t('instructor_class.explore_catalog')}
              </BreakoutButton>
            )}

          <ModeSwitchButton />

          <FloatingIconActionButton
            kind="secondary"
            className="flex justify-end"
            dropdownClassName="w-[220px] "
            menuBottom
            buttonClassName="!rounded-2xl"
            Icon={() => <MoreHorizontalFill className="rotate-90" />}
            actions={
              [
                !!cubit.sectionOrganization &&
                  cubit.sectionOrganization.data.organizationInvoiceStatus ===
                    OrganizationInvoiceStatus.adminApproved && {
                    text: t('instructor_class.request_to_invoice'),
                    disabled: disableRequestToInvoice,
                    tooltipPlacement: 'left',
                    tooltip:
                      cubit.promotions.length > 0 &&
                      t('instructor_class.promotions_exist'),
                    Icon: () => <SendIcon size={16} />,
                    onClick: () => {
                      showDialog(() => (
                        <InvoiceRequestDialog
                          onCreate={() => {
                            toast.success(
                              t('instructor_class.invoice_request_success')
                            )
                          }}
                          createSectionInvoiceRequest={({
                            sectionRequestAssignmentCount,
                            sectionRequestStudentCount,
                          }) =>
                            cubit.createSectionInvoiceRequest({
                              sectionId: cubit.section!.id,
                              sectionRequest: {
                                sectionRequestAssignmentCount,
                                sectionRequestStudentCount,
                                requestedAt: new Date(),
                                organizationId: cubit.sectionOrganization!.id,
                                sectionId: cubit.section!.id,
                              },
                            })
                          }
                        />
                      ))
                    },
                  },
                {
                  text: t('instructor_class.edit_class'),
                  Icon: () => <PencilIcon size={16} />,
                  disabled: !section || sectionCompleted || readOnly,
                  onClick: () => {
                    showDialog(({ remove }) => (
                      <CreateOrEditSectionDialog
                        createSectionInvoiceRequest={
                          cubit.createSectionInvoiceRequest
                        }
                        hasApprovedInvoiceRequest={hasApprovedInvoiceRequest}
                        section={section}
                        saveSection={cubit.updateSection}
                        redeemPromotions={cubit.redeemPromotions}
                        onSave={({ invoiceCreated }) => {
                          remove()
                          toast.success(
                            invoiceCreated
                              ? t(
                                  'instructor_class.section_updated_invoice_created'
                                )
                              : t('instructor_class.section_updated')
                          )
                        }}
                      />
                    ))
                  },
                },
                {
                  Icon: () => (
                    <XSmallIcon className="text-core-error" size={16} />
                  ),
                  disabled: sectionCompleted || readOnly,
                  onClick: () => {
                    showDialog(() => <EndClassDialog cubit={cubit} />)
                  },
                  text: (
                    <strong
                      className={classNames(
                        'text-label-medium',
                        !sectionCompleted && !readOnly
                          ? 'text-core-error'
                          : 'text-on-surface-disabled'
                      )}
                    >
                      {t('instructor_class.end_class')}
                    </strong>
                  ),
                },
              ].filter(Boolean) as MenuButtonProps[]
            }
          />

          {user.isCorre && (
            <FloatingIconActionButton
              kind="secondary"
              className="z-10 flex justify-end"
              aria-label={t('buttons.admin_actions')}
              menuBottom={true}
              Icon={AdminPanelSettingsIcon}
              actions={[
                {
                  text: sectionInvoiced
                    ? t('instructor_class.invoiced_tooltip')
                    : t('instructor_class.make_invoiced_tooltip'),
                  Icon: Money,
                  onClick: () => {
                    if (!sectionInvoiced)
                      showDialog(() => (
                        <InvoicedClassDialog
                          invoicedSection={cubit.invoicedSection}
                        />
                      ))
                  },
                },
                // Admins cannot edit sections
              ]}
            />
          )}
        </Actions>
      </Header>
    )
  }
)
