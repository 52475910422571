import type { Cubit } from '@breakoutlearning/firebase-repository/cubits/core'
import type { DependencyList } from 'react'
import { useEffect, useMemo } from 'react'

export function useCubitBuilder<T extends Cubit<object>>(
  builder: () => T,
  dependencies: DependencyList,
  options = {
    preventInitialize: false,
  }
) {
  const cubit = useMemo(() => {
    return builder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  useEffect(() => {
    if (!options?.preventInitialize) cubit.initialize()
    return () => {
      cubit.dispose()
    }
  }, [cubit, options.preventInitialize])

  return cubit
}
