export function LockOn({
  color = 'currentColor',
  fillColor = 'none',
  className,
  size = 24,
  strokeWidth = 2,
  ...otherProps
}: {
  fillColor?: string
  size?: number | string
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fillColor}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <rect x="3" y="10" width="18" height="12" rx="2" />
      <path d="M6 6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v4H6V6z" />
    </svg>
  )
}
