import classNames from 'classnames'
import { ChevronLeft } from './icons/ChevronLeft'

export function Breadcrumb({
  children,
  className,
  onClick,
  'aria-label': ariaLabel,
}: {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  'aria-label'?: string
}) {
  return (
    <div
      aria-label={ariaLabel}
      className={classNames(
        className,
        'flex flex-row items-center',
        onClick && 'cursor-pointer'
      )}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? 'button' : undefined}
      onKeyDown={(e) => {
        if (onClick && (e.key === 'Enter' || e.key === ' ')) {
          onClick()
        }
      }}
    >
      {onClick && <ChevronLeft className="inline-block flex-shrink-0" />}
      <strong className="text-headline-large text-core-on-tertiary md:text-4xl">
        {children}
      </strong>
    </div>
  )
}
