import { NavLink } from './NavLink'
import { Home } from '../../components/icons/Home'
import { Search } from '../../components/icons/Search'
import { PeopleMultiple } from '../../components/icons/PeopleMultiple'
import { PeopleGroup } from '../../components/icons/PeopleGroup'
import { Dashboard } from '../../components/icons/Dashboard'
import { BookOpen } from '../../components/icons/BookOpen'
import { GridIcon } from '../../components/icons/Grid'
import { Person } from '../../components/icons/Person'
import { SignOut } from '../../components/icons/SignOut'
import { useRootStore } from 'hooks/rootStore'
import { useTranslation } from 'react-i18next'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { ThreeLineHorizontalIcon } from '../../components/icons/ThreeLineHorizontal'
import { useCallback, useState } from 'react'
import { KeyCapIcon } from '../../components/icons/KeyCap'
import { BreakoutTooltip } from '../../components/design-system/BreakoutTooltip'
import { BreakoutDrawer } from '../../components/design-system/BreakoutDrawer'
import { BookClose } from 'components/icons/BookClose'
import { Environment, environment } from 'config/environment'
import { Forum } from 'components/icons/Forum'
import { useRepository } from 'hooks/auth'
import { BankIcon } from 'components/icons/BankIcon'
import { useDialogs } from 'hooks/dialogs'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { GraduationCapIcon } from 'components/icons/GraduationCap'

const activeClassName = 'bg-surface'
const navLinkClassName = `inline-block cursor-pointer rounded-lg p-2 hover:bg-surface`

function AdminNavigation() {
  const { t } = useTranslation()
  const user = useBreakoutUser()

  return (
    <>
      <div className="text-center md:mb-8 ">
        <NavLink
          tooltip={t('navigation.home')}
          to="home"
          className={navLinkClassName}
          activeClassName={activeClassName}
          activeOn="home, assignment, assignmentWithRoom"
          data-testid="home"
        >
          <Home aria-hidden size={25} className="inline" />
        </NavLink>
      </div>
      <hr className="mx-auto w-12" />
      <div className="hidden md:block">
        <div className="mb-14 mt-6 text-center">
          <NavLink
            tooltip={t('navigation.instructor_classes')}
            to="instructorClasses"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorClass, instructorClasses, instructorClassAssignment"
            data-testid="instructorClasses"
          >
            <GraduationCapIcon aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <div className="mb-14 text-center">
          <NavLink
            tooltip={t('navigation.instructor_library')}
            to="instructorLibrary"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorLibrary, instructorSlideDeck"
            data-testid="instructorLibrary"
          >
            <Search aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <div className="mb-14 text-center">
          <NavLink
            tooltip={t('navigation.instructor_assistants')}
            to="instructorAssistants"
            className={navLinkClassName}
            activeClassName={activeClassName}
            data-testid="instructorAssistants"
          >
            <PeopleMultiple aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <BreakoutTooltip content={t('navigation.admin_dashboard')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_dashboard')}
              to="adminDashboard"
              className={navLinkClassName}
              activeClassName={activeClassName}
              data-testid="adminDashboard"
              showWhen={user.isCorre}
            >
              <Dashboard aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_library')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_library')}
              to="adminLibrary"
              className={navLinkClassName}
              activeClassName={activeClassName}
              data-testid="adminLibrary"
              showWhen={user.isAuthor}
            >
              <BookOpen aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_catalogs')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_catalogs')}
              to="adminCatalogs"
              className={navLinkClassName}
              activeClassName={activeClassName}
              data-testid="adminCatalogs"
              showWhen={user.isEditor}
            >
              <GridIcon aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_users')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_users')}
              to="adminUsers"
              className={navLinkClassName}
              activeClassName={activeClassName}
              data-testid="adminUsers"
              showWhen={user.isCorre}
            >
              <PeopleGroup aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_demos')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_demos')}
              to="adminDemos"
              className={navLinkClassName}
              activeClassName={activeClassName}
              data-testid="adminDemos"
              showWhen={user.isCorre}
            >
              <Forum aria-hidden size={35} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
      </div>
    </>
  )
}

function FacultyNavigation() {
  const { t } = useTranslation()
  const user = useBreakoutUser()

  return (
    <>
      <div className="text-center md:mb-8">
        <NavLink
          tooltip={t('navigation.home')}
          to="home"
          className={navLinkClassName}
          activeClassName={activeClassName}
          activeOn="home, instructorClass, instructorClasses, instructorClassAssignment"
          data-testid="instructor-home"
        >
          <Home aria-hidden size={25} className="inline" />
        </NavLink>
      </div>
      <hr className="mx-auto w-12" />
      <div className="hidden md:block">
        <div className="mb-14  mt-6 text-center">
          <NavLink
            tooltip={t('navigation.instructor_library')}
            to="instructorLibrary"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorLibrary, instructorSlideDeck"
            data-testid="instructor-library"
          >
            <Search aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <div className="mb-14 text-center">
          <NavLink
            tooltip={t('navigation.student_view')}
            to="instructorStudentView"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorStudentView, assignment, assignmentWithRoom"
            data-testid="instructor-student-view"
          >
            <BookClose aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        {user.isInstructor && (
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.instructor_assistants')}
              to="instructorAssistants"
              className={navLinkClassName}
              activeClassName={activeClassName}
              activeOn="instructorAssistants"
              data-testid="instructor-assistants"
            >
              <PeopleMultiple aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        )}
      </div>
    </>
  )
}

function StudentNavigation() {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-center md:mb-8">
        <NavLink
          tooltip={t('navigation.home')}
          to="home"
          className="inline-block cursor-pointer rounded-lg p-2 hover:bg-surface"
          activeClassName="bg-surface"
          activeOn="home, assignment , assignmentWithRoom"
        >
          <Home aria-hidden size={25} className="inline" />
        </NavLink>
      </div>
    </>
  )
}

function MobileNavigationDrawer({ closeDrawer }: { closeDrawer: () => void }) {
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const breakoutUser = useBreakoutUser()

  const linkClass =
    'inline-block w-full cursor-pointer py-6 px-4 hover:bg-surface'
  const iconClass = 'mr-3 inline'

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="my-5 text-center">
        <img
          src="/assets/images/logo.png"
          tabIndex={0}
          width={48}
          className="inline"
          alt={t('breakout_learning')}
        />
      </div>
      <div className="h-full w-full text-left text-sm">
        <NavLink
          to="home"
          onClick={closeDrawer}
          className={linkClass}
          activeClassName="bg-surface"
          activeOn="home, assignment"
        >
          <Home size={25} className={iconClass} />
          {t('navigation.home')}
        </NavLink>
        {breakoutUser.isFaculty && (
          <>
            <NavLink
              to="instructorClasses"
              onClick={closeDrawer}
              className={linkClass}
              activeClassName="bg-surface"
            >
              <GraduationCapIcon aria-hidden size={25} className="inline" />
              {t('navigation.instructor_classes')}
            </NavLink>
            <NavLink
              to="instructorStudentView"
              onClick={closeDrawer}
              className={linkClass}
              activeClassName="bg-surface"
            >
              <BookClose aria-hidden size={25} className={iconClass} />
              {t('navigation.student_view')}
            </NavLink>
            {breakoutUser.isInstructor && (
              <NavLink
                to="instructorAssistants"
                onClick={closeDrawer}
                className={linkClass}
                activeClassName="bg-surface"
              >
                <PeopleMultiple aria-hidden size={25} className={iconClass} />
                {t('navigation.instructor_assistants')}
              </NavLink>
            )}
          </>
        )}
      </div>
      <div className="w-full text-left text-sm">
        {environment() !== Environment.production && (
          <NavLink
            to="designSystem"
            onClick={closeDrawer}
            className={linkClass}
            activeClassName="bg-surface"
          >
            <KeyCapIcon size={25} className={iconClass} />
            {t('internal.design_system')}
          </NavLink>
        )}
        <NavLink
          to="profile"
          onClick={closeDrawer}
          className={linkClass}
          activeClassName="bg-surface"
        >
          <Person size={25} className={iconClass} />
          {t('navigation.profile')}
        </NavLink>
        <a
          className={linkClass}
          onClick={() => {
            closeDrawer()
            rootStore.navigateTo('logout')
          }}
        >
          <SignOut size={25} className={iconClass} />
          {t('navigation.logout')}
        </a>
      </div>
    </div>
  )
}

const MobileTopNavigation = observer(function MobileTopNavigation() {
  const [open, setOpen] = useState(false)
  const [mounted, setMounted] = useState(false)
  const { t } = useTranslation()

  const openDrawer = useCallback(() => {
    setOpen(true)
    setMounted(true)
  }, [])
  const closeDrawer = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      setMounted(false)
    }, 200)
  }, [])

  return (
    <div
      className={`
      flex
      flex-row
      items-center
      justify-between
      border-b
      border-light-grey-text
      py-3
      md:hidden
  `}
    >
      <div className="w-[25%] flex-none text-left">
        <span className="mx-5"> </span>
        {/* empty for now, necessary for logo to be in the middle */}
      </div>
      <div className="mx-5 flex-1 text-center">
        <img
          src="/assets/images/logo.png"
          width={48}
          className="inline"
          alt={t('breakout_learning')}
          tabIndex={0}
        />
      </div>
      <div className="w-[25%] flex-none text-right">
        <button
          aria-hidden
          aria-label="Open Navigation Drawer"
          onClick={openDrawer}
        >
          <ThreeLineHorizontalIcon className="mx-5 inline" />
        </button>
        <BreakoutDrawer
          open={open}
          onClose={closeDrawer}
          className=""
          placement="right"
        >
          {mounted && <MobileNavigationDrawer closeDrawer={closeDrawer} />}
        </BreakoutDrawer>
      </div>
    </div>
  )
})

const BrowserNavigation = observer(function Navigation() {
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const breakoutUser = useBreakoutUser()
  const {
    featureFlags: {
      data: { showOrganizationsUI },
    },
  } = useRepository()

  const { showDialog } = useDialogs()

  return (
    <div
      role="navigation"
      className={`
      mb-5
      box-border
      hidden
      items-center
      justify-start
      rounded-3xl bg-core-tertiary
      text-center

      md:mb-0 md:flex md:h-full md:w-[4%] md:min-w-[100px] md:flex-shrink-0 md:flex-grow-0 md:flex-col
      `}
    >
      <div className="my-9 ml-5 text-center md:ml-0">
        <img
          src="/assets/images/logo.png"
          width={48}
          className="inline"
          data-testid="logo"
          tabIndex={0}
          alt={t('breakout_learning_logo')}
        />
      </div>
      <div className="isolate w-full pt-2 md:ml-0 md:mt-14 md:h-full md:overflow-y-auto">
        {breakoutUser.isInternal && <AdminNavigation />}
        {breakoutUser.isFaculty && <FacultyNavigation />}
        {breakoutUser.isStudent && <StudentNavigation />}
        <BreakoutTooltip content={t('navigation.organizations')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.organizations')}
              to="organizations"
              className={navLinkClassName}
              activeClassName={activeClassName}
              data-testid="organizations"
              showWhen={
                showOrganizationsUI &&
                (breakoutUser.isCorre ||
                  breakoutUser.organizationAdmins.length > 0)
              }
            >
              <BankIcon aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
      </div>

      <div className="flex w-full flex-grow flex-row justify-end pt-8 md:block md:w-auto md:flex-none">
        {environment() !== Environment.production && breakoutUser.isAdmin && (
          <div className="mr-5 text-center md:mx-auto md:mb-5">
            <NavLink
              tooltip={t('internal.design_system')}
              to="designSystem"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <KeyCapIcon aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        )}
        <div className="mr-5 text-center md:mx-auto md:mb-5">
          <NavLink
            to="profile"
            className={navLinkClassName}
            activeClassName={activeClassName}
            tooltip={t('navigation.profile')}
          >
            <Person aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <div className="mr-5 text-center md:mx-auto md:mb-9">
          <BreakoutTooltip content={t('navigation.logout')}>
            <a
              href={'/logout'}
              aria-label={t('navigation.logout')}
              className={navLinkClassName}
              onClick={(e) => {
                e.preventDefault()
                showDialog(() => {
                  return (
                    <ConfirmationDialog
                      text={t('navigation.logout_confirmation')}
                      subtitle={t('navigation.logout_confirmation_subtitle')}
                      buttonText={t('navigation.logout_confirmation_button')}
                      onConfirm={async () => {
                        rootStore.navigateTo('logout')
                      }}
                    />
                  )
                })
              }}
              data-testid="logOut"
            >
              <SignOut aria-hidden size={25} className="inline" />
            </a>
          </BreakoutTooltip>
        </div>
      </div>
    </div>
  )
})

export function Navigation() {
  return (
    <>
      <MobileTopNavigation />
      <BrowserNavigation />
    </>
  )
}
