import type { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { CircleAlert } from 'components/icons/CircleAlert'
import { useRepository } from 'hooks/auth'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const GroupConfirmAddUserMultiGroup = observer(
  function GroupConfirmAddUserMultiGroup({
    roomState,
    userId,
    currentGroups,
    addUserToGroup,
    onClick,
  }: {
    userId: string
    roomState: RoomState
    currentGroups: string[]
    addUserToGroup: InstructorAssignmentCubit['addUserToGroup']
    onClick?: () => void
  }) {
    const repository = useRepository()
    const user = repository.userStore.getUser(userId)
    const { t } = useTranslation()
    const checkboxRef = useRef<HTMLInputElement>(null)

    return (
      <Dialog size="sm" className="!bg-core-tertiary">
        <DialogCloseButton />
        <div className="text-headline-medium text-center ">
          {t('instructor.add_student_to_the_group')}
        </div>
        <div className="text-body-medium mt-3 text-center text-on-surface">
          {t('instructor.add_student_to_the_group_description')}
        </div>
        <div className="text-label-small mt-2 flex items-center justify-center text-core-error">
          <CircleAlert size={15} className="mr-1" />{' '}
          {t('instructor.student_in_multiple_groups')}
        </div>
        <div className="divide-y-1 divide-light-grey">
          <div className="flex flex-row items-center justify-between py-4">
            <div className="text-body-medium text-grey-text">
              {t('instructor.group_user_student_name')}
            </div>
            <div className="text-label-medium flex flex-row items-center gap-2">
              <BreakoutUserAvatar user={user} radius={12} />

              {user.fullName}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between py-4">
            <div className="text-body-medium text-grey-text">
              {t('instructor.current_groups')}
            </div>
            <div className="text-label-medium">{currentGroups.join(', ')}</div>
          </div>
        </div>

        <div className="mt-5">
          <BreakoutButton
            size="large"
            fullWidth
            kind="primary"
            onClick={() => {
              addUserToGroup({
                roomId: roomState.id,
                userId,
                isGroupLeader: checkboxRef.current?.checked ?? false,
              })
              onClick?.()
            }}
          >
            {t('instructor.confirm')}
          </BreakoutButton>
        </div>
      </Dialog>
    )
  }
)
