import { useMemo } from 'react'
import { StorageUrlManager, StorageUrlContext } from 'hooks/storageUrl'

export const StorageUrlContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const manager = useMemo(() => new StorageUrlManager(), [])

  return (
    <StorageUrlContext.Provider value={{ manager }}>
      {children}
    </StorageUrlContext.Provider>
  )
}
