import { observer } from 'mobx-react-lite'
import { Spinner } from 'components/Spinner'
import { ListItem } from './ListItem'
import { EmptyListPlaceholder } from './EmptyListPlaceholder'
import type { StudentLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import { BreakoutCard } from 'components/design-system/BreakoutCard'

export const LibraryObjectList = observer(function LibraryObjectList({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  if (cubit.isLoading) {
    return (
      <div className="flex h-full min-h-[65vh] w-full flex-wrap items-center justify-center ">
        <Spinner />
      </div>
    )
  }

  if (cubit.filteredLibraryObjects.length === 0) {
    return <EmptyListPlaceholder />
  }

  return (
    <BreakoutCard.Grid data-testid="library-object-list">
      {cubit.filteredLibraryObjects.map((libraryObject) => (
        <ListItem
          libraryObject={libraryObject}
          key={libraryObject.libraryObjectId}
        />
      ))}
    </BreakoutCard.Grid>
  )
})
