import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { SlideDeckRubricTestResult } from '@breakoutlearning/firebase-repository/models/SlideDeckRubricTestResult'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { RubricType } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import classNames from 'classnames'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { CheckIcon } from 'components/icons/Check'
import { Close } from 'components/icons/Close'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { getRubricBgColorByScore, getRubricScoreLabel } from 'util/rubric'

export const RubricScoreWidget = observer(function RubricScoreWidget({
  rubric,
  rubricResult,
  showTitle = false,
}: {
  rubric: SlideRubric
  rubricResult: RoomStateRubricResult | SlideDeckRubricTestResult
  showTitle?: boolean
}) {
  const { t } = useTranslation()

  let boxElement: JSX.Element
  if (rubric.rubricType === RubricType.blooms) {
    boxElement = (
      <BreakoutTooltip content={rubric.data.rubric} placement="top">
        <div className="group relative">
          <div
            className={classNames(
              'flex h-6 w-6 items-center justify-center rounded bg-opacity-30',
              getRubricBgColorByScore({
                type: RubricType.blooms,
                score: rubricResult.data.score,
              })
            )}
          >
            <strong
              className={classNames('text-label-small text-shadow-white', {
                'text-breakout-color-scale-0': rubricResult.data.score === 0,
                'text-breakout-color-scale-1': rubricResult.data.score === 1,
                'text-breakout-color-scale-2': rubricResult.data.score === 2,
                'text-breakout-color-scale-3': rubricResult.data.score === 3,
                'text-breakout-color-scale-4': rubricResult.data.score === 4,
                'text-breakout-color-scale-5': rubricResult.data.score === 5,
                'text-breakout-color-scale-6': rubricResult.data.score === 6,
              })}
            >
              {rubricResult.data.score}
            </strong>
          </div>
        </div>
      </BreakoutTooltip>
    )
  } else {
    boxElement = (
      <BreakoutTooltip content={rubric.data.rubric} placement="top">
        <div className="group relative">
          <div
            className={classNames(
              'flex h-6 w-6 items-center justify-center rounded bg-opacity-30',
              getRubricBgColorByScore({
                type: RubricType.passFail,
                score: rubricResult.data.score,
              })
            )}
          >
            {/*  if score is 1 show CheckIcon otherwise show CloseIcon */}
            {rubricResult.data.score === 1 ? (
              <CheckIcon className="w-4 text-breakout-dark-green text-shadow-white" />
            ) : (
              <Close className="w-4 text-breakout-red text-shadow-white" />
            )}
          </div>
        </div>
      </BreakoutTooltip>
    )
  }

  if (!showTitle) return boxElement

  const score = rubricResult.data.score

  const labelText = getRubricScoreLabel(
    t,
    rubric.rubricType!,
    rubricResult.data.score,
    {
      rubricLabel0: rubric.data.rubricLabel0,
      rubricLabel1: rubric.data.rubricLabel1,
    }
  )

  const passFail = rubric.rubricType === RubricType.passFail

  return (
    <div className="flex flex-row items-center gap-1">
      {boxElement}
      <strong
        className={classNames('text-label-small text-nowrap', {
          'text-breakout-red': passFail && score === 0,
          'text-breakout-dark-green': passFail && score === 1,
          'text-breakout-color-scale-0': !passFail && score === 0,
          'text-breakout-color-scale-1': !passFail && score === 1,
          'text-breakout-color-scale-2': !passFail && score === 2,
          'text-breakout-color-scale-3': !passFail && score === 3,
          'text-breakout-color-scale-4': !passFail && score === 4,
          'text-breakout-color-scale-5': !passFail && score === 5,
          'text-breakout-color-scale-6': !passFail && score === 6,
        })}
      >
        {labelText}
      </strong>
    </div>
  )
})
