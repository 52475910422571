import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutSwitch } from 'components/design-system/BreakoutSwitch'
import { useBreakoutUser } from 'hooks/profile'
import { useSettings } from 'hooks/settings'
import { setLanguage as updateLanguageInLocalStorage } from 'i18n/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDarkModeSettings } from 'util/darkMode'

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'pl', label: 'Polski' },
]

export const ProfileSettings = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)
  const { animationsEnabled, updateAnimationsEnabled } = useSettings()
  const { darkMode, setDarkMode } = useDarkModeSettings()
  const breakoutUser = useBreakoutUser()

  const changeLanguage = useCallback((newLanguage: string) => {
    setLanguage(newLanguage)
    updateLanguageInLocalStorage(newLanguage)
  }, [])

  const changeAnimation = useCallback(() => {
    updateAnimationsEnabled(!animationsEnabled)
  }, [animationsEnabled, updateAnimationsEnabled])

  return (
    <div>
      <div className="text-headline-large text-core-on-tertiary">
        {t('profile.settings')}
      </div>
      <div className="text-body-large mt-1 text-core-on-tertiary">
        {t('profile.settings_description')}
      </div>
      <div className="mt-6 space-y-3">
        {/* todo: do we want this for all internal users */}
        {breakoutUser.isAdmin && (
          <BreakoutSelect
            testId="language-select"
            kind="secondary"
            options={languageOptions}
            onChange={changeLanguage}
            value={language}
          />
        )}
        <ProfileSettingToggle
          label={t('profile.animations')}
          checked={animationsEnabled}
          onChange={() => changeAnimation()}
          dataTestId="animations-toggle"
        />
        {/* todo: do we want this for all internal users */}
        {breakoutUser.isAdmin && (
          <ProfileSettingToggle
            label={t('profile.dark_mode')}
            checked={darkMode}
            onChange={setDarkMode}
          />
        )}
      </div>
    </div>
  )
}

const ProfileSettingToggle = ({
  label,
  checked,
  onChange,
  dataTestId,
}: {
  label: string
  checked: boolean
  onChange: (value: boolean) => void
  dataTestId?: string
}) => {
  return (
    <div className="flex h-[52px] items-center justify-between rounded-2xl bg-core-secondary px-4">
      <div className="text-body-medium">{label}</div>
      <BreakoutSwitch
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        testId={dataTestId}
      />
    </div>
  )
}
