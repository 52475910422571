import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Trans, useTranslation } from 'react-i18next'
import { ArrowRepeatIcon } from 'components/icons/ArrowRepeat'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useEffect, useState } from 'react'
import { Spinner } from 'components/Spinner'
import { useDialogs } from 'hooks/dialogs'
import { toast } from 'react-hot-toast'
import { captureException } from '@sentry/react'
import { TriangleAlertFillIcon } from 'components/icons/TriangleAlertFill'

const RESET_CONFIG = {
  single: {
    titleKey: 'instructor_assignment.reset_group',
    descriptionKey: 'instructor_assignment.reset_one_description',
    proceedTextKey: 'instructor_assignment.reset_one_proceed_text',
    resettingTextKey: 'instructor_assignment.resetting_group',
    failTimeoutTextKey: 'instructor_assignment.reset_failed_timeout',
    failOtherTextKey: 'instructor_assignment.reset_failed',
  },
  multiple: {
    titleKey: 'instructor_assignment.reset_all_groups',
    descriptionKey: 'instructor_assignment.reset_all_description',
    proceedTextKey: 'instructor_assignment.reset_all_proceed_text',
    resettingTextKey: 'instructor_assignment.resetting_groups',
    failTimeoutTextKey: 'instructor_assignment.reset_failed_timeout',
    failOtherTextKey: 'instructor_assignment.reset_failed',
  },
} as const

export const RestartGroupsDialog = observer(function RestartGroupsDialog({
  groups,
  onReset,
}: {
  groups: string[]
  onReset: () => Promise<void>
}) {
  const { t } = useTranslation()
  const [confirmText, setConfirmText] = useState('')

  const [step, setStep] = useState<
    'form' | 'resetting' | 'failedTimeout' | 'failedOther'
  >('form')
  const { popDialog } = useDialogs()

  const singleGroup = groups.length === 1
  const config = singleGroup ? RESET_CONFIG.single : RESET_CONFIG.multiple

  const expectedText = t(config.titleKey).toLowerCase()
  const isConfirmed = confirmText.toLowerCase() === expectedText

  const handleReset = async () => {
    if (expectedText !== confirmText.toLowerCase()) {
      return
    }

    setStep('resetting')

    try {
      await onReset()
      toast.success(t('instructor_assignment.reset_success'))
      popDialog()
    } catch (e) {
      console.error(e)
      captureException(e)
      if (e instanceof Error && e.message.includes('timeout'))
        return setStep('failedTimeout')
      setStep('failedOther')
    }
  }

  // Reset the dialog state when it's opened.
  useEffect(() => {
    //setStep('form')
    setConfirmText('')
  }, [])

  return (
    <Dialog
      testId="end-class-dialog"
      size={step === 'form' ? 'lg' : 'sm'}
      ignoreBackdropClick={step === 'resetting'}
    >
      {!(step === 'resetting') && (
        <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      )}
      <div className="flex h-[400px] items-center justify-between">
        <div className="mx-auto flex h-full w-full max-w-[400px] flex-col items-center justify-center gap-3">
          {step === 'resetting' && (
            <>
              <h1 className="text-headline-large">
                {t(config.resettingTextKey)}
              </h1>

              <Spinner className="mt-4" />
            </>
          )}
          {step === 'form' && (
            <>
              <ArrowRepeatIcon className="h-16 w-16 text-core-error" />
              <h1 className="text-headline-large">
                {t(config.titleKey)} {singleGroup ? groups[0] : ''}?
              </h1>
              <p className="text-body-medium text-center">
                <Trans
                  i18nKey={config.descriptionKey}
                  components={{
                    bold: <span className="font-bold text-core-error" />,
                    br: <br />,
                  }}
                />
              </p>
              <p className="text-body-medium text-center">
                <Trans
                  i18nKey={config.proceedTextKey}
                  components={{
                    bold: <span className="font-bold" />,
                  }}
                />
              </p>

              <BreakoutTextInput
                className="w-full"
                kind="secondary"
                placeholder={t(config.titleKey).toLowerCase()}
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isConfirmed) {
                    e.preventDefault()
                    handleReset()
                  }
                }}
              />
              <BreakoutButton
                kind="error"
                size="large"
                fullWidth
                variant="contained"
                disabled={!isConfirmed}
                onClick={handleReset}
              >
                {t('instructor_assignment.reset')}
              </BreakoutButton>
            </>
          )}
          {(step === 'failedTimeout' || step === 'failedOther') && (
            <>
              <div className="flex flex-grow flex-col items-center justify-center gap-3 text-center">
                <TriangleAlertFillIcon size={52} className="fill-core-error" />
                <p className="text-body-large text-center">
                  {t(
                    step === 'failedTimeout'
                      ? config.failTimeoutTextKey
                      : config.failOtherTextKey
                  )}
                </p>
              </div>

              <BreakoutButton
                size="large"
                fullWidth
                onClick={() => popDialog()}
              >
                {t('instructor_assignment.close')}
              </BreakoutButton>
            </>
          )}
        </div>
      </div>
    </Dialog>
  )
})
