import type { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { createContext, useContext } from 'react'

const InstructorAssignmentCubitContext = createContext<
  InstructorAssignmentCubit | undefined
>(undefined)

export const InstructorAssignmentCubitProvider =
  InstructorAssignmentCubitContext.Provider

export function useInstructorAssignmentCubit() {
  const cubit = useContext(InstructorAssignmentCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useInstructorAssignmentCubit must be used within a CubitProvider'
    )
  }

  return cubit
}
