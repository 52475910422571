import type React from 'react'

// Stolen from https://github.com/sajadevo/material-ripple-effects
// I brought it in because:
// 1. I needed to change the logic a bit (the onfinish event)
// 2. It's small enough to be included here

export class RippleEffect {
  x: number
  y: number
  z: number

  constructor() {
    this.x = 0
    this.y = 0
    this.z = 0
  }

  findFurthestPoint(
    clickPointX: number,
    elementWidth: number,
    offsetX: number,
    clickPointY: number,
    elementHeight: number,
    offsetY: number
  ) {
    this.x = clickPointX - offsetX > elementWidth / 2 ? 0 : elementWidth
    this.y = clickPointY - offsetY > elementHeight / 2 ? 0 : elementHeight
    this.z = Math.hypot(
      this.x - (clickPointX - offsetX),
      this.y - (clickPointY - offsetY)
    )

    return this.z
  }

  applyStyles(
    circle: HTMLElement,
    color: string,
    rect: DOMRect,
    radius: number,
    event: React.MouseEvent
  ) {
    circle.classList.add('ripple')
    circle.style.backgroundColor =
      color === 'dark' ? 'rgba(0,0,0, 0.2)' : 'rgba(255,255,255, 0.3)'
    circle.style.borderRadius = '50%'
    circle.style.pointerEvents = 'none'
    circle.style.position = 'absolute'
    circle.style.left = event.clientX - rect.left - radius + 'px'
    circle.style.top = event.clientY - rect.top - radius + 'px'
    circle.style.width = circle.style.height = radius * 2 + 'px'
  }

  applyAnimation(element: Element) {
    return element.animate(
      [
        {
          transform: 'scale(0)',
          opacity: 1,
        },
        {
          transform: 'scale(1.5)',
          opacity: 0,
        },
      ],
      {
        duration: 500,
        easing: 'linear',
      }
    )
  }

  createFromEvent(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    color: string
  ) {
    const element = event.currentTarget

    this.create(element, event, color)
  }

  create(
    element: HTMLElement,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    color: string
  ) {
    element.style.position = 'relative'
    element.style.overflow = 'hidden'

    const rect = element.getBoundingClientRect()

    const radius = this.findFurthestPoint(
      event.clientX,
      element.offsetWidth,
      rect.left,
      event.clientY,
      element.offsetHeight,
      rect.top
    )

    const circle = document.createElement('span')

    this.applyStyles(circle, color, rect, radius, event)
    const animation = this.applyAnimation(circle)

    element.appendChild(circle)

    animation.onfinish = () => {
      circle.remove()
    }
  }
}
