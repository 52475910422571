import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { MeetingPreviewLoader } from 'pages/meeting/MeetingPreview'

export function ShowExperienceDialog({ slideDeckId }: { slideDeckId: string }) {
  return (
    <Dialog
      size="custom"
      className="relative flex h-[95vh] w-[95vw] flex-col justify-start overflow-hidden rounded-3xl bg-core-secondary"
    >
      <DialogCloseButton className="z-[100]" />
      <div className="h-full p-6">
        <div className="relative h-full">
          <MeetingPreviewLoader slideDeckId={slideDeckId} />
        </div>
      </div>
    </Dialog>
  )
}
