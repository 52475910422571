import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { ErrorBoundary } from 'react-error-boundary'

export function SuspenseErrorBoundary(props: React.PropsWithChildren) {
  return <ErrorBoundary FallbackComponent={ErrorFallback} {...props} />
}

function ErrorFallback() {
  const { popDialog } = useDialogs()
  return (
    <Dialog
      size="sm"
      className="!overflow-hidden !bg-core-tertiary !p-0"
      innerClassName="h-full flex flex-col gap-4 items-center justify-center"
    >
      <DialogCloseButton
        // Leaving in bg-white here, because dark mode should not effect this button.
        className="absolute !right-0 !top-0 z-[50] cursor-pointer rounded-bl-2xl bg-white bg-opacity-50 p-4"
      />
      <div className="text-body-large">Failed to load</div>
      <BreakoutButton onClick={() => popDialog()}>Close</BreakoutButton>
    </Dialog>
  )
}
