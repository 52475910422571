import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { useTranslation } from 'react-i18next'
import { ArrowRightIcon } from 'components/icons/ArrowRight'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import type { ComponentProps } from 'react'
import { BreakoutTooltip } from '../BreakoutTooltip'
import { DateTime } from 'luxon'

interface CardProps extends ComponentProps<'div'> {
  imageUrl?: string
  title: string
  subtitle?: string
  instructorName: string
  sectionName: string
  className: string
  state: LibraryObjectState
  scheduledAt?: DateTime
  onJoinSessionClick?: () => void
  onClick?: () => void
}

export function StudentLibraryCard({
  imageUrl,
  title,
  subtitle,
  instructorName,
  sectionName,
  className,
  state,
  scheduledAt,
  onJoinSessionClick,
  onClick,
  ...rest
}: CardProps) {
  const { t } = useTranslation()
  return (
    <BreakoutCard onClick={onClick} {...rest}>
      <BreakoutCard.Header>
        {scheduledAt && state === LibraryObjectState.scheduled && (
          <BreakoutTooltip
            content={scheduledAt.toLocaleString(DateTime.DATETIME_MED)}
          >
            <span>
              <BreakoutPill.LibraryObjectState kind="primary" state={state} />
            </span>
          </BreakoutTooltip>
        )}
        {(!scheduledAt || state !== LibraryObjectState.scheduled) && (
          <BreakoutPill.LibraryObjectState kind="primary" state={state} />
        )}
      </BreakoutCard.Header>
      <BreakoutCard.Body>
        {imageUrl && (
          <div className="flex h-[50px] w-[50px] flex-col items-center justify-center ">
            <img
              src={imageUrl}
              className="inline max-h-[30px] max-w-[30px]"
              alt={title}
              aria-hidden
            />
          </div>
        )}
        <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
          {title}
        </h2>
        {subtitle && (
          <h2 className="text-title-large line-clamp-2 text-on-surface">
            {subtitle}
          </h2>
        )}
      </BreakoutCard.Body>
      <BreakoutCard.Footer>
        {state === LibraryObjectState.live && (
          <BreakoutButton
            size="large"
            onClick={(e) => {
              e.stopPropagation()
              onJoinSessionClick?.()
            }}
            iconOnRight
            icon={<ArrowRightIcon size={14} />}
          >
            {t('library.join_session')}
          </BreakoutButton>
        )}
        {state !== LibraryObjectState.live && (
          <div>
            <div className="text-label-medium line-clamp-1 text-on-surface">
              {instructorName}
            </div>
            <div className="text-body-small line-clamp-1 text-on-surface-var">
              {className} {sectionName}
            </div>
          </div>
        )}
      </BreakoutCard.Footer>
    </BreakoutCard>
  )
}
