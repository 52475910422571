import { useEffect, useState, createContext, useContext } from 'react'
import { useStorage } from './auth'
import { decodeStorageUrl } from 'util/storageUrl'

type StorageUrlManagerEntry = {
  url: string
  expires: number
}

export class StorageUrlManager {
  private cache: Record<string, StorageUrlManagerEntry> = {}

  get(path: string | null | undefined): string | null {
    if (!path) return null

    const entry = this.cache[path]
    if (entry && entry.expires > Date.now()) {
      return entry.url
    }
    return null
  }

  set(path: string, url: string, expires: number) {
    this.cache[path] = {
      url,
      expires,
    }
  }
}

type StorageUrlContextStore = { manager: StorageUrlManager }

export const StorageUrlContext = createContext<StorageUrlContextStore | null>(
  null
)

function useStorageUrlManager() {
  const context = useContext(StorageUrlContext)
  if (!context) {
    throw new Error(
      'useStorageUrlManager must be used within a StorageUrlContext'
    )
  }
  return context.manager
}

export function useStorageUrl(
  baseUrl: string | null | undefined,
  expirationInMinutes = 60
) {
  const manager = useStorageUrlManager()

  const [imageUrl, setImageUrl] = useState<string | null>(manager.get(baseUrl))
  const storage = useStorage()
  useEffect(() => {
    if (!baseUrl) return
    const cached = manager.get(baseUrl)

    if (cached) {
      setImageUrl(cached)
      return
    }

    setImageUrl(null)
    const run = async () => {
      const url = await decodeStorageUrl(storage, baseUrl)
      const expires = Date.now() + 1000 * 60 * expirationInMinutes
      manager.set(baseUrl, url, expires)
      setImageUrl(url)
    }
    run()
  }, [storage, baseUrl, expirationInMinutes, manager])

  return imageUrl
}
