import type { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useRepository } from 'hooks/auth'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const GroupRemoveUserDialog = observer(function GroupRemoveUserDialog({
  cubit,
  roomState,
  userId,
  onClick,
}: {
  cubit: InstructorAssignmentCubit
  userId: string
  roomState: RoomState
  onClick?: () => void
}) {
  const repository = useRepository()
  const user = repository.userStore.getUser(userId)
  const { t } = useTranslation()
  return (
    <Dialog size="sm" className="!bg-core-tertiary">
      <DialogCloseButton />
      <div className="text-headline-medium text-center">
        {t('instructor.group_user_remove_confirmation')}
      </div>
      <div className="my-5 flex flex-row items-center justify-between">
        <div className="text-body-medium text-grey-text">
          {t('instructor.group_user_student_name')}
        </div>
        <div className="text-label-medium flex flex-row items-center gap-2">
          <BreakoutUserAvatar user={user} radius={18} />

          {user.fullName}
        </div>
      </div>
      <div className="my-5 flex flex-row items-center justify-between">
        <div className="text-body-medium text-grey-text">
          {t('instructor.group_user_group_name')}
        </div>
        <div className="text-label-medium">{roomState?.roomStateName}</div>
      </div>
      <div className="mt-5">
        <BreakoutButton
          size="large"
          fullWidth
          kind="error"
          onClick={() => {
            cubit.removeUserFromGroup({
              roomId: roomState.id,
              userId,
            })
            onClick?.()
          }}
        >
          {t('instructor.group_user_remove_from_group')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
})
