import { ClockIcon } from 'components/icons/Clock'
import { FullPageSpinner } from 'components/Spinner'
import { observer } from 'mobx-react-lite'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

export const StudentAssignmentLoadingPage = observer(
  function StudentAssignmentLoadingPage() {
    return createPortal(
      <div className="fixed left-0 top-0 h-screen w-screen bg-core-secondary">
        <FullPageSpinner />
      </div>,
      document.body
    )
  }
)

export const StudentAssignmentPendingPage = observer(
  function StudentAssignmentPendingPage() {
    const { t } = useTranslation()

    // put it in a portal to jump out of containers
    return createPortal(
      <div className="lti-configuration fixed left-0 top-0 min-h-screen w-screen">
        <div className="pending flex min-h-screen w-full flex-col items-start justify-center">
          <div className="ml-[5vw] max-w-[400px]">
            <div className="mb-5">
              <div>
                <ClockIcon className="stroke-fixed-accent-color" />
              </div>
              <h1 className="text-headline-large">
                {t('lti.configuration.class_pending')}
              </h1>
              <div className="text-body-large">
                {t('lti.configuration.class_pending_description')}
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    )
  }
)
