import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { PlusIcon } from 'components/icons/Plus'
import { useCubitBuilder } from 'hooks/cubits'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { CreateOrganizationDialog } from './CreateOrganizationDialog'
import { OrganizationsCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationsCubit'
import { useRepository } from 'hooks/auth'
import { useTranslation } from 'react-i18next'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'hooks/rootStore'
import { EyeOpen } from 'components/icons/EyeOpen'
import { EyeClosed } from 'components/icons/EyeClosed'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { Organization } from '@breakoutlearning/firebase-repository/models/Organization'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { useBreakoutUser } from 'hooks/profile'
import { useEffect, useState } from 'react'
import { FullPageSpinner } from 'components/Spinner'

export const OrganizationsPage = observer(function OrganizationsPage() {
  const [redirecting, setRedirecting] = useState(false)
  const repository = useRepository()
  const store = useRootStore()
  const breakoutUser = useBreakoutUser()
  const { t } = useTranslation()

  const cubit = useCubitBuilder(
    () => new OrganizationsCubit(repository),
    [repository]
  )

  useEffect(() => {
    const numOrgs = cubit.organizations.length

    if (numOrgs !== 1 || breakoutUser.isCorre) {
      // We want to show the entire org table
      return
    }

    setRedirecting(true)
    // Lets go to the only organization
    store.navigateTo(
      'organization',
      {
        organizationId: cubit.organizations[0].id,
      },
      {
        singleOrg: true,
      }
    )
  }, [breakoutUser.isCorre, cubit.organizations, store])

  if (cubit.loading || redirecting) {
    return <FullPageSpinner />
  }

  return (
    <MainPane>
      <Header>
        <Headline>{t('organizations.organizations')}</Headline>
        {breakoutUser.isCorre && <AdminOrganizationsActions cubit={cubit} />}
      </Header>
      <Contents className="h-full min-h-0">
        <OrganizationsPageContents cubit={cubit} />
      </Contents>
    </MainPane>
  )
})

const AdminOrganizationsActions = observer(function AdminOrganizationsActions({
  cubit,
}: {
  cubit: OrganizationsCubit
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  return (
    <Actions>
      <FloatingIconActionButton
        kind="secondary"
        className="flex justify-end"
        aria-label={t('buttons.admin_actions')}
        Icon={AdminPanelSettingsIcon}
        menuBottom={true}
        actions={[
          {
            text: t('organizations.create_new_organization'),
            Icon: PlusIcon,
            onClick: () => {
              showDialog(() => <CreateOrganizationDialog cubit={cubit} />)
            },
          },
          {
            text: t(
              `organizations.${cubit.showArchived ? 'hide_archived' : 'show_archived'}`
            ),
            Icon: cubit.showArchived ? EyeClosed : EyeOpen,
            onClick: () => cubit.toggleShowArchived(),
          },
        ]}
      />
    </Actions>
  )
})

const OrganizationsPageContents = observer(function AdminCatalogsPageContents({
  cubit,
}: {
  cubit: OrganizationsCubit
}) {
  const { t } = useTranslation()

  return (
    <div className="h-full overflow-auto">
      <BreakoutTable>
        <thead>
          <tr className="sticky top-0 z-[10]">
            <th>{t('organization.institution')}</th>
            <th>{t('organization.organization_title')}</th>

            <th>
              <BreakoutTextInput
                className="float-right max-w-[300px]"
                kind="tertiary"
                placeholder={t('organization.filter_by')}
                onKeyDown={(e) => {
                  if (
                    e.key === 'Enter' &&
                    e.currentTarget.value.trim() !== ''
                  ) {
                    e.preventDefault()
                    cubit.addFilter(e.currentTarget.value)
                    e.currentTarget.value = ''
                  }
                }}
                LeadingIcon={Search}
              />
            </th>
          </tr>
          {cubit.filters.length > 0 && (
            <tr>
              <td colSpan={3}>
                <div className="py-3">
                  <BreakoutPill.Chips
                    labels={cubit.filters}
                    onClick={cubit.removeFilter}
                  />
                </div>
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {cubit.organizations.length === 0 && (
            <tr>
              <td className="text-center" colSpan={3}>
                <div className="p-5">
                  {t('organization.no_organizations_found')}
                </div>
              </td>
            </tr>
          )}
          {cubit.organizations.map((organization) => {
            return <Row key={organization.id} organization={organization} />
          })}
        </tbody>
      </BreakoutTable>
    </div>
  )
})

const Row = observer(function ClassRow({
  organization,
}: {
  organization: Organization
}) {
  const store = useRootStore()
  const { t } = useTranslation()

  return (
    <tr>
      <td>
        <strong className="text-label-medium">
          {organization.data.organizationInstitution}
        </strong>
      </td>
      <td>
        <strong className="text-label-medium">
          {organization.data.organizationName}
        </strong>
      </td>

      <td>
        <div className="flex flex-row flex-wrap justify-end gap-2">
          <BreakoutButton
            kind="secondary"
            onClick={() => {
              store.navigateTo('organization', {
                organizationId: organization.id,
              })
            }}
            size="medium"
          >
            {t('organization.view_details')}
          </BreakoutButton>
        </div>
      </td>
    </tr>
  )
})
