import { useTranslation } from 'react-i18next'
import { GoogleStorageVideo } from './GoogleStorageVideo'
import { Spinner } from './Spinner'
import { Dialog, InlineDialog } from './dialogs/Dialog'
import { DialogCloseButton } from './dialogs/DialogCloseButton'

export function VideoPreviewDialog({
  src,
  inline = false,
  onDismiss,
  requiresAuthHeaders = false,
  alt,
}: {
  src: string
  inline?: boolean
  onDismiss?: () => void
  requiresAuthHeaders?: boolean
  alt: string
}) {
  const { t } = useTranslation()
  const DialogToUse = inline ? InlineDialog : Dialog

  return (
    <DialogToUse
      onDismiss={onDismiss}
      size="custom"
      className="relative flex aspect-video max-h-[90vh] w-[90vw] flex-col overflow-hidden rounded-2xl bg-surface !p-0"
      innerClassName="!p-0 overflow-hidden flex flex-col items-center h-full "
    >
      <DialogCloseButton
        className="z-[50]"
        onClick={() => {
          if (onDismiss) onDismiss()
          // returning true pops dialog
          return !inline
        }}
      />
      <div className="aspect-video h-full w-full overflow-hidden">
        {!requiresAuthHeaders ? (
          <video
            autoPlay
            className="!aspect-video max-h-full w-full max-w-full rounded-2xl"
            controls
            src={src}
          />
        ) : (
          <GoogleStorageVideo
            namespace="preview-dialog"
            autoPlay
            className="!aspect-video max-h-full w-full max-w-full rounded-2xl"
            controls
            src={src}
            alt={alt}
            loader={
              <div className="flex aspect-video min-w-[300px] items-center justify-center sm:min-w-[400px] md:min-w-[600px]">
                <div>
                  <div className="text-label-medium mb-2">
                    {t('videos_preview.loading_video')}
                  </div>
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </DialogToUse>
  )
}
