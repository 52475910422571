import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { PurchaseDialogBody } from './PurchaseDialogBody'
import { EnrollDialogBody } from './EnrollDialogBody'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useBreakoutUser } from 'hooks/profile'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useRootStore } from 'hooks/rootStore'
import { Spinner } from 'components/Spinner'
import { useTranslation } from 'react-i18next'

function ProcessingView() {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex h-full flex-1 flex-col items-center justify-center gap-5 text-center">
        <div className="text-title-large">
          {t('student_assignment.processing_payment')}
        </div>
        <Spinner />
      </div>
    </>
  )
}

export const PurchaseOrEnrollDialog = observer(function PurchaseOrEnrollDialog({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const rootStore = useRootStore()
  const user = useBreakoutUser()
  const [showProcessing, setShowProcessing] = useState(false)

  useEffect(() => {
    cubit.onDialogMountUnmount('mount', 'enroll')
    return () => {
      cubit.onDialogMountUnmount('unmount', 'enroll')
    }
  })

  const queryParams = rootStore.router.queryParams
  const isPostSuccess = queryParams?.payment === 'success'

  const slideDeckPrice = cubit.slideDeck.data.slideDeckPrice
  const tokensRequired = slideDeckPrice - user.availableTokens
  let DialogContents =
    tokensRequired > 0 ? PurchaseDialogBody : EnrollDialogBody

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    // when we have a successful payment, but the tokens are not yet updated
    // show a processing view, but time it out after 10 seconds
    if (isPostSuccess && tokensRequired > 0) {
      setShowProcessing(true)
      timeout = setTimeout(() => {
        setShowProcessing(false)
      }, 10000)
    } else {
      setShowProcessing(false)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isPostSuccess, tokensRequired])

  if (showProcessing) {
    DialogContents = ProcessingView
  }

  return (
    <Dialog
      size="md"
      innerClassName="flex flex-col items-center justify-between gap-2"
      ignoreBackdropClick
      ignoreEscapeKey
    >
      <DialogCloseButton />
      <DialogContents cubit={cubit} />
    </Dialog>
  )
})
