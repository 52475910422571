import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const Disclaimer = observer(function Disclaimer() {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-surface px-8 py-4 md:flex-row md:items-center">
      <TriangleAlertIcon
        size={28}
        className="min-w-[28px] stroke-breakout-yellow"
      />
      <div className="flex flex-col">
        <strong className="text-title-medium">
          {t('meeting.results.disclaimer')}
        </strong>
        <p className="text-body-large">
          {t('meeting.results.disclaimer_text')}
        </p>
      </div>
    </div>
  )
})
