import * as React from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import classNames from 'classnames'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import type { FormFieldDefaults } from './types'
import { FormLabel } from './form/FormLabel'
import { FormError } from './form/FormError'
import { fixedForwardRef } from 'util/refs'
import { useTranslation } from 'react-i18next'
import { TrashCanIcon } from 'components/icons/TrashCan'

export type SelectOption<T> = {
  label: string | React.ReactNode
  value: T
  selectedLabel?: string | React.ReactNode
  metadata?: Record<string, string | number | boolean>
  key?: string | number
}

interface BreakoutSelectProps<T> extends FormFieldDefaults<'select'> {
  options: SelectOption<T>[]
  // kind secondary is the default and represents the "primary" style in figma
  // tertiary is the secondary style in figma
  kind?: 'secondary' | 'tertiary'
  onChange?: (value: T) => void
  fullWidth?: boolean
  inputClassName?: string
  labelComponent?: React.ReactNode
  icon?: React.ReactNode
  showChevron?: boolean
  allowClear?: boolean
  placeholder?: string
}
const MAX_SELECT_BOX_HEIGHT = 500

export const BreakoutSelect = fixedForwardRef(function BreakoutSelect<
  T = string,
>(
  {
    disabled,
    kind = 'tertiary',
    showChevron = true,
    name,
    label,
    labelComponent,
    labelClass,
    testId,
    options,
    icon,
    error,
    errorClass,
    required,
    value,
    inputClassName,
    placeholder,
    allowClear,
    onChange,
    fullWidth,
    className,
    'aria-label': ariaLabel,
    ...rest
  }: BreakoutSelectProps<T>,
  fwdRef: React.ForwardedRef<HTMLSelectElement>
) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null)

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (open) => {
      if (disabled) return
      setIsOpen(open)
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, MAX_SELECT_BOX_HEIGHT)}px`,
            maxWidth: fullWidth ? undefined : `${rects.reference.width}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const { selectedIndex, selectedLabel } = React.useMemo(() => {
    const index = options.findIndex((option) => option.value === value)

    return {
      selectedIndex: index,
      // If the selected option has a selectedLabel, use that, otherwise use the label
      selectedLabel: options[index]?.selectedLabel
        ? options[index].selectedLabel
        : options[index]?.label,
    }
  }, [value, options])

  const listRef = React.useRef<Array<HTMLElement | null>>([])
  const isTypingRef = React.useRef(false)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'select' })

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex: hoveredIndex,
    selectedIndex,
    onNavigate: (index) => {
      if (index === null) return
      setHoveredIndex(index)
    },
    loop: false,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, click]
  )

  const handleSelect = React.useCallback(
    (value: T) => {
      onChange?.(value)
      setIsOpen(false)
      setHoveredIndex(null)
    },
    [onChange]
  )

  const dataTestId = testId || (name ? `${name}-select` : undefined)

  const { t } = useTranslation()
  return (
    <div
      className={classNames('flex flex-col items-start gap-1', className, {
        'flex-1': fullWidth,
      })}
    >
      {labelComponent === undefined && (
        <FormLabel
          label={label}
          name={name}
          labelClass={labelClass}
          required={required}
        />
      )}
      {labelComponent}
      {name && (
        <select
          className="hidden"
          {...rest}
          ref={fwdRef}
          name={name}
          value={value}
          onChange={() => {}}
        />
      )}
      <div
        data-testid={dataTestId}
        tabIndex={0}
        ref={refs.setReference}
        aria-autocomplete="none"
        className={classNames(
          inputClassName,
          'text-label-medium flex h-[3.25rem] w-full select-none flex-row items-center justify-between rounded-2xl px-4 py-1',
          {
            'bg-core-secondary': kind === 'secondary' && !disabled,
            'bg-core-tertiary': kind === 'tertiary' && !disabled,
            'bg-surface-dim': disabled,
            'text-on-surface-disabled': disabled,
            'border border-core-error': !!error,
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
            'is-disabled': disabled,
          }
        )}
        {...getReferenceProps()}
        // aria-label={ariaLabel || label}
        role="group"
        aria-label={`${ariaLabel || label} ${t('components.dropdown_cta')}`}
      >
        {icon !== undefined && <div className="mr-2">{icon}</div>}
        <div className="w-full overflow-hidden truncate">
          {selectedLabel ? (
            selectedLabel
          ) : (
            <span className="text-on-surface-disabled">
              {placeholder || t('components.default_select_placeholder')}
            </span>
          )}
        </div>
        {allowClear && value && !disabled && (
          <button
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onChange?.('' as T)
            }}
          >
            <TrashCanIcon size={14} />
          </button>
        )}
        {showChevron && (!value || !allowClear) && (
          <span className={classNames(isOpen ? 'rotate-90' : 'rotate-270')}>
            <ChevronLeft size={14} />
          </span>
        )}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className={classNames(
                'text-label-medium z-[9999] min-w-24 space-y-1 overflow-y-auto overscroll-contain rounded-2xl p-4 shadow-xl',
                {
                  'bg-core-secondary': kind === 'secondary',
                  'bg-core-tertiary': kind === 'tertiary',
                }
              )}
              style={{
                ...floatingStyles,
              }}
              {...getFloatingProps()}
              aria-label={t('components.listbox_cta')}
            >
              {options.map((option, i) => {
                const hovered = i === hoveredIndex
                const selected = i === selectedIndex
                return (
                  <div
                    key={
                      option.key !== undefined
                        ? option.key
                        : (option.value as string | number)
                    }
                    ref={(node) => {
                      listRef.current[i] = node
                    }}
                    role="option"
                    tabIndex={hovered ? 0 : -1}
                    aria-selected={hovered}
                    className="cursor-pointer"
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect?.(option.value)
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault()
                          handleSelect?.(option.value)
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault()
                          handleSelect?.(option.value)
                        }
                      },
                    })}
                  >
                    <div
                      data-testid={dataTestId + `-option-${option.value}`}
                      className={classNames(
                        'cursor-pointer rounded-lg border-transparent p-4 py-2 outline-none',
                        {
                          'bg-surface-dim': selected,
                          'bg-core-secondary':
                            (!kind || kind === 'tertiary') &&
                            hovered &&
                            !selected,
                          'bg-core-tertiary':
                            kind === 'secondary' && hovered && !selected,
                        }
                      )}
                    >
                      {option.label}
                    </div>
                  </div>
                )
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
      <FormError error={error} errorClass={errorClass} />
    </div>
  )
})
