import type { OrganizationCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationCubit'
import { createContext, useContext } from 'react'

const OrganizationCubitContext = createContext<OrganizationCubit | undefined>(
  undefined
)

export const OrganizationCubitProvider = OrganizationCubitContext.Provider

export function useOrganizationCubit() {
  const cubit = useContext(OrganizationCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useOrganizationCubit must be used within a OrganizationCubitProvider'
    )
  }

  return cubit
}
