export function DownloadIcon({
  color = 'currentColor',
  fillColor = 'none',
  className,
  size = 24,
  strokeWidth = 2.5,
  onClick, //ts complains without this idk why
  ...otherProps
}: {
  fillColor?: string
  size?: number | string
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fillColor}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      onClick={onClick}
      {...otherProps}
    >
      <path d="M12 15V3m0 12l-4-4m4 4l4-4" />
      <path d="M2 17l.621 2.485A2 2 0 0 0 4.561 21H19.439a2 2 0 0 0 1.94-1.515L22 17" />
    </svg>
  )
}
