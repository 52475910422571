export const ArrowRepeatIcon = ({
  color = 'currentColor',
  size = '24',
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M18 2l3 3-3 3" />
      <path d="M6 22l-3-3 3-3" />
      <path d="M21 5H10a7 7 0 0 0-7 7" />
      <path d="M3 19h11a7 7 0 0 0 7-7" />
    </svg>
  )
}
