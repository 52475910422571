import { RubricType } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import type { BloomScore } from '@breakoutlearning/firebase-repository/types'
import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextArea } from 'components/design-system/BreakoutTextArea'
import { FormError } from 'components/design-system/form/FormError'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  getBloomLongDescription,
  getRubricBgColorByScore,
  getRubricScoreLabel,
} from 'util/rubric'

export const RubricFeedbackFormDialog = observer(
  function RubricFeedbackFormDialog({
    onSubmit,
  }: {
    onSubmit: (data: { feedback: string }) => Promise<void>
  }) {
    const { t } = useTranslation()
    const [error, setError] = useState<string>()

    const {
      control,
      formState: { isSubmitting, isSubmitted },
      handleSubmit,
    } = useForm({
      defaultValues: {
        feedback: '',
      },
    })

    const submit = async (data: { feedback: string }) => {
      await onSubmit(data).catch(() => {
        setError(t('meeting.feedback_submit_error'))
      })
    }

    return (
      <Dialog size="xl">
        <DialogCloseButton />
        <form onSubmit={handleSubmit(submit)}>
          <div className="text-headline-small text-on-surface">
            {t('meeting.share_feedback')}
          </div>
          <div className="text-body-large text-on-surface">
            {t('meeting.before_you_share')}
          </div>
          <div className="mt-3 rounded-2xl border border-outline-variant p-11">
            <div className="text-title-large text-on-surface">
              {t('meeting.bloom_framework')}
            </div>
            <div className="text-body-large mt-3 space-y-2 text-on-surface">
              <div>{t('meeting.bloom_desc_paragraph_1')}</div>
              <div>{t('meeting.bloom_desc_paragraph_2')}</div>
              <div>{t('meeting.bloom_desc_paragraph_3')}</div>
              <div>{t('meeting.bloom_desc_paragraph_4')}</div>
            </div>
            <div className="mt-4 flex flex-col space-y-1">
              <ScoreDetailsLong score={6} />
              <ScoreDetailsLong score={5} />
              <ScoreDetailsLong score={4} />
              <ScoreDetailsLong score={3} />
              <ScoreDetailsLong score={2} />
              <ScoreDetailsLong score={1} />
              <ScoreDetailsLong score={0} />
            </div>
            <div className="text-body-large mt-3 space-y-2 text-on-surface">
              <div>{t('meeting.bloom_desc_paragraph_5')}</div>
              <div>{t('meeting.bloom_desc_paragraph_6')}</div>
            </div>
          </div>
          <div className="mt-3 rounded-2xl border border-outline-variant p-11">
            <div className="text-title-large text-on-surface">
              {t('meeting.pass_fail_rubric')}
            </div>
            <div className="text-body-large mt-3 space-y-2 text-on-surface">
              <div>{t('meeting.pass_fail_paragraph_1')}</div>
              <div>{t('meeting.pass_fail_paragraph_2')}</div>
            </div>
          </div>
          <div className="mt-5">
            <div className="text-headline-small mb-3">
              {t('meeting.feedback_prompt')}
            </div>
            <Controller
              control={control}
              name="feedback"
              rules={{
                required: t('meeting.feedback_required'),
              }}
              render={({ field, fieldState }) => (
                <BreakoutTextArea
                  disabled={isSubmitted || isSubmitting}
                  kind="secondary"
                  placeholder={t('meeting.feedback_placeholder')}
                  error={fieldState.error}
                  {...field}
                  onChange={(e) => {
                    setError(undefined)
                    field.onChange(e)
                  }}
                />
              )}
            />
            <div
              className={classNames(
                'mt-3 flex items-center',
                isSubmitted ? 'justify-between' : 'justify-end'
              )}
            >
              {isSubmitted && (
                <div className="text-body-large">
                  {t('meeting.thank_you_feedback')}
                </div>
              )}
              {error && (
                <FormError
                  error={{
                    type: 'error',
                    message: error,
                  }}
                />
              )}
              <BreakoutButton
                loading={isSubmitting}
                disabled={isSubmitted || isSubmitting}
                kind={isSubmitted ? 'secondary' : 'primary'}
                type="submit"
                onClick={() => {
                  setError(undefined)
                }}
              >
                {isSubmitted
                  ? t('meeting.feedback_submitted')
                  : t('meeting.submit_feedback')}
              </BreakoutButton>
            </div>
          </div>
        </form>
      </Dialog>
    )
  }
)

const ScoreDetailsLong = ({ score }: { score: BloomScore }) => {
  const { t } = useTranslation()
  const label = getRubricScoreLabel(t, RubricType.blooms, score)
  const bgColor = getRubricBgColorByScore({ type: RubricType.blooms, score })
  const description = getBloomLongDescription(t, score)

  return (
    <div
      className="relative flex items-center gap-2"
      style={{ height: '100%' }}
    >
      <div className="py-4 pl-4">
        <div className="absolute left-0 top-0 h-full w-2.5">
          <div className={classNames('h-full w-2.5 bg-opacity-30', bgColor)} />
        </div>

        <div className="text-label-medium">{label}</div>
        <div className="text-body-medium text-on-surface-var">
          {description}
        </div>
      </div>
    </div>
  )
}
