import { createContext, useContext } from 'react'
import type { RootStore } from 'store/root'

// Use React context to make your store available in your application
const StoreContext = createContext<RootStore | null>(null)
export const StoreProvider = StoreContext.Provider

export function useRootStore() {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('useRootStore must be used within a StoreProvider')
  }
  return store
}
