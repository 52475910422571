import { useDialogs } from 'hooks/dialogs'
import { XSmallIcon } from '../icons/XSmall'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

export function DialogCloseButton({
  onClick,
  className,
  iconClassName,
}: {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => boolean
  className?: string
  iconClassName?: string
}) {
  const { popDialog } = useDialogs()
  const { t } = useTranslation()

  return (
    <div
      className={classnames(
        'absolute right-4 top-4 flex justify-end',
        className
      )}
    >
      <button
        data-testid="dialog-close-button"
        className="p-1"
        aria-label={t('dialogs.close_dialog')}
        onClick={(e) => {
          if (onClick) {
            const res = onClick(e)
            if (res) popDialog()
          } else {
            popDialog()
          }
        }}
      >
        <XSmallIcon aria-hidden size={22} className={iconClassName} />
      </button>
    </div>
  )
}
