import type {
  DialogBuilder,
  DialogConfig,
  DialogStoreEntry,
} from 'components/contexts/dialogs'
import { DialogContext, DialogStore } from 'components/contexts/dialogs'

export const DialogContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const dialogStore = new DialogStore()

  const dialogContextObject = {
    dialogStore,
    showDialog: (dialog: DialogBuilder, config?: DialogConfig) => {
      return dialogStore.showDialog(dialog, config)
    },
    popDialog: () => dialogStore.pop(),
    removeDialog: (entry: DialogStoreEntry) => dialogStore.remove(entry),
    clearAllDialogs: () => dialogStore.clearAllDialogs(),
    anyDialogsOpen: () => dialogStore.dialogs.length > 0,
  }

  return (
    <DialogContext.Provider value={dialogContextObject}>
      {children}
    </DialogContext.Provider>
  )
}
