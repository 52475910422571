export function Forum({
  color = 'currentColor',
  size = 24,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 42 41"
      fill="none"
      stroke={color}
      strokeWidth="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path
        d="M15.7943 26.7502C15.4991 26.7502 15.2517 26.6503 15.0521 26.4507C14.8524 26.251 14.7526 26.0036 14.7526 25.7085V23.6252H28.2943V14.2502H30.3776C30.6727 14.2502 30.9201 14.35 31.1198 14.5496C31.3194 14.7493 31.4193 14.9967 31.4193 15.2918V28.3908C31.4193 28.8595 31.2066 29.1851 30.7812 29.3673C30.3559 29.5496 29.9783 29.4759 29.6484 29.146L27.2526 26.7502H15.7943ZM14.7526 21.5418L12.3568 23.9377C12.0269 24.2675 11.6493 24.3413 11.224 24.159C10.7986 23.9767 10.5859 23.6512 10.5859 23.1825V11.1252C10.5859 10.83 10.6858 10.5826 10.8854 10.383C11.0851 10.1833 11.3325 10.0835 11.6276 10.0835H25.1693C25.4644 10.0835 25.7118 10.1833 25.9115 10.383C26.1111 10.5826 26.2109 10.83 26.2109 11.1252V20.5002C26.2109 20.7953 26.1111 21.0427 25.9115 21.2423C25.7118 21.442 25.4644 21.5418 25.1693 21.5418H14.7526ZM24.1276 19.4585V12.1668H12.6693V19.4585H24.1276Z"
        fill="black"
      />
    </svg>
  )
}
