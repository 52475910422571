import { Button } from 'components/Button'
import { useRootStore } from 'hooks/rootStore'

export const NotFoundPage = function ProfilePage() {
  const rootStore = useRootStore()
  return (
    <div className="box-border flex h-full w-full flex-grow flex-col items-center justify-center rounded-3xl bg-core-tertiary p-8 px-[2.5rem] align-middle text-core-on-tertiary">
      <div className="mb-5">Page Not Found</div>
      <div>
        <Button
          onClick={() => rootStore.navigateTo('home')}
          className="bg-core-primary text-core-on-primary"
        >
          Back to Home
        </Button>
      </div>
    </div>
  )
}
