import classNames from 'classnames'
import { Toaster, resolveValue } from 'react-hot-toast'

export function Theme({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Toaster
        data-testid="toaster"
        containerClassName="!inset-0"
        gutter={0}
        position="bottom-center"
      >
        {(t) => {
          let className
          if (t.type === 'error') {
            className = 'bg-core-error text-core-on-error'
          } else if (t.type === 'success') {
            className = 'bg-core-success text-core-on-success'
          } else {
            className = 'bg-on-surface-var text-core-on-primary'
          }

          return (
            <div
              role="alert"
              className={classNames(
                'w-full',
                className,
                t.visible ? 'animate-toast-enter' : 'animate-toast-leave'
              )}
            >
              <div className="p-4">{resolveValue(t.message, t)}</div>
            </div>
          )
        }}
      </Toaster>
      {children}
    </>
  )
}
