import { useRootStore } from 'hooks/rootStore'
import { useEffect } from 'react'

export const WelcomeRedirect = () => {
  const store = useRootStore()

  useEffect(() => {
    store.navigateTo('home')
  }, [store])

  return null
}
