import { useRepository } from 'hooks/auth'
import { useObjectUrlCacheManager } from 'hooks/objectUrl'
import { useEffect, useState } from 'react'
import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'

type GoogleStorageImageProps = {
  src?: string
  alt: string
  loader?: React.ReactNode
  namespace: string
  decoding?: 'sync' | 'async' | 'auto'
} & React.HTMLAttributes<HTMLImageElement>

export const GoogleStorageImage = observer(function GoogleStorageImage({
  src,
  namespace,
  loader,
  ...rest
}: GoogleStorageImageProps) {
  const { authToken } = useRepository()
  const objectUrlCache = useObjectUrlCacheManager()
  const initialObjectUrl = objectUrlCache.get(namespace, src)
  const [objectUrl, setObjectUrl] = useState<string | undefined>(
    initialObjectUrl
  )
  const [retries, setRetries] = useState(0)

  useEffect(() => {
    if (!src || !authToken) return
    if (objectUrl) return
    if (retries >= 3) return

    const options = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }

    setTimeout(
      () => {
        fetch(src, options)
          .then((res) => res.blob())
          .then((blob) => {
            const newObjectURL = URL.createObjectURL(blob)
            objectUrlCache.set(namespace, src, newObjectURL)
            setObjectUrl(newObjectURL)
            setRetries(0)
          })
          .catch((err) => {
            captureException(err)
            if (retries < 3) {
              setRetries(retries + 1)
            } else {
              console.error('Failed to fetch image', err)
            }
          })
      },
      1000 * retries * 2 // exponential backoff - 0s, 2s, 4s, 8s
    )
  }, [authToken, src, objectUrlCache, namespace, objectUrl, retries])

  if (!objectUrl)
    return loader ? (
      <div className="flex h-full w-full items-center justify-center">
        {loader}
      </div>
    ) : null

  return (
    <img
      src={objectUrl}
      onError={() => {
        if (retries < 3) {
          // if the image fails to load, remove the object url from the cache
          // to fetch it again
          // but only if we haven't retried too many times
          if (src) objectUrlCache.remove(namespace, src)
          setObjectUrl(undefined)
          setRetries(retries + 1)
        }
      }}
      {...rest}
    />
  )
})
