import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'

export function roomStateName({
  roomState,
  section,
  usersMap,
}: {
  roomState: RoomState
  section: Section
  usersMap: Map<string, PublicUser>
}) {
  let groupName = roomState.roomStateName
  if (!groupName || groupName === '') {
    if (roomState.groupLeaderUserIds.length > 0) {
      const groupLeaderId = roomState.groupLeaderUserIds[0]
      const groupLeader = usersMap.get(groupLeaderId)
      if (groupLeader) {
        groupName = groupLeader.fullName
      } else if (roomState.groupLeaderUserIds.includes(section.instructor.id)) {
        groupName = section.instructor.fullName
      }
    }
  }
  return groupName || 'Group has no name'
}
