import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { Dialog } from 'components/dialogs/Dialog'
import { SuspenseErrorBoundary } from 'components/SuspenseErrorBoundary'
import { observer } from 'mobx-react-lite'
import { Suspense, lazy } from 'react'

const QuizRunner = lazy(
  () => import('components/PreWorkQuizRunner/PreWorkQuizRunner')
)

const PreWorkModalBody = observer(function PreWorkModalBody({
  closeModal,
  cubit,
}: {
  closeModal: () => void
  cubit: StudentAssignmentCubit
}) {
  if (cubit.questions.isLoading) {
    return <div>Loading...</div>
  }
  // take over the screen
  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-surface">
      <SuspenseErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <QuizRunner cubit={cubit} closeModal={closeModal} />
        </Suspense>
      </SuspenseErrorBoundary>
    </div>
  )
})

export function QuizModal({
  cubit,
  closeModal,
}: {
  cubit: StudentAssignmentCubit
  closeModal: () => void
}) {
  return (
    <Dialog size="xxl" ignoreBackdropClick ignoreEscapeKey>
      <PreWorkModalBody closeModal={closeModal} cubit={cubit} />
    </Dialog>
  )
}
