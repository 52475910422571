import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { SlideQuestionType } from '@breakoutlearning/firebase-repository/models/SlideQuestionType'
import { Spinner } from 'components/Spinner'
import { EmptyState } from 'components/breakout/EmptyState'
import { ChevronRight } from 'components/icons/ChevronRight'
import { GraphBarIcon } from 'components/icons/GraphBar'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PollDetailRow } from '../poll_components/PollDetailRow'
import classNames from 'classnames'
import { useRootStore } from 'hooks/rootStore'

export const SummaryPolls = observer(function SummaryPolls({
  className,
}: {
  className?: string
}) {
  const store = useRootStore()
  const cubit = useInstructorAssignmentCubit()
  const { t } = useTranslation()
  const onClick = useCallback(() => {
    cubit.changeResultsTab('polls')
    cubit.changeTab('results', (q) => store.updateQueryParams(q))
  }, [cubit, store])

  const [hasPolls, hasAnswers, pollEntries] = useMemo(() => {
    return [
      cubit.polls.size > 0,
      Array.from(cubit.polls.values()).some((answers) => answers.length),
      Array.from(cubit.polls.entries()),
    ]
  }, [cubit.polls])

  if (cubit.questionsAnswerDataIsLoading) {
    return (
      <div className="flex w-full flex-col items-center justify-center md:h-full">
        <Spinner />
      </div>
    )
  }
  if (!hasPolls || !hasAnswers) {
    return (
      <EmptyState
        Icon={GraphBarIcon}
        text={t('instructor_assignment.polls_missing')}
      />
    )
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between border-b border-border-grey pb-4">
        <div tabIndex={0}>
          <h1 className="text-title-large">
            {t('instructor_assignment.polls')}
          </h1>
          <div className="text-body-medium text-grey-text">
            {t('instructor_assignment.n_answers_captured', {
              count: cubit.uniquePollAnswerCount,
            })}
          </div>
        </div>
        <div
          className="text-body-medium flex cursor-pointer items-center justify-between text-grey-text"
          onClick={onClick}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick()
            }
          }}
        >
          {t('instructor_assignment.view_details')}
          <strong className="text-grey-text">
            <ChevronRight size={14} />
          </strong>
        </div>
      </div>
      <div className="flex flex-wrap pt-4">
        {pollEntries.map(([question, answers], index) => {
          if (question.data.questionType === SlideQuestionType.poll)
            return (
              <div
                tabIndex={0}
                key={index}
                className={classNames(
                  'flex border-b border-border-grey px-4 lg:w-1/2'
                )}
              >
                <SimplePoll
                  key={question.id}
                  answers={answers}
                  index={index}
                  question={question}
                />
              </div>
            )
          if (question.data.questionType === SlideQuestionType.customPoll)
            return (
              <div
                tabIndex={0}
                key={index}
                className="flex border-b border-border-grey px-4 lg:w-1/2"
              >
                <CustomPoll
                  key={question.id}
                  answers={answers}
                  index={index}
                  question={question}
                />
              </div>
            )
          return null
        })}
      </div>
    </div>
  )
})

const SimplePoll = observer(function SimplePoll({
  answers,
  index,
  question,
}: {
  answers: RoomStateAnswer[]
  index: number
  question: SlideQuestion
}) {
  const yes = answers.filter((answer) => answer.data.answer === 1).length
  const yesPercentage = (yes / answers.length) * 100
  const noPercentage = 100 - yesPercentage
  const { t } = useTranslation()

  const [yesStr, noStr] = useMemo(() => {
    return [t('instructor_assignment.yes'), t('instructor_assignment.no')]
  }, [t])
  const height = 150

  return (
    <div
      className="flex w-full flex-col justify-between py-4"
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
      }}
    >
      <strong className="text-body-medium text-grey-text">
        {t('instructor_assignment.poll_n', { count: index + 1 })}
      </strong>
      <div className="flex-grow">
        <strong className="text-body-medium line-clamp-3">
          {question.data.question}
        </strong>
      </div>
      <div className="flex w-full flex-col">
        <PollDetailRow
          answer={yesStr}
          title={yesStr}
          selectedPercentage={yesPercentage}
        />
        <PollDetailRow
          answer={noStr}
          title={noStr}
          selectedPercentage={noPercentage}
        />
      </div>
    </div>
  )
})

const CustomPoll = ({
  answers,
  index,
  question,
}: {
  answers: RoomStateAnswer[]
  index: number
  question: SlideQuestion
}) => {
  //todo: do we even need this for the layout?
  const height = Math.max(150, 24 * question.data.answers.length + 100)
  const { t } = useTranslation()

  return (
    <div
      className="flex w-full flex-col justify-between py-4"
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
      }}
    >
      <strong className="text-title-medium mr-3">
        {t('instructor_assignment.poll_n', { count: index + 1 })}
      </strong>
      <div className="flex-grow">
        <strong className="text-body-medium line-clamp-3">
          {question.data.question}
        </strong>
      </div>
      <div className="flex w-[250px] min-w-[250px] flex-col">
        {question.data.answers.map((answer, i) => {
          const selected = answers.filter((a) => a.data.answer === i).length
          const selectedPercentage = (selected / answers.length) * 100
          return (
            <PollDetailRow
              key={i}
              answer={answer}
              title={String.fromCharCode(i + 65)}
              selectedPercentage={selectedPercentage}
            />
          )
        })}
      </div>
    </div>
  )
}
