import classnames from 'classnames'

export function ShadowBox(
  props: React.PropsWithChildren<{
    className?: string
  }>
): React.ReactElement {
  return (
    <div
      className={classnames(
        'mx-auto h-[250px] w-[250px] rounded-2xl bg-core-secondary shadow-3xl',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}
