import { useEffect, useMemo } from 'react'
import { ObjectUrlCacheManager, ObjectUrlContext } from 'hooks/objectUrl'

export const ObjectUrlContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const manager = useMemo(() => new ObjectUrlCacheManager(), [])

  useEffect(() => {
    // reset the entire cache every hour - this is to be a good citizen
    // and pre-emptively prevent memory leaks
    const interval = setInterval(() => manager.clearAll(), 1000 * 60 * 60)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <ObjectUrlContext.Provider value={{ manager }}>
      {children}
    </ObjectUrlContext.Provider>
  )
}
