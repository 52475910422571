import { DialogContext } from 'components/contexts/dialogs'
import { useContext } from 'react'

export function useDialogs() {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('useDialogs must be used within an DialogContext provider')
  }
  return context
}
