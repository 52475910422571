import type { SectionUserData } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { SectionState } from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { RubricScoreWidget } from 'components/breakout/RubricScoreWidget'
import { BreakoutIconButton } from 'components/design-system/BreakoutButton'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { DownloadIcon } from 'components/icons/Download'
import { Search } from 'components/icons/Search'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { AssignmentRubricGroupDialog } from 'pages/instructor/dialogs/AssignmentRubricGroupDialog'
import { useTranslation } from 'react-i18next'
import { downloadSectionUserDataAsCSV } from 'util/csv'
import { formatFilename } from 'util/strings'

export const StudentsView = observer(function StudentsView() {
  const cubit = useInstructorSectionCubit()
  const { t } = useTranslation()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const filter = formData.get('filter') as string
    cubit.addFilterChip(filter)
    // clear form
    e.currentTarget.reset()
  }

  if (cubit.section.isLoading) {
    return (
      <div className="flex w-full flex-col items-center justify-center md:h-full">
        <Spinner />
      </div>
    )
  }

  if (cubit.section.data.userIds.length > 0) {
    return (
      <table role="presentation" className="breakout-table w-full text-left">
        <thead className="text-title-small w-full">
          <tr>
            <td className="header rounded-bl-xl rounded-tl-xl pl-4" colSpan={2}>
              {t('instructor_class.full_name')}
            </td>
            <td className="header">
              <span className="pl-2">{t('instructor_class.assignment')}</span>
            </td>
            <td className="header">
              <span className="pl-2">{t('instructor_class.quiz_results')}</span>
            </td>
            <td className="header pr-4">
              <span>{t('instructor_class.rubric_assessment')}</span>
            </td>
            <td className="header actions flex flex-row justify-end">
              <form onSubmit={onSubmit}>
                <BreakoutTextInput
                  name="filter"
                  LeadingIcon={Search}
                  kind="tertiary"
                  placeholder={t('instructor_class.filter_names')}
                  className="w-[160px] pr-1"
                />
              </form>
              <BreakoutIconButton
                onClick={() => {
                  // Ex. business_211_section_1_students.csv
                  const filename = formatFilename({
                    nameParts: [
                      cubit.section.data.className,
                      cubit.section.data.sectionName,
                      t('instructor_class.students'),
                    ],
                    extension: 'csv',
                  })
                  downloadSectionUserDataAsCSV(
                    cubit.slideDeckMap,
                    cubit.sectionUserData,
                    filename
                  )
                }}
                aria-title={t('instructor_class.download_csv')}
                title={t('instructor_class.download_csv')}
                kind="tertiary"
                icon={<DownloadIcon size={20} />}
                className="!bg-transparent"
              />
            </td>
          </tr>
        </thead>
        <tbody className="divide-b-1 divide-outline-variant">
          {cubit.filterChips.length > 0 && (
            <tr className="border-b-1 border-outline-variant">
              <td colSpan={5}>
                <div>
                  <div className="py-0">
                    <BreakoutPill.Chips
                      labels={cubit.filterChips}
                      onClick={(label) => cubit.removeFilterChip(label)}
                    />
                  </div>
                </div>
              </td>
            </tr>
          )}
          {cubit.sectionUserDataFiltered.map((sectionData) => {
            return (
              <UserRow key={sectionData.user.id} sectionData={sectionData} />
            )
          })}
        </tbody>
      </table>
    )
  }
  return (
    <div className="flex h-full w-full items-center justify-center rounded-3xl bg-light-grey px-10 pt-8">
      {/* z index to position over image */}
      <div className="z-10 flex h-[202px] flex-col gap-3 text-center">
        <h2 className="text-headline-large" tabIndex={0}>
          {t('instructor_class.no_students')}
        </h2>
        {cubit.section.data.sectionState !== SectionState.completed && (
          <strong className="text-body-medium line-clamp-2" tabIndex={0}>
            {t('instructor_class.no_students_description')}
          </strong>
        )}
      </div>
      <img
        tabIndex={0}
        alt={t('instructor_class.no_students_alt')}
        className="absolute bottom-0 left-10 h-[277px] w-[424px]"
        src="/assets/images/bg2.png"
      />
    </div>
  )
})

const UserRow = observer(function UserRow({
  sectionData,
}: {
  sectionData: SectionUserData
}) {
  const assignments = Array.from(sectionData.assignment.entries())
  const user = sectionData.user

  return (
    <>
      <tr className="border-none">
        <td className="w-14 pl-4" rowSpan={assignments.length + 1}>
          <BreakoutUserAvatar radius={18} user={user} />
        </td>
        <td className="" rowSpan={assignments.length + 1}>
          <strong className="text-title-small">{user.fullName}</strong>
        </td>
      </tr>
      {assignments.map(([roomState, assignment], index) => {
        // if this is the last field in the array, don't add the bottom border
        const isLast = index === assignments.length - 1
        // if user is is not part of the assignment (no corresponding roomState), don't render
        // we return an empty row here because we can't return null without breaking the table
        if (roomState.isEmpty)
          return <tr key={`${user.id}-${roomState.id}-${assignment.id}`} />
        return (
          <UserAssignmentRow
            key={`${user.id}-${roomState.id}-${assignment.id}`}
            roomState={roomState}
            sectionData={sectionData}
            assignment={assignment}
            isLast={isLast}
          />
        )
      })}
    </>
  )
})

const UserAssignmentRow = observer(function UserAssignmentRow({
  assignment,
  isLast,
  roomState,
  sectionData,
}: {
  assignment: SectionAssignment
  isLast: boolean
  roomState: RoomState
  sectionData: SectionUserData
}) {
  const { showDialog } = useDialogs()
  const cubit = useInstructorSectionCubit()
  const slideDeck = cubit.slideDeckMap.get(assignment.data.slideDeckId)
  const quizScore = sectionData.quizScore.get(roomState) || 0
  const rubricResults =
    sectionData.rubricResults.get(roomState) ||
    new Map<SlideRubric, RoomStateRubricResult[]>()
  const assignmentCubit = cubit.assignmentCubits.get(assignment.id)
  const dialogResults = sectionData.rubricResults.get(roomState)
  const groupData = assignmentCubit?.assignmentGroupData.find(
    (groupData) => groupData.roomState?.id === roomState.id
  )

  // do nothing with isLast for now
  if (isLast) {
    // no op
  }

  return (
    <tr className="border-b-1 border-outline-variant">
      <td className="border-l-1 border-outline-variant">
        <div className="flex items-center space-x-2 pl-4">
          <img
            alt={slideDeck?.data.slideDeckName}
            src={slideDeck?.data.slideDeckImageURL}
            className="h-7 w-7"
          />
          <strong className="text-body-medium">
            {slideDeck?.data.slideDeckName}
          </strong>
        </div>
      </td>
      <td className=" items-center py-2">
        <span className="pl-2">
          {(((quizScore || 0) * 1000) / 10).toFixed(1)}%
        </span>
      </td>
      <td className=" items-center py-2">
        <div
          className="flex flex-row gap-2"
          onClick={
            groupData
              ? () => {
                  showDialog(() => (
                    <AssignmentRubricGroupDialog
                      groupData={groupData}
                      groupResults={dialogResults}
                      singleUserMode={true}
                      userId={sectionData.user.id}
                    />
                  ))
                }
              : undefined
          }
        >
          {/* iterate over every rubricResults and call ScoreBox*/}
          {Array.from(rubricResults.entries()).map(
            ([rubric, rubricResults]) => {
              return (
                <RubricScoreWidget
                  key={rubric.id}
                  rubric={rubric}
                  rubricResult={rubricResults[0]}
                />
              )
            }
          )}
        </div>
      </td>
      {/* Empty td to correspond to the filter th above */}
      <td />
    </tr>
  )
})
