import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import type { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'

export const EndClassDialog = observer(function EndClassDialog({
  cubit,
}: {
  cubit: InstructorSectionCubit
}) {
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_class.${key}`),
    [t]
  )
  const { popDialog } = useDialogs()
  const store = useRootStore()

  return (
    <Dialog testId="end-class-dialog" size="xs">
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <div className="flex h-[400px] flex-col justify-between">
        <div className="flex flex-col gap-3">
          <h1 className="text-headline-large">{tScoped('end_class')}</h1>
          <p className="text-body-large">{tScoped('end_class_confirmation')}</p>
        </div>
        <div className="flex flex-col gap-3">
          <BreakoutButton
            className="w-full"
            size="large"
            kind="error"
            onClick={() => {
              cubit.closeSection()
              popDialog()
              // go to classes page
              store.navigateTo('instructorClasses')
            }}
          >
            {tScoped('yes')}
          </BreakoutButton>
          <BreakoutButton
            className="w-full"
            size="large"
            kind="secondary"
            onClick={() => {
              popDialog()
            }}
          >
            {tScoped('no')}
          </BreakoutButton>
        </div>
      </div>
    </Dialog>
  )
})
