import { Environment, environment } from 'config/environment'
import { useCallback, useState } from 'react'

function toggleDarkMode(state: boolean) {
  if (state) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

// eslint-disable-next-line import/no-unused-modules
export function changeDarkMode(state: boolean) {
  if (state === true) {
    localStorage.setItem('darkMode', 'on')
    toggleDarkMode(state)
  } else {
    localStorage.setItem('darkMode', 'off')
    toggleDarkMode(state)
  }
}

function getDarkModeState() {
  return document.documentElement.classList.contains('dark')
}

export function useDarkModeSettings() {
  const [darkMode, setDarkModeState] = useState(getDarkModeState())

  const setDarkMode = useCallback((state: boolean) => {
    setDarkModeState(state)
    changeDarkMode(state)
  }, [])

  return {
    darkMode,
    setDarkMode,
  }
}

export function startDarkModeWatcher() {
  // disabled until we have a proper dark mode implementation
  return
  if (environment() !== Environment.development) return

  if (localStorage.getItem('darkMode') === 'on') {
    toggleDarkMode(true)
  }
  if (window.matchMedia) {
    const match = window.matchMedia('(prefers-color-scheme: dark)')
    if (!localStorage.getItem('darkMode')) toggleDarkMode(match.matches)

    match.addEventListener('change', () => {
      if (!localStorage.getItem('darkMode')) toggleDarkMode(match.matches)
    })
  }
}
