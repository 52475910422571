import { createContext, useContext } from 'react'

type ObjectUrlCacheEntry = {
  namespace: string
  url: string
  // expires: number
}

export class ObjectUrlCacheManager {
  private cache: Record<string, ObjectUrlCacheEntry> = {}

  get(namespace: string, path: string | null | undefined): string | undefined {
    if (!path) return undefined

    const key = `${namespace}:${path}`
    const entry = this.cache[key]
    return entry?.url || undefined
  }

  set(namespace: string, path: string, url: string) {
    const key = `${namespace}:${path}`
    this.cache[key] = {
      namespace,
      url,
    }
  }

  remove(namespace: string, path: string) {
    const key = `${namespace}:${path}`
    const entry = this.cache[key]
    if (entry) {
      URL.revokeObjectURL(entry.url)
      delete this.cache[key]
    }
  }

  clear(namespace: string) {
    for (const key in this.cache) {
      const entry = this.cache[key]
      if (entry.namespace === namespace) {
        URL.revokeObjectURL(entry.url)
        delete this.cache[key]
      }
    }
  }

  clearAll() {
    for (const key in this.cache) {
      const entry = this.cache[key]
      URL.revokeObjectURL(entry.url)
    }
    this.cache = {}
  }
}

type ObjectUrlContextStore = { manager: ObjectUrlCacheManager }

export const ObjectUrlContext = createContext<ObjectUrlContextStore | null>(
  null
)

export function useObjectUrlCacheManager() {
  const context = useContext(ObjectUrlContext)
  if (!context) {
    throw new Error(
      'useStorageUrlManager must be used within a StorageUrlContext'
    )
  }
  return context.manager
}
