import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useCubitBuilder } from 'hooks/cubits'
import { PreviewMeetingCubit } from '@breakoutlearning/firebase-repository/cubits/PreviewMeetingCubit'
import { useBreakoutUser } from 'hooks/profile'
import { useRepository } from 'hooks/auth'
import { MeetingResultsSlideView2 } from 'pages/meeting/MeetingPanelMain/MeetingResultsSlideView2'
import { MeetingResultsSlide } from '@breakoutlearning/firebase-repository/cubits/meeting/slides/MeetingResultsSlide'
import { SlideModel } from '@breakoutlearning/firebase-repository/models/SlideModel'
import { Spinner } from 'components/Spinner'
import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { RoomStateEngagement } from '@breakoutlearning/firebase-repository/models/RoomStateEngagement'
import type { RoomStateRubricResultDetail } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResultDetail'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
// Eagerly load the ConversationMapAnimatedGraphBucketed component
// It's used in MeetingResultsSlideView2, but it's lazy load might happen later,
// which means that if we deploy a new version of the app, the component might not be available
// and will cause a page reload
// Be doing this, we ensure that the component is available when needed and no reload is needed
;(async () => {
  await import(
    'pages/meeting/MeetingPanelMain/MeetingResultsSlideView2/ConversationMapAnimatedGraphBucketed'
  )
})()

export const MeetingResultsDialog = observer(function MeetingResultsDialog({
  roomId,
  roomStateAnswersForGroup,
  roomStateAnswersPerUser,
  roomState,
  roomStateEngagement,
  rubricResults,
  rubricResultDetails,
  slideDeckId,
  users,
}:
  | {
      roomId: string
      roomStateAnswersForGroup?: never
      roomStateAnswersPerUser?: never
      roomState?: never
      roomStateEngagement?: never
      rubricResults?: never
      rubricResultDetails?: never
      slideDeckId?: never
      users?: never
    }
  | {
      roomId?: never
      roomStateAnswersForGroup: RoomStateAnswer[]
      roomStateAnswersPerUser: Map<string, RoomStateAnswer[]>
      roomState: RoomState
      roomStateEngagement: RoomStateEngagement[]
      rubricResults: RoomStateRubricResult[]
      rubricResultDetails: RoomStateRubricResultDetail[]
      slideDeckId: string
      users: PublicUser[]
    }) {
  const user = useBreakoutUser()
  const repository = useRepository()

  // init cubits
  const meetingCubit = useCubitBuilder(() => {
    if (roomId !== undefined) {
      return new PreviewMeetingCubit({
        roomId,
        repository,
        currentUser: user,
      })
    }
    return new PreviewMeetingCubit({
      roomId,
      slideDeckId,
      repository,
      currentUser: user,
      seedData: {
        roomStateAnswersForGroup,
        roomStateAnswersPerUser,
        roomState,
        users,
      },
    })
  }, [repository, user, roomId])

  const dataReady =
    meetingCubit.roomState.isLoaded && meetingCubit.slideDeck.isLoaded

  // create a mock slide
  const resultsSlide = new MeetingResultsSlide(
    meetingCubit,
    SlideModel.empty(repository),
    0
  )

  return (
    <Dialog
      size="xl"
      className="flex flex-col justify-start overflow-hidden bg-core-secondary"
      innerClassName="!p-0 flex"
    >
      <DialogCloseButton className="absolute right-0 top-0 pb-0" />
      {dataReady ? (
        <div className="h-full overflow-auto">
          <MeetingResultsSlideView2
            slide={resultsSlide}
            inDialog={true}
            seedData={
              roomStateEngagement &&
              rubricResultDetails &&
              rubricResults && {
                roomStateEngagement,
                rubricResultDetails,
                rubricResults,
              }
            }
          />
        </div>
      ) : (
        <div className="flex w-full flex-grow items-center justify-center">
          <Spinner />
        </div>
      )}
    </Dialog>
  )
})
