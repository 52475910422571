import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { UserPromotion } from '@breakoutlearning/firebase-repository/models/UserPromotion'
import type { UserPromotionRedemption } from '@breakoutlearning/firebase-repository/models/UserPromotionRedemption'
import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { FormLabel } from 'components/design-system/form/FormLabel'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { useUserPromotions } from 'hooks/useUserPromotions'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const PromotionSelectionWidget = observer(
  function PromotionSelectionWidget({
    kind = 'secondary',
    selectClassName,
    section,
    onChange,
  }: {
    kind?: 'secondary' | 'tertiary'
    selectClassName?: string
    section?: Section
    onChange: (ids: string[]) => void
  }) {
    const { t } = useTranslation()
    const {
      hasPromotions,
      selectedUserPromotion,
      promotionIsActive,
      promotionIsConsumable,
      selectedUserPromotionIds,
      userPromotionOptions,
      userPromotions,
      userPromotionRedemptions,
      setSelectedUserPromotionIds,
      setSelectedUserPromotion,
    } = useUserPromotions(section)

    let tooltip: string | undefined = undefined
    if (selectedUserPromotion && !promotionIsActive) {
      tooltip = t('instructor_library.promotion_not_active')
    } else if (selectedUserPromotion && !promotionIsConsumable) {
      tooltip = t('instructor_library.promotion_not_consumable')
    }

    if (!hasPromotions) return null

    return (
      <div className="flex-1">
        <div className="flex flex-col items-start gap-1">
          <FormLabel label={t('instructor_library.apply_promotions')} />
          <div className="flex w-full flex-row items-center justify-between gap-2">
            <BreakoutSelect
              inputClassName={selectClassName}
              fullWidth
              kind={kind}
              value={selectedUserPromotion}
              onChange={(value) => {
                setSelectedUserPromotion(value)
              }}
              aria-label={t('instructor_library.select_promotion')}
              className="!w-full"
              options={userPromotionOptions}
            />
            <BreakoutButton
              size="large"
              disabled={
                !selectedUserPromotion ||
                !promotionIsConsumable ||
                !promotionIsActive
              }
              kind={kind}
              className="!max-w-[100px]"
              tooltip={tooltip}
              onClick={() => {
                if (!promotionIsConsumable) return
                if (selectedUserPromotion) {
                  const newValues = [
                    ...selectedUserPromotionIds,
                    selectedUserPromotion,
                  ]
                  onChange(newValues)
                  setSelectedUserPromotionIds(newValues)
                  setSelectedUserPromotion(null)
                }
              }}
            >
              {t('instructor_library.apply')}
            </BreakoutButton>
          </div>
          <div className="flex max-h-[350px] w-full flex-col gap-2 overflow-auto">
            {selectedUserPromotionIds.map((id) => {
              const userPromotion = userPromotions?.find((p) => p.id === id)
              const redemptions = userPromotionRedemptions?.get(id) || []
              if (!userPromotion) return null

              return (
                <UserPromotionRow
                  key={id}
                  userPromotion={userPromotion}
                  redemptions={redemptions}
                  onRemove={() => {
                    setSelectedUserPromotionIds(
                      selectedUserPromotionIds.filter((i) => i !== id)
                    )
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
)

const UserPromotionRow = observer(function UserPromotionRow({
  userPromotion,
  redemptions,
  onRemove,
}: {
  userPromotion: UserPromotion
  redemptions: UserPromotionRedemption[]
  onRemove?: () => void
}) {
  const { t } = useTranslation()
  const data = userPromotion.data
  const isActive = userPromotion.isActive
  const consumed = redemptions.length
  const expirationDateTime = DateTime.fromJSDate(data.expirationDate)
  return (
    <div className="flex flex-row  items-center justify-between rounded-2xl bg-surface p-5">
      <div>
        <div
          className={classNames('text-label-small uppercase', {
            'text-core-error': !isActive,
            'text-core-success': isActive,
          })}
        >
          {isActive ? t('profile.active') : t('profile.inactive')}
        </div>
        <div className="text-label-medium">{data.promotionName}</div>
        <div className="text-body-small text-on-surface-var">
          {t('profile.consumed_x_of_y', {
            consumed,
            total: data.consumableCount,
          })}
        </div>
        <div className="text-body-small text-on-surface-var">
          {t('profile.expires_at', {
            expirationDate: expirationDateTime.toLocaleString(
              DateTime.DATE_FULL
            ),
          })}
        </div>
      </div>
      {redemptions.length === 0 && (
        <BreakoutTooltip
          content={t('instructor_library.delete_promotion_tooltip')}
          className="max-w-[330px]"
        >
          <div className="text-center">
            <TrashCanIcon
              onClick={onRemove}
              size={15}
              className="cursor-pointer"
            />
          </div>
        </BreakoutTooltip>
      )}
    </div>
  )
})
