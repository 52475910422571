import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { Spinner } from 'components/Spinner'
import { useOrganizationCubit } from 'hooks/cubits/organization'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditProfessorDialog } from './EditProfessorDialog'
import type { OrganizationInstructorDetails } from '@breakoutlearning/firebase-repository/types'
import type { OrganizationCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationCubit'

export const OrganizationInstructorsView = observer(
  function OrganizationInstructorsView() {
    const cubit = useOrganizationCubit()
    const { t } = useTranslation()

    const [filters, setFilters] = useState<string[]>([])

    const filteredInstructors = useMemo(() => {
      if (filters.length === 0) {
        return cubit.instructorsForOrganization.users
      }
      return cubit.instructorsForOrganization.users.filter((instructor) => {
        return filters.some((filter) =>
          instructor.publicUser.fullName
            .toLowerCase()
            .includes(filter.toLowerCase())
        )
      })
    }, [cubit.instructorsForOrganization.users, filters])

    if (cubit.instructorsForOrganization.loading) {
      return (
        <div className="flex h-full -translate-y-20 items-center justify-center">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="h-full min-h-0 rounded-3xl bg-surface p-6">
        <div className="h-full overflow-auto rounded-3xl bg-core-tertiary">
          <BreakoutTable className="white max-h-full">
            <thead>
              <tr className="sticky top-0 z-[10]">
                <th>{t('organization.name')}</th>
                <th>{t('organization.auto_approve_invoices')}</th>
                <th>
                  <BreakoutTextInput
                    kind="secondary"
                    className="float-right !min-w-[230px] max-w-[300px]"
                    placeholder={t('organization.filter_by_instructor_name')}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'Enter' &&
                        e.currentTarget.value.trim() !== ''
                      ) {
                        e.preventDefault()
                        setFilters([...filters, e.currentTarget.value])
                        e.currentTarget.value = ''
                      }
                    }}
                    LeadingIcon={Search}
                  />
                </th>
              </tr>
              {filters.length > 0 && (
                <tr>
                  <td colSpan={3}>
                    <div className="py-3">
                      <BreakoutPill.Chips
                        labels={filters}
                        onClick={(label) =>
                          setFilters((oldFilters) => {
                            const filtersCopy = [...oldFilters]
                            return filtersCopy.filter((item) => item !== label)
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
              )}
            </thead>
            <tbody className="max-h-full overflow-auto">
              {filteredInstructors.length === 0 && (
                <tr>
                  <td className="text-center" colSpan={3}>
                    <div className="p-5">
                      {t('organization.no_instructors_found')}
                    </div>
                  </td>
                </tr>
              )}
              {filteredInstructors.map((user) => (
                <Row
                  key={user.publicUser.id}
                  instructorDetails={user}
                  updateOrgInstructor={cubit.updateOrgInstructor}
                  cubit={cubit}
                />
              ))}
            </tbody>
          </BreakoutTable>
        </div>
      </div>
    )
  }
)

const Row = observer(function ClassRow({
  instructorDetails: { publicUser, orgInstructor },
  updateOrgInstructor,
  cubit,
}: {
  instructorDetails: OrganizationInstructorDetails
  updateOrgInstructor: OrganizationCubit['updateOrgInstructor']
  cubit: OrganizationCubit
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  return (
    <tr>
      <td>
        <div className="flex h-full items-center justify-start gap-1 border-none">
          <BreakoutUserAvatar user={publicUser} radius={13} />
          <strong className="text-label-medium">{publicUser.fullName}</strong>
        </div>
      </td>
      <td>
        <strong className="text-label-medium">
          {(orgInstructor.autoApproveInvoice ?? false)
            ? t('organization.yes')
            : t('organization.no')}
        </strong>
      </td>

      <td>
        <div className="flex   justify-end gap-2">
          <BreakoutButton
            kind="secondary"
            className="!min-w-[62px]"
            onClick={() => {
              showDialog(() => (
                <EditProfessorDialog
                  removeInstructorFromOrganization={cubit.removeInstructor}
                  orgId={cubit.organizationId}
                  orgInstructor={orgInstructor}
                  publicUser={publicUser}
                  updateOrgInstructor={updateOrgInstructor}
                  getCatalogsForInstructor={(userId) =>
                    cubit.getCatalogsForInstructor(userId)
                  }
                  updateCatalogsForUser={(params) =>
                    cubit.updateCatalogsForUser(params)
                  }
                  catalogs={cubit.catalogs}
                />
              ))
            }}
            size="medium"
          >
            {t('organization.edit')}
          </BreakoutButton>
        </div>
      </td>
    </tr>
  )
})
