import type { ValidLibraryObject } from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import { StudentLibraryCard } from 'components/design-system/cards/StudentLibraryCard'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'

export const ListItem = observer(function ListItem({
  libraryObject,
}: {
  libraryObject: ValidLibraryObject
}) {
  const store = useRootStore()

  return (
    <StudentLibraryCard
      data-testid={`library-object-list-item-${libraryObject.libraryObjectId}`}
      imageUrl={libraryObject.slideDeck.data.slideDeckImageURL}
      title={libraryObject.slideDeck.data.slideDeckName}
      subtitle={libraryObject.slideDeck.data.slideDeckTeaser}
      instructorName={libraryObject.section.instructor.fullName || ''}
      sectionName={libraryObject.section.data.sectionName}
      className={libraryObject.section.data.className}
      state={libraryObject.libraryObjectState}
      scheduledAt={libraryObject.roomState.scheduledAtDate}
      onJoinSessionClick={() => {
        const roomStateId = libraryObject.roomState.id
        const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
        store.navigateTo(route, {
          assignmentId: libraryObject.assignment.id,
          sectionId: libraryObject.section.id,
          roomStateId: libraryObject.roomState.id,
        })
      }}
      onClick={() => {
        const roomStateId = libraryObject.roomState.id
        const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
        store.navigateTo(route, {
          assignmentId: libraryObject.assignment.id,
          sectionId: libraryObject.section.id,
          roomStateId: libraryObject.roomState.id,
        })
      }}
    />
  )
})
