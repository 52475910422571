import classNames from 'classnames'
import type { FormFieldDefaults } from './types'
import { FormLabel } from './form/FormLabel'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import { FormError } from './form/FormError'

interface BreakoutInputProps extends FormFieldDefaults<'textarea'> {
  // kind secondary is the default and represents the "primary" style in figma
  // tertiary is the secondary style in figma
  kind?: 'secondary' | 'tertiary'
  containerClassName?: string
  inputClassName?: string
}

export const BreakoutTextArea = forwardRef(function BreakoutTextArea(
  {
    disabled,
    kind = 'tertiary',
    name,
    label,
    labelClass,
    containerClassName,
    inputClassName,
    testId,
    error,
    errorClass,
    required,
    ...rest
  }: BreakoutInputProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <div
      className={classNames(
        'flex flex-col items-start gap-1',
        containerClassName
      )}
    >
      <FormLabel
        label={label}
        name={name}
        labelClass={labelClass}
        required={required}
      />

      <textarea
        data-testid={testId || name ? `${name}-textarea` : undefined}
        ref={ref}
        tabIndex={0}
        name={name}
        disabled={disabled}
        className={classNames(
          'text-label-medium max-h-[250px] min-h-[150px] w-full rounded-2xl p-4 outline-none',
          {
            'bg-core-secondary': kind === 'secondary' && !disabled,
            'bg-core-tertiary': kind === 'tertiary' && !disabled,
            'bg-surface-dim': kind === 'secondary' && disabled,
            'bg-surface': kind === 'tertiary' && disabled,
            'text-on-surface-disabled': disabled,
            'focus:ring-1 focus:ring-outline-variant': !error,
            'border border-core-error focus:ring-1 focus:ring-core-error':
              !!error,
            'cursor-not-allowed': disabled,
          },
          inputClassName
        )}
        {...rest}
      />
      <FormError error={error} errorClass={errorClass} />
    </div>
  )
})
