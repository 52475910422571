import './config/sentry.ts'
import { createRoot } from 'react-dom/client'
import 'hooks/settings'
import { AuthenticationContextProvider } from './components/core/AuthContext.tsx'
import { Entrypoint } from './components/core/Entrypoint.tsx'
import './index.css'
import { startDarkModeWatcher } from 'util/darkMode.ts'
import { startVersionMonitor } from 'util/commitSha.ts'
import { startFirestoreDocTracker } from 'util/firestore/counter.ts'
import './util/wdio.helpers'
import { Environment, environment } from 'config/environment.ts'
import { startFetchWrapper } from 'util/fetchWrapper.ts'
import { startSkipToMainContentWatcher } from 'util/skipToMainContent.ts'

startDarkModeWatcher()

startFetchWrapper()

startSkipToMainContentWatcher()

if (environment() !== Environment.production) {
  startFirestoreDocTracker()
}

if (environment() !== Environment.development) {
  startVersionMonitor()
}

// This is a handler for a situation where the app is running, it's requesting
// a new chunk, but we've deployed a new version of the app and that chunk is
// not available. This will force a reload of the app. Preloads only happen
// on navigation, so this is safe.
window.addEventListener('vite:preloadError', (e) => {
  e.preventDefault()
  window.location.reload()
})

createRoot(document.getElementById('root')!).render(
  <AuthenticationContextProvider>
    <Entrypoint />
  </AuthenticationContextProvider>
)
