import type { Catalog } from '@breakoutlearning/firebase-repository/models/Catalog'
import type { SlideDeckGroup } from '@breakoutlearning/firebase-repository/stores/SlideDeckGroup'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { AdminSlideDeckVersionTile } from '../components/AdminSlideDeckVersionTile'

export const SlideDeckGroupDialog = observer(function SlideDeckGroupDialog({
  slideDeckGroup,
  catalogs,
  onSlideDeckClick,
}: {
  slideDeckGroup: SlideDeckGroup
  catalogs: Catalog[]
  onSlideDeckClick?: (slideDeckId: string) => void
}) {
  const imageUrl = slideDeckGroup.imageUrl
  const title = slideDeckGroup.name
  const subtitle = slideDeckGroup.teaser

  const store = useRootStore()

  const onSlideDeckTileClick = useCallback(
    (slideDeckId: string) => {
      if (onSlideDeckClick) return onSlideDeckClick(slideDeckId)
      store.navigateToAdminSlideDeck(slideDeckId)
    },
    [onSlideDeckClick, store]
  )

  return (
    <Dialog size="md">
      <DialogCloseButton />

      <div className="flex flex-row items-center">
        {imageUrl && (
          <div className="flex h-[50px] w-[50px] flex-col items-center justify-center ">
            <img src={imageUrl} className="inline max-h-[30px] max-w-[30px]" />
          </div>
        )}
        {!imageUrl && (
          <div className="flex h-[50px] w-[50px] flex-col items-center justify-center ">
            <img className="inline max-h-[30px] max-w-[30px]" />
          </div>
        )}
        <div>
          <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
            {title}
          </h2>
          {subtitle && (
            <h2 className="text-title-large line-clamp-2 text-on-surface">
              {subtitle}
            </h2>
          )}
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-3">
        {slideDeckGroup.sortedSlideDecks.map((slideDeck) => {
          const catalogNames = slideDeck.data.catalogIds
            .map((catalogId) =>
              catalogs.find((catalog) => catalog.id === catalogId)
            )
            // Typescript 5.5 cannot come soon enough
            .filter((catalog): catalog is Catalog => !!catalog)
            .map((catalog) => catalog.data.catalogName)

          return (
            <AdminSlideDeckVersionTile
              key={slideDeck.id}
              slideDeck={slideDeck}
              catalogNames={catalogNames}
              onClick={onSlideDeckTileClick}
            />
          )
        })}
      </div>
    </Dialog>
  )
})
