import { useRepository } from './auth'
import { UserContext } from './contexts'
import { observer } from 'mobx-react-lite'
import { FullPageSpinner } from 'components/Spinner'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'

/// passedProfile can be used for testing purposes
export const UserContextProvider = observer(function UserContextProvider({
  children,
  passedUser,
}: {
  children: React.ReactNode
  passedUser?: BreakoutUser
}) {
  const repo = useRepository()
  const { breakoutUser } = repo

  const user = passedUser || breakoutUser

  if (!user || user.isLoading || !user.hasData)
    return <FullPageSpinner className="!border-t-surface-dim" />

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
})
