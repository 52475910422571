import type { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { createContext, useContext } from 'react'

const InstructorSectionCubitContext = createContext<
  InstructorSectionCubit | undefined
>(undefined)

export const InstructorSectionCubitProvider =
  InstructorSectionCubitContext.Provider

export function useInstructorSectionCubit() {
  const cubit = useContext(InstructorSectionCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useInstructorSectionCubit must be used within a CubitProvider'
    )
  }

  return cubit
}
