import classNames from 'classnames'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { BookOpen } from 'components/icons/BookOpen'
import { ChatDots } from 'components/icons/ChatDots'
import { LightBulbIcon } from 'components/icons/LightBulb'
import { QuestionMark } from 'components/icons/QuestionMark'
import { Shield } from 'components/icons/Shield'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = React.HtmlHTMLAttributes<HTMLDivElement>

export const HelpButton = observer(function HelpButton({
  className,
  ...rest
}: Props) {
  const { t } = useTranslation()
  const tScoped = useCallback((key: string) => t(`student_library.${key}`), [t])
  const store = useRootStore()

  return (
    <FloatingIconActionButton
      {...rest}
      kind="tertiary"
      aria-label={tScoped('help')}
      Icon={QuestionMark}
      className={classNames(className, 'flex flex-col items-end')}
      buttonClassName="!h-10 !w-10 bg-surface hover:bg-surface-dim !border !border-solid !border-outline-variant"
      actions={[
        {
          Icon: LightBulbIcon,
          text: tScoped('see_how_it_works'),
          onClick: () => {
            store.navigateTo('onboarding')
          },
        },
        {
          Icon: Shield,
          text: tScoped('privacy_policy'),
          onClick: () => {
            window.open(
              'https://www.breakoutlearning.com/legal/privacy-policy',
              '_blank'
            )
          },
        },
        {
          Icon: BookOpen,
          text: tScoped('help_center'),
          onClick: () => {
            window.open(
              'https://support.breakoutlearning.com/help-center/',
              '_blank'
            )
          },
        },
        {
          Icon: ChatDots,
          text: tScoped('message_support'),
          onClick: () => {
            window.open('https://www.breakoutlearning.com/contact-us', '_blank')
          },
        },
      ]}
    />
  )
})
