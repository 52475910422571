import type { RouteType } from 'config/routes'
import type { QueryParams } from 'mobx-router'
import { TwoColumnAnimatedPane } from 'pages/layout/TwoColumn'
import { OrganizationPage } from './Organization'
import { OrganizationsPage } from './Organizations'

// This function controls animated transitions between subpages
function calculateAnimationController(
  params: QueryParams,
  route: RouteType | undefined
) {
  const routeTitle = route?.title
  let key: string | null = null
  let component: JSX.Element | null = null
  let mode = 'stack'
  const organizationId = params?.organizationId

  if (routeTitle === 'organization' && typeof organizationId === 'string') {
    key = `organization-${organizationId}`
    component = <OrganizationPage key={key} organizationId={organizationId} />

    // If we are in singleOrg mode, we do NOT want to animate in the organization
    const urlParams = new URLSearchParams(window.location.search)
    mode = urlParams.get('singleOrg') ? '' : 'slideIn'
  } else if (routeTitle === 'organizations') {
    key = 'organizations'
    component = <OrganizationsPage />
    mode = 'slideOut'
  } else {
    mode = 'leave'
    component = null
  }

  return {
    key,
    component,
    mode,
  }
}

const OrganizationsEntrypoint = () => {
  return (
    <TwoColumnAnimatedPane calculateController={calculateAnimationController} />
  )
}

// eslint-disable-next-line import/no-default-export
export default OrganizationsEntrypoint
