import { useEffect, useId, useMemo, useState } from 'react'

export function usePortalDiv() {
  const portalId = useId()
  const [portalDiv, setPortalDiv] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!portalDiv) return
  }, [portalDiv])

  const PortalDiv = useMemo(
    () =>
      function PortalDiv() {
        return <div ref={setPortalDiv} id={portalId} />
      },
    [portalId]
  )

  return {
    portalId,
    PortalDiv,
  }
}
