import classNames from 'classnames'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'

export const PassFailLabel = ({
  label,
  level,
  width = 170,
  className,
}: {
  label: string
  level: number
  width?: number
  className?: string
}) => {
  return (
    <div
      className="flex flex-row items-center gap-1.5"
      style={{
        maxWidth: width,
        width: width,
      }}
    >
      <div
        className={classNames(
          'h-[10px] min-h-[10px] w-[10px] min-w-[10px] rounded-full',
          {
            'bg-breakout-red': level === 0,
            'bg-breakout-dark-green': level === 1,
          }
        )}
      ></div>
      <BreakoutTooltip content={label}>
        <span className={`${className} text-nowrap`}>{label}</span>
      </BreakoutTooltip>
    </div>
  )
}
