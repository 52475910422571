export const BankIcon = ({
  color = 'currentColor',
  size = 24,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 26 25"
      fill="none"
      stroke={color}
      {...otherProps}
    >
      <path
        d="M3.625 21.875H22.375M5.70833 20.8333V15.625H7.79167M18.2083 20.8333V15.625H20.2917M11.9583 20.8333V15.625H14.0417M21.3333 11.4583V8.33333L13 3.125L4.66667 8.33333V11.4583H21.3333Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
