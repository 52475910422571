import { UserPromotionCubit } from '@breakoutlearning/firebase-repository/cubits/UserPromotionCubit'
import { useCubitBuilder } from 'hooks/cubits'
import { useRepository } from 'hooks/auth'
import { useBreakoutUser } from 'hooks/profile'
import { UserPromotions } from './UserPromotionsDialog'

export const ProfilePromotions = () => {
  const repository = useRepository()
  const breakoutUser = useBreakoutUser()

  const cubit = useCubitBuilder(
    () => new UserPromotionCubit(repository, breakoutUser.user.id),
    [repository, breakoutUser.user.id]
  )

  return <UserPromotions cubit={cubit} />
}
