import { useEffect } from 'react'
import { Dialog } from './dialogs/Dialog'
import { DialogCloseButton } from './dialogs/DialogCloseButton'
import { BreakoutTextInput } from './design-system/BreakoutTextInput'
import { LinkIcon } from './icons/Link'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'

export const LinkCopiedToClipboardDialog = ({
  url,
  title,
  warning,
  customBottomContent,
}: {
  url?: string | null
  title?: string
  warning?: string
  customBottomContent?: React.ReactNode
}) => {
  const { clearAllDialogs } = useDialogs()
  const { t } = useTranslation()

  useEffect(() => {
    url && navigator.clipboard.writeText(url)
  }, [url])

  return (
    <Dialog
      size="xs"
      key="dialog-show-url"
      innerClassName="flex flex-col justify-center"
      className="!bg-surface"
    >
      <DialogCloseButton />

      <h3 className="text-center text-xl font-semibold">
        {title ?? t('components.link_copied_to_clipboard')}
      </h3>
      {warning && <p className="mt-4 text-center text-xs">{warning}</p>}
      <BreakoutTextInput
        data-testid="invitation-url"
        className="mt-6"
        inputClassName="cursor-text w-full"
        iconClassName="cursor-pointer"
        LeadingIcon={({ ...props }) => (
          <LinkIcon
            onClick={() => {
              if (!url) return
              navigator.clipboard.writeText(url)
              clearAllDialogs()
            }}
            {...props}
          />
        )}
        value={url}
        readOnly
      />
      {customBottomContent}
    </Dialog>
  )
}
