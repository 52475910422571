import type { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { UserPromotion } from '@breakoutlearning/firebase-repository/models/UserPromotion'
import type { ImpersonationInfo } from '@breakoutlearning/firebase-repository/types'
import { OrganizationInvoiceStatus } from '@breakoutlearning/firebase-repository/types'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { FormError } from 'components/design-system/form/FormError'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useFetchOrganizations } from 'hooks/useFetchOrganizations'
import { useUserPromotions } from 'hooks/useUserPromotions'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InvoiceRequestDialog } from '../InvoiceRequestDialog'
import { PromotionSelectionWidget } from './PromotionSelectionWidget'

type FormValues = {
  className: string
  sectionName: string
  organizationId?: string
}

type Params = {
  className: string
  sectionName: string
  organizationId?: string
  impersonationInfo?: ImpersonationInfo
}

type SaveSection = (params: Params) => Promise<string>

export const CreateOrEditSectionDialog = observer(function AddSectionDialog({
  impersonationInfo,
  section,
  hasApprovedInvoiceRequest,
  createSectionInvoiceRequest,
  saveSection,
  onSave,
  redeemPromotions,
}: {
  impersonationInfo?: ImpersonationInfo
  section?: Section
  hasApprovedInvoiceRequest?: boolean
  createSectionInvoiceRequest: InstructorSectionCubit['createSectionInvoiceRequest']
  onSave?: ({
    sectionId,
    invoiceCreated,
  }: {
    sectionId: string
    invoiceCreated?: boolean
  }) => void
  saveSection: SaveSection
  redeemPromotions: (
    sectionId: string,
    userPromotions: UserPromotion[]
  ) => Promise<void>
}) {
  const [loadingOrgs, organizations] = useFetchOrganizations()
  const { t } = useTranslation()
  const [error, setError] = useState<string | null>(null)
  const { showDialog, clearAllDialogs } = useDialogs()
  const user = useBreakoutUser()

  const {
    hasPromotions,
    selectedUserPromotionIds,
    userPromotions,
    setSelectedUserPromotionIds,
  } = useUserPromotions(section)

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      className: section?.data.className ?? '',
      sectionName: section?.data.sectionName ?? '',
      organizationId: section?.data.organizationId,
    },
  })

  const submit = useCallback(
    async ({ className, sectionName, organizationId }: FormValues) => {
      // Create the section.
      const [sectionId, err] = await noTryAsync(() =>
        saveSection({
          className,
          sectionName,
          organizationId,
          impersonationInfo,
        })
      )

      if (err || !sectionId) {
        // There was a problem creating the section.
        err && console.error(err)
        setError(t('instructor_library.error_adding_class'))
        return
      }

      const selectedUserPromotions =
        userPromotions?.filter((p) =>
          selectedUserPromotionIds.includes(p.id)
        ) || []

      if (selectedUserPromotions.length > 0) {
        // Redeem the promotions.
        const [, redemptionErr] = await noTryAsync(() =>
          redeemPromotions(sectionId, selectedUserPromotions)
        )

        // There was a problem redeeming the promotions.
        if (redemptionErr) {
          redemptionErr && console.error(redemptionErr)
          setError(t('instructor_library.error_redeeming_promotions'))
        }
      }

      const organization = organizations.find(
        (org) => org.id === organizationId
      )
      if (
        organization &&
        selectedUserPromotions.length === 0 &&
        organization.organizationInvoiceStatus ===
          OrganizationInvoiceStatus.adminApproved
      ) {
        // When an organization has been selected, we want to create a section request if
        // the organization allows invoice requests.
        return showDialog(() => (
          <InvoiceRequestDialog
            onCreate={() => {
              clearAllDialogs()
              onSave?.({ sectionId, invoiceCreated: true })
            }}
            onSkip={() => {
              clearAllDialogs()
              onSave?.({ sectionId, invoiceCreated: false })
            }}
            createSectionInvoiceRequest={async ({
              sectionRequestAssignmentCount,
              sectionRequestStudentCount,
            }) => {
              await createSectionInvoiceRequest({
                sectionId,
                sectionRequest: {
                  sectionRequestAssignmentCount,
                  sectionRequestStudentCount,
                  requestedAt: new Date(),
                  organizationId: organizationId!,
                  sectionId,
                },
              })
            }}
          />
        ))
      }

      // Call the onCreate callback.
      onSave?.({ sectionId, invoiceCreated: false })
    },
    [
      clearAllDialogs,
      createSectionInvoiceRequest,
      impersonationInfo,
      onSave,
      organizations,
      redeemPromotions,
      saveSection,
      selectedUserPromotionIds,
      showDialog,
      t,
      userPromotions,
    ]
  )

  const organizationInstructors = user.organizationInstructors
  const orgsFilteredByIfUserIsOrgInstructor = organizations.filter((org) =>
    organizationInstructors.models.some(
      (instructor) => instructor.data.organizationId === org.id
    )
  )

  return (
    <Dialog
      testId="add-section-dialog"
      size="md"
      innerClassName={classNames('flex flex-col justify-between ', {
        'md:!w-[800px]': hasPromotions,
      })}
    >
      <DialogCloseButton />
      <div className="text-headline-large mb-4 text-core-on-tertiary">
        {section?.id
          ? t('instructor_library.edit_class')
          : t('instructor_library.add_class')}
      </div>
      <form
        className="flex flex-1 flex-col justify-between gap-5"
        onSubmit={handleSubmit(submit)}
      >
        <div>
          <div className="flex flex-row gap-3">
            <div className="flex flex-1 flex-col gap-2">
              <Controller
                control={control}
                name="className"
                rules={{
                  required: t('instructor_library.class_name_required'),
                }}
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    label={t('instructor_library.class_name')}
                    autoFocus
                    data-testid="class-name-input"
                    kind="secondary"
                    error={fieldState.error}
                    {...field}
                  />
                )}
              />

              <Controller
                control={control}
                name="sectionName"
                rules={{
                  required: t('instructor_library.section_name_required'),
                }}
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    label={t('instructor_library.section_name')}
                    data-testid="section-name-input"
                    kind="secondary"
                    error={fieldState.error}
                    {...field}
                  />
                )}
              />

              {orgsFilteredByIfUserIsOrgInstructor.length > 0 && (
                <BreakoutTooltip
                  content={t(
                    'instructor_library.organization_disabled_tooltip'
                  )}
                  enabled={!!hasApprovedInvoiceRequest}
                >
                  <div>
                    <Controller
                      control={control}
                      name="organizationId"
                      render={({ field, fieldState }) => (
                        <BreakoutSelect
                          disabled={hasApprovedInvoiceRequest}
                          allowClear
                          kind="secondary"
                          label={t('instructor_library.organization')}
                          options={organizations.map((org) => ({
                            label: org.organizationName,
                            value: org.id,
                          }))}
                          error={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </BreakoutTooltip>
              )}
            </div>
            <PromotionSelectionWidget
              section={section}
              selectClassName="max-w-[180px] min-w-[180px]"
              onChange={setSelectedUserPromotionIds}
            />
          </div>

          {error && (
            <FormError
              errorClass="mt-2"
              error={{
                type: 'error',
                message: error,
              }}
            />
          )}
        </div>
        <div>
          <BreakoutButton
            size="large"
            type="submit"
            fullWidth
            loading={isSubmitting || loadingOrgs}
            data-testid="add-class-button"
          >
            {section?.id
              ? t('instructor_class.save')
              : t('instructor_library.add_class')}
          </BreakoutButton>
        </div>
      </form>
    </Dialog>
  )
})
