import { Fragment } from 'react'

export const MultiLineText = ({ string }: { string: string }) => {
  const strArr = string.split('\n')
  return (
    <>
      {strArr.map((str, index) => {
        return (
          <Fragment key={index}>
            {str}
            {index !== strArr.length - 1 && <br />}
          </Fragment>
        )
      })}
    </>
  )
}
