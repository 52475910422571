import { GraphqlIcon } from 'components/icons/Graphql'
import { Spinner } from 'components/Spinner'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { observer } from 'mobx-react-lite'
import { lazy, Suspense } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useWaitFor } from './use-wait-for'
const ConversationMapAnimatedGraphBucketed = lazy(
  () => import('./ConversationMapAnimatedGraphBucketed')
)

export const ConversationMap = observer(function ConversationMap() {
  const { t } = useTranslation()
  const cubit = useMeetingResultsCubit()
  const waitedFor3Min = useWaitFor(3)

  if (cubit.conversationMapDataLoading) {
    return <Spinner />
  }
  const data = cubit.conversationMapData
  const bucketsEmpty = !data?.buckets.some((bucket) => bucket.length > 0)

  if (bucketsEmpty) {
    // if ai finished with success/fail, return no results
    if (cubit.aiStatus !== 'pending')
      return (
        <div className="flex h-full w-full flex-col items-center justify-center gap-1 text-center">
          <GraphqlIcon size={28} gTagClassName="fill-fixed-accent-color" />
          <h2 className="text-title-large">{t('meeting.conversation_map')}</h2>
          <strong className="text-body-medium">
            {t('meeting.conversation_map_no_results')}
          </strong>
        </div>
      )

    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <Spinner size={1.75} />
        <h2 className="text-title-large">{t('meeting.conversation_map')}</h2>
        <strong className="text-body-medium">
          {waitedFor3Min
            ? // if longer than 3 minute wait, show long loading message, else show short loading message
              t('meeting.conversation_map_loading_long')
            : t('meeting.conversation_map_loading')}
        </strong>
      </div>
    )
  }

  return (
    <>
      <h2 className="text-title-large">{t('meeting.conversation_map')}</h2>
      <div className="text-body-medium">
        <Trans
          i18nKey="meeting.conversation_map_description"
          components={{
            bold: <strong />,
          }}
        />
      </div>
      <div
        className="mt-4"
        data-testid="meeting-results-conversation-map"
        data-sentry-block
      >
        <Suspense fallback={<div>Loading...</div>}>
          <ConversationMapAnimatedGraphBucketed data={data!} />
        </Suspense>
      </div>
    </>
  )
})
