import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { SlideDeckContent } from 'pages/instructor/slide_deck/InstructorSlideDeckPage'
import { InstructorSlideDeckCubitProvider } from 'hooks/cubits/instructorSlideDeck'

export const MaterialsView = observer(function MaterialsView() {
  const repository = useRepository()
  const cubit = useInstructorAssignmentCubit()
  const slideDeckId = cubit.assignment.data.slideDeckId

  const instructorSlideDeckCubit = useCubitBuilder(
    () => new InstructorSlideDeckCubit(repository, slideDeckId),
    [repository, slideDeckId]
  )

  return (
    <InstructorSlideDeckCubitProvider value={instructorSlideDeckCubit}>
      <SlideDeckContent showPreviewBtn />
    </InstructorSlideDeckCubitProvider>
  )
})
