import { observer } from 'mobx-react-lite'
import { InstructorSlideDeckPage } from './InstructorSlideDeckPage'

export const InstructorSlideDeckPageWrapper = observer(
  function InstructorSlideDeckPageWrapper({
    slideDeckId,
  }: {
    slideDeckId: string
  }) {
    return <InstructorSlideDeckPage slideDeckId={slideDeckId} />
  }
)
