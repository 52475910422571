import type { ChangeEvent } from 'react'

export const formatInt = (e: ChangeEvent<HTMLInputElement>) => {
  // Don't allow leading 0s
  if (
    e.currentTarget.value.startsWith('0') &&
    e.currentTarget.value.length > 1
  ) {
    // If all values are 0, then replace with 0
    if (parseInt(e.currentTarget.value, 10) === 0) {
      e.currentTarget.value = '0'
    } else {
      // Clear all leading 0s
      e.currentTarget.value = e.currentTarget.value.replace(/^0+/, '')
    }
  }
}
