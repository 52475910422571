import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { AssignmentRubricDialog } from '../dialogs/AssignmentRubricDialog'
import { BloomsLabel } from './BloomsLabel'
import { RubricCount } from './RubricCount'
import classNames from 'classnames'

export const BloomsRubricChart = observer(function PassFailRubric({
  extraClasses,
  results,
  rubric,
  roomStates,
  section,
  usersMap,
  tabIndex,
}: {
  extraClasses?: string
  results: Array<RoomStateRubricResult & { user: PublicUser }>
  rubric: SlideRubric
  roomStates: RoomState[]
  section: Section
  usersMap: Map<string, PublicUser>
  tabIndex?: number
}) {
  const { showDialog } = useDialogs()
  const roomStateRubricResults = (() => {
    const roomStateRubricResults = new Map<string, RoomStateRubricResult>()
    for (const result of results) {
      const currentResult = roomStateRubricResults.get(result.data.roomId)
      if (!currentResult) {
        roomStateRubricResults.set(result.data.roomId, result)
      } else {
        if (result.data.score > currentResult.data.score) {
          roomStateRubricResults.set(result.data.roomId, result)
        }
      }
    }
    return roomStateRubricResults
  })()

  const scoreCount = (() => {
    const scoreCount = [0, 0, 0, 0]
    for (const result of roomStateRubricResults.values()) {
      const score = result.data.score
      if (score === 0) scoreCount[0]++
      if (score === 1 || score === 2) scoreCount[1]++
      if (score === 3 || score === 4) scoreCount[2]++
      if (score === 5 || score === 6) scoreCount[3]++
    }
    return scoreCount
  })()

  return (
    <div
      className={classNames(
        'flex cursor-pointer flex-row justify-between gap-[40px]',
        extraClasses
      )}
      tabIndex={tabIndex ?? 0}
    >
      <div
        className="flex flex-col justify-center"
        onClick={() => {
          showDialog(() => (
            <AssignmentRubricDialog
              rubric={rubric}
              roomStates={roomStates}
              section={section}
              results={results}
              usersMap={usersMap}
            />
          ))
        }}
      >
        <p className="text-title-medium line-clamp-3">{rubric.data.rubric}</p>
        <p className="text-body-medium line-clamp-3 text-grey-text">
          {rubric.data.rubricDescription}
        </p>
      </div>
      <div className="flex h-[114px] w-[470px] flex-col gap-0.5">
        <div className="flex flex-row justify-between">
          <BloomsLabel level={0} className="text-body-small" />
          <RubricCount
            counts={scoreCount}
            index={0}
            rubric={rubric}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
            rubricResults={results}
          />
        </div>
        <div className="flex flex-row justify-between">
          <BloomsLabel level={1} className="text-body-small" />
          <RubricCount
            counts={scoreCount}
            index={1}
            rubric={rubric}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
            rubricResults={results}
          />
        </div>
        <div className="flex flex-row justify-between">
          <BloomsLabel level={2} className="text-body-small" />
          <RubricCount
            counts={scoreCount}
            index={2}
            rubric={rubric}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
            rubricResults={results}
          />
        </div>
        <div className="flex flex-row justify-between">
          <BloomsLabel level={3} className="text-body-small" />
          <RubricCount
            counts={scoreCount}
            index={3}
            rubric={rubric}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
            rubricResults={results}
          />
        </div>
      </div>
    </div>
  )
})
