import { useBreakoutUser } from 'hooks/profile'
import { useQueryParams } from 'hooks/router'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const useEmailVerificationHandler = () => {
  const [updating, setUpdating] = useState(false)
  const { t } = useTranslation()
  const queryParams = useQueryParams()
  const breakoutUser = useBreakoutUser()

  useEffect(() => {
    const completeEmailUpdate = queryParams?.completeEmailUpdate || ''

    if (updating) {
      // Already updating
      return
    }

    if (!completeEmailUpdate || completeEmailUpdate !== 'true') {
      // No query param, so we don't need to do anything
      return
    }

    delete queryParams!.completeEmailUpdate

    const updateEmail = async () => {
      setUpdating(true)
      const errorKey = await breakoutUser.profile.updateEmail()
      setUpdating(false)

      if (errorKey) {
        toast.error(t(`profile.${errorKey}`))
        return
      }

      toast.success(t('profile.email_update_success'))
    }

    updateEmail()
  }, [breakoutUser.profile, queryParams, t, updating])

  return
}
