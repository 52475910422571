import { useEffect } from 'react'
import { useAuth, useRepository } from '../../hooks/auth'
import { useRootStore } from 'hooks/rootStore'
import { useBreakoutUser } from 'hooks/profile'

export function LogoutPage() {
  const auth = useAuth()
  const rootStore = useRootStore()
  const repository = useRepository()
  const breakoutUser = useBreakoutUser()
  useEffect(() => {
    const logUserOut = async () => {
      breakoutUser.dispose()
      rootStore.impersonationInfo && rootStore.stopImpersonation()
      repository.logout()

      auth.signOut().finally(async () => {
        const { queryParams } = rootStore.router
        if (
          queryParams?.redirectURL &&
          typeof queryParams.redirectURL === 'string'
        ) {
          // if redirect URL param present, replace the current URL with the redirect URL
          const redirectURL = decodeURIComponent(queryParams.redirectURL)
          return (window.location.href = redirectURL)
        }
        rootStore.navigateTo('home')
      })
    }
    logUserOut()
  }, [auth, rootStore, repository, breakoutUser])

  return (
    <div className="relative box-border flex h-full flex-grow flex-col items-center justify-center overflow-auto rounded-3xl bg-core-tertiary p-8 px-[2.5rem]">
      <div>Logging you out...</div>
    </div>
  )
}
