function padded(num: number) {
  return num.toString().padStart(2, '0')
}
function dateToLocalISO(date: Date) {
  const dateParts = [
    date.getFullYear(),
    padded(date.getMonth() + 1),
    padded(date.getDate()),
  ]

  const timeParts = [padded(date.getHours()), padded(date.getMinutes())]

  return `${dateParts.join('-')}T${timeParts.join(':')}`
}

// @ts-expect-error go to hell
window.setDateTimeToNow2 = () => {
  // set datetime to now
  const nowInMilliseconds = Date.now()
  const nowPlusOneMinute = nowInMilliseconds + 60000
  const now = new Date(nowPlusOneMinute)

  const nowString = dateToLocalISO(now)

  // @ts-expect-error go to hell
  document.querySelector('[data-testid="room-state-form-date"]').value =
    nowString
}
