import { useCallback, useState } from 'react'

export const useCanvasMode = () => {
  const [canvasMode, _setCanvasMode] = useState(window.name === 'canvasMode')

  const setCanvasMode = useCallback((mode: boolean) => {
    if (mode) {
      window.name = 'canvasMode'
      _setCanvasMode(mode)
    }
  }, [])

  return {
    canvasMode,
    setCanvasMode,
  }
}
