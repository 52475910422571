import { signInAnonymously } from 'firebase/auth'
import { useRepository } from 'hooks/auth'
import { useCallback, useEffect, useState } from 'react'
import { useRootStore } from 'hooks/rootStore'
import { Spinner } from 'components/Spinner'
import {
  DocumentDoesNotExistError,
  InvitationConsumeResult,
} from '@breakoutlearning/firebase-repository/types'
import { t } from 'i18next'
import { toast } from 'react-hot-toast'

export function DemoInvitationPage() {
  const repository = useRepository()
  const rootStore = useRootStore()
  const router = rootStore.router
  const breakoutUser = repository.breakoutUser
  const invitationId = router.params?.invitationId as string | undefined
  const roomId = router.queryParams?.roomId as string | undefined
  const [step, setStep] = useState<string>('initializing')

  const navigateToRoomOrWelcome = useCallback(() => {
    roomId
      ? rootStore.navigateToDemoWaitingRoom(roomId)
      : rootStore.navigateTo('demoWelcome')
  }, [rootStore, roomId])

  useEffect(() => {
    // We can only consume an invitation if we have an invitationId.
    if (!invitationId) {
      setStep('No invitation provided')
      return
    }

    // We only want to run authenticated logic when the user is authenticated.
    if (
      !repository.isAuthenticated ||
      !breakoutUser ||
      !breakoutUser.isLoaded
    ) {
      setStep('Waiting for user')
      return
    }

    if (breakoutUser.isCorre) {
      // If the user is an admin, we should navigate to the admin demos page.
      // if roomId is provided, provide in query param to trigger start session dialog if appropriate
      const queryParams = roomId ? { roomId } : undefined
      // we wrap this in setTimeout with 0 dur to let the current render cycle finish
      // if we don't do this, we will fail to render the component from the new route
      setTimeout(() => {
        rootStore.navigateTo('adminDemos', undefined, queryParams)
      }, 0)
      return
    }

    setStep('Consuming invitation')
    // Consume the invitation and handle the result.
    breakoutUser
      .consumeInvitation(invitationId)
      .then((response) => {
        const result = response.result
        switch (result) {
          case InvitationConsumeResult.success:
            toast.success(t('invitation.accepted'))
            setStep('Invitation consumed successfully')
            break
          case InvitationConsumeResult.errorAlreadyConsumed:
            toast.error('Invitation already consumed')
            setStep('Invitation already consumed')
            return navigateToRoomOrWelcome()
          case InvitationConsumeResult.errorCreatedByYou:
            toast.error('Invitation created by you')
            setStep('Invitation created by you')
            return navigateToRoomOrWelcome()
          case InvitationConsumeResult.errorInvitationExpired:
            toast.error('Invitation expired')
            setStep('Invitation expired')
            return navigateToRoomOrWelcome()
          case InvitationConsumeResult.errorInvitationNotFound:
            toast.error('Invitation not found')
            setStep('Invitation not found')
            return navigateToRoomOrWelcome()
          case InvitationConsumeResult.errorPromotionAlreadyConsumed:
            toast.error('Promotion already consumed')
            setStep('Promotion already consumed')
            return navigateToRoomOrWelcome()
        }

        if (!roomId || typeof roomId !== 'string') {
          console.error('No roomId provided')
          setStep('No room provided, going home')
          // There is no room to redirect to.
          rootStore.navigateTo('demoWelcome')
          return
        }

        setStep('Waiting for room to be ready')
        breakoutUser
          .waitForRoomStateLoaded(roomId)
          .then(() => {
            // Navigate to the demo waiting room.
            setStep('Navigating to demo waiting room')
            rootStore.navigateToDemoWaitingRoom(roomId)
          })
          .catch((error) => {
            if (error instanceof DocumentDoesNotExistError) {
              setStep('Demo Canceled')
              toast.error(t('demo.demo_canceled_notification'))
            } else {
              console.error('Error waiting for room', error)
              setStep('Error waiting for room')
            }
            rootStore.navigateTo('demoWelcome')
          })
      })
      .catch((error) => {
        console.error('Error consuming invitation', error)
      })
  }, [
    navigateToRoomOrWelcome,
    repository.isAuthenticated,
    breakoutUser,
    breakoutUser?.isLoaded,
    repository,
    rootStore,
    invitationId,
    roomId,
  ])

  useEffect(() => {
    // Exit if the user is already authenticated.
    if (repository.isAuthenticated) {
      return
    }

    setStep('Signing in...')
    // Sign in anonymously without awaiting, as the rest of the signup process
    // is handled by a trigger and the user will become authenticated when it completes.
    // The first useEffect will run again when the user is authenticated.
    signInAnonymously(repository.auth)
  }, [repository.isAuthenticated, repository])

  return (
    <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
      <div className="text-body-small flex flex-col items-center justify-center gap-2 text-center">
        <Spinner />
        {step}
      </div>
    </div>
  )
}
