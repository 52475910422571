import type { SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { InstructorAssignmentCard } from 'components/design-system/cards/InstructorAssignmentCard'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'

export const CaseListItem = observer(function CaseListItem({
  assignment,
}: {
  assignment: SectionAssignment
}) {
  const store = useRootStore()
  const cubit = useInstructorSectionCubit()

  const slideDeck = cubit.slideDeckMap.get(assignment.data.slideDeckId)

  return (
    <InstructorAssignmentCard
      imageUrl={slideDeck?.data.slideDeckImageURL}
      title={slideDeck?.data.slideDeckName || ''}
      subtitle={slideDeck?.data.slideDeckTeaser || ''}
      state={assignment.assignmentState}
      onClick={() =>
        store.navigateTo('instructorClassAssignment', {
          id: assignment.sectionId,
          assignmentId: assignment.id,
        })
      }
      expiresAt={assignment.expiresAtDate}
      testId={`instructor-assignment-${assignment.id}`}
    />
  )
})
