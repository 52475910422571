import classNames from 'classnames'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { usePortalDiv } from 'hooks/portals'
import React, { useMemo } from 'react'

type BreakoutBarGraphVerticalProps = {
  histogram: Record<string, number>
  scale: number[]
  xLabel: string
  yLabel: string
  colorBackground?: string
  colorForeground?: string
  colorSelected?: string
  colorLabel?: string
  colorTicks?: string
  colorText?: string
  onPressed?: (selected: string) => void
  selectedBucket?: string
  className?: string
  reverse?: boolean
}

export const BreakoutBarGraphVertical: React.FC<
  BreakoutBarGraphVerticalProps
> = ({
  histogram,
  scale,
  xLabel,
  yLabel,
  colorBackground = 'bg-core-tertiary',
  colorForeground = 'bg-grey-text',
  colorSelected = 'bg-grey-text',
  colorLabel = 'text-grey-text',
  colorTicks = 'border-medium-grey',
  colorText = 'text-core-on-tertiary',
  onPressed,
  selectedBucket = '',
  className,
  reverse = false,
}) => {
  const maxValue = Math.max(...Object.values(scale))
  const reversedScale = [...scale].reverse()

  const entries = useMemo(() => {
    const entries = Object.entries(histogram)
    if (reverse) return entries.reverse()
    return entries
  }, [histogram, reverse])

  const { PortalDiv, portalId } = usePortalDiv()

  return (
    <>
      <PortalDiv />

      <table role="presentation" className={classNames('h-full', className)}>
        <tbody>
          <tr>
            <td>
              <div className="flex max-w-6 flex-col-reverse items-center whitespace-nowrap pr-4">
                <strong
                  className={classNames(
                    'text-label-small -rotate-90 transform',
                    colorLabel
                  )}
                >
                  {yLabel}
                </strong>
              </div>
            </td>
            <td className="h-full w-full">
              {/* Y-Axis Label */}
              {/* Setting a maximum width for the y-axis label container */}
              <div className="flex h-full">
                <div className="flex flex-grow">
                  {/* Scale Values */}
                  <div className="flex flex-col justify-between pr-2">
                    {reversedScale.map((value, index) => (
                      <div
                        key={index}
                        className="flex items-stretch justify-end"
                      >
                        <strong
                          className={classNames('text-label-small', colorText)}
                        >
                          {value}
                        </strong>
                      </div>
                    ))}
                  </div>

                  {/* Histogram Bars */}
                  <div
                    className={classNames(
                      'flex h-full flex-grow items-end space-x-4 border-b border-l pb-2 pl-2',
                      colorTicks
                    )}
                  >
                    {entries.map(([key, value], index) => {
                      const barHeight = (value / maxValue) * 100
                      const isSelected = key === selectedBucket

                      return (
                        <BreakoutTooltip
                          key={index}
                          content={value.toString()}
                          portalId={portalId}
                        >
                          <div
                            key={index}
                            className={classNames(
                              'relative flex h-full flex-1 flex-col items-center',
                              onPressed && 'cursor-pointer'
                            )}
                            onClick={() => onPressed && onPressed(key)}
                          >
                            {/* Background Bar */}
                            <div
                              className={classNames(
                                'h-full w-full rounded-md',
                                colorBackground
                              )}
                            />
                            {/* Foreground Bar */}

                            <div
                              className={classNames(
                                'absolute bottom-0 w-full rounded-md transition-all',
                                isSelected ? colorSelected : colorForeground
                              )}
                              style={{ height: `${barHeight}%` }}
                            />
                          </div>
                        </BreakoutTooltip>
                      )
                    })}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              {/* X-Axis Labels */}
              <div className="flex items-center justify-center space-x-4 pl-4">
                {entries.map(([key]) => (
                  <div key={key} className="flex-1 text-center">
                    <strong
                      className={classNames('text-label-small', colorText)}
                    >
                      {key}
                    </strong>
                  </div>
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <td />
            <td colSpan={1}>
              {/* X-Axis Label */}
              <div className="pl-4 text-center">
                <strong className={classNames('text-label-small', colorLabel)}>
                  {xLabel}
                </strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
