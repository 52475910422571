import type { Placement } from '@floating-ui/react'
import classNames from 'classnames'
import { Spinner } from 'components/Spinner'
import {
  BreakoutIconButton,
  type ButtonKind,
} from 'components/design-system/BreakoutButton'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { observer } from 'mobx-react-lite'
import React, {
  isValidElement,
  useCallback,
  useEffect,
  useState,
  cloneElement,
} from 'react'

type Props = React.HtmlHTMLAttributes<HTMLDivElement>

export const FloatingIconActionButton = observer(function FloatingActionButton({
  className,
  buttonClassName,
  actions,
  Icon,
  kind,
  menuBottom,
  'aria-label': ariaLabel,
  dropdownClassName,
  ...rest
}: Props & {
  Icon: React.ComponentType<{ size: number }>
  actions: React.ReactNode | Array<MenuButtonProps>
  kind?: ButtonKind
  buttonClassName?: string
  'aria-label'?: string
  dropdownClassName?: string
  menuBottom?: boolean
}) {
  const [menuVisible, setMenuVisible] = useState(false)

  const onClickHideMenu = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const toggleMenuVisible = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setMenuVisible(!menuVisible)
  }

  useEffect(() => {
    if (menuVisible) {
      return window.addEventListener('click', onClickHideMenu)
    }
    window.removeEventListener('click', onClickHideMenu)

    return () => {
      window.removeEventListener('click', onClickHideMenu)
    }
  }, [menuVisible, onClickHideMenu])

  return (
    <div {...rest} className={classNames('relative', className)}>
      <BreakoutIconButton
        kind={kind}
        aria-label={ariaLabel}
        aria-haspopup="true"
        onClick={(e) => toggleMenuVisible(e)}
        icon={<Icon aria-hidden size={24} />}
        className={classNames(
          'rounded-full border-none shadow-sm',
          buttonClassName
        )}
      />
      {menuVisible && (
        <RenderChildren
          menuBottom={menuBottom || false}
          dropdownClassName={dropdownClassName}
        >
          {actions}
        </RenderChildren>
      )}
    </div>
  )
})

export type MenuButtonProps = {
  Icon: React.ComponentType<{ size: number; className?: string }>
  text?: string | React.ReactNode
  onClick: () => void
  isLoading?: boolean
  disabled?: boolean
  tooltip?: string
  tooltipPlacement?: Placement
}

const MenuButton = ({
  Icon,
  text,
  tooltip = '',
  onClick,
  isLoading,
  disabled,
  tooltipPlacement,
}: MenuButtonProps) => {
  return (
    <BreakoutTooltip
      content={tooltip}
      enabled={!!tooltip}
      placement={tooltipPlacement}
    >
      <div className="w-full">
        <button
          onClick={!isLoading ? onClick : undefined}
          disabled={disabled || isLoading}
          className={classNames(
            'flex w-full flex-row items-center gap-1 px-4 py-3 hover:bg-surface',
            disabled ? 'text-on-surface-disabled' : '',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
        >
          {isLoading ? (
            <Spinner aria-hidden size={1.5} />
          ) : disabled ? (
            <Icon aria-hidden size={24} className="text-on-surface-disabled" />
          ) : (
            <Icon aria-hidden size={24} />
          )}
          <strong className="text-label-medium">{text}</strong>
        </button>
      </div>
    </BreakoutTooltip>
  )
}

const RenderChildren = ({
  children,
  menuBottom,
  dropdownClassName,
}: {
  children: React.ReactNode | Array<MenuButtonProps>
  menuBottom: boolean
  dropdownClassName?: string
}) => {
  if (!Array.isArray(children)) {
    if (!isValidElement(children)) return null
    return cloneElement(children, {
      className: `${children.props.className || ''} absolute z-20 ${menuBottom ? 'top-full mt-2' : 'bottom-full mb-2'}`,
    } as Partial<unknown>) // Add the 'Attributes' type to the type definition
  }
  // render menu buttons
  return (
    <div
      role="menu"
      className={classNames(
        `absolute z-20 ${menuBottom ? 'top-full mt-2' : 'bottom-full mb-2'} flex flex-col text-nowrap rounded-lg bg-core-tertiary py-2 shadow-3xl`,
        dropdownClassName
      )}
    >
      {children.map((child, index) => (
        <MenuButton key={index} {...child} />
      ))}
    </div>
  )
}
