import classNames from 'classnames'
import { Spinner } from 'components/Spinner'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RippleEffect } from './RippleEffect'
import { useSettings } from 'hooks/settings'
import { BreakoutTooltip } from './BreakoutTooltip'

export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'error'
  | 'success'
type ButtonVariant = 'text' | 'outlined' | 'contained'
type ButtonSize = 'small' | 'medium' | 'large'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  kind?: ButtonKind
  variant?: ButtonVariant
  size?: ButtonSize
  ripple?: boolean
  children?: React.ReactNode
  fullWidth?: boolean
  icon?: React.ReactNode
  loading?: boolean
  loadingText?: string
  tooltip?: string
  tooltipOpen?: boolean
  iconOnRight?: boolean
  tooltipTransitionDuration?: number
}

const baseClass = 'select-none'
const kinds: Record<ButtonKind, string> = {
  primary:
    'bg-core-primary text-core-on-primary hover:opacity-80 disabled:opacity-30',
  secondary:
    'bg-core-secondary text-core-on-secondary hover:bg-neutral-100 disabled:opacity-30',
  tertiary: 'bg-core-tertiary text-core-on-tertiary disabled:opacity-30',
  accent: 'bg-fixed-accent-color text-core-on-secondary disabled:opacity-30',
  error: 'bg-core-error text-core-on-error disabled:opacity-30',
  success: 'bg-core-success text-core-on-success disabled:opacity-30',
}

const rippleEffects: Record<ButtonKind, string> = {
  primary: 'light',
  secondary: 'dark',
  tertiary: 'dark',
  error: 'light',
  accent: 'light',
  success: 'light',
}

const sizes: Record<ButtonSize, string> = {
  large:
    'w-fit h-fit min-h-[3.25rem] min-w-[7.5rem] md:min-w-[9.5rem] px-3 md:px-5 py-1 text-xs md:text-sm font-semibold leading-[normal] rounded-2xl',
  medium:
    'w-fit h-fit min-w-[9.5rem] min-h-[2.5rem] px-5 py-1 text-xs font-semibold leading-[normal] rounded-xl',
  small:
    'w-fit h-fit min-h-[2rem] px-5 py-1 text-[10px] font-semibold leading-[normal] rounded-xl',
}

const sizesIconOnly: Record<ButtonSize, string> = {
  large: 'w-[3.25rem] h-[3.25rem] rounded-xl',
  medium: 'w-[3.25rem] h-[3.25rem] rounded-xl',
  small: 'w-10 h-10 rounded-xl',
}

export function BreakoutButton({
  kind,
  size,
  children,
  ripple,
  icon,
  iconOnRight,
  fullWidth,
  loading,
  type,
  tooltip,
  tooltipTransitionDuration,
  loadingText,
  onKeyDown,
  tooltipOpen,
  ...otherProps
}: ButtonProps) {
  const { animationsEnabled } = useSettings()
  const iconOnly = !children && icon
  const rippleEffect = useMemo(() => {
    if (!animationsEnabled) return undefined
    const rippleOrDefault = ripple !== undefined ? ripple : true
    return rippleOrDefault ? new RippleEffect() : undefined
  }, [ripple, animationsEnabled])
  const kindOrDefault = kind || 'primary'
  const kindRef = useRef(kindOrDefault)
  const elementRef = useRef<HTMLButtonElement>(null)

  const className = useMemo(() => {
    const sizeOrDefault = size || 'medium'
    const classes: string[] = [baseClass]

    classes.push(kinds[kindOrDefault])

    if (iconOnly) {
      classes.push(sizesIconOnly[sizeOrDefault])
    } else {
      classes.push(sizes[sizeOrDefault])
    }

    if (icon || loading) {
      classes.push('inline-flex items-center justify-center gap-1')
    }

    if (loading) {
      classes.push('cursor-default')
    }

    if (fullWidth) {
      classes.push('!w-full')
    }

    return classes.join(' ')
  }, [size, icon, iconOnly, loading, fullWidth, kindOrDefault])

  const spinnerSize = useMemo(() => {
    if (size === 'small') return 0.8
    if (size === 'medium') return 1.1
    return 1.3
  }, [size])

  useEffect(() => {
    kindRef.current = kindOrDefault
  }, [kindOrDefault])

  const onMouseUp = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (loading) return
      if (rippleEffect) {
        // Use requestAnimationFrame to respond to kind change
        // When someone clicks and we change kind, we also change colors
        // which means we need a different effect. The way to do it is to
        // use requestAnimationFrame to wait for the next frame to get the
        // updated element and kind
        requestAnimationFrame(() => {
          if (elementRef.current) {
            rippleEffect.create(
              elementRef.current,
              event,
              rippleEffects[kindRef.current]
            )
          }
        })
      }
    },
    [rippleEffect, loading, kindRef]
  )

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // Prevent click event if button is loading
      if (loading) return
      if (otherProps.onClick) {
        otherProps.onClick(e)
      }
    },
    [loading, otherProps]
  )

  const component = (
    <button
      {...otherProps}
      type={type || 'button'}
      ref={elementRef}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseUp={onMouseUp}
      className={classNames(className, otherProps.className)}
    >
      {loading && (
        <Spinner
          aria-hidden
          size={spinnerSize}
          className={classNames({
            'on-primary': kind === 'primary',
            'on-secondary': kind === 'secondary' || kind === 'accent',
            'on-tertiary': kind === 'tertiary',
            'on-error': kind === 'error',
            'on-success': kind === 'success',
          })}
        />
      )}
      {loading && loadingText && <span className="ml-2">{loadingText}</span>}
      {!loading && (
        <>
          {icon && !iconOnRight && (
            <span aria-hidden className="inline-block">
              {icon}
            </span>
          )}
          {children}
          {icon && iconOnRight && (
            <span aria-hidden className="inline-block">
              {icon}
            </span>
          )}
        </>
      )}
    </button>
  )

  if (tooltip) {
    return (
      <BreakoutTooltip
        forceOpen={tooltipOpen}
        content={tooltip}
        transitionDuration={tooltipTransitionDuration}
      >
        {component}
      </BreakoutTooltip>
    )
  } else {
    return component
  }
}

export function BreakoutIconButton(
  props: Omit<ButtonProps, 'size' | 'iconOnRight'> & {
    size?: 'large' | 'small'
  }
) {
  return <BreakoutButton {...props} />
}

type AsyncButtonProps = ButtonProps & {
  onClick: () => Promise<void>
}

export const BreakoutAsyncButton = ({
  onClick,
  loading,
  children,
  ...rest
}: AsyncButtonProps) => {
  const [requestInProgress, setRequestInProgress] = useState(false)
  return (
    <BreakoutButton
      {...rest}
      loading={requestInProgress || loading}
      onClick={async () => {
        setRequestInProgress(true)
        try {
          await onClick()
        } finally {
          setRequestInProgress(false)
        }
      }}
    >
      {children}
    </BreakoutButton>
  )
}
