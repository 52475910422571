import { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
// import { Contents, MainPane } from 'components/layout/TwoColumn'
import { SlideProjector } from 'components/SlideProjector'
import { Contents, MainPane } from 'pages/layout/TwoColumn'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { observer } from 'mobx-react-lite'
import { CasesView } from './CasesView/CasesView'
import { InstructorClassPageHeader } from './InstructorClassPageHeader'
import { StudentsView } from './StudentsView/StudentsView'
import {
  InstructorSectionView,
  SectionRequestState,
} from '@breakoutlearning/firebase-repository/types'
import {
  InstructorSectionCubitProvider,
  useInstructorSectionCubit,
} from 'hooks/cubits/instructorSection'
import { useRootStore } from 'hooks/rootStore'
import type { SectionRequest } from '@breakoutlearning/firebase-repository/models/SectionRequest'
import { ClockIcon } from 'components/icons/Clock'
import type { ReactNode } from 'react'
import { ErrorIcon } from 'components/icons/Error'
import { useTranslation } from 'react-i18next'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { toast } from 'react-hot-toast'
import { useDialogs } from 'hooks/dialogs'
import { ViewRequestDialog } from 'pages/organizations/ViewRequestDialog'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { InvoiceRequestDialog } from '../InvoiceRequestDialog'
import { ConfirmationDialog } from 'components/ConfirmationDialog'

export function InstructorClassPage({ id }: { id: string }) {
  const repository = useRepository()
  const rootStore = useRootStore()

  const instructorUserId = rootStore.impersonationInfo?.userId
  const cubit = useCubitBuilder(
    () =>
      new InstructorSectionCubit(repository, id, {
        instructorUserId,
      }),
    [repository, id]
  )

  if (!cubit) return null

  return (
    <InstructorSectionCubitProvider value={cubit}>
      <InstructorClassPageContents key={cubit.id} />
    </InstructorSectionCubitProvider>
  )
}

function InstructorClassPageContents() {
  return (
    <MainPane className="z-50">
      <InstructorClassPageHeader />
      <InvoiceRequest />
      <Contents className="w-full md:h-full md:overflow-auto">
        <PageContent />
      </Contents>
    </MainPane>
  )
}

const InvoiceRequest = observer(function InvoiceRequest() {
  const cubit = useInstructorSectionCubit()
  const activeSectionRequest = cubit.activeSectionRequest
  const { t } = useTranslation()

  if (!activeSectionRequest) {
    // There is nothing to show.
    return null
  }

  if (
    [SectionRequestState.withdrawn, SectionRequestState.approved].includes(
      activeSectionRequest.data.sectionRequestState
    )
  ) {
    // We don't show the invoice request if it's withdrawn or approved.
    return null
  }

  return (
    <InvoiceRequestDisplay
      status={
        activeSectionRequest.data.sectionRequestState as
          | SectionRequestState.pending
          | SectionRequestState.rejected
      }
      request={activeSectionRequest}
      section={cubit.section}
      withdrawSectionRequest={async () => {
        await cubit.withdrawSectionRequest(activeSectionRequest.id)
        toast.success(t('instructor_class.withdrawn'))
      }}
      createSectionInvoiceRequest={cubit.createSectionInvoiceRequest}
    />
  )
})

const InvoiceRequestDisplay = observer(function InvoiceRequestDisplay({
  status,
  request,
  section,
  createSectionInvoiceRequest,
  withdrawSectionRequest,
}: {
  status: SectionRequestState.pending | SectionRequestState.rejected
  request: SectionRequest
  section: Section
  createSectionInvoiceRequest: InstructorSectionCubit['createSectionInvoiceRequest']
  withdrawSectionRequest: () => void
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  let icon: ReactNode
  let statusText: string = ''
  let description: string = ''
  switch (status) {
    case SectionRequestState.pending:
      icon = <ClockIcon size={20} className="text-fixed-accent-color" />
      statusText = t('instructor_class.pending')
      description = t('instructor_class.pending_description')
      break
    case SectionRequestState.rejected:
      icon = <ErrorIcon size={20} className="text-core-error" />
      statusText = t('instructor_class.rejected')
      description = t('instructor_class.rejected_description')
      break
    default:
      break
  }

  return (
    <div className="mt-3 rounded-2xl bg-core-secondary p-5">
      <div className="grid lg:grid-cols-5">
        <div className="space-y-1 lg:col-span-3">
          <div className="text-title-large flex items-center gap-1">
            {icon} {t('instructor_class.invoice_request')}: {statusText}
          </div>
          <div className="text-body-medium text-on-surface-var">
            {description}
          </div>
        </div>
        <div className="mt-3 flex items-center gap-2 lg:col-span-2 lg:mt-0 lg:justify-end lg:gap-1">
          <BreakoutButton
            onClick={() => {
              return showDialog(() => (
                <ConfirmationDialog
                  onConfirm={async () => withdrawSectionRequest()}
                  text={t('instructor_class.change_to_student_pay_question')}
                  subtitle={t(
                    'instructor_class.change_to_student_pay_description'
                  )}
                />
              ))
            }}
            kind="tertiary"
          >
            {t('instructor_class.change_to_student_pay')}
          </BreakoutButton>
          {status === SectionRequestState.rejected &&
            section.data.organizationId && (
              <BreakoutButton
                onClick={() => {
                  showDialog(() => (
                    <InvoiceRequestDialog
                      onCreate={() =>
                        toast.success(
                          t('instructor_class.invoice_request_success')
                        )
                      }
                      createSectionInvoiceRequest={({
                        sectionRequestAssignmentCount,
                        sectionRequestStudentCount,
                      }) =>
                        createSectionInvoiceRequest({
                          sectionId: section.id,
                          sectionRequest: {
                            sectionRequestAssignmentCount,
                            sectionRequestStudentCount,
                            requestedAt: new Date(),
                            organizationId: section.data.organizationId!,
                            sectionId: section.id,
                          },
                        })
                      }
                    />
                  ))
                }}
                kind="primary"
              >
                {t('instructor_class.resubmit_request')}
              </BreakoutButton>
            )}
          <div className="lg:hidden">
            <BreakoutButton
              kind="tertiary"
              onClick={() => {
                showDialog(({ remove }) => {
                  return (
                    <ViewRequestDialog
                      request={request}
                      section={section}
                      close={remove}
                    />
                  )
                })
              }}
            >
              {t('instructor_class.view_details')}
            </BreakoutButton>
          </div>
        </div>
      </div>
      <div className="mt-2 hidden space-x-2 rounded-xl bg-core-tertiary px-4 py-2 lg:flex">
        <div className="text-body-medium flex items-center text-on-surface-var">
          {t('instructor_class.request_details')}
        </div>
        <div className="grid flex-auto grid-cols-5 gap-2">
          <div className="col-span-1 text-center">
            <div className="text-body-medium text-on-surface-var">
              {t('instructor_class.submitted_at')}
            </div>
            <div className="text-label-medium">{request.monthDayYear}</div>
          </div>
          <div className="col-span-1 text-center">
            <div className="text-body-medium text-on-surface-var">
              {t('instructor_class.num_students')}
            </div>
            <div className="text-label-medium">
              {request.data.sectionRequestStudentCount}
            </div>
          </div>
          <div className="col-span-1 text-center">
            <div className="text-body-medium text-on-surface-var">
              {t('instructor_class.num_experiences')}
            </div>
            <div className="text-label-medium">
              {request.data.sectionRequestAssignmentCount}
            </div>
          </div>
          <div className="col-span-1 text-center">
            <div className="text-body-medium text-on-surface-var">
              {t('instructor_class.estimated_cost')}
            </div>
            <div className="text-label-medium">{request.estimatedCost}</div>
          </div>
          <div className="col-span-1 text-center">
            <div className="text-body-medium text-on-surface-var">
              {t('instructor_class.status')}
            </div>
            <div className="text-label-medium flex items-center justify-center gap-1">
              {status === SectionRequestState.pending ? (
                <ClockIcon size={13} className="text-fixed-accent-color" />
              ) : (
                <ErrorIcon size={13} className="text-core-error" />
              )}
              {status === SectionRequestState.pending
                ? t('instructor_class.pending')
                : t('instructor_class.rejected')}
            </div>
          </div>
        </div>
        {status === SectionRequestState.rejected && (
          <BreakoutButton
            size="small"
            kind="secondary"
            onClick={() => {
              showDialog(({ remove }) => {
                return (
                  <ViewRequestDialog
                    request={request}
                    section={section}
                    close={remove}
                  />
                )
              })
            }}
          >
            {t('instructor_class.view_details')}
          </BreakoutButton>
        )}
      </div>
    </div>
  )
})

const PageContent = observer(function PageContent() {
  const cubit = useInstructorSectionCubit()

  return (
    <SlideProjector
      index={cubit.selectedView === InstructorSectionView.cases ? 0 : 1}
    >
      {cubit.selectedView === InstructorSectionView.cases && <CasesView />}
      {cubit.selectedView === InstructorSectionView.students && (
        <StudentsView />
      )}
    </SlideProjector>
  )
})
