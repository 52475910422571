import { useCallback } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useRepository } from '../../hooks/auth'

const ROLE_EMAIL_MAP: Record<DevLoginRoleType, string> = {
  admin: 'josh@breakoutlearning.com',
  student: 'student@example.com',
  instructor: 'professor.plum.683@example.com',
  ta: 'svetlana.boginskaya.928@example.com',
}

type DevLoginRoleType = 'admin' | 'student' | 'instructor' | 'ta'

export function DevLoginButton({ role }: { role: DevLoginRoleType }) {
  const repository = useRepository()
  const onDevLogin = useCallback(() => {
    const email = ROLE_EMAIL_MAP[role]

    signInWithEmailAndPassword(repository.auth, email, 'password')
  }, [repository, role])

  return (
    <button
      id={`autologin-${role}`}
      className="cursor-pointer rounded-2xl bg-core-secondary px-5 py-3"
      onClick={onDevLogin}
    >
      {role}
    </button>
  )
}
