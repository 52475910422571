import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useState } from 'react'
import { Spinner } from 'components/Spinner'

export const GoogleSlidesDialog = observer(function GoogleSlidesDialog({
  url,
}: {
  url: string
}) {
  const viewId = 'google_slides_frame'
  const [loading, setLoading] = useState(true)

  return (
    <Dialog size="custom" noWrap={true}>
      <div
        id={viewId}
        className="relative flex aspect-video max-h-full w-[90%] bg-core-tertiary"
      >
        {loading && (
          <div className="absolute inset-0 flex h-full items-center justify-center">
            <Spinner />
          </div>
        )}
        <iframe
          src={`${url}/preview`}
          onLoad={() => setLoading(false)}
          className="h-full w-full border-none"
        />
        <DialogCloseButton
          iconClassName="stroke-core-tertiary"
          className="!right-0 !top-0"
        />
      </div>
    </Dialog>
  )
})
