import { useMemo, useState } from 'react'
import { useBreakoutUser } from './profile'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'

export const useUserPromotions = (section?: Section) => {
  const user = useBreakoutUser()

  const [selectedUserPromotion, setSelectedUserPromotion] = useState<
    string | null
  >(null)
  const [selectedUserPromotionIds, setSelectedUserPromotionIds] = useState<
    string[]
  >([])

  const userPromotionRedemptionsArray = user.userPromotionRedemptionsArray
  const userPromotions = user.userPromotions.models
  const userPromotionRedemptions = user.userPromotionRedemptions

  const redemptionsForSection = useMemo(() => {
    if (!section) return []
    return userPromotionRedemptionsArray.filter(
      (redemption) => redemption.data.sectionId === section.id
    )
  }, [userPromotionRedemptionsArray, section])

  const usedPromotionIds = useMemo(() => {
    const fromRedemptions = redemptionsForSection.map(
      (redemption) => redemption.data.userPromotionId
    )
    return [...fromRedemptions, ...selectedUserPromotionIds]
  }, [redemptionsForSection, selectedUserPromotionIds])

  const promotionIsActive = useMemo(() => {
    if (!selectedUserPromotion) return false
    const userPromotion = userPromotions?.find(
      (p) => p.id === selectedUserPromotion
    )
    return !!userPromotion?.isActive
  }, [selectedUserPromotion, userPromotions])

  const promotionIsConsumable = useMemo(() => {
    if (!selectedUserPromotion) return false
    const userPromotion = userPromotions?.find(
      (p) => p.id === selectedUserPromotion
    )
    const redemptions =
      userPromotionRedemptions?.get(selectedUserPromotion) || []
    if (!userPromotion) return false
    if (!userPromotion.data.consumableCount) return false
    const consumedCount = redemptions.length
    return consumedCount < userPromotion.data.consumableCount
  }, [selectedUserPromotion, userPromotions, userPromotionRedemptions])

  const userPromotionOptions = useMemo(() => {
    const unusedPromotions = userPromotions?.filter(
      (p) => !usedPromotionIds.includes(p.id)
    )
    return (
      unusedPromotions?.map((userPromotion) => ({
        label: userPromotion.data.promotionName,
        value: userPromotion.id,
      })) || []
    )
  }, [userPromotions, usedPromotionIds])

  const hasPromotions = !!userPromotions && userPromotions.length > 0

  return useMemo(
    () => ({
      userPromotions,
      selectedUserPromotion,
      selectedUserPromotionIds,
      usedPromotionIds,
      promotionIsActive,
      promotionIsConsumable,
      userPromotionOptions,
      hasPromotions,
      userPromotionRedemptions,
      setSelectedUserPromotion,
      setSelectedUserPromotionIds,
    }),
    [
      usedPromotionIds,
      promotionIsActive,
      promotionIsConsumable,
      selectedUserPromotion,
      selectedUserPromotionIds,
      userPromotionOptions,
      userPromotions,
      hasPromotions,
      userPromotionRedemptions,
    ]
  )
}
