import type { useTranslation } from 'react-i18next'
import { ZodIssueCode, z } from 'zod'
import { OrganizationInvoiceStatus } from '@breakoutlearning/firebase-repository/types'

export const getSchema = (
  t: ReturnType<typeof useTranslation>['t'],
  // if creating an organization, require at least one catalog
  createSchema?: boolean
) =>
  z
    .object({
      organizationName: z
        .string({
          message: t('organizations.organization_name_required'),
        })
        .min(1, t('organizations.organization_name_required')),
      organizationInvoiceStatus: z.nativeEnum(OrganizationInvoiceStatus),
      organizationCatalogIds: z
        .array(z.string())
        .min(createSchema ? 1 : 0, t('organizations.catalog_required'))
        .default([]),
      // must be subset of above
      organizationDefaultCatalogIds: z.array(z.string()).default([]),
      institutionId: z.string(),
    })
    .superRefine(
      ({ organizationCatalogIds, organizationDefaultCatalogIds }, ctx) => {
        const validDefaultIds = organizationDefaultCatalogIds.every((id) =>
          organizationCatalogIds.includes(id)
        )
        if (validDefaultIds) return true

        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: t('organizations.invalid_default_catalog_ids'),
          path: ['organizationDefaultCatalogIds'],
        })
      }
    )

export type FormValues = z.infer<ReturnType<typeof getSchema>>
