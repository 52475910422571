import { Spinner } from 'components/Spinner'
import { Suspense, lazy, useState } from 'react'
import { useMediaPermissions } from 'hooks/av-test'
import { PermissionsErrorBanner } from './PermissionErrorBanner'

const AvTest = lazy(() => import('./AvTest'))

function AvSpinner() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Spinner />
    </div>
  )
}
export function AvTestEntrypoint() {
  const [showPermissionsBanner, setShowPermissionsBanner] = useState(false)

  const [permissionState] = useMediaPermissions({
    onDenied: () => setShowPermissionsBanner(true),
  })

  if (permissionState === 'waiting') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <AvSpinner />
      </div>
    )
  }

  return (
    <Suspense fallback={<AvSpinner />}>
      <div className="flex h-full w-full flex-col md:items-center md:justify-center">
        {showPermissionsBanner && (
          <PermissionsErrorBanner
            onDismiss={() => setShowPermissionsBanner(false)}
          />
        )}
        <div className="max-h-[800px] w-full max-w-[1200px] rounded-2xl bg-core-tertiary md:overflow-x-hidden xl:max-w-[1200px]">
          <AvTest permissionState={permissionState} />
        </div>
      </div>
    </Suspense>
  )
}
