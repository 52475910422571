import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { Pill } from 'components/breakout/Pill'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { CelebrationIcon } from 'components/icons/Celebration'
import { XSmallIcon } from 'components/icons/XSmall'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

type PropsWithSection = {
  section: Section
}

const SectionBox = observer(function SectionBox({ section }: PropsWithSection) {
  return (
    <div className="mx-auto my-8  h-[250px] w-[250px] rounded-2xl bg-core-secondary shadow-3xl">
      <div className="flex h-full flex-col p-2">
        <div className="text-right">
          <Pill
            icon={<CelebrationIcon size={14} className="-mt-1 inline" />}
            label={'Enrolled'}
          />
        </div>
        <div className="flex flex-1 flex-col items-start justify-center pl-2">
          <div className="text-headline-small">{section.data.className}</div>
          <div className="text-body-medium text-grey-text">
            {section.data.sectionName}
          </div>
        </div>
        <div className="ml-2 h-10 flex-grow-0 text-left">
          {section.instructor.hasData && (
            <div className="text-xs text-grey-text">
              {section.instructor.data.imageUrl && (
                <img
                  src={section.instructor.data.imageUrl}
                  alt={section.instructor.data.firstName}
                  className="mr-2 inline-block h-8 w-8 rounded-full"
                />
              )}
              <strong className="text-label-medium text-gray-800">
                {section.instructor.data.firstName}{' '}
                {section.instructor.data.lastName}
              </strong>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export function InvitationAcceptedDialog({ section }: PropsWithSection) {
  const rootStore = useRootStore()
  const { popDialog } = useDialogs()
  const { t } = useTranslation()
  return (
    <Dialog size="xs" className="p-5 text-center text-core-primary">
      <div className="flex justify-end">
        <button className="p-1" onClick={popDialog}>
          <XSmallIcon size={20} />
        </button>
      </div>
      <div>
        <div className="text-body-medium mb-2">
          {t('dart.class_invitation')}
        </div>
        <div className="text-headline-medium">
          {t('dart.class_joined_successfully')}
        </div>
        <div className="text-body-medium mx-auto max-w-[290px]">
          {t('dart.class_joined_successfully_description')}
        </div>
      </div>
      <div>
        <SectionBox section={section} />
      </div>
      <div className="mb-8 px-10">
        <BreakoutButton
          color="black"
          size="large"
          className="mx-auto rounded-2xl"
          fullWidth
          onClick={() => {
            popDialog()
            rootStore.navigateTo('home')
          }}
        >
          {t('dart.go_to_library')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
}
