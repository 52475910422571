import { useRepository } from 'hooks/auth'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { BreakoutButton } from './design-system/BreakoutButton'

export const ImpersonationInfo = observer(function ImpersonationInfo() {
  const { t } = useTranslation()
  const repository = useRepository()
  const rootStore = useRootStore()

  if (!rootStore.impersonationInfo) {
    // Not impersonating.
    return null
  }

  const instructorUser = repository.userStore.getUser(
    rootStore.impersonationInfo.userId
  )

  return (
    <strong className="text-label-medium flex-grow pl-8 pt-2">
      {t('instructor_classes.impersonating', {
        instructor: instructorUser.fullName,
      })}
      <BreakoutButton
        size="small"
        kind="secondary"
        onClick={() => {
          rootStore.stopImpersonation()
        }}
        className="text-label-small ml-2"
      >
        {t('instructor_classes.stop_impersonating')}{' '}
      </BreakoutButton>
    </strong>
  )
})
