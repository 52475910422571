import { MotionGlobalConfig } from 'framer-motion'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

// eslint-disable-next-line react-refresh/only-export-components
export function useSettings() {
  const context = useContext(SettingsContext)
  if (!context) {
    throw new Error(
      'useSettings must be used within an SettingsContextProvider'
    )
  }
  return context
}

type SettingsContextStore = {
  animationsEnabled: boolean
  updateAnimationsEnabled: (enabled: boolean) => void
}

const SettingsContext = createContext<SettingsContextStore | null>(null)

const lsKey = 'settings-animationEnabled'
const defaultValue = true

const getInitialSetting = () => {
  try {
    const lsSetting = localStorage.getItem(lsKey)
    return lsSetting ? JSON.parse(lsSetting) : defaultValue
  } catch {
    return defaultValue
  }
}

const initialConfig = { animationEnabled: getInitialSetting }

export const SettingsContextProvider = ({
  forceDisableAnimations,
  children,
}: {
  forceDisableAnimations?: boolean
  children: React.ReactNode
}) => {
  const [animationsEnabled, setAnimationsEnabled] = useState<boolean>(
    forceDisableAnimations ?? initialConfig.animationEnabled
  )

  const updateAnimationsEnabled = useCallback((enabled: boolean) => {
    setAnimationsEnabled(enabled)
    localStorage.setItem(lsKey, enabled.toString())
  }, [])

  const context = useMemo(
    () => ({ animationsEnabled, updateAnimationsEnabled }),
    [animationsEnabled, updateAnimationsEnabled]
  )

  useEffect(() => {
    // changing this setting will affect all framer-motion animations
    MotionGlobalConfig.skipAnimations = !animationsEnabled
  }, [animationsEnabled])

  return (
    <SettingsContext.Provider value={context}>
      {children}
    </SettingsContext.Provider>
  )
}
