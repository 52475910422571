import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'

/**
 * given a map of users and a room state, return the users in the room state
 * in an array
 */
export function roomStateUsers(
  roomState: RoomState,
  usersMap: Map<string, PublicUser>
): PublicUser[] {
  const users = []
  for (const userId of roomState.userIds) {
    const user = usersMap.get(userId)
    if (user) {
      users.push(user)
    }
  }
  return users
}
