import type { InstructorTeachingPlanCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorTeachingPlanCubit'
import { createContext, useContext } from 'react'

const InstructorTeachingPlanCubitContext = createContext<
  InstructorTeachingPlanCubit | undefined
>(undefined)

export const InstructorTeachingPlanCubitProvider =
  InstructorTeachingPlanCubitContext.Provider

export function useInstructorTeachingPlanCubit() {
  const cubit = useContext(InstructorTeachingPlanCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useInstructorTeachingPlanCubit must be used within a CubitProvider'
    )
  }

  return cubit
}
