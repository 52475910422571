import classNames from 'classnames'
import type { ComponentProps, PropsWithChildren } from 'react'

interface CardProps extends ComponentProps<'div'> {}

export function BreakoutCard({ children, className, ...rest }: CardProps) {
  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.currentTarget.click()
        }
      }}
      className={classNames(
        `box-border 
         h-[250px] w-full
         cursor-pointer
         items-center justify-between
         rounded-3xl border-4
         border-surface bg-surface 
         text-center
         hover:border-outline-variant
      `,
        className
      )}
      {...rest}
    >
      <div className="flex h-full flex-col p-4">{children}</div>
    </div>
  )
}

interface CardProps extends ComponentProps<'div'> {
  className?: string
}
type CardPartProps = PropsWithChildren<CardProps>

BreakoutCard.Header = function CardHeader({
  children,
  className,
  ...rest
}: CardPartProps) {
  return (
    <div className={classNames('flex-none', className)} {...rest}>
      {children}
    </div>
  )
}

BreakoutCard.Body = function CardBody({
  children,
  className,
  ...rest
}: CardPartProps) {
  return (
    <div
      className={classNames(
        'flex w-full flex-1 flex-col items-center justify-center',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

BreakoutCard.Footer = function CardFooter({
  children,
  className,
}: CardPartProps) {
  return (
    <div className={classNames('flex-none px-4', className)}>{children}</div>
  )
}

BreakoutCard.Grid = function CardGrid({
  children,
  className,
  ...rest
}: CardPartProps) {
  return (
    <div
      // prettier-ignore
      className={
        classNames(
         `grid 
          grid-cols-2
          gap-2
          md:gap-5 
          md:grid-cols-2
          lg:grid-cols-3 
          xl:grid-cols-4 
          2xl:grid-cols-5 
          3xl:grid-cols-6 `, className)}
      {...rest}
    >
      {children}
    </div>
  )
}
