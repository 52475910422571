import classNames from 'classnames'

export function BreakoutDrawer({
  open,
  onClose,
  className,
  placement,
  children,
}: {
  open: boolean
  onClose: () => void
  className?: string
  placement: string
  children?: React.ReactNode
}) {
  if (!open) return null

  return (
    <div
      onClick={onClose}
      className={classNames(
        'fixed inset-0 z-50 bg-dialog-background',
        className
      )}
    >
      <div
        className={classNames(
          'fixed z-50 h-full w-[300px] bg-core-tertiary shadow-lg',
          className,
          {
            'right-0': placement === 'right',
            'left-0': placement === 'left',
          }
        )}
      >
        {children}
      </div>
    </div>
  )
}

{
  /* <Drawer
open={open}
onClose={closeDrawer}
className=""
placement="right"
>
{mounted && <MobileNavigationDrawer closeDrawer={closeDrawer} />}
</Drawer> */
}
