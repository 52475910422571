import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { FormValues } from './schema'
import { getSchema } from './schema'
import { type OrganizationsCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationsCubit'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { OrganizationInvoiceStatus } from '@breakoutlearning/firebase-repository/types'
import { toast } from 'react-hot-toast'
import { BreakoutMultiSelect } from 'components/design-system/BreakoutMultiSelect'

export function CreateOrganizationDialog({
  cubit,
}: {
  cubit: OrganizationsCubit
}) {
  const { t } = useTranslation()
  const { clearAllDialogs } = useDialogs()

  const schema = useMemo(() => getSchema(t, true), [t])

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      organizationName: '',
      organizationInvoiceStatus: OrganizationInvoiceStatus.noInvoices,
      organizationCatalogIds: [],
      organizationDefaultCatalogIds: [],
    },
  })

  const onSubmit = async (data: FormValues) => {
    const organizationInstitution = cubit.institutions.models.find(
      (i) => i.id === data.institutionId
    )?.data.institutionName

    // copy name to organization institution field on create
    if (!organizationInstitution) toast.error('Institution no longer exists')
    else
      await cubit.createOrganization({
        ...data,
        organizationInstitution,
        catalogIds: data.organizationCatalogIds,
      })

    clearAllDialogs()
  }

  return (
    <Dialog
      className="!bg-surface"
      size="md"
      innerClassName="flex h-full flex-col"
    >
      <DialogCloseButton />
      <div className="text-headline-large mb-6">
        {t('organizations.create_new_organization')}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-1 flex-col"
      >
        <div className="flex flex-1 flex-col gap-5">
          <Controller
            control={control}
            name="organizationName"
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                {...field}
                autoFocus
                error={fieldState.error}
                type="text"
                label={t('organizations.organization_name')}
              />
            )}
          />
          <Controller
            control={control}
            name="institutionId"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                error={fieldState.error}
                label={t('organizations.organization_institution')}
                options={cubit.institutions.models.map((inst) => ({
                  label: inst.data.institutionName,
                  value: inst.id,
                }))}
              />
            )}
          />
          <Controller
            control={control}
            name="organizationInvoiceStatus"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                error={fieldState.error}
                label={t('organizations.organization_invoice_status')}
                options={[
                  {
                    label: t('organizations.no_invoices'),
                    value: OrganizationInvoiceStatus.noInvoices,
                  },
                  {
                    label: t('organizations.admin_approved'),
                    value: OrganizationInvoiceStatus.adminApproved,
                  },
                ]}
              />
            )}
          />
          <Controller
            control={control}
            name="organizationCatalogIds"
            render={({ field, fieldState }) => (
              <BreakoutMultiSelect
                {...field}
                error={fieldState.error}
                label={t('organization.catalog_access')}
                options={cubit.catalogs.models
                  .map((c) => ({
                    label: c.data.catalogName,
                    value: c.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                placeholder={t('organization.catalogs_selected.zero')}
                labelGenerator={({ length }) => {
                  if (!length) return t('organization.catalogs_selected.zero')
                  if (length === 1)
                    return t('organization.catalogs_selected.one')
                  return t('organization.catalogs_selected.many', {
                    count: length,
                  })
                }}
              />
            )}
          />
        </div>
        <div className="mt-4">
          <BreakoutButton
            size="large"
            type="submit"
            className="mt-2"
            data-testid="create-organization-button"
            fullWidth
            loading={isSubmitting}
          >
            {t('admin.confirm')}
          </BreakoutButton>
        </div>
      </form>
    </Dialog>
  )
}
