import { type OrganizationCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type OrganizationIntegration } from '@breakoutlearning/firebase-repository/models/OrganizationIntegration'
import { z } from 'zod'
import { OrganizationIntegrationType } from '@breakoutlearning/firebase-repository/types'
import { useMemo } from 'react'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { toast } from 'react-hot-toast'
import { useDialogs } from 'hooks/dialogs'
import { formatInt } from 'util/number-input'
import { zodResolver } from '@hookform/resolvers/zod'

const getSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({
    integrationName: z.string().min(1, {
      message: t('organization.integration_name_required'),
    }),
    integrationHostname: z
      .string()
      .min(1, {
        message: t('organization.integration_hostname_required'),
      })
      .url(),
    integrationClientId: z.string().min(1, {
      message: t('organization.integration_client_id_required'),
    }),
  })

type FormValues = z.infer<ReturnType<typeof getSchema>>

export const CreateOrEditOrganizationIntegrationDialog = observer(
  function CreateOrEditOrganizationIntegrationDialog({
    cubit,
    organizationIntegration,
  }: {
    cubit: OrganizationCubit
    organizationIntegration: OrganizationIntegration | null
  }) {
    const { t } = useTranslation()
    const { popDialog } = useDialogs()

    const {
      control,
      formState: { isSubmitting },
      handleSubmit,
    } = useForm<FormValues>({
      resolver: zodResolver(getSchema(t)),
      defaultValues: {
        integrationHostname:
          organizationIntegration?.data.integrationHostname || '',
        integrationName: organizationIntegration?.data.integrationName || '',
        integrationClientId: organizationIntegration?.data.integrationClientId,
      },
    })

    const submit = handleSubmit(async (data) => {
      const { integrationName, integrationHostname, integrationClientId } = data

      const integrationId = organizationIntegration?.id || undefined

      await cubit.createOrUpdateOrganizationIntegration({
        integrationId,
        integrationName,
        integrationHostname,
        integrationClientId,
        integrationType: OrganizationIntegrationType.canvas,
      })
      popDialog()
    })

    const sanitizeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (!value) return
      try {
        const url = new URL(e.target.value)
        const protoAndHost = url.protocol + '//' + url.host
        e.target.value = protoAndHost
      } catch (e) {
        //
      }
    }

    const integrationURL = useMemo(() => {
      const protocol = window.location.protocol
      let hostname = window.location.hostname
      if (window.location.port) hostname += `:${window.location.port}`
      return `${protocol}//${hostname}/lti/canvas-lti-local.json`
    }, [])

    return (
      <Dialog
        testId="add-section-dialog"
        size="xl"
        innerClassName="flex flex-col gap-8"
      >
        <DialogCloseButton />
        <div className="flex flex-row items-center gap-1">
          <div className="gap- flex flex-grow flex-col">
            <h2 className="text-headline-large">
              {t('organization.integration.canvas_integration')}
            </h2>
            <p className="text-body-large">
              {t('organization.integration.setup_lti')}
            </p>
          </div>
          <BreakoutPill
            label={t('organization.integration.integration_active')}
            dotClassName="bg-core-success"
            size="large"
          />
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-title-large">
            {t('organization.integration.setup_step_1')}
          </h3>
          <div className="flex flex-row items-end gap-3">
            <BreakoutTextInput
              className="flex-grow"
              label={t('organization.integration.breakout_integration_url')}
              value={integrationURL}
              disabled
            ></BreakoutTextInput>
            <BreakoutButton
              size="large"
              kind="secondary"
              onClick={() => {
                navigator.clipboard.writeText(integrationURL)
                toast.success(t('components.link_copied_to_clipboard'))
              }}
            >
              {t('organization.integration.copy')}
            </BreakoutButton>
          </div>
        </div>
        <div>
          <h3 className="text-title-large mb-3">
            {t('organization.integration.setup_step_2')}
          </h3>
          <form className="flex  flex-col" onSubmit={submit}>
            <div className="flex flex-col gap-2">
              <Controller
                control={control}
                name="integrationName"
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    label={t(t('organization.integration.integration_name'))}
                    kind="secondary"
                    error={fieldState.error}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="integrationHostname"
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    label={t(
                      t('organization.integration.organization_canvas_url')
                    )}
                    kind="secondary"
                    error={fieldState.error}
                    {...field}
                    onBlur={(e) => {
                      sanitizeUrl(e)
                      field.onChange(e)
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="integrationClientId"
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    kind="secondary"
                    label={t('organization.integration.integration_unique_id')}
                    error={fieldState.error}
                    {...field}
                    onChange={(e) => {
                      formatInt(e)
                      field.onChange(e)
                    }}
                  />
                )}
              />
            </div>
            <BreakoutButton
              className="mt-8"
              size="large"
              type="submit"
              fullWidth
              loading={isSubmitting}
            >
              {t('organization.integration.save_integration')}
            </BreakoutButton>
          </form>
        </div>
      </Dialog>
    )
  }
)
