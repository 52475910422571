import { useCallback, useEffect, useState } from 'react'
import { useRepository } from './auth'

type PermissionState = 'waiting' | 'granted' | 'denied'
export type DetailedPermissionState =
  | 'initializing'
  | 'waiting'
  | 'denied'
  | 'granted'
  | 'NotAllowed'
  | 'NotReadable'
  | 'Overconstrained'
  | 'Security'
  | 'NotAllowed'

export const useMediaPermissions = ({
  onDenied,
}: {
  onDenied?: () => void
}) => {
  const [permissionState, setPermissionState] =
    useState<PermissionState>('waiting')

  useEffect(() => {
    if (!navigator.mediaDevices) {
      setPermissionState('denied')
      onDenied?.()
      return
    }
    // query for both audio and video devices at the same time
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        const tracks = mediaStream.getTracks()
        tracks.forEach((track) => {
          track.stop()
        })
        setPermissionState('granted')
      })
      .catch(() => {
        setPermissionState('denied')
        onDenied?.()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [permissionState, setPermissionState] as const
}

const runError = (
  e: Error,
  setter: (state: DetailedPermissionState) => void
) => {
  switch (e.name) {
    case 'NotAllowedError':
      setter('NotAllowed')
      break
    case 'NotReadableError':
      setter('NotReadable')
      break
    case 'OverconstrainedError':
      setter('Overconstrained')
      break
    case 'SecurityError':
      setter('Security')
      break
    default:
      setter('denied')
  }
}

export const useDetailedMediaPermissionInfo = () => {
  const [permissionState, setPermissionState] =
    useState<DetailedPermissionState>('initializing')
  const [audioPermissionState, setAudioPermissionState] =
    useState<DetailedPermissionState>('initializing')
  const [videoPermissionState, setVideoPermissionState] =
    useState<DetailedPermissionState>('initializing')

  const repository = useRepository()

  const runChecks = useCallback(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      repository.logEvent('media_permissions_unsupported')
      setPermissionState('denied')
      return
    }

    // after 1 second, if we haven't gotten a response, switch to waiting
    // so that UI can show a helpful message
    const timeout = setTimeout(() => {
      setPermissionState('waiting')
    }, 1000)
    // query for both audio and video devices at the same time
    // - that triggers a single permission prompt for both
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        repository.logEvent('media_permissions_granted')
        const tracks = mediaStream.getTracks()
        tracks.forEach((track) => track.stop())
        setPermissionState('granted')
        setVideoPermissionState('granted')
        setAudioPermissionState('granted')
      })
      .finally(() => {
        clearTimeout(timeout)
      })
      .catch((e1) => {
        repository.logEvent('media_permissions_failed', {
          error_name: e1.name,
        })
        runError(e1, setPermissionState)

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            repository.logEvent('media_permissions_audio_granted')
            setAudioPermissionState('granted')

            // if we have at least access to the mic, move forward
            setPermissionState('granted')
          })
          .catch((e) => {
            repository.logEvent('media_permissions_audio_failed', {
              error_name: e.name,
            })
            runError(e, setAudioPermissionState)
          })

        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(() => {
            repository.logEvent('media_permissions_video_granted')
            setVideoPermissionState('granted')
          })
          .catch((e) => {
            repository.logEvent('media_permissions_video_failed', {
              error_name: e.name,
            })
            runError(e, setVideoPermissionState)
          })
      })
  }, [repository])

  useEffect(() => {
    runChecks()
  }, [runChecks])

  return {
    permissionState,
    audioPermissionState,
    videoPermissionState,
    skipChecks: () => {
      repository.logEvent('media_permissions_skipped')
      setPermissionState('granted')
      setAudioPermissionState('granted')
      setVideoPermissionState('granted')
    },
    runChecks,
  } as const
}
