import { observer } from 'mobx-react-lite'
import { StudentAssignmentStep1 } from './StudentAssignmentStep1'
import { StudentAssignmentStep2 } from './StudentAssignmentStep2'
import { StudentAssignmentStep3 } from './StudentAssignmentStep3'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'

export const StudentAssignmentStepsLoading = () => {
  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-start overflow-auto rounded-3xl bg-core-tertiary lg:w-auto">
      <div className="h-full max-h-full">
        <div className="flex h-full flex-col items-center justify-center overflow-auto">
          Loading...
        </div>
      </div>
    </div>
  )
}

export const StudentAssignmentSteps = observer(
  function StudentAssignmentSteps() {
    const cubit = useStudentAssignmentCubit()
    const hasPreWorkMaterials = cubit.materials.models.some((m) => m.isPreWork)
    const showStep2 = hasPreWorkMaterials || cubit.hasPreWorkQuiz
    //const showStep2 = false

    if (showStep2)
      return (
        <div className="flex w-full flex-grow flex-col items-center justify-between space-y-3 rounded-3xl bg-core-tertiary p-3 md:h-full  md:overflow-auto">
          <StudentAssignmentStep1 className="mt-1 w-full py-5 text-center" />
          <div className="w-[90%] max-w-[90%] border-t border-gray-200 md:w-[500px]"></div>

          <StudentAssignmentStep2
            className="mt-1 flex-grow-0 py-5 text-center"
            hasPreMeetingQuiz={cubit.hasPreWorkQuiz}
            hasPreWorkMaterials={hasPreWorkMaterials}
          />
          <div className="w-[90%] max-w-[90%] border-t border-gray-200 md:w-[500px]"></div>

          <StudentAssignmentStep3 className="pb-8 pt-4 text-center" />
        </div>
      )

    return (
      <div className="flex w-full flex-grow flex-col items-center rounded-3xl bg-core-tertiary p-3 md:h-full  md:overflow-auto">
        <StudentAssignmentStep1 className="flex flex-1 flex-col justify-center text-center" />
        <div className="w-[90%] max-w-[90%] border-t border-gray-200 md:w-[500px]"></div>
        <StudentAssignmentStep3
          showStep2Title={!showStep2}
          className="flex flex-1 flex-col items-center justify-center py-5 text-center"
        />
      </div>
    )
  }
)
