import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'

export function roomStateGroupLeader({
  roomState,
  section,
  usersMap,
}: {
  roomState: RoomState
  section: Section
  usersMap: Map<string, PublicUser>
}) {
  if (roomState.groupLeaderUserIds.length > 0) {
    const groupLeaderId = roomState.groupLeaderUserIds[0]
    const groupLeader = usersMap.get(groupLeaderId)
    if (groupLeader) {
      return groupLeader
    } else if (roomState.groupLeaderUserIds.includes(section.instructor.id)) {
      return section.instructor
    }
  }
}
