import type { SlideDeckAuthor } from '@breakoutlearning/firebase-repository/models/SlideDeckAuthor'
import type { TeachingPlan } from '@breakoutlearning/firebase-repository/models/TeachingPlan'
import { GoogleStorageImage } from 'components/GoogleStorageImage'
import { useInstructorLibraryWithTeachingPlansCubit } from 'hooks/cubits/instructorLibraryWithTeachingPlans'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const TeachingPlanCard = observer(function TeachingPlanCard({
  teachingPlan,
  onClick,
}: {
  teachingPlan: TeachingPlan
  onClick: () => void
}) {
  const { t } = useTranslation()
  const cubit = useInstructorLibraryWithTeachingPlansCubit()
  const teachingPlanAggregations =
    cubit.teachingPlanAggregationByTeachingPlanId.get(teachingPlan.id)
  const moduleCount = teachingPlanAggregations?.data?.moduleCount ?? 0
  const experienceCount = teachingPlanAggregations?.data?.slideDeckCount ?? 0

  const authors = cubit.teachingPlansAuthorsByPlanId.get(teachingPlan.id)

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') onClick()
      }}
      onClick={onClick}
      data-testid={`teaching-plan-card-${teachingPlan.id}`}
      className="flex cursor-pointer flex-col rounded-2.5xl border-3 border-surface-bright bg-surface-bright p-3 hover:border-outline-variant"
    >
      <img
        src={teachingPlan.data.teachingPlanImageURL}
        alt={teachingPlan.data.teachingPlanName}
        aria-hidden
        className="mb-1 aspect-video w-full rounded-xl object-cover"
      />
      <div className="text-title-large truncate text-on-surface">
        {teachingPlan.data.teachingPlanName}
      </div>
      <div className="text-label-medium text-on-surface-var">
        <span>
          {t('instructor_library.modules_number', { count: moduleCount })}
        </span>{' '}
        &mdash;{' '}
        <span>
          {t('instructor_library.experiences_number', {
            count: experienceCount,
          })}
        </span>
      </div>
      {authors && authors.models.length > 0 && (
        <TeachingPlanAuthors authors={authors.models} />
      )}
    </div>
  )
})

const TeachingPlanAuthors = observer(function TeachingPlanAuthors({
  authors,
}: {
  authors: SlideDeckAuthor[]
}) {
  // View for only one author.
  if (authors.length === 1) {
    const author = authors[0]
    return (
      <div className="mt-1 flex flex-row items-center gap-2">
        <div>
          <GoogleStorageImage
            src={author.data.authorImageURL}
            className="aspect-square max-h-[30px] max-w-[30px] rounded-full object-cover"
            namespace="instructor-library"
            alt={author.data.authorName}
          />
        </div>
        <div>
          <div className="text-title-small line-clamp-1 text-on-surface">
            {author.data.authorName}
          </div>
          <div className="text-body-small line-clamp-1 text-on-surface-var">
            {author.data.authorTitle}
          </div>
          <div className="text-body-small text-on-surface-var">
            {author.data.authorInstitution}
          </div>
        </div>
      </div>
    )
  }

  const authorsToShow = authors.slice(0, 3)
  const dedupedAuthorInstitutions = Array.from(
    new Set(authors.map((author) => author.data.authorInstitution))
  )

  // View for multiple authors.
  return (
    <div className="mt-1 flex flex-grow flex-row items-center gap-2">
      <div>
        <div className="flex flex-row justify-center -space-x-3">
          {/* Show the first three author avatars */}
          {authorsToShow.map((author) => (
            <GoogleStorageImage
              key={author.id}
              src={author.data.authorImageURL}
              className="aspect-square max-h-[30px] max-w-[30px] rounded-full object-cover"
              namespace="instructor-library"
              alt={author.data.authorName}
            />
          ))}
        </div>
      </div>
      <div>
        <div className="text-title-small line-clamp-1  text-on-surface">
          Authors From
        </div>
        <div className="text-body-small  text-on-surface">
          {dedupedAuthorInstitutions.join(', ')}
        </div>
      </div>
    </div>
  )
})
