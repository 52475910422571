import { BreakoutTooltip } from './design-system/BreakoutTooltip'

export function Button({
  children,
  className,
  defaultWidth = true,
  overrideClassName,
  onClick,
  type = 'button',
  tooltip,
  testId,
  disabled,
}: {
  defaultWidth?: boolean
  children: React.ReactNode
  className?: string
  overrideClassName?: string
  disabled?: boolean
  onClick?: () => void
  testId?: string
  tooltip?: string
  type?: 'button' | 'submit' | 'reset'
}) {
  const widthClass = defaultWidth ? 'w-[182px]' : ''
  const buttonClassName =
    overrideClassName ||
    `${className} ${widthClass} h-[54px] rounded-2xl text-sm font-semibold antialiased overflow-hidden`
  const component = (
    <button
      data-testid={testId}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClassName}
    >
      {children}
    </button>
  )
  if (!tooltip) return component
  return <BreakoutTooltip content={tooltip}>{component}</BreakoutTooltip>
}
