// Fetch wrapper to decorate the Load Failed error with the resource that caused it
// so that we can have a sliver of a chance to debug it
export function startFetchWrapper() {
  const originalFetch = window.fetch
  window.fetch = (input: RequestInfo | URL, init?: RequestInit) => {
    return originalFetch(input, init).catch((error: TypeError) => {
      if (error instanceof TypeError) {
        // decorate the error with the resource that caused it
        const stringResource = String(input)
        error.message = `${error.message} (${stringResource})`
      }
      throw error
    })
  }
}
