import { StudentLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import { FullPageSpinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

export const DemoWelcomePage = observer(function DemoWelcomePage() {
  const rootStore = useRootStore()
  const repository = useRepository()

  const libraryCubit = useCubitBuilder(
    () => new StudentLibraryCubit(repository),
    [repository]
  )

  useEffect(() => {
    if (!libraryCubit.roomStates.isLoaded) return

    const roomStates = libraryCubit.roomStates.models.filter((roomState) => {
      return roomState.data.isDemo
    })

    const first = roomStates.length ? roomStates[0] : null

    if (first) {
      rootStore.navigateToDemoWaitingRoom(first.id)
    } else {
      rootStore.navigateTo('logout')
    }
  }, [
    rootStore,
    libraryCubit.roomStates.isLoaded,
    libraryCubit.roomStates.models,
  ])

  return <FullPageSpinner />
})
