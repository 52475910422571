function getAppCommitSha() {
  return process.env.APP_COMMIT_SHA || 'development'
}

async function getCurrentGitCommitSha() {
  try {
    const response = await fetch('/commit.txt?_=' + Date.now())

    if (!response.ok) return undefined

    const text = await response.text()
    const trimmed = text.trim()

    if (trimmed === '') return undefined

    return trimmed
  } catch (error) {
    // if we fail to fetch the commit sha, we just ignore it
    return undefined
  }
}

export function getShortAppCommitSha() {
  const sha = getAppCommitSha()
  return sha.slice(0, 7)
}

async function checkForNewSha() {
  const newSha = await getCurrentGitCommitSha()

  if (!newSha) return

  if (newSha !== getAppCommitSha()) {
    const event = new CustomEvent('breakout:version-update', {
      detail: newSha,
    })
    window.dispatchEvent(event)

    clearInterval(versionMonitorInterval!) // we have new version, no need to check anymore
  }
}

let versionMonitorInterval: NodeJS.Timeout | null = null

const minutes = 5
const checkInterval = 1000 * 60 * minutes

export function startVersionMonitor() {
  versionMonitorInterval = setInterval(checkForNewSha, checkInterval)
  window.addEventListener('focus', checkForNewSha)
}
