import { FirebaseRepository } from '@breakoutlearning/firebase-repository/models/FirebaseRepository'
import type { IReactionDisposer } from 'mobx'
import { reaction } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import { getFirebaseConfig } from '../../config/firebase'
import { AuthenticationContext } from '../../hooks/auth'
import { inBeta, inE2E, environment } from 'config/environment'

export const AuthenticationContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const repository = useMemo(() => {
    return FirebaseRepository.build({
      firebase: getFirebaseConfig(),
      useLocalPersistence: inE2E(),
      disableAnalytics: inE2E(),
      inBeta: inBeta(),
      environment: environment(),
    }).initialize()
  }, [])

  const [authenticated, setAuthenticated] = useState(repository.isAuthenticated)
  const [initialized, setInitialized] = useState(repository.initialized)

  const usingLocalEmulator =
    import.meta.env.VITE_USE_EMULATOR === 'true' ||
    process.env.VITE_USE_EMULATOR === 'true'

  useEffect(() => {
    if (usingLocalEmulator) {
      const host = import.meta.env.VITE_USE_EMULATOR_HOST || 'localhost'
      try {
        repository.connectToEmulator(host)
      } catch (e) {
        console.error(e)
      }
    }
  }, [repository, usingLocalEmulator])

  useEffect(() => {
    const disposers: IReactionDisposer[] = []
    disposers.push(
      reaction(
        () => repository.isAuthenticated,
        (isAuthenticated) => {
          setAuthenticated(isAuthenticated)
        },
        { fireImmediately: true }
      )
    )
    // only add this reaction if the component is not initialized
    if (!initialized) {
      disposers.push(
        reaction(
          () => repository.initialized,
          (isInitialized) => {
            setInitialized(isInitialized)
          },
          { fireImmediately: true }
        )
      )
    }
    return () => {
      disposers.forEach((disposer) => disposer())
    }
  }, [repository, initialized])

  return (
    <AuthenticationContext.Provider
      value={{
        initialized,
        repository,
        authenticated,
        usingLocalEmulator,
        setAuthenticated,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
