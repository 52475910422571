import type { MeetingCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingCubit'
import { createContext, useContext } from 'react'

function useMeetingContext() {
  return useContext(MeetingContext)
}

export function useMeeting() {
  const context = useMeetingContext()
  if (!context) {
    throw new Error('useMeeting must be used within a MeetingContext')
  }
  return context.meeting
}

// Use React context to make your store available in your application
const MeetingContext = createContext<{ meeting: MeetingCubit } | null>(null)
export const MeetingContextProvider = MeetingContext.Provider
