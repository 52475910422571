import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { Spinner } from 'components/Spinner'
import { useOrganizationCubit } from 'hooks/cubits/organization'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { SectionRequest } from '@breakoutlearning/firebase-repository/models/SectionRequest'
import { useDialogs } from 'hooks/dialogs'
import { DoubleCheckIcon } from 'components/icons/DoubleCheck'
import { ClockIcon } from 'components/icons/Clock'
import { CircleX } from 'components/icons/CircleX'
import { CheckIcon } from 'components/icons/Check'
import { ViewRequestDialog } from './ViewRequestDialog'
import { SectionRequestState } from '@breakoutlearning/firebase-repository/types'

type Tab = 'pending' | 'not_completed'
const tabs = ['pending', 'not_completed'] as Tab[]
export const OrganizationInvoiceRequestView = observer(
  function OrganizationInvoiceRequestView() {
    const cubit = useOrganizationCubit()
    const { t } = useTranslation()

    const [filters, setFilters] = useState<string[]>([])

    const filteredInvoiceRequests = useMemo(() => {
      const requests = cubit.invoiceRequestsForOrganization.filter(
        (request) =>
          request.data.sectionRequestState !== SectionRequestState.withdrawn
      )
      if (filters.length === 0) {
        return requests
      }
      return requests.filter((request) => {
        const section = cubit.sectionsForOrganizationBySectionId.get(
          request.data.sectionId
        )

        if (!section) {
          return false
        }

        return filters.some((filter) => {
          return (
            section.data.className
              .toLowerCase()
              .includes(filter.toLowerCase()) ||
            section.data.sectionName
              .toLowerCase()
              .includes(filter.toLowerCase()) ||
            section.instructor.fullName
              .toLowerCase()
              .includes(filter.toLowerCase())
          )
        })
      })
    }, [
      cubit.invoiceRequestsForOrganization,
      cubit.sectionsForOrganizationBySectionId,
      filters,
    ])

    if (!cubit.invoiceRequestsForOrganization) {
      return (
        <div className="flex h-full -translate-y-20 items-center justify-center">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="h-full min-h-0 rounded-3xl bg-surface p-6">
        <div className="h-full overflow-auto rounded-b-3xl">
          <div className="flex flex-row flex-wrap gap-2 pb-5">
            {tabs.map((tab) => (
              <BreakoutButton
                key={tab}
                size="medium"
                kind={
                  tab === cubit.filterSectionRequestsBy
                    ? 'tertiary'
                    : 'secondary'
                }
                onClick={() => {
                  if (cubit.filterSectionRequestsBy === tab) {
                    return
                  }

                  cubit.setFilterSectionRequestsBy(
                    tab as typeof cubit.filterSectionRequestsBy
                  )
                }}
              >
                {t(`organization.${tab}`)}
              </BreakoutButton>
            ))}
          </div>
          <div className="overflow-auto rounded-3xl bg-core-tertiary">
            <BreakoutTable className="white max-h-full">
              <thead>
                <tr className="sticky top-0 z-[10] !px-5">
                  <th>{t('organization.date')}</th>
                  <th>{t('organization.class')}</th>
                  <th>{t('organization.instructor')}</th>
                  <th>{t('organization.est_students')}</th>
                  <th>{t('organization.est_experiences')}</th>
                  <th>{t('organization.est_cost')}</th>
                  <th>{t('organization.status')}</th>

                  <th>
                    <BreakoutTextInput
                      kind="secondary"
                      className="float-right !min-w-[230px] max-w-[300px]"
                      placeholder={t('organization.filter_by_class_professor')}
                      onKeyDown={(e) => {
                        if (
                          e.key === 'Enter' &&
                          e.currentTarget.value.trim() !== ''
                        ) {
                          e.preventDefault()
                          setFilters([...filters, e.currentTarget.value])
                          e.currentTarget.value = ''
                        }
                      }}
                      LeadingIcon={Search}
                    />
                  </th>
                </tr>
                {filters.length > 0 && (
                  <tr>
                    <td colSpan={3}>
                      <div className="py-3">
                        <BreakoutPill.Chips
                          labels={filters}
                          onClick={(label) =>
                            setFilters((oldFilters) => {
                              const filtersCopy = [...oldFilters]
                              return filtersCopy.filter(
                                (item) => item !== label
                              )
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </thead>
              <tbody className="max-h-full overflow-auto">
                {filteredInvoiceRequests.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={9}>
                      <div className="p-5">
                        {t('organization.no_invoice_requests_found')}
                      </div>
                    </td>
                  </tr>
                )}
                {filteredInvoiceRequests.map((request) => {
                  const section = cubit.sectionsForOrganizationBySectionId.get(
                    request.data.sectionId
                  )
                  const result =
                    cubit.instructorsForOrganizationByInstructorId.get(
                      section?.instructor.id ?? ''
                    )

                  const autoApproveInvoice =
                    result?.orgInstructor.autoApproveInvoice ?? false

                  if (!section) {
                    return null
                  }
                  return (
                    <Row
                      key={request.id}
                      request={request}
                      section={section}
                      autoApproveInvoice={autoApproveInvoice}
                    />
                  )
                })}
              </tbody>
            </BreakoutTable>
          </div>
        </div>
      </div>
    )
  }
)

const Row = observer(function ClassRow({
  section,
  request,
  autoApproveInvoice,
}: {
  section: Section
  request: SectionRequest
  autoApproveInvoice: boolean
}) {
  const cubit = useOrganizationCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  return (
    <tr>
      <td>
        <div className="text-label-medium text-nowrap">
          {request.monthDayYear}
        </div>
        <div className="text-body-medium text-nowrap text-on-surface-var">
          {request.timeAndTimezone}
        </div>
      </td>
      <td>
        <div className="text-label-medium text-nowrap">
          {section.data.className}
        </div>
        <div className="text-body-medium text-nowrap text-on-surface-var">
          {section.data.sectionName}
        </div>
      </td>

      <td>
        <div className="flex h-full shrink-0 items-center justify-start gap-1 border-none">
          <BreakoutUserAvatar
            className="shrink-0"
            user={section.instructor}
            radius={13}
          />
          <strong className="text-label-medium shrink-0">
            {section.instructor.fullName}
          </strong>
        </div>
      </td>
      <td>
        <div className="text-label-medium text-nowrap">
          {request.data.sectionRequestStudentCount}
        </div>
      </td>

      <td>
        <div className="text-label-medium text-nowrap">
          {request.data.sectionRequestAssignmentCount}
        </div>
      </td>

      <td>
        <div className="text-label-medium text-nowrap">
          {request.estimatedCost}
        </div>
      </td>
      <td>
        <RequestStatus
          request={request}
          autoApproveInvoice={autoApproveInvoice}
        />
      </td>

      <td>
        <div className="flex   justify-end gap-2">
          <BreakoutButton
            kind="secondary"
            className="!min-w-[62px]"
            onClick={() => {
              showDialog(({ remove }) => {
                return (
                  <ViewRequestDialog
                    request={request}
                    section={section}
                    approveRequest={async (request) => {
                      await cubit.approveRequest(request)
                      cubit.setFilterSectionRequestsBy('not_completed')
                    }}
                    rejectRequest={async (request, reason) => {
                      await cubit.rejectRequest(request, reason)
                      cubit.setFilterSectionRequestsBy('not_completed')
                    }}
                    close={remove}
                  />
                )
              })
            }}
            size="medium"
          >
            {t('organization.view_request')}
          </BreakoutButton>
        </div>
      </td>
    </tr>
  )
})

const RequestStatus = observer(function RequestStatus({
  request,
  autoApproveInvoice,
}: {
  request: SectionRequest
  autoApproveInvoice: boolean
}) {
  const { t } = useTranslation()

  if (autoApproveInvoice) {
    return (
      <div className="text-label-medium flex items-center gap-1">
        <DoubleCheckIcon className="shrink-0 text-core-success" />{' '}
        {t('organization.auto_approved')}
      </div>
    )
  }
  switch (request.data.sectionRequestState) {
    case 0:
      return (
        <div className="text-label-medium flex items-center gap-1">
          <ClockIcon className="shrink-0 text-fixed-accent-color" size={14} />{' '}
          {t('organization.pending')}
        </div>
      )
    case 1:
      return (
        <div className="text-label-medium flex items-center gap-1">
          <CheckIcon size={14} className="text-core-success" />{' '}
          {t('organization.approved')}
        </div>
      )
    case 2:
      return (
        <div className="text-label-medium flex items-center gap-1">
          <CircleX className="shrink-0 stroke-core-error" size={14} />{' '}
          {t('organization.rejected')}
        </div>
      )
    default:
      return null
  }
})
