// assets/images/onboarding/onboarding_podcast.png

import { Button } from 'components/Button'
import { httpsCallable } from 'firebase/functions'
import { useFunctions } from 'hooks/auth'
import { useRootStore } from 'hooks/rootStore'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { FullPageSpinner } from 'components/Spinner'

function getApplePodcastsUrl(rssUrl: string) {
  if (rssUrl === '') return rssUrl
  return rssUrl?.replace(/https?/, 'podcast')
}

function getDowncastPodcastsUrl(rssUrl: string) {
  if (rssUrl === '') return rssUrl
  return rssUrl?.replace(/https?/, 'downcast')
}

function PodcastButton({
  rssURL,
  image,
  label,
  onClick,
}: {
  rssURL: string
  image: string
  label: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}) {
  const disabled = rssURL === ''
  return (
    <div
      className={classNames(
        'flex h-[100px] w-full flex-col items-center justify-center rounded-lg bg-surface p-5 text-center',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        disabled ? 'opacity-25' : ''
      )}
    >
      <a
        onClick={disabled ? undefined : onClick}
        href={rssURL}
        rel="noreferrer"
      >
        <img
          src={`assets/images/podcast/${image}.png`}
          className="inline"
          alt={label}
        />
        <span className="mt-2 block text-xs font-medium">{label}</span>
      </a>
    </div>
  )
}

export const PodcastsComponent = ({
  onboarding = false,
  hideImage,
  titleOverride,
  hideBackBtn,
  contentClassName,
  titleClassName,
  subTextClassName,
}: {
  onboarding?: boolean
  hideImage?: boolean
  titleOverride?: string
  hideBackBtn?: boolean
  contentClassName?: string
  titleClassName?: string
  subTextClassName?: string
}) => {
  const rootStore = useRootStore()
  const { t } = useTranslation()
  const [rssURL, setRssURL] = useState('')

  const functions = useFunctions()

  useEffect(() => {
    httpsCallable<{ data: null }, string>(
      functions,
      'getRssURL'
    )({ data: null }).then((result) => {
      setRssURL(result.data)
    })
  }, [functions])

  return (
    <main
      id="main"
      className={classNames(
        'flex h-full flex-col items-stretch justify-stretch overflow-auto md:flex-row',
        { 'py-5': onboarding }
      )}
    >
      {!hideImage && (
        <div className="mx-auto mt-5 flex flex-shrink-0 flex-col items-center justify-center align-middle md:mt-0 md:h-full md:w-[400px] md:max-w-[30%]">
          <img
            width={!onboarding ? 400 : undefined}
            className={classNames({
              'mr-auto h-full': onboarding,
            })}
            src="assets/images/onboarding/onboarding_podcast.png"
            alt="Podcast"
          />
        </div>
      )}
      <div className="flex h-full flex-col justify-between align-middle">
        <div
          className={classNames(
            'md:ml-10',
            { 'md:mt-20': !onboarding },
            contentClassName
          )}
        >
          {onboarding && (
            <p className="text-body-large mb-3 text-grey-text">
              {t('onboarding.before_you_go')}
            </p>
          )}
          <h1
            className={classNames(
              'mb-5 mt-5 text-center  md:mt-0 md:text-left',
              titleClassName,
              { 'text-4xl font-extrabold': !titleClassName }
            )}
          >
            {titleOverride ? titleOverride : t('onboarding.podcast')}
          </h1>
          <p
            className={classNames(subTextClassName, {
              'text-label-large mb-5 text-sm text-core-on-tertiary':
                !subTextClassName,
            })}
          >
            {t('onboarding.podcast_sub')}
          </p>
          <div className="grid grid-flow-row grid-cols-4 gap-4">
            <PodcastButton
              rssURL={rssURL}
              onClick={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(rssURL)
                toast.success(t('components.link_copied_to_clipboard'), {
                  id: 'link-copied ',
                })
              }}
              image="podcast_rss"
              label="Copy RSS Feed"
            />
            <PodcastButton
              rssURL={getApplePodcastsUrl(rssURL)}
              onClick={(e) => {
                e.preventDefault()
                window.open(getApplePodcastsUrl(rssURL), '_blank')
              }}
              image="podcast_apple"
              label="Apple Podcasts"
            />
            <PodcastButton
              rssURL={getDowncastPodcastsUrl(rssURL)}
              onClick={(e) => {
                e.preventDefault()
                window.open(getDowncastPodcastsUrl(rssURL), '_blank')
              }}
              image="podcast_downcast"
              label="Downcast"
            />
          </div>
        </div>
        <div></div>

        {!onboarding && !hideBackBtn && (
          <div className="mt-5 text-center md:mt-0 md:text-right ">
            <Button
              onClick={() => {
                const returnTo = rootStore.router.queryParams?.returnTo
                if (returnTo && typeof returnTo === 'string') {
                  window.location.href = returnTo
                } else {
                  rootStore.navigateTo('home')
                }
              }}
              className="mr-4 bg-core-primary text-core-on-primary"
            >
              Back
            </Button>
          </div>
        )}
      </div>
    </main>
  )
}

export const PodcastsPage = () => {
  const rootStore = useRootStore()
  useEffect(() => {
    const returnTo = rootStore.router.queryParams?.returnTo
    setTimeout(() => {
      rootStore.navigateToPodcastsPage({
        returnTo: returnTo,
      })
    }, 1000)
  }, [rootStore])
  return <FullPageSpinner />
}
