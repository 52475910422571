import type { InstructorAssistantsCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssistantsCubit'
import { LinkCopiedToClipboardDialog } from 'components/LinkCopiedToClipboard'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { copyAsyncTextToClipboard } from 'util/clipboard'

export function AddAssistantDialog({
  cubit,
}: {
  cubit: InstructorAssistantsCubit
}) {
  const [invitationUrl, setInvitationUrl] = useState<string | null>(null)

  useEffect(() => {
    const generateInvitation = async () => {
      const { output: invitationUrl } = await copyAsyncTextToClipboard(
        async () => {
          const id = await cubit.createInvitation()

          return `${window.location.origin}/invitation/${id}`
        }
      )

      setTimeout(() => {
        // A cosmetic delay to show the spinner for a bit longer. Avoids flickering.
        setInvitationUrl(invitationUrl)
      }, 300)
    }

    generateInvitation()
  }, [cubit])

  return (
    <LinkCopiedToClipboardDialog
      url={invitationUrl}
      warning={t('instructor_assistants.link_warning_assistants')}
    />
  )
}
