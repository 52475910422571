import type { ButtonKind } from 'components/design-system/BreakoutButton'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ConfirmationDialog = ({
  text,
  subtitle,
  buttonText,
  buttonKind = 'error',
  inline = false,
  open,
  topIcon,
  btnTestId,
  onConfirm,
  dismiss,
}: {
  text: string
  subtitle?: string | React.ReactNode
  buttonText?: string
  buttonKind?: ButtonKind
  inline?: boolean
  open?: boolean
  topIcon?: React.ReactNode
  btnTestId?: string
  onConfirm: () => Promise<void>
  dismiss?: () => void
}) => {
  const [confirming, setConfirming] = useState(false)
  const { popDialog } = useDialogs()
  const { t } = useTranslation()
  const DialogToUse = inline ? InlineDialog : Dialog
  const dismissDialog = dismiss ?? popDialog

  return (
    <DialogToUse
      size="xs"
      innerClassName="flex"
      className="!bg-surface"
      open={open}
      onDismiss={dismiss}
      ignoreBackdropClick={confirming}
      ignoreEscapeKey={false}
    >
      <DialogCloseButton
        onClick={(e) => {
          e.preventDefault()
          dismissDialog()
          return false
        }}
      />
      <div className="flex w-full flex-col justify-between text-center">
        <div className="flex flex-grow flex-col justify-center gap-3">
          {topIcon && <div className="mx-auto">{topIcon}</div>}
          <h3 className="text-headline-medium">{text}</h3>
          {subtitle && typeof subtitle === 'string' ? (
            <p className="text-body-medium">{subtitle}</p>
          ) : (
            subtitle
          )}
        </div>
        <BreakoutButton
          disabled={confirming}
          loading={confirming}
          size="large"
          kind={buttonKind}
          className="w-full"
          data-testid={btnTestId}
          onClick={() => {
            setConfirming(true)
            onConfirm().finally(() => {
              setConfirming(false)
              dismissDialog()
            })
          }}
        >
          {buttonText || t('admin.confirm')}
        </BreakoutButton>
      </div>
    </DialogToUse>
  )
}
