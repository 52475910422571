import type { InstructorLibraryWithTeachingPlansCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorLibraryWithTeachingPlansCubit'
import { createContext, useContext } from 'react'

const InstructorLibraryWithTeachingPlansCubitContext = createContext<
  InstructorLibraryWithTeachingPlansCubit | undefined
>(undefined)

export const InstructorLibraryWithTeachingPlansCubitProvider =
  InstructorLibraryWithTeachingPlansCubitContext.Provider

export function useInstructorLibraryWithTeachingPlansCubit() {
  const cubit = useContext(InstructorLibraryWithTeachingPlansCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useInstructorLibraryWithTeachingPlansCubit must be used within a CubitProvider'
    )
  }

  return cubit
}
