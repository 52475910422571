import type { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { InvitationType } from '@breakoutlearning/firebase-repository/types'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { LinkCopiedToClipboardDialog } from 'components/LinkCopiedToClipboard'
import { useDialogs } from 'hooks/dialogs'
import { t } from 'i18next'
import { useState } from 'react'
import { copyAsyncTextToClipboard } from 'util/clipboard'

export function SectionInvitationDialog({
  cubit,
}: {
  cubit: InstructorSectionCubit
}) {
  const [loadingLinkType, setLoadingLinkType] = useState<InvitationType>()
  const { showDialog } = useDialogs()

  const handleGenerateLink = async (type: InvitationType) => {
    setLoadingLinkType(type)

    const { success, output: invitationUrl } = await copyAsyncTextToClipboard(
      async () => {
        const id = await cubit.createStudentInvitation({ type })
        const invitationUrl = `${window.location.origin}/invitation/${id}`
        return invitationUrl
      }
    )

    const title = success
      ? 'components.link_copied_to_clipboard'
      : 'instructor_class.linked_copied_clipboard_failure'
    if (success) {
      setLoadingLinkType(undefined)
    }

    showDialog(() => (
      <LinkCopiedToClipboardDialog
        url={invitationUrl}
        title={t(title)}
        warning={t('instructor_class.link_warning')}
      />
    ))
  }

  // Disable buttons if we are loading a link.
  const disableButtons = typeof loadingLinkType !== 'undefined'

  return (
    <Dialog
      key="dialog-select-url"
      size="xs"
      className="overflow-auto bg-core-tertiary outline-none"
    >
      <DialogCloseButton />

      <h2 className="text-2xl font-semibold">
        {t('instructor_class.create_invite_link')}
      </h2>
      <p className="mt-4 whitespace-pre-wrap text-sm">
        {t('instructor_class.invite_students_instructions')}
      </p>
      <BreakoutButton
        disabled={disableButtons}
        size="large"
        onClick={() => handleGenerateLink(InvitationType.multiUse)}
        className="mt-6 w-full"
      >
        {t('instructor_class.three_month_link')}
      </BreakoutButton>
    </Dialog>
  )
}
