import { type AdminSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/AdminSlideDeckCubit'
import {
  type SlideDeck,
  SlideDeckState,
} from '@breakoutlearning/firebase-repository/models/SlideDeck'
import classNames from 'classnames'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const AdminSlideDeckVersionTile = observer(
  function AdminSlideDeckVersionTile({
    slideDeck,
    catalogNames,
    onClick,
    moduleData,
  }: {
    slideDeck: SlideDeck
    catalogNames: string[]
    onClick: (slideDeckId: string) => void
    moduleData?: AdminSlideDeckCubit['slideDeckModulesForSlideDecksOfTypeByCatalogId']['string']
  }) {
    const { t } = useTranslation()
    const showCatalogs =
      slideDeck.slideDeckState === SlideDeckState.published &&
      catalogNames.length > 0

    const showModules = moduleData?.length

    return (
      <div
        className={classNames(
          'cursor-pointer rounded-2xl border-2 border-surface bg-surface p-3 hover:border-surface-dim'
        )}
        onClick={() => onClick(slideDeck.id)}
      >
        <div className="flex flex-row items-center justify-between ">
          <div className="text-label-medium text-on-surface">
            <div>
              {t('admin.version')} {slideDeck.data.slideDeckVersion}
            </div>
            <div className="text-body-small text-on-surface-var">
              {DateTime.fromJSDate(slideDeck.data.updatedAt).toLocaleString(
                DateTime.DATE_MED
              )}
            </div>
            <div></div>
          </div>
          <div>
            <BreakoutPill.SlideDeckState
              kind="primary"
              state={slideDeck.slideDeckState}
            />
          </div>
        </div>
        {showCatalogs && (
          <div className="mt-2 flex flex-row flex-wrap gap-2">
            {catalogNames.map((name, i) => (
              <div
                className="text-body-small rounded-xl bg-surface-bright p-1 px-2 text-on-surface-var"
                key={i}
              >
                {name}
              </div>
            ))}
          </div>
        )}
        {showModules && (
          <div className="mt-2 flex flex-col gap-2">
            {moduleData.map(({ teachingPlan, teachingPlanModule }, i) => (
              <div
                className="text-body-small rounded-xl bg-surface-bright p-1 px-2 text-on-surface-var"
                key={
                  teachingPlan.isNotEmpty && teachingPlanModule.isNotEmpty
                    ? `${teachingPlan.id} - ${teachingPlanModule.id}`
                    : i
                }
              >
                {teachingPlan.data.teachingPlanName || 'unknown'} -{' '}
                {teachingPlanModule.data.moduleName || 'unknown'}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
)
