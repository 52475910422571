import { SuspenseErrorBoundary } from 'components/SuspenseErrorBoundary'
import { Suspense, lazy } from 'react'

const MaterialPdfDialog = lazy(() => import('./MaterialPdfDialog'))

export const LazyMaterialPdfDialog = ({
  src,
  slideDeckId,
  inline,
  onDismiss,
}: {
  src: string
  slideDeckId: string
  inline?: boolean
  onDismiss?: () => void
}) => {
  return (
    <SuspenseErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <MaterialPdfDialog
          src={src}
          slideDeckId={slideDeckId}
          inline={inline}
          onDismiss={onDismiss}
        />
      </Suspense>
    </SuspenseErrorBoundary>
  )
}
