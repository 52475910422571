import type { FirebaseStorage } from 'firebase/storage'
import { getDownloadURL, ref } from 'firebase/storage'

export async function decodeStorageUrl(storage: FirebaseStorage, url: string) {
  const path = url.split('appspot.com/o/').pop()?.split('?').shift()
  const unescapedPath = decodeURIComponent(path || '')

  const fileRef = ref(storage, unescapedPath)
  const fileUrl = await getDownloadURL(fileRef)
  return fileUrl
}
