import { Spinner } from 'components/Spinner'
import { useCallback, useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useRepository } from '../../hooks/auth'
import { Button } from 'components/Button'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { useRootStore } from 'hooks/rootStore'

function PasswordLogin() {
  const [loggingIn, setLoggingIn] = useState(false)
  const rootStore = useRootStore()

  const repository = useRepository()

  const onPasswordLogin = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const form = e.currentTarget
      const email = form.email.value
      const password = form.password.value

      setLoggingIn(true)
      signInWithEmailAndPassword(repository.auth, email, password)
        .then(() => {
          rootStore.navigateTo('home')
        })
        .catch((error) => {
          setLoggingIn(false)
          console.error(error)
        })
    },
    [repository, rootStore]
  )

  if (loggingIn) {
    return <Spinner />
  }

  return (
    <div className="bg-lightgray mt-5 w-1/3 rounded-2xl p-5">
      <form onSubmit={onPasswordLogin} className="flex flex-col gap-5">
        <BreakoutTextInput
          kind="secondary"
          id="email"
          label="Email"
          name="email"
        />
        <BreakoutTextInput
          id="password"
          kind="secondary"
          label="Password"
          name="password"
          type="password"
        />
        <Button
          data-testid="login-button"
          type="submit"
          className="mt-3 w-full bg-core-primary text-core-on-primary"
        >
          Login
        </Button>
      </form>
    </div>
  )
}

export function LoginWithPassword() {
  return (
    <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
      <PasswordLogin />
    </div>
  )
}
