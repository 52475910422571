import { useSettings } from 'hooks/settings'

function PillDot({
  color = 'bg-dark-grey',
  pulsing = false,
}: {
  color?: string
  pulsing?: boolean
}) {
  const { animationsEnabled } = useSettings()
  const shouldPulse = animationsEnabled && pulsing
  const animation = shouldPulse ? 'pulse-animation' : ''
  return (
    <span
      className={`mr-1 inline-block h-[8px] w-[8px] rounded-full ${color} ${animation}`}
    ></span>
  )
}

export function Pill({
  dot,
  icon,
  dotColor,
  dotPulsing,
  label,
  font,
  className,
}: {
  dot?: boolean
  icon?: React.ReactNode
  label: string
  dotColor?: string
  dotPulsing?: boolean
  font?: string
  className?: string
}) {
  const fontDef = font ? font : 'text-label-medium'
  const baseClassName = `inline-block rounded-full bg-core-tertiary px-4 py-1 ${fontDef}`
  const finalClassName = className
    ? `${baseClassName} ${className}`
    : baseClassName
  return (
    <strong className={finalClassName}>
      {dot ? <PillDot color={dotColor} pulsing={dotPulsing} /> : null}
      {icon ? <span className="mr-1 inline">{icon}</span> : null}
      {label}
    </strong>
  )
}
