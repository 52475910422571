export const MoreHorizontalFill = ({
  color = 'currentColor',
  size = 24,
  strokeWidth = 2,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    strokeWidth={strokeWidth}
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
    />
  </svg>
)
