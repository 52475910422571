import { EyeClosed } from 'components/icons/EyeClosed'
import { EyeOpen } from 'components/icons/EyeOpen'
import { useTranslation } from 'react-i18next'
import { BreakoutButton } from './design-system/BreakoutButton'

export function ShowCompletedButton({
  onClick,
  showCompleted,
}: {
  onClick: () => void
  showCompleted: boolean
}) {
  const { t } = useTranslation()
  return (
    <BreakoutButton
      data-testid="show-completed-button"
      size="large"
      kind="secondary"
      onClick={onClick}
      icon={
        showCompleted ? (
          <EyeClosed className="inline" size={14} />
        ) : (
          <EyeOpen className="inline" size={14} />
        )
      }
    >
      {showCompleted
        ? t('buttons.hide_completed')
        : t('buttons.show_completed')}
    </BreakoutButton>
  )
}
