const language = {
  translation: {
    breakout_learning: 'Breakout Learning',
    breakout_learning_logo: "Breakout Learning's Logo",
    internal: {
      design_system: 'Design System',
    },
    components: {
      link_copied_to_clipboard: 'Link Copied to Clipboard',
      something_went_wrong: 'Something went wrong',
      default_select_placeholder: 'Select Option',
      dropdown_cta: 'Dropdown, click to open',
      listbox_cta: 'List of option, press up or down to navigate',
    },
    banners: {
      new_app_version_popup:
        'New app version is available, refresh your browser to update',
      new_app_version_popup_refresh: 'Refresh',
      do_later: "I'll do this later",
    },
    demo: {
      waiting_for_host:
        'Waiting for our team representative to start this meeting...',
      demo_complete: 'Demo Complete',
      explore: 'Explore',
      see_more: 'Want to see more?',
      thank_you: 'Thank You for Joining the Product Demo',
      thank_you_long:
        "We appreciate your time and interest in Breakout Learning. If you have any questions or need further assistance, please feel free to reach out to our team. We're here to help you get started and make the most of our platform.",
      join_meeting: 'Join Meeting',
      welcome_text: 'Welcome to Breakout Learning Demo',
      welcome_description:
        "Your demo session will begin shortly. Please enter your name and ensure your audio and video settings are ready. Please note that this meeting is being recorded to demonstrate the capabilities of our AI-powered software. Don't worry, we won't keep any of the data after we're done!",
      first_name: 'First Name',
      first_required: 'Please enter your first name',
      last_name: 'Last Name',
      last_required: 'Please enter your last name',
      start_time: 'Start Time:',
      meeting_with: 'Meeting With:',
      demo_canceled_notification:
        'Demo Canceled: contact your sales rep to reschedule',
      become_a_customer: 'Join Breakout Learning',
    },
    pills: {
      live: 'Live',
      invited: 'Invited',
      not_started: 'Not Started',
      in_progress: 'In Progress',
      completed: 'Completed',
      expired: 'Expired',
      enrolled: 'Enrolled',
      abandoned: 'Abandoned',
      draft: 'Draft',
      archived: 'Archived',
      published: 'Published',
      must_schedule: 'Schedule Now',
      scheduled: 'Scheduled',
      ready_to_start: 'Ready to Start',
      pending: 'Pending',
      suspended: 'Incomplete',
    },
    inputs: {
      click_icon_to_upload: 'Click the icon to upload',
    },
    dialogs: {
      close_dialog: 'Close Dialog',
    },
    design_system: {
      '12h': '12h',
      '24h': '24h',
      am: 'am',
      pm: 'pm',
      now: 'Now',
      ok: 'Ok',
    },
    zoom_controls: {
      enter_zoom: 'Enter Zoom',
      exit_zoom: 'Exit Zoom',
      zoom_out: 'Zoom Out',
      zoom_in: 'Zoom In',
    },
    login: {
      title: 'A Reinvented\nLearning Experience',
      title_sub:
        'Breakout revolutionizes education by offering a multimedia experience crafted to enhance student learning.',
      google_login: 'Continue with Google',
      microsoft_login: 'Continue with Microsoft',
      login_terms: '* By creating an account you accept our',
      terms_and_conditions: 'Terms and Conditions',
      auth_link: {
        existing_provider_dialog_title: 'Existing Account Found',
        existing_provider_dialog_sub:
          "We've detected an existing account linked with the same email but different provider.<br/><br/>Would you like to sign in with <bold>{{provider}}</bold> to link your accounts?",
        link_provider_dialog_title: 'Successfully Signed In',
        link_provider_dialog_sub:
          'Would you like to link your current account with your <bold>{{provider}}</bold> account now?',
      },
    },
    walkthrough: {
      title: 'The Breakout Learning Experience',

      description:
        'The Breakout Learning experience is divided into one or more segments. Each segment is centered around a discussion and may include videos, polls, exhibits, and more.',
      video_title: 'Segment Videos',
      video_description:
        'Videos provide additional information about the assigned material.',
      poll_title: 'Segment Polls',
      poll_description:
        'Polls will ask you for your thoughts about the assigned material.',
      poll_warning: 'All students are required to participate in the polls',
      discussion_title: 'Segment Discussions',
      discussion_description:
        'Your participation and comprehension will be reported to your professor, so be sure to participate, formulate clear thoughts and opinions, and engage with your group.',
      discussion_warning:
        'Balanced discussion is key, so make sure you engage with your peers',
      exhibits: 'Exhibits',
      exhibits_description:
        'Exhibits provide additional information about the assigned material.',
      exhibits_warning:
        'Make sure to review the exhibits section before every discussion',
      skip_walkthrough: 'Skip Walkthrough',
      next: 'Next',
      back: 'Back',
      join_session: 'Join Session',
    },
    preview: {
      slide_type_discussion: 'Slide - Discussion',
      close_exhibit: 'Close Exhibit',
    },
    navigation: {
      home: 'Home',
      profile: 'Profile',
      logout: 'Log out',
      instructor_classes: 'Instructor View',
      instructor_library: 'Explore Catalog',
      instructor_assistants: 'Assistants',
      admin_dashboard: 'Admin Dashboard',
      admin_library: 'Admin Library',
      admin_catalogs: 'Catalogs',
      admin_users: 'Users',
      admin_demos: 'Demos',
      organizations: 'Organizations',
      admin_institutions: 'Institutions',
      instructor_organizations: 'Your Organizations',
      student_view: 'Student View',

      logout_confirmation: 'Sure you want to log out?',
      logout_confirmation_subtitle:
        'You will be redirected to the login page, where you can log in again.',

      logout_confirmation_button: 'Yes, log me out',
    },
    instructor_organizations: {
      instructor_invite_warning:
        'Inviting an instructor will allow organization admins within the organization to view, create, and modify assignments on their behalf. Are you sure you wish to proceed?',
      admin_invite_warning:
        'Inviting an admin will allow the user to view and modify all assignments within the organization, as well as invite other admins and instructors to the organization. Are you sure you wish to proceed?',
    },
    invitation: {
      accepted: 'Invitation accepted',
      already_accepted: 'Already accepted invitation',
      created_by_you: 'Invitation created by you',
      expired: 'Invitation expired',
      not_found: 'Invitation not found',
      already_accepted_promotion: 'Already accepted promotion',
      admin_denied: 'Invitation can not be consumed by admin',
    },
    onboarding: {
      onboarding: 'Onboarding',
      welcome: 'Welcome to Breakout Learning',
      welcome_sub:
        'We make traditional learning modern with engaging content, and AI-moderated small group discussions.',
      class_assignments: 'Class Assignments',
      class_assignments_sub:
        'Your job is to work with your peers to finish the assignment on time.',
      enrolling: 'Get Enrolled - Breakout Learning Tokens',
      enrolling_sub:
        'Purchase and use Breakout Learning Tokens to unlock the material, and join the experience.',
      groups: 'Get In Groups',
      groups_sub:
        'Breakout Learning Experiences happen in small groups. Join or create a group to complete the experience with your peers.',
      prework: 'Pre-Work & Quiz',
      prework_sub:
        'Get ready for the small group discussion by finishing your pre-work, which may be a podcast, a reading assignment or more!',
      experience: 'Main Experience',
      experience_sub:
        'Meet with your group in the Breakout Learning room at the scheduled time. There, you will discuss the assigned material with your group and may interact with videos, polls, exhibits, and more.',
      podcast: 'Link your podcast App',
      podcast_sub:
        "For experiences that have Pre-Work, you'll be required to listen to a podcast. We can deliver this right to your podcast app.",
      before_you_go: 'Before you go..',
      link_podcast_app: 'Link Breakout Learning to your podcast App',
      continue: 'Continue',
    },
    buttons: {
      hide_completed: 'Hide Completed',
      show_completed: 'Show Completed',

      admin_actions: 'Admin Actions',
    },
    library: {
      headline: 'Assignments',
      page_title: 'Student Library Page',
      join_session: 'Join Session',
      filter_prefix: 'Filter: {{text}}',
      all_assignments: 'All Assignments',
      future_assignments: 'Future Assignments',
      open_assignments: 'Open Assignments',
      past_assignments: 'Past Assignments',
      next_action: 'Your Next Action',
      available_on: 'Available on',
      course: 'Course',
      experience: 'Experience',
      due_date: 'Due Date',
      session_date: 'Session Date',
      action: 'Action',
      search_experiences: 'Search Experiences',
      filter_assignments: 'Filter Assignments',
      actions: {
        view_results: 'View Results',
        join_session: 'Join Session',
        start_session: 'Start Session',
        continue_session: 'Continue Session',
        pending: 'Waiting to Schedule',
        join_group: 'Join Group',
        join_live_session: 'Join Live Session',
        enroll: 'Enroll (${{dollars}})',
        complete_quiz: 'Complete Quiz',
        schedule_session: 'Schedule Session',
        session_scheduled: 'Session Scheduled',
        experience_expired: 'Experience Expired',
        header: {
          enroll: 'Enroll in Experience',
          joinGroup: 'Join Group',
          scheduleSession: 'Schedule Session',
          completeQuiz: 'Complete Pre-Work Quiz',
          joinSession: 'Join Live Session',
          startSession: 'Start Session',
        },
      },
    },
    summary: {
      ai_summary: 'AI Summary',

      our_servers_are_busy:
        "Our servers are busy processing your session. When the data is ready it will be available on your assignment's page. Thanks again for participating in this Breakout Learning session.",
      summary_hint:
        'Breakout Learning captures lots of amazing insights from your session including this AI Summary. Your instructor will have access to all of it and will share with you most of these insights during your next class.',
      quiz_performance: 'Group Quiz Performance',
      quiz_performance_hint: 'This measures the aggregate results of the quiz',
      poll_agreement: 'Group Agreement Percentage',
      poll_agreement_hint: 'This measures how your poll answers were similar',
    },
    instructor_assignment: {
      close: 'Close',
      reset: 'Reset',
      restart_assignment: 'Restart Assignment',
      reset_one_description:
        'All student data associated with this group <bold>will be deleted</bold>.<br/> Students will stay in this group but <bold>must redo the experience</bold>.',
      reset_one_proceed_text:
        'To proceed, type "<bold>reset group</bold>" below.',
      reset_all_groups: 'Reset All Groups',
      resetting_group: 'Resetting Group',
      resetting_groups: 'Resetting Groups',
      reset_success: 'Reset successful',
      reset_all_description:
        'All student data associated with this assignment <bold>will be deleted</bold>.<br/> Students will stay in groups but <bold>must redo the experience</bold>.',
      reset_all_proceed_text:
        'To proceed, type "<bold>reset all groups</bold>" below.',

      reset_group: 'Reset Group',
      reset_failed: 'Reset failed, contact support if the issue persists',
      reset_failed_timeout:
        'Reset failed due to timeout, contact support if the issue persists',
      actions: 'Actions',
      quality_filter: 'Quality Filter',
      back_to_class_page: 'Click to go back to the class page',
      absent_decorator: '(ABSENT)',
      results: 'results',
      empty: 'Empty',
      save_failed: 'Save failed',
      can_only_be_moved_forward:
        'Deadline can only be moved forward when the assignment has been scheduled',
      add_group: 'Add Group',
      disabled_because_section_completed: 'Section is completed',
      disabled_because_it_has_students_in_section:
        'Can not be changed once students are in the section',
      disabled_because_its_started:
        'Students have already started this assignment',
      disabled_because_it_has_started:
        'Can not be changed once the assignment has started',
      experience_settings: 'Experience Settings',
      schedule_failure: 'Schedule Failure',
      assign_to_class: 'Assign to Class',
      save_settings: 'Save Settings',
      delete_experience: 'Delete Experience',
      delete_experience_confirmation:
        'Are you sure you want to delete "{{slideDeckName}}" from {{sectionClassName}}-{{sectionName}}?',
      delete_experience_subtitle: 'This action cannot be undone.',
      assignment_deleted: 'Assignment deleted',
      start_date: 'Assignment Start Date',
      start_date_required: 'Start date is required',
      start_date_invalid: 'Start date must be at least one day before deadline',
      deadline: 'Assignment Deadline',
      deadline_required: 'Deadline is required',
      deadline_below_min: 'Deadline must be in the future',
      deadline_must_be_future:
        'The deadline can be adjusted, but only to a date after the currently scheduled session.',
      deadline_above_max: 'Deadline must be within the next year',
      deadline_must_be_one_day_after_start_date:
        'Deadline must be at least one day after the start date',
      settings: 'Settings',
      student_view: 'Student View',
      back_to_group_view: 'Back to Group View',
      group_leader_name: 'Group Leader: {{name}}',
      quiz_results: 'Quiz Results',
      view_details: 'View Details',
      completion: 'Completion',
      view_teaching_slides: 'View Teaching Slides',
      scheduled_date_invalid: 'Scheduled time must be later than current time',
      choose_deadline: 'Choose Deadline',
      assignment_deadline: 'Assignment Deadline',
      view_session: 'View Session',
      send_test_email: 'Send Test Email',
      send_test_email_confirmation:
        'Are you sure you want to send a test of this professor email to yourself?',
      yes: 'Yes',
      no: 'No',
      questions: 'Questions',
      polls: 'Polls',
      quiz: 'Quiz',
      rubric: 'Rubric',
      questions_missing:
        'Questions may be available after at least 1 group completes the assignment.',
      full_name: 'Full Name',
      group: 'Group',
      filter_name_question: 'Filter by Name/Question',
      filter_names: 'Filter Names',

      show_summary: 'View Summary',
      monitor_session: 'Monitor Session',

      add_member: 'Add Member',
      remove_member: 'Remove Member',

      room_state_status_live: 'Live',
      room_state_status_abandoned: 'Abandoned',
      room_state_status_must_schedule: 'Must Schedule',
      room_state_status_completed: 'Completed',
      room_state_status_scheduled: 'Scheduled',
      room_state_status_expired: 'Expired',
      room_state_status_suspended: 'Incomplete',

      not_assigned_to_group: 'Not Assigned to Group',
      student_count_zero: 'No students',
      student_count_one: '1 Student',
      student_count_other: '{{count}} Students',
      blooms_taxonomy: "Bloom's Taxonomy",
      blooms_score: "Bloom's Score",
      blooms_empty_justification: 'Participant did not discuss',

      bloom_0: 'Did Not Discuss',
      bloom_0_long_description: 'The topic was not covered or mentioned',

      bloom_1: 'Remembering',
      bloom_1_description:
        'You demonstrated your ability to recall specific facts and general concepts related to the discussion topic.',
      bloom_1_long_description:
        'This is the act of retrieving relevant knowledge from long-term memory. Examples include listing key terms or recalling specific facts and events. If you achieved the Remembering level, your responses demonstrated your ability to effectively recall specific facts and general concepts related to the discussion topic. This forms the foundation for more complex thinking.',

      bloom_2: 'Understanding',
      bloom_2_description:
        'You were able to explain the main concepts related to the case and discussion in detail.',
      bloom_2_long_description:
        'In this phase, learners construct their own meaning from what they have learned so far. It involves interpreting, exemplifying, classifying, summarizing, inferring, comparing, and explaining. The Understanding level means you were not only able to recall concepts related to the discussion topic and grasp the material, but also explain the concepts in detail and articulate their meaning.',

      bloom_3: 'Applying',
      bloom_3_description:
        'You were able to transfer knowledge to a different scenario and demonstrated your ability to use learned concepts in real-world situations.',
      bloom_3_long_description:
        'This level involves carrying out or using a procedure in a given context. It includes implementing techniques, methods, or theories in practical situations. When your responses reflect the Applying level, you’ve shown you can apply what you already know to a new situation or context. Being able to transfer knowledge to a different scenario is important because it demonstrates the ability to use learned concepts in real-world situations.',

      bloom_4: 'Analyzing',
      bloom_4_description:
        'You understood the structure of information and could explain the relationships between concepts, allowing you to problem-solve',
      bloom_4_long_description:
        'Analyzing encompasses breaking material into parts and understanding its structure. Skills involved include differentiating, organizing, and attributing, which help in comprehending how parts relate to one another and to an overall structure. Reaching the Analyzing level means you were able to break down the information presented to you into smaller parts and examine or explain how they are connected. This matters because it helps you understand the structure of information and the relationships between concepts, which is necessary for problem-solving.',

      bloom_5: 'Evaluating',
      bloom_5_description:
        'Your responses critiqued ideas based on specific criteria and resulted in well-supported judgments and decisions.',
      bloom_5_long_description:
        'At this stage, learners make judgments based on criteria and standards. It involves checking and critiquing processes or products, such as evaluating the credibility of sources or the effectiveness of different interventions. Achieving the Evaluating level involves justifying a decision or course of action. Your responses assessed or critiqued ideas based on specific criteria and resulted in well-supported judgments. This helps provide reasoned arguments which is key to decision-making.',

      bloom_6: 'Creating',
      bloom_6_description:
        'Your responses generated new ideas or perspectives by integrating different pieces of knowledge in a new way, allowing you to propose solutions.',
      bloom_6_long_description:
        'The highest level in the revised taxonomy involves putting elements together to form a coherent or functional whole. This includes generating, planning, and producing new ideas, projects, or theories. Creating requires producing new or original work. Your responses generated new ideas or perspectives by integrating different pieces of knowledge in a new way. Being able to do so is necessary to innovate beyond the provided material and find solutions.',

      bloom_low: 'Low Understanding',
      bloom_medium: 'Medium Understanding',
      bloom_high: 'High Understanding',
      pass_fail: 'Pass/Fail',
      pass_fail_0: 'Did Not Discuss',
      pass_fail_0_description:
        'The student did not cover the topic of the rubric in their discussion',
      pass_fail_1: 'Discussed',
      pass_fail_1_description:
        'The student covered the topic of the rubric in their discussion',
      groups: 'Groups',
      rubric_details: 'Rubric Details',
      switch_to_student_view: 'Switch to Student View',
      switch_to_group_view: 'Switch to Group View',
      justification: 'Justification',
      highlighted_justification: 'Highlighted Justification',
      no_results: 'We did not find any results with these filters.',
      search_students: 'Search Students',
      all_results: 'All Results',
      assignment_summary: 'Assignment Summary',
      quiz_assessment: 'Quiz Assessment',
      n_answers_captured: '{{count}} Answers Captured',
      number_of_students: 'Number of Students',
      n_questions_captured: '{{count}} Questions Captured',
      overall_rubric_assessment: 'Overall Rubric Assessment',
      rubric_assessment: 'Rubric Assessment',
      n_captured_responses: '{{count}} Captured Responses',
      polls_answered: 'Polls Answered',
      highlighted_question: 'Highlighted Question',
      most_common_wrong_answer: 'Most Common Wrong Answer',
      number_of_groups: 'Number of Groups',
      group_rubric_assessment: 'Group Rubric Assessment',
      group_scoring_formula: 'Group Scoring Formula',
      methodology: 'Methodology',
      view_ai_summary: 'View AI Summary',
      ai_summary: 'AI Summary',
      our_servers_are_busy:
        "Our servers are busy processing your session. When the data is ready it will be available on your assignment's page. Thanks again for participating in this Breakout Learning session.",
      summary_hint:
        'Breakout Learning captures lots of amazing insights from your session including this AI Summary. Your instructor will have access to all of it and will share with you most of these insights during your next class.',
      students_signed_up: 'Students Signed Up',
      groups_created: 'Groups Created',
      polls_missing:
        'Polls results will be available after at least 1 group completes the assignment.',
      quizzes_missing:
        'Quiz results will be available after the students complete the assignment.',
      sessions_summary_missing:
        'Sessions summary will be available after at least 1 group completes the assignment.',
      students_missing:
        'Students details will be available after they join the class.',
      results_missing:
        'Results will be available after at least 1 group completes the assignment.',
      score: 'Score',
      average_score: 'Average Score',
      individual_average_score: 'Individual Average Score',
      average_individual_score: 'Average Individual Score',
      group_score_vs_best_individual_score: 'Group Better Than Best Individual',
      group_score_vs_average_individual_score:
        'Group Better Than Average Individual',
      best_individual_score: 'Best Individual Score',
      group_average_score: 'Group Average Score',
      group_score: 'Group Score',
      n_students: '{{count}} Students',
      n_responses: '{{count}} Responses',
      slide_question: 'Question',
      slide_question_type_sorting: 'Sorting Exercise',
      students: 'Students',
      version_label: 'Version {{version}}',
      description: 'Description',
      poll_n: 'Poll {{count}}',
      download_csv: 'Download CSV',

      close_summary: 'Close Summary',
      expand_summary: 'Expand Summary',

      toggle_justification: 'Toggle Justification',
    },
    instructor: {
      show_users_not_in_group: 'Only Show Users Not in a Group',
      arguments: 'Arguments',
      rubric: 'Rubric',
      score: 'Score',
      highlighted_justification: 'Highlighted Justification',
      justification: 'Justification',
      rubric_assessment: 'Rubric Assessment',
      show_arguments: 'Show Arguments',
      hide_arguments: 'Hide Arguments',
      rubric_result_0: 'Did Not Discuss',
      rubric_result_1: 'Discussed',
      rubric_result_2: 'Understanding',
      rubric_result_3: 'Applying',
      rubric_result_4: 'Analyzing',
      rubric_result_5: 'Evaluating',
      rubric_result_6: 'Creating',

      add_student_to_the_group:
        'Are you sure you want to add this student to the group?',
      add_student_to_the_group_description:
        'This student will be part of multiple groups and will be required to join all sessions.',
      student_in_multiple_groups: 'Student in Multiple Groups',
      error_redeeming_promotions: 'Error redeeming promotions',
      group_user_remove_confirmation:
        'Are you sure you want to remove this student from the group?',
      group_user_student_name: 'Student Name',
      group_user_group_name: 'Group Name',
      group_user_remove_from_group: 'Remove from Group',
      current_groups: 'Current Group(s)',

      group_user_add_student_to_group: 'Add Student to Group',
      group_user_add_search_by_name: 'Search by Name',
      group_user_not_in_group: 'Not in a group',
      group_user_add_to_group:
        'Are you sure you want to Add this student to group?',
      group_leader: 'Group Leader',

      confirm: 'Confirm',
    },
    instructor_library: {
      back_to_library_page: 'Click to go back to the library page',
      preview_experience: 'Preview Experience',
      edit_class: 'Edit Class',
      error_adding_class: 'Error adding class',
      class_name_required: 'Please enter a class name',
      section_name_required: 'Please enter a section name',
      organization_disabled_tooltip:
        "Can't change organization since there is an approved invoice request",

      collapse: 'Collapse',
      expand: 'Expand',
      collapsed: 'Collapsed',
      expanded: 'Expanded',
      page_title: 'Instructor Library Page',

      delete_promotion_tooltip:
        'Deleting this promotion removes it from the class and returns the unit to your promotion balance.',
      instructor_view: 'Instructor View',
      admin_view: 'Admin View',
      headline: 'Explore Catalog',
      students: 'Students',
      authors: 'Authors',
      experiences: 'Experiences',
      learning_objectives: 'Learning Objectives',
      rubrics: 'Rubrics',
      instructor_materials: 'Instructor Materials',
      student_experience: 'Student Experience',
      materials: 'Materials',
      quiz: 'Quiz',
      pre_work_materials: 'Pre-Work Materials',
      main_experience: 'Main Experience',
      main_experience_subtitle:
        'Explore the course content and activities just as your students will.',
      view_student_experience: 'View Student Experience',
      other_materials: 'Other Materials',
      based_on: 'Based on',

      price_student_free: 'Free',
      price_student_zero: '<bold>0</bold> Tokens/Student',
      price_student_one: '<bold>{{ count }}</bold> Token/Student',
      price_student_other: '<bold>{{ count }}</bold> Tokens/Student',
      price_tokens_zero: '0 Tokens',
      price_tokens_one: '1 Token',
      price_tokens_other: '{{ count }} Tokens',
      price_tokens_per_student: 'per Student',
      experience_settings: 'Experience Settings',
      assignment_type_student_led: 'Student Led',
      assignment_type_instructor_led: 'Instructor Led',
      assignment_type: 'Assignment Type',
      select_class: 'Select Class',
      students_self_grouping: 'Student Self Grouping',
      automatic_randomized_grouping: 'Automatic Randomized Grouping',
      student_grouping: 'Student Grouping',
      automatic_grouping: 'Automatic Grouping',
      alternative_to: 'Alternative to',
      confirm_auto_grouping1:
        'The software will assign students to groups based on your selected group size. Any student that is left without a group will be placed into a group on:',
      confirm_auto_grouping2:
        'You can also manually assign students to groups from the Students tab in your professor view.',
      confirm_self_grouping1:
        'Students will be responsible for creating and joining groups. The software allows a maximum of eight students per group.',
      confirm_self_grouping2:
        'You can also manually assign students to groups using the Students tab in your professor view.',
      assign_to_class: 'Assign to Class',
      add_to_class: 'Add to Class',
      add_to_canvas: 'Add to Canvas',
      student: 'Student',
      deadline: 'Deadline',
      section_missing: 'Please select a class',
      deadline_invalid: 'Deadline is invalid',
      deadline_must_be_future: 'Deadline can only be moved forward',
      deadline_too_far_out: 'Deadline must be less than a year from now',
      deadline_required: 'Please select a deadline',
      deadline_missing: 'Please set deadline',
      desired_group_size: 'Desired Group Size',
      grouping_size_invalid: 'group size must be between 2 and 8',
      preview: 'Preview',
      add_class: 'Add Class',
      organization: 'Organization',
      retire_as_ta: 'Retire as TA',
      retire_as_ta_confirmation:
        "Are you sure you want to cease being this instructor's TA?",
      yes: 'Yes',
      no: 'No',
      no_classes_found: 'No classes found.',
      pending: 'Pending',
      completed: 'Completed',
      class_name: 'Class Name',
      section_name: 'Section Name',
      apply: 'Apply',
      apply_promotions: 'Apply Promotions',
      select_promotion: 'Select Promotion',

      promotion_not_consumable:
        'You have already consumed this entire promotion',
      promotion_not_active: 'This promotion is not active anymore',

      promotions_applied_one: '1 Promotion Applied',
      promotions_applied_other: '{{count}} Promotions Applied',

      teaching_plans: 'Teaching Plans',
      teaching_plans_description:
        'Our teaching plans, designed by top educators, provide a clear roadmap for courses, outlining objectives, content, and assessments. They ensure efficient and goal-oriented learning for both professors and students.',

      modules_number: '{{count}} Modules',
      modules_number_one: '1 Module',
      modules_number_zero: 'No Modules',

      experiences_number: '{{count}} Experiences',
      experiences_number_one: '1 Experience',
      experiences_number_zero: 'No Experiences',

      all_experiences: 'All Experiences',
      all_experiences_description:
        'Our Experiences offer immersive, hands-on learning opportunities designed to engage students and enhance their understanding of course material. Created by top educators, these Experiences combine real-world scenarios with interactive activities to bring academic concepts to life.',

      search_experiences: 'Search Experiences',
      select_catalog: 'Select Catalog',

      no_results_found: 'No results found',
      no_results_found_description_1:
        'We couldn’t find experiences matching your search.',
      no_results_found_description_2:
        'We recommend exploring our teaching plans for more options and inspirations',
      clear_search: 'Clear Search',
      price: '${{price}} / Student', // many
      price_zero: 'Free', // one

      by_author: 'By {{author}}',

      class_details: 'Class Details',
      add_new_class: 'Add New Class',

      play_trailer: 'Play Trailer',
    },
    instructor_class: {
      back_to_class_list: 'Click to go back to the class list',
      invoice_rejected: 'Invoice Rejected',
      change_to_student_pay_question: 'Change to Student Pay?',
      change_to_student_pay_description:
        'This action will make this class’s assignments available for students to pay and complete.',
      view_details: 'View Details',
      status: 'Status',
      estimated_cost: 'Estimated Cost',
      num_students: '# of students',
      num_experiences: '# of experiences',
      request_details: 'Request Details',
      invoice_pending: 'Invoice Pending',
      submitted_at: 'Submitted at',
      invoice_request: 'Invoice Request',
      withdrawn: 'Request Withdrawn',
      change_to_student_pay: 'Change to Student Pay',
      rejected: 'Rejected',
      resubmit_request: 'Resubmit Request',
      pending_description:
        'You requested this class to be invoiced by your organization’s administrators. This request is still pending approval. Please reach out to your administrator to complete this action.',
      rejected_description:
        'Your request for this class to be invoiced is rejected by your organization’s administrators. Please reach out to your organization administrators to resolve this issue.',
      pending: 'Pending',
      invoice_class: 'Invoice Class?',
      student_count_min: "Student count can't be less than 1",
      invoice_request_failed:
        'Invoice request failed. Please try again and contact support if the issue persists.',
      invoice_request_success: 'Invoice request submitted successfully',
      assignment_count_min: "Experience count can't be less than 1",
      submit_invoice_request: 'Submit Request',
      dont_submit_invoice_request: 'Don’t invoice this class',
      invoice_class_estimated_cost:
        'Total Estimated cost = <bold>${{dollars}}</bold>',
      invoice_class_description:
        'You are part of an organization that supports invoicing for classes. If you want to invoice this class, please fill in these details.',
      student_count_required: 'Please enter the number of students',
      assignment_count_required: 'Please enter the number of experiences',
      section_request_assignment_count_label: 'Number of Expected Experiences',
      section_request_student_count_label: 'Number of Expected Students',
      promotions_exist: 'Sections with promotions cannot be invoiced',
      section_updated_invoice_created: 'Class updated and invoice created',
      section_created_invoice_created: 'Class and invoice created',
      class_created: 'Class created',
      section_updated: 'Class updated',
      cases: 'Experiences',
      students: 'Students',
      explore_catalog: 'Explore Catalog',
      invite_students: 'Invite Students',
      invoiced_section: 'Section Invoiced',
      request_to_invoice: 'Request to Invoice',
      invite_students_tooltip:
        'Create an invitation link to share with your students',
      make_invoiced_tooltip: 'Make Section Invoiced',
      invoiced_tooltip: 'Section Is Invoiced',
      full_name: 'Full Name',
      assignment: 'Assignment',
      quiz_results: 'Quiz Results',
      rubric_assessment: 'Rubric Assessment',
      filter_names: 'Filter Names',
      end_class: 'End Class',
      reset_assignment: 'Reset Assignment',
      end_class_confirmation:
        'Ending the class will disallow further experiences to be assigned to this class.',
      invoiced_class: 'Make Section Invoiced',
      invoiced_class_confirmation:
        'Are you sure you want to make this section invoiced?',
      yes: 'Yes',
      no: 'No',
      no_students: 'No students are enrolled in this class',
      no_students_alt: 'Image of three smiling students holding books',
      no_students_description:
        'Copy your invitation link and share it with your class to get started',
      no_cases: 'No experiences added to this class',
      no_cases_description:
        'Explore our collection of interactive experiences and assign them to this class',
      create_invite_link: 'Create Invite Link',
      invite_students_instructions:
        'Students who receive this link and use it within the validity period will be added to this class.\n\nThe link will remain valid for 3 months, and anyone with access to it will be added to this class.\n\nThe link will be copied to your clipboard for easy sharing.',
      linked_copied_clipboard_failure: 'Copy the link below',
      link_warning: 'Make sure you only share this link with your students.',
      one_month_link: 'Create One Month Link',
      three_month_link: 'Create Three Month Link',

      edit_class: 'Edit Class',
      save: 'Save changes',

      download_csv: 'Download CSV',
    },
    instructor_classes: {
      page_title: 'Instructor Classes Page',
      demo: 'Demo',
      headline: 'Classes',
      impersonating: 'Impersonating: {{instructor}}',
      stop_impersonating: 'Stop Impersonating',
    },
    instructor_assistants: {
      page_title: 'Instructor Assistants Page',
      headline: 'Assistants',
      create_assistant_invitation: 'Create Assistant Invitation',
      teachers_assistants_welcome:
        "In Assistants view, instructors can create assistant invitations for TAs. Assistants are authorized to operate the platform on instructors' behalf. They can create classes, assign experiences, invite students and view students' completed session results.",
      remove_ta: 'Remove TA',
      remove_ta_confirmation: 'Are you sure you want to remove this TA?',
      yes: 'Yes',
      no: 'No',
      link_warning_assistants:
        'Make sure you only share this link with your assistants.',
      remove_assistant: 'Remove Assistant',
    },
    admin_dashboard: {
      headline: 'Admin Dashboard',
      running: 'Running Classes',
      scheduled: 'Scheduled Classes',
      demo_class: 'Demo Class',
      filter_names: 'Filter Names',
    },
    video_upload: {
      processing_video_upload: 'Processing Video Upload',
      processing_video_saving: 'Saving Video…',
      processing_video_saved: 'Video saved!',
      show_processing_video: 'processing_video.webm',
    },
    slide_deck_tags: {
      required: 'required',
      placeholder: 'Enter Tag',
      slide_deck_tags: 'Slide Deck Tags',
      save_tags: 'Save Tags',
      add_tag: 'Add',
      modified_warning_title: 'Tags Modified',
      modified_warning_subtitle:
        'The tags which were modified ({{tags}}) will update all experiences to which they are currently assigned. Would you still like to proceed?',
      delete_warning_title: 'Delete Tag',
      delete_warning_subtitle:
        'If you proceed this tag will no longer be present on experiences to which it is currently assigned',
      duplicate: 'Tag already exists',
      edit_slide_deck_tags: 'Edit Slide Deck Tags',
    },
    admin_library: {
      filter: 'Filter Experiences by Title / Subtitle',
      headline: 'Admin Library',
      versions: '{{count}} Versions',
      versions_one: '1 Version',
      versions_zero: '0 Versions',
      show_hidden: 'Show Hidden',
      hide_hidden: 'Hide Hidden',
      add_case_study: 'Add Experiences',
      video: 'Video',
    },
    admin_slide_deck: {
      delete: 'Delete',
      preview_meeting: 'Preview Meeting',
      preview_experience: 'Preview Experience',
      make_a_copy: 'Make a Copy',
      experience_details: 'Experience Details',
      slides: 'Slides',
      interactive_elements: 'Interactive Elements',
      references: 'References',
      rubrics: 'Rubrics',
      exhibits: 'Exhibits',
      course_materials: 'Course Materials',
      authors: 'Authors',
      slide_deck_create_copy_dialog_title_waiting: 'Creating a new copy...',
      slide_deck_create_copy_dialog_subtitle_waiting:
        'Please do not close this page till the process is completed',
      slide_deck_create_copy_dialog_title_resolved: 'New Version Created',
      slide_deck_create_copy_dialog_subtitle_resolved:
        'A new duplicate for this version has been created and added to your library',
      slide_deck_deep_copy_dialog_button_title: 'Go to New Version',
      slide_deck_deep_copy_input_dialog_title: 'Create a copy',
      slide_deck_deep_copy_input_dialog_subtitle:
        'A new duplicate of this experience will be created and added to your library.',
      version_name: 'Version Name',
      version_invalid: 'Version name invalid',
      version_exists: 'Version exists',
      select_catalog: 'Select Catalog',
      slide_deck_delete_dialog_title: 'Delete Experience',
      slide_deck_delete_dialog_subtitle:
        'Are you sure you want to delete this experience?',
      slide_deck_hide_dialog_title:
        'This action will mark this experience as hidden',
      slide_deck_hide_dialog_subtitle:
        "Hidden experiences won't be visible by default. You can reverse this by choosing to re-publish the experience in the future.",
      slide_deck_remove_feature_dialog_title:
        'This action will cause the experience to no longer be featured',
      slide_deck_remove_feature_dialog_subtitle:
        'Once an experience is no longer featured, it will no longer be visible on the login page.',
      hide: 'Hide',
      un_feature_and_hide: 'Un-feature and Hide',
      unhide: 'Unhide',
      fork_experience_success: 'Experience forked successfully',
      fork_experience: 'Fork Experience',
      root_experience_tooltip:
        'This experience is the first version of its type, and cannot be forked.',
      fork_experience_description:
        'Forking this experience will remove the version from the experience group. Are you sure you wish to proceed? ',
      new_experience_type: 'New Experience Type',
      new_experience_type_tooltip:
        'Enabling this option will create a new experience type, omitting this version from the current experience list and making it the first version of the new type.',
    },
    teaching_plan: {
      details: 'Details',
      modules: 'Modules',
      authors: 'Authors',
      add_new_module: 'Add New Module',
      add_module: 'Add Module',
      teaching_plan_details: 'Teaching Plan Details',
      teaching_plan_title: 'Teaching Plan Title',
      teaching_plan_title_required: 'Please enter a teaching plan title',
      teaching_plan_image: 'Teaching Plan Image',
      teaching_plan_image_required: 'Please upload a teaching plan image',
      teaching_plan_image_placeholder: 'Upload Image',
      teaching_plan_video: 'Teaching Plan Video',
      teaching_plan_video_required: 'Please upload a teaching plan video',
      teaching_plan_video_placeholder: 'Upload Video',
      teaching_plan_description: 'Teaching Plan Description',
      teaching_plan_description_required:
        'Please enter a teaching plan description',
      teaching_plan_modules_required: 'Please add at least one module',
      teaching_plan_authors_required: 'Please add at least one author',
      unpublish: 'Unpublish',
      unpublished: 'Unpublished',
      published: 'Published',
      author_details: 'Author Details',
      author_name: 'Author Name',
      author_name_required: 'Please enter an author name',
      author_image: 'Author Image',
      author_image_required: 'Please upload an author image',
      author_title: 'Author Title',
      author_title_required: 'Please enter an author title',
      author_institution: 'Author Institution',
      author_institution_required: 'Please enter an author institution',
      author_bio: 'Author Bio',
      author_bio_required: 'Please enter an author bio',

      save_changes: 'Save Changes',
      publish: 'Publish',
      delete: 'Delete',
      module_title: 'Module Title',
      module_title_required: 'Please enter a module title',
      module_description: 'Module Description',
      module_description_required: 'Please enter a module description',
      module_details: 'Module Details',
      module_experiences_required: 'Please add at least one experience',
      module: 'Module',
      experiences: 'Experiences',
      add_experience: 'Add Experience',
      select_experience: 'Select Experience',
      select_experience_required: 'Please select an experience',
      select_version: 'Select Version',
      select_version_required: 'Please select a version',
      no_versions_available: 'All versions already added',
      save_module: 'Save Module',
      add_new: 'Add New',
      unsaved_changes:
        'You have unsaved changes. Please save them before changing tabs.',
      module_saved: 'Module saved successfully',
      deleted: 'Teaching Plan Deleted',
      unpublish_teaching_plan_title: 'Are you sure you want to unpublish?',
      unpublish_teaching_plan_subtitle:
        'Unpublishing teaching plans will remove it from our website and product catalog pages.',
      publish_teaching_plan_title:
        'Are you sure you want to publish this teaching plan?',
      publish_teaching_plan_subtitle:
        'Published teaching plans will appear on our website and product catalog pages.',
      delete_teaching_plan_title:
        'Are you sure you want to delete this teaching plan?',
      delete_teaching_plan_subtitle:
        'This will remove all modules and experiences associated with this teaching plan.',
      delete_module_confirmation:
        'Are you sure you want to delete this module?',
    },
    instructor_teaching_plan: {
      about: 'About',
    },
    admin_institutions: {
      institution: 'Institution',
      institutions: 'Institutions',
      create_new_institution: 'Create new institution',
      institution_name: 'Institution Name',
      institution_name_required: 'Please enter an institution name',
      institution_name_exists: 'Institution name already exists',
      delete_institution: 'Are you sure you want to delete this institution?',
      delete_institution_orgs_warning:
        'Can not delete institution with organizations',
    },
    organizations: {
      organizations: 'Organizations',
      create_new_organization: 'Create new organization',
      catalog_required: 'Please select at least one catalog',
      invalid_default_catalog_ids:
        'default catalog ids must be subset of organization catalog ids',
      organization_name: 'Organization Title',
      organization_name_required: 'Please enter an organization name',
      organization_institution: 'Institution',
      organization_institution_required:
        'Please enter an organization institution',
      organization_invoice_status: 'Organization Invoice Status',
      no_invoices: 'No Invoices',
      admin_approved: 'Admin Approved',
      auto_approved: 'Auto Approved',
      show_archived: 'Show Archived',
      hide_archived: 'Hide Archived',
    },
    organization: {
      add: 'Add',
      catalog_not_managed_by_organization:
        "The instructor's access to this catalog is not controlled by the organization.",
      remove_catalog_access_dialog_title:
        'Remove access to catalog for this user?',
      remove_catalog_access_dialog_subtitle:
        'This user will not see this catalog in their library page anymore.',
      remove_catalog: 'Remove Catalog',
      select_catalog: 'Select Catalog',
      yes: 'Yes',
      no: 'No',
      organization_updated: 'Organization Updated',
      save_edits: 'Save Edits',
      details: 'Details',
      save: 'Save',
      instructor_updated: 'Professor Updated',
      failed_to_save:
        'Failed to save professor details. Please try again and if the problem persists please contact support.',
      auto_approve_label:
        'Automatically approve invoice requests created by this professor',
      professor_full_name: 'Instructor Full Name',
      invoice_class_settings: 'Invoice Class Settings',
      catalogs: 'Catalogs',
      professor_account_settings: 'Instructor Account Settings',
      add_myself_as_instructor: 'Add Myself as Instructor',
      added_as_instructor: 'Added as Instructor',
      invite_instructor_warning:
        'Make sure you ONLY share this link with the intended users.',
      add_user: 'Add User',
      remove_user_from_organization: 'Remove User from Organization',
      reject_request: 'Reject Request',
      reject_request_sub:
        'Rejection reason will be shared with the instructor on their portal.',
      reject_description:
        'Rejection reason will be shared with the instructor on their portal.',
      reject_reason: 'Rejection Reason',
      instructors: 'Instructors',
      request_approved: 'Request Approved',
      date: 'Date',
      organization_admins: 'Organization Admins',
      add_new_administrator: 'Add A New Administrator',
      administrators: 'Administrators',
      invite_new_professor: 'Invite New Professor',
      request_rejected: 'Request Rejected',
      invite_new_instructor: 'Invite New Instructor',
      name: 'Name',
      pending: 'Pending',
      accepted: 'Accepted',
      approve: 'Approve',
      reject: 'Reject',
      confirm: 'Confirm',
      not_completed: 'Completed',
      edit: 'Edit',
      by: 'By',
      approved: 'Approved',
      auto_approved: 'Auto Approved',
      rejected: 'Rejected',
      invoice_request: 'Invoice Request',
      instructor_name: 'Instructor Name',
      section_title: 'Section Title',
      view_request: 'View Request',
      class: 'Class',
      instructor: 'Instructor',
      est_students: 'Est. Students',
      est_experiences: 'Est. Experiences',
      est_cost: 'Est. Cost',
      status: 'Status',
      impersonate: 'Impersonate',
      auto_approve_invoices: 'Auto-Approve Invoices',
      delete_organization: 'Delete Organization',
      delete_dialog_title: 'Are you sure you want to delete this organization?',
      remove_user_dialog_button: 'Remove User',
      remove_user_dialog_title:
        'Are you sure you want to remove access for this user?',
      remove_user_dialog_subtitle:
        'Users who are removed will not be able to see and manage the organization from their account.',
      no_users_to_add: 'No users to add',
      add_instructor: 'Add Instructor',
      add_organization_admin: 'Add Organization Admin',
      organization_details: 'Organization Details',
      archive_organization: 'Archive Organization',
      unarchive_organization: 'Unarchive Organization',
      institution: 'Institution',
      organization_title: 'Organization Title',
      filter_by: 'Filter by Institution/Org',
      filter_by_class_professor: 'Filter by Class / Professor',
      filter_by_instructor_name: 'Filter by Instructor Name',
      filter_by_class_instructor: 'Filter by Class/Instructor',
      view_details: 'View Details',
      no_organizations_found: 'No organizations found',
      no_instructors_found: 'No instructors found',
      class_title: 'Class Title',
      students: 'Students',
      units: 'Units',
      view_class: 'View Class',
      invoiced: 'Invoiced',
      experiences: 'Experiences',
      no_classes_found: 'No classes found',
      no_invoice_requests_found: 'No invoice requests found',
      settings: 'Settings',
      professor: 'Professor',
      classes: 'Classes',
      invoice_requests: 'Invoice Requests',
      catalogs_selected: {
        zero: 'No Catalogs Selected',
        one: '1 Catalog Selected',
        many: '{{count}} Catalogs Selected',
      },
      catalog_access: 'Catalog Access - Multiple Select',
      default_catalogs: 'Default Catalogs - Multiple Select',
      request_rejected_sub:
        'The instructor will be notified with the rejection of their request.',
      request_approved_sub:
        'Students will be able to access experiences added to this class Free of Charge.',
      integrations: 'integrations',
      integration: {
        copy: 'Copy',
        enable: 'Enable',
        view: 'View',
        canvas_integration: 'Canvas Integration',
        integration_active: 'Integration Active',
        setup_lti:
          'Use the links and instructions provided here to setup your LTI 1.3 integrations',
        setup_step_1:
          'Step 1 : Create a new LTI developer key in Canvas. Go to the Admin view, then click on ‘Developer keys’ to get started.',
        setup_step_2:
          'Step 2 : Copy the integration values from your Canvas screen and paste them here',
        breakout_integration_url: 'Breakout Integration URL',
        integration_name: 'Integration Name',
        organization_canvas_url: 'Organization Canvas URL',
        integration_unique_id: 'Integration Unique ID',
        save_integration: 'Save Integration',
      },
    },
    admin_catalogs: {
      users: 'Users',
      organizations: 'Organizations',
      modules: 'Modules',
      module: 'Module',
      teaching_plans_tab: 'Teaching Plans',
      teaching_plan: 'Teaching Plan',
      headline: 'Catalogs',
      new_catalog: 'New Catalog',

      create_new_catalog: 'Create a new catalog',

      experiences: '{{count}} Experiences',
      experiences_one: '1 Experience',
      experiences_zero: 'No Experiences',

      catalog_name: 'Catalog Name',

      catalog_description: 'Catalog Description',
      confirm: 'Confirm',

      name_required: 'Name is required',
      description_required: 'Description is required',

      invite_users: 'Invite Users',
      add_experiences: 'Add Experiences',
      add_teaching_plan: 'Add Teaching Plan',
      create_teaching_plan: 'Save',
      edit_sections: 'Edit Sections',
      teaching_plan_name: 'Teaching Plan Title',
      teaching_plan_name_required: 'Please enter a teaching plan title',
      professor_count: {
        one: '1 Professor',
        other: '{{count}} Professors',
      },
      remove_organization_dialog_title:
        'Remove access to this catalog for this Organization?',
      remove_organization_dialog_subtitle:
        'All organization members will not see this catalog in their library page.',

      version: 'Version',

      experiences_tab: 'Experiences',
      details_tab: 'Details',

      delete_catalog: 'Delete Catalog',
      delete_catalog_protected: 'Catalog is protected and can not be deleted',

      catalog_details: 'Catalog Details',
      catalog_delete_dialog_title:
        'Are you sure you want to delete this catalog?',
      catalog_delete_dialog_subtitle:
        "Once a catalog is deleted, all users with access to this catalog won't be able to see it in their library.",

      catalog_remove_user_dialog_title:
        'Are you sure you want to remove access from this user?',
      catalog_remove_user_dialog_subtitle:
        'Users who are removed will not see this catalog in their library page.',

      authorized_users: 'Authorized Users',
      add_new_user: 'Add A New User',
      add_new_organization: 'Add A New Organization',

      select_user: 'Select User',
      select_organization: 'Select Organization',

      remove: 'Remove',

      no_instructors: 'No instructors found',
      no_organizations: 'No organizations found',

      select_section: 'Select Section',

      select_experience: 'Select Experience',
      select_version: 'Select Version',

      create_invite_link: 'Create Invite Link',
      catalog_invite_link_description:
        'Users who receive this link and use it within the validity period will get access to this catalog.\n\nLink created will be copied to your clipboard for sharing.',

      catalog_invite_link_warning:
        'Make sure you ONLY share this link with the intended users.',

      one_time_link: 'Create One Time Link',
      one_month_link: 'Create One Month Link',
    },
    admin_users: {
      filter_name_emails: 'Filter by Name/Email',
      headline: 'Users',
      enter_student_email_to_search: 'Please enter an email to search students',
      tokens_granted: 'Tokens Granted',
      grant_tokens: 'Grant Tokens',
      grant_tokens_dialog_sub:
        'Choose a token amount to grant to the selected user',
      grant_amount_tokens: 'Grant {{count}} Tokens',
      impersonate: 'Impersonate',
      filter_by_email: 'Filter by Email',
      create_instructor_invitation: 'Create Instructor Invitation',
      admin_users_instructor_invite_dialog_title: 'Invite Instructor',
      admin_users_instructor_invite_dialog_subtitle:
        'The catalog dictates which experiences the instructor will have access to',

      select_catalog: 'Select Catalog',

      promotions: 'Promotions',
      promotions_description:
        'Enter promotion parameters to add a new promotion to this user',

      promotion_details: 'Promotion Details',
      promotion_details_description:
        'Enter promotion parameters to add a new promotion to this user',

      promotion_type: 'Promotion Type',
      promotion_name: 'Promotion Name',
      expiration_date: 'Expiration Date',

      consumable_count: 'Consumable Count',
      collision_code: 'Collision Code',

      delete: 'Delete',
      save: 'Save',

      add_promotion: 'Add Promotion',

      promotion_delete_confirm: 'Are you sure you want to delete promotion?',

      delete_disabled:
        'Delete is disabled when promotion was used at least once',
      save_disabled:
        'Editing is disabled when promotion was used at least once',

      promotion_type_missing: 'Please select a promotion type',

      collision_code_unique: 'Collision code must be unique',

      consumable_count_required: 'Consumable count is required',

      copied_to_clipboard: 'Copied to clipboard',
    },
    promotion: {
      promotion: 'Promotion',
      promotion_applied: 'Promotion Applied!',
      valid_till: 'Valid till {{date}}',

      consumable_count_zero: 'No Free Classes',
      consumable_count_one: '1 Free Class',
      consumable_count_other: '{{count}} Free Classes',
    },
    profile: {
      institution: 'Institution',
      email_update_success: 'Email updated successfully',
      email_update_failed: 'Failed to update email',
      invalid_verification_link: 'Invalid verification link.',
      verifying_email: 'Verifying Email',
      email_verification_sent:
        'Please check your email inbox for a verification link to complete email update. If you do not receive it, make sure you used the right email address, and that you are not already using it on a different account.',
      user_not_logged_in: 'User not logged in',
      email_not_changed: 'Email not changed',
      failed_to_reauthenticate: 'Failed to reauthenticate',
      failed_to_verify_email: 'Failed to verify email',
      settings: 'Settings',
      animations: 'Application Animations',
      settings_description: 'Customize your experience on Breakout Learning',
      dark_mode: 'Dark Mode',
      promotions: 'Promotions',
      promotions_description:
        'View your active promotions and consumption history.',
      role_instructor: 'Instructor Account',
      role_admin: 'Admin Account',
      role_assistant: 'Assistant Account',
      role_student: 'Student Account',
      headline: 'Profile',
      version: 'Version',
      personal_details: 'Personal Details',
      first_name_required: 'Please enter your first name',
      email: 'Email',
      podcasts: 'Podcasts',
      last_name_required: 'Please enter your last name',
      email_required: 'Please enter your email',
      transactions: 'Transactions',
      av_test: 'Audio/Video Settings',
      tokens: 'Tokens',
      your_podcasts: 'Your Podcasts',
      tokens_available: 'Tokens Available',
      tokens_consumed: 'Tokens Consumed',
      transactions_description:
        'A list of all the transactions you made on your breakout account',
      privacy_policy: 'Privacy Policy',
      profile_photo_aria_label: 'User Profile Photo',
      tokens_headline: 'Breakout Learning Tokens',
      tokens_explanation:
        'Breakout Learning uses a token system for experiences. You have <bold>{{tokensAvailable }}</bold> tokens. To join the experiences your professors assign to your class, you need to purchase tokens.',

      details_headline: 'Personal Details',

      first_name: 'First Name',
      last_name: 'Last Name',
      email_address: 'Email Address',

      select_language: 'Select Language',

      save: 'Save',

      other_resources: 'Other Resources',
      test_av: 'Test Audio/Video',
      disable_animations: 'Disable Animations',
      enable_animations: 'Enable Animations',

      profile_updated: 'Profile updated successfully',

      active: 'Active',
      inactive: 'Inactive',

      consumed: 'Consumed',
      consumed_x_of_y: '{{consumed}} out of {{total}} consumed',

      expires_at: 'Expires {{expirationDate}}',

      no_promotions: 'You don’t have any promotions added to your account',
      no_promotions_found: 'No Promotions Found',
      denied_permissions: 'Allow App to use your camera and microphone',
      denied_permissions_description:
        'App needs access to your camera and microphone so that other participants can see and hear you.',
      av: 'Audio/Video Settings',
      av_description:
        'Breakout Learning is a zoom like experience that requires joining video call sessions where your camera and microphone should be on.',

      integrations: 'Integrations',
      integrations_description:
        'Connect your Breakout Learning account with other services to enhance your experience.',
      no_integrations_found: 'No integrations found',
      no_integrations:
        'You have not connected any external services to your Breakout Learning account.',
      connect: 'Connect',
      connected: 'Connected',
    },

    student_assignment: {
      back_to_class_list: 'Click to go back to the assignment list',
      library_state_live: 'Live',
      library_state_ready_to_start: 'Start Now',
      library_state_scheduled: 'Scheduled',
      library_state_pending: 'Pending',
      library_state_expired: 'Expired',
      library_state_schedule_now: 'Schedule Now',
      library_state_completed: 'Completed',
      library_state_invited: 'Invited',
      library_state_suspended: 'Incomplete',
      start_date: 'Start Date',
      case_description: 'Case Description',
      deadline: 'Deadline',
      future_date_assignment: 'Future-Dated Assignment',
      future_date_assignment_description:
        'Thank you for enrolling in this Experience. The assignment will become available on {{date}} as scheduled by your professor.',
      podcasts: 'Pre-Work Podcasts',
      restart_case_study: 'Restart Experience',
      process_ai_again: 'Process AI Again',
      ai_processing_started: 'AI Processing Started',
      ai_processing_running: 'AI Processing Already Running',
      group_name_exists: 'Group name already exists.',
      group_name_required: 'Group name is required.',
      scheduled_date_after_deadline:
        'Scheduled time must be before the class deadline (${deadline})',
      scheduled_date_invalid: 'Invalid date',
      scheduled_date_in_past: 'Scheduled time must be in the future',
      scheduled_date_required: 'Please pick a scheduled time',
      edit_group_details: 'Edit Group Details',
      leave_group: 'Leave Group',
      no_students: 'No students are enrolled in this class',
      assignment_expired: 'Assignment Expired',
      assignment_expired_description:
        'It looks like you missed out on this assignment.The deadline has passed. We look forward to seeing you in the next one!',
      prework_listen_to_podcast: 'Listening to the podcast',
      prework_read_case_summary: 'Reading the pdf',
      time_to_join: 'Time to Join Session',
      continue_suspended_session: 'Continue Incomplete Session',
      session_live: 'Session is Live',
      quiz_required:
        'You must take the pre-session quiz before joining the session.',
      course_materials: 'Course Materials',
      course_materials_info:
        'Course materials help you understand the core concepts of the experience and prepare you for the discussion.',
      start_quiz: 'Start Quiz',
      quiz_duration: 'Time needed to complete (10 mins)',
      start_quiz_warning:
        'You can take this quiz only once. So make sure you have the time to complete it.',
      // complete_pre_work: 'Complete Pre-Work',
      // complete_pre_work_body:
      //   'Please complete the Pre-Work for this assignment and take the quiz.',
      step_two: {
        complete_pre_work: 'Complete pre-work',
        complete_pre_work_sub:
          'Please complete the pre-work for this assignment before completing the experience.',
        take_quiz: 'Take Quiz',
        take_quiz_sub:
          'Please take this quiz before completing the experience.',
        complete_pre_work_take_quiz: 'Complete pre-work and take quiz',
        complete_pre_work_take_quiz_sub:
          'Please complete the pre-work for this assignment then take the quiz. ',
      },
      please_join_now: 'Please join now.',
      join_session: 'Join Session',
      join_disabled_starts_at: 'Meeting can be joined at {{startsAt}}',
      join_disabled_please_schedule: 'Please schedule the meeting',
      pre_work: 'Pre-Work',
      pre_work_complete:
        'Complete the Pre-Work to prepare you to join the discussion.',
      listening_to_the_experience: 'Listening to the experience summary',
      reading_the_experience_summary: 'Reading the experience summary',
      start_session: 'Start Session',
      continue_session: 'Continue Session',
      quiz_results: 'Quiz Results',
      quiz_completed: 'Quiz Completed',
      quiz_completed_description:
        'Thank you for taking the quiz. You will be able to join the session with your peers when it starts.',
      exit_portal: 'Exit to Portal',
      view_details: 'View Details',
      tokens_available_title: 'Tokens Available',
      enroll_in_case: 'Enroll in Experience',
      token_description:
        'Breakout Learning experiences are purchased using tokens. To access the experiences assigned by your instructors, you first need to purchase tokens.',
      purchase_tokens: 'Purchase Tokens',
      token: 'Token',
      tokens_consumed_title: 'Tokens Consumed',
      tokens_available_colon: 'Tokens Available:',
      tokens_required_colon: 'Tokens Required:',
      tokens_to_purchase: 'Tokens To Purchase',
      tokens: 'Tokens',
      total_price_colon: 'Total Price:',
      purchase_tokens_description:
        'Breakout Learning works with a Token based system. You need to purchase tokens to enroll in experiences. Tokens are valid for 1 year and can be used for any classes we host.',
      pay_with_stripe: 'Pay with Stripe',
      pay_with_stripe_failure: 'Unexpected payment failure',
      enroll_failure: 'Unexpected enrollment failure',
      correct_answer: 'Correct Answer',
      view_experience: 'View Experience',
      view_summary: 'View Summary',
      join_group: 'Join Group',
      create_new_group: 'Create a New Group',
      group_name: 'Group Name',
      group_name_placeholder: 'Enter a name for your group',
      scheduled_time_info:
        '*You can change the schedule date and time after creating the group',

      students_in_group: 'Students in Group',
      group_details: 'Group Details',
      scheduled_date: 'Scheduled Date',
      session_date: 'Session Date',
      edit: 'Edit',
      schedule_now: 'Schedule Now',
      action: 'Action',
      join_group_confirm: 'Are you sure you want to join this group?',
      confirm: 'Confirm',
      start_session_confirmation:
        'Are you sure you want to start the session?\n\nYou will be responsible for controlling the slides throughout the session.\n\nYou should only do this when the group is expecting to meet because each meeting can only run once.',
      step_1: 'Step 1',
      step_2: 'Step 2',
      step_3: 'Step 3',
      join_or_create_group_no_results: 'No results Found',
      join_or_create_group_no_results_description:
        'We couldn’t find students matching your search.\nConsider creating a new group and inviting others to join.',
      join_or_create_group_first_one_here: "You're the first one here",
      join_or_create_group_first_one_here_description:
        'There are no groups created by any of your class peers.\nConsider creating a new group and inviting others to join.',
      join_or_create_group_search_placeholder: 'Search group and students',
      join_or_create_group: 'Join or Create a Group',
      join_or_create_group_dialog_sub:
        'Search for your peers and join an existing group, or create a new group for others to join.',
      join_or_create_group_description:
        'Please join a group to complete this step. You can also create a new group and your peers will be able to join it.',
      uses_automatic_randomized_grouping: 'Assignment uses automatic grouping',
      group_tbd: 'Group (TBD)',
      group_tbd_description:
        'Our system will assign you to a group soon. You can still join a group manually if you coordinate with your professor or TA',
      manually_join_group: 'Manually Join a Group',

      not_scheduled: 'Not Scheduled',
      scheduled: 'Scheduled',
      not: 'Not',
      view_prework: 'View Pre-Work',
      prework: 'Pre-Work',
      prework_description: 'Complete the Pre-Work by:',
      processing_payment: 'Processing Payment',

      video_method: 'Video Method',
    },
    student_library: {
      see_how_it_works: 'See How It Works',
      privacy_policy: 'Privacy Policy',
      help_center: 'Help Center',
      message_support: 'Message Support',
      other_resources: 'Other Resources',
      // English is so nice, that's the only setup you need
      token_number: '{{count}} Tokens', // many
      token_number_one: '{{count}} Token', // one
      empty_placeholder:
        'Experiences will appear here when invited by professor',
      empty_placeholder_description:
        'If you followed an invitation link but your library remains empty, \nyour invitation link may be expired. Your instructor or TA can\n supply you with another invitation link. If you are unable to reach\n your instructor/TA, you can send us an email at:',
      empty_placeholder_alt:
        'Image of a pair of smiling students holding books',
      help: 'Help',

      no_results_found: 'No results found',
      no_results_found_description_1:
        'We couldn’t find assignments matching your search.',
    },
    meeting: {
      next_slide_button_disabled_tooltip:
        'You can advance when video is finished',
      view_full_screen: 'View Full Screen',
      rubric: 'Rubric',
      no_rubric_results:
        'Our system did not capture any rubric results for your user',
      thank_you_feedback: 'Thank you for sharing your feedback!',
      submit_feedback: 'Submit Feedback',
      pass_fail_rubric: 'Pass or Fail Rubric',

      feedback_submit_error:
        'Something went wrong saving your feedback. Please try again and if the problem persists, contact support.',
      before_you_share:
        'Before you share your feedback, please make sure you read and understand the evaluation criteria.',
      pass_fail_paragraph_1:
        "Utilizing Bloom's Taxonomy ensures that you engage with the material at progressively deeper levels. By categorizing responses according to this framework, we can more accurately gauge the development of critical thinking skills and the ability to apply knowledge in diverse contexts.",
      pass_fail_paragraph_2:
        "At Breakout Learning, our goal is for you to reach the analyze level or higher by the end of each discussion segment. The prompts are meant to guide you through the thinking process by beginning at lower levels of Bloom’s, and eventually ask you to analyze material, evaluate a decision, or create innovative solutions. Your progression through these levels prepares you for complex problem-solving and decision-making in real-world scenarios. Keep striving to reach higher levels of thinking, and use this understanding of Bloom's Taxonomy to assess and improve your own learning strategies.",

      bloom_desc_paragraph_1:
        'Bloom’s Taxonomy is a framework developed in 1956 that is used to categorize learning goals and objectives. Since its creation, educational and pedagogical communities have used it in teaching, learning, and assessment development to categorize the levels of thinking skills required to complete a task or achieve a learning objective. Over time, Bloom’s Taxonomy has become accepted as a best practice when it comes to learning in any field. It is now widely used by educational and corporate institutions for practical purposes, such as the surgical training curricula at the National Institutes of Health (NIH), the development of curriculum frameworks in top universities like Harvard and Stanford, and corporate training and professional development programs by a number of Fortune 500 companies.',
      bloom_desc_paragraph_2:
        'At Breakout Learning, Bloom’s Taxonomy is used to gauge the depth of understanding and application of knowledge demonstrated in responses, as well as critical thinking skills. Levels range from basic recall of facts, to creating new and original work and ideas. Bloom’s Taxonomy was revised in 2001 to reflect a more dynamic and modern approach to teaching and learning, and is the model used in Breakout’s platform today. The revised framework is as follows:',
      bloom_desc_paragraph_3:
        "Utilizing Bloom's Taxonomy ensures that you engage with the material at progressively deeper levels. By categorizing responses according to this framework, we can more accurately gauge the development of critical thinking skills and the ability to apply knowledge in diverse contexts.",
      bloom_desc_paragraph_4:
        "At Breakout Learning, our goal is for you to reach the analyze level or higher by the end of each discussion segment. The prompts are meant to guide you through the thinking process by beginning at lower levels of Bloom’s, and eventually ask you to analyze material, evaluate a decision, or create innovative solutions. Your progression through these levels prepares you for complex problem-solving and decision-making in real-world scenarios. Keep striving to reach higher levels of thinking, and use this understanding of Bloom's Taxonomy to assess and improve your own learning strategies.",
      bloom_desc_paragraph_5:
        "Utilizing Bloom's Taxonomy ensures that you engage with the material at progressively deeper levels. By categorizing responses according to this framework, we can more accurately gauge the development of critical thinking skills and the ability to apply knowledge in diverse contexts.",
      bloom_desc_paragraph_6:
        "At Breakout Learning, our goal is for you to reach the analyze level or higher by the end of each discussion segment. The prompts are meant to guide you through the thinking process by beginning at lower levels of Bloom’s, and eventually ask you to analyze material, evaluate a decision, or create innovative solutions. Your progression through these levels prepares you for complex problem-solving and decision-making in real-world scenarios. Keep striving to reach higher levels of thinking, and use this understanding of Bloom's Taxonomy to assess and improve your own learning strategies.",

      feedback_prompt:
        'Feedback will be shared and considered by your professor.',
      feedback_required: 'Please enter in your feedback',

      evaluation_method: 'Evaluation Method',
      evaluation_summary: 'Evaluation Summary',
      evaluation_points: 'Evaluation Points',
      blooms_taxonomy: 'Bloom’s Taxonomy',
      bloom_framework: 'Bloom’s Taxonomy Framework',
      evaluation_description:
        'evaluates students by assessing their cognitive skills, from basic recall to complex creation. This method encourages deeper understanding and critical thinking.',

      hide_details: 'Hide Details',
      show_details: 'Show Details',
      more_details: 'More Details',

      no_exhibits: 'No Exhibits Available',
      no_exhibits_sub:
        'Exhibits may appear here as you progress through the experience.',
      click_to_connect_microphone_and_camera:
        'Click to connect microphone and camera',
      click_to_join_session: 'Click to join session',
      welcome_back: 'Welcome back',
      quiz_previous: 'Previous',
      discussion_index:
        'Discussion {{discussionSlideIndex}} of {{discussionSlideCount}}',
      welcome_to_breakout: 'Welcome to Breakout',
      processing_session: 'Processing Session Data... Please Wait',
      take_poll:
        'First, take the poll below, then advance to the next slide to begin your discussion.',
      waiting_poll: 'Waiting for your peers to complete the poll',
      take_quiz: 'Please take the following quiz',
      take_group_quiz:
        'Please answer the following question with input from your group.',
      waiting_quiz: 'Waiting for your peers to complete the quiz',
      exhibit_grid: 'Exhibit Grid',
      advance_to_next_slide:
        'Advance to the next slide when your group is ready',
      advance_to_next_question: 'Advance to the next question',
      when_ready: 'When your group is ready!',
      waiting_for_next_slide:
        'Waiting for the group leader to advance to the next slide',
      progress_time_left: 'Time left',
      progress_loading: 'Loading',
      progress_time_up: 'Time is up',
      time_left_zero: '<1 min',
      time_left_one: '1 min',
      time_left_other: '{{count}} mins',
      great_session: 'You did great on this session. Thank you!',
      disconnect: 'Leave Room',
      disconnect_prompt: 'Are you sure you want to leave this room?',
      quiz_question_count: 'Question {{index}} out of {{count}}',

      send_message: 'Send Message',

      confirm: 'Confirm',

      disclaimer: 'Disclaimer',
      generated_by_ai:
        'This report is generated by AI. Sometimes, the AI may not correctly identify gender or other details.',

      bottom_icon_disable_camera: 'Disable Camera',
      bottom_icon_enable_camera: 'Enable Camera',
      bottom_icon_mute_audio: 'Mute Audio',
      bottom_icon_unmute_audio: 'Unmute Audio',
      bottom_icon_start_screen_share: 'Share Screen',
      bottom_icon_stop_screen_share: 'Stop Sharing Screen',
      bottom_icon_cannot_screen_share_now:
        'Screen Share Disabled on this Slide',
      bottom_icon_media_device: 'Configure Audio/Video',
      bottom_icon_assert_leadership: 'Assert Leadership',
      bottom_icon_toggle_sidebar: 'Toggle Sidebar',
      bottom_icon_leave_meeting: 'Leave Room',
      bottom_icon_captions: 'Toggle Captions',
      exit_portal: 'Exit to Portal',
      end_timer: 'End Timer',
      next_slide: 'Next Slide',
      group_leader: 'Group Leader',
      promote_to_group_leader: 'Promote to Group Leader',

      restart_video: 'Restart Video',
      toggle_video: 'Toggle Video',

      share: 'Share',
      stop_sharing: 'Stop Sharing',
      confirm_exhibit_action_title:
        'Are you sure your want to share this exhibit?',
      confirm_exhibit_action:
        'This will present this exhibit to all group members.',

      livekit_errors: {
        PublicationTimeoutError:
          'There was an issue when connecting to the server.\n Click the button to refresh and attempt to reconnect.\n\nIf the issue persists, please contact us at support@breakoutlearning.com.',
        CouldNotEstablishConnectionError:
          'Could not establish connection. \n Click the button to refresh and attempt to reconnect.\n If the issue persists, please contact us at support@breakoutlearning.com.',
        CouldNotEstablishPcConnectionError:
          'Could not establish connection. \n Click the button to refresh and attempt to reconnect.\n If the issue persists, please contact us at support@breakoutlearning.com.',
        CouldNotCreateOfferError:
          'Could not establish connection. \n Click the button to refresh and attempt to reconnect.\n If the issue persists, please contact us at support@breakoutlearning.com.',
        ServerNotReachableError:
          'Lost connection to server. \n Click the button to refresh and attempt to reconnect.\n If the issue persists, please contact us at support@breakoutlearning.com.',
        click_to_refresh: 'Click to Refresh',
      },

      absent_dialog: {
        title: "You're missing some of your group members.",
        warning:
          'Once you advance to the next slide, you will not be able to go back.',
        advance: 'Advance to Next Slide',
        mark_absent: 'Don’t show this again',
      },
      solo_dialog: {
        step_one: {
          title: 'Is this a group experience?',
          subtitle:
            'Make sure to wait for your group members if this is a group experience.',
          yes: 'Yes, Group',
          no: 'No, Solo',
        },
        step_two: {
          title:
            'You shouldn’t proceed if you’re expecting other group members',
          warning:
            'Once you advance to the next slide, you will not be able to go back.',
          advance: 'Advance to Next Slide',
        },
      },
      group_leader_dialog: {
        title: 'You are assigned the group leader role',
        subtitle:
          'You will be responsible for controlling the slides throughout the session.',
        acknowledge: 'Acknowledge',
      },
      skip_slide_dialog: {
        title: 'Are you sure you want to go forward?',
        subtitle:
          'Make sure your group is ready and have covered all requirements before continuing.',
        warning:
          'You will not be able to go back once you advance to the next slide.',
        advance: 'Next Slide',
      },

      video_not_readable_error_popup:
        "Breakout Learning can't access your camera. Please close all applications using your camera, or check your system settings.",
      audio_not_readable_error_popup:
        "Breakout Learning can't access your microphone. Please close all applications using your microphone, or check your system settings.",
      devices_not_readable_error_popup:
        "Breakout Learning can't access your devices. Please close all applications using your devices, or check your system settings.",
      screenshare_not_readable_error_popup:
        "Breakout Learning can't access your screen. Please check your system settings.",
      results: {
        disclaimer: 'Disclaimer',
        disclaimer_text:
          'This report is generated by AI. Sometimes, the AI may not correctly identify gender or other details.',
        breakout_session_results: 'Breakout Session Results',
        breakout_session_results_per_user: "{{name}}'s Session Results",
        breakout_session_results_description:
          'Your professors will see these metrics, which may or may not impact your grade.',
        talk_time: 'Your Talk Time',
        quiz_score: 'Your Quiz Score',
        camera_on: 'Your Camera On',
        group_avg: 'Group Avg',
        mins: 'mins',
        individual_talk_time: 'Individual Talk Time',
        average_talk_time: 'Average Talk Time',
        group_balance_loading:
          'Our system is processing the session data—please hold on…',
        group_balance_empty:
          'Our system did not capture your group discussion.',
      },
      individual_eval_loading:
        'Our system is processing the session data—please hold on…',
      individual_eval_no_results:
        'Our system did not capture your group discussion.',
      conversation_map: 'Conversation Map',
      conversation_map_no_results:
        'Our system did not capture your group discussion.',
      conversation_map_loading:
        'Our system is processing the session data—please hold on…',

      conversation_map_loading_long:
        "It is taking longer than expected to generate your group's results. Please check back later.",

      conversation_map_description:
        'The Conversation Map shows a timeline of individual comments <bold>related to the experience</bold> rubrics. The size of each bubble reflects the depth of understanding in each comment.',

      group_balance_score: 'Group Balance Score',
      group_balance_score_balanced_description:
        'Your group had a <bold>balanced discussion</bold>. Group members did participate equally in the discussion. Good job!',
      group_balance_score_asymmetric_description:
        'Your group had an <bold>asymmetric discussion</bold>. Group members did not participate equally in the discussion. Next time, try to work with your group to have a balanced discussion',

      individual_evaluation: 'Individual Evaluation',
      individual_evaluation_description:
        'Our system monitored your discussion throughout the experience and analyzed it against the experience rubrics. ',
      devices_are_in_use:
        "Breakout Learning can't access your devices as they are in use by another application. Please close all applications using your devices, or check your system settings.",
      permissions_not_granted_error_popup:
        'Please grant permissions to access your camera and microphone.',
      timeline: 'Timeline',
      comments_shared: 'Comments Shared',

      room_failed_to_load: 'Room failed to load',

      next_exhibit: 'Next Exhibit',
      previous_exhibit: 'Previous Exhibit',

      broadcast_play: 'Play',
      broadcast_pause: 'Pause',

      advance: 'Advance',

      watch: 'Watch',
      dont_watch: "Don't Watch",
      mute: 'Mute',
      mute_user_confirmation: 'Mute {{name}} for everyone?',
      mute_user_confirmation_description:
        'Only {{name}} can unmute themselves.',

      hidden_user_solo_quiz_notification_dialog_title: 'Individual Quiz Mode',
      hidden_user_solo_quiz_notification_dialog_subtitle:
        'Participants are currently completing an individual quiz. They’ll be visible again when they complete it.',

      media_troubleshooting_permissions_camera:
        'You have not granted permission to access your camera. Please enable camera access in your browser settings.',
      media_troubleshooting_permissions_mic:
        'You have not granted permission to access your microphone. Please enable microphone access in your browser settings.',

      media_troubleshooting_mic_inuse:
        'Your microphone is in use by another application. Try closing all applications using your camera. You can also try restarting your browser.',
      media_troubleshooting_mic:
        'We were not able to access your microphone. It might be in use by another application or not working. Potential solutions include: close all applications using your microphone, restarting your browser, reconnecting the microphone.',

      media_troubleshooting_permissions_click: 'Click here to find out how.',
      media_troubleshooting_camera_inuse:
        'Your camera is in use by another application. Try closing all applications using your camera. You can also try restarting your browser.',
      media_troubleshooting_camera:
        'We were not able to access your camera. It might be in use by another application or not working. Potential solutions include: close all applications using your camera, restarting your browser, reconnecting the camera.',
      media_troubleshooting_devices:
        'We were not able to access your devices. They might be in use by another application or not working. Potential solutions include: close all applications using your devices, restarting your browser, reconnecting the devices.',

      media_troubleshooting_no_devices:
        'We were not able to access your devices. They might be in use by another application or not working. Potential solutions include: close all applications using your devices, restarting your browser, reconnecting the devices.',

      media_troubleshooting_video_no_problems:
        'No video permissions issues detected',
      media_troubleshooting_audio_no_problems:
        'No audio permissions issues detected',

      media_troubleshooting: 'Troubleshooting',
      media_troubleshooting_solutions: 'Solutions',
      media_troubleshooting_solutions_description:
        'Try reconnecting to our servers by clicking on this button',
      media_troubleshooting_solutions_step_1: 'Step 1',
      media_troubleshooting_solutions_step_2: 'Step 2',
      media_troubleshooting_audio_issue_analysis: 'Audio Issue Analysis',
      media_troubleshooting_video_issue_analysis: 'Video Issue Analysis',

      media_troubleshooting_reconnect: 'Reconnect',
      media_troubleshooting_reload: 'Reload Page',

      media_troubleshooting_still_have_issues:
        'Having issues with audio or video?',
      media_troubleshooting_nobody_can_hear_or_see_me:
        'Yes, others cannot see or hear me or I cannot see or hear others',
      media_troubleshooting_nobody_can_hear_or_see_me_reconnect:
        'Click here to reconnect to the room',

      media_troubleshooting_nobody_can_hear_or_see_me_reload:
        'Click here to attempt a page reload',

      media_troubleshooting_lk_speaking_check: {
        success: 'Correctly received your speech',
        error:
          'Failed to capture audio. It might mean our servers are not receiving your audio',
        pending:
          'Trying to capture speech. Try saying something to verify our servers capture your speech',
      },

      media_troubleshooting_lk_audio_playback_check: {
        success: 'Audio playback is enabled',
        error: 'Audio playback failed',
        pending: 'Checking audio playback status',
      },

      media_troubleshooting_lk_video_playback_check: {
        success: 'Video playback is enabled',
        error: 'Video playback failed',
        pending: 'Checking video playback status',
      },

      media_troubleshooting_video_capture_check: {
        success: 'Video captured successfully, your camera is working',
        error:
          'Failed to capture video. It might mean your camera is not working',
        pending:
          'Trying to capture video. Try waving to the camera or moving around to verify your camera is working',
      },

      media_troubleshooting_audio_capture_check: {
        success: 'Audio captured successfully, your microphone is working',
        error:
          'Failed to capture audio. It might mean your microphone is not working',
        pending:
          'Trying to capture audio. Try saying something to verify your microphone is working',
      },

      feedback_choose_why_low_score: 'What can we improve?',
      feedback_choose_why_high_score: 'What did you enjoy?',
      feedback_preset_selected: 'selected',
      feedback_preset_deselected: 'deselected',

      feedback_presets_negative: {
        negative_audio: 'Audio',
        negative_video: 'Video',
        negative_usability: 'Platform Usability',
        negative_content: 'Content',
        negative_instructions: 'Instructions',
      },
      feedback_presets_positive: {
        positive_content: 'Content',
        positive_format: 'Discussion Format',
        positive_usability: 'Platform Usability',
        positive_outcomes: 'Learning Outcomes',
      },
      feedback_confirm_leave_title: 'Feedback Not Yet Submitted',
      feedback_confirm_leave:
        'You have not finished submitting feedback. Are you sure you want to return to the meeting?',
      feedback_leave: 'Return to the meeting',
      feedback_dont_leave: 'Submit Feedback First',
      feedback_rate_star: 'Rate {{score}} out of 5',

      feedback: 'Feedback',
      feedback_title: 'Share your feedback with your professor.',
      feedback_send: 'Confirm',

      share_feedback: 'Share Feedback',
      please_rate: 'How would you rate your experience today?',
      other_feedback: 'Any other feedback?',
      feedback_placeholder: 'Please share your feedback here. (Optional)',
      thank_you_for_feedback: 'Thank you for sharing your feedback!',
      feedback_submitted: 'Feedback Submitted',
      hear_thoughts: 'How would you rate your experience today?',

      mins_remaining: '{{count}} mins',
      mins_remaining_one: '{{count}} min',
      mins_remaining_zero: '',

      results_select_user: 'Select User',
    },
    avcheck: {
      select_audio_device: 'Select Audio Device',
      select_video_device: 'Select Video Device',

      permissions_denied_description:
        'It looks like you haven’t enabled access to your camera and microphone. To join the session fully and interact with others, you’ll need to enable these permissions.',
      re_run_permissions_check: 'Re-run permissions check',
      skip_permissions_check: 'Skip this step',
      skip_permissions_check_tooltip:
        'That will skip this step, but you might not have a working camera or microphone',

      welcome_text: 'Welcome to the Breakout Learning Room',
      session_date: 'Session Date',
      group: 'Group',

      join_meeting: 'Join Meeting',
      back_to_portal: 'Back to Portal',
      waiting_for_participants: 'Waiting for more participants...',

      av_check_title: 'Audio/Video Check',
      av_check_description:
        'Ensure your video and audio are working properly before starting in order to avoid any issues during your session.',

      permissions: 'Permissions',
      devices_captured: 'Devices Captured',

      permissions_headline: 'Allow Camera and Microphone access',

      audio_permissions: 'Audio Permissions',
      video_permissions: 'Video Permissions',

      audio_captured: 'Audio Captured',
      video_captured: 'Video Captured',

      participants_waiting: 'Your group members are waiting.',

      participants_noone_joined:
        'No one has joined the meeting. \nWould you like to start it?',

      participants_in_group_of_one: 'You are currently in a group of one',
      participants_in_group_of_one_description:
        'If you’re expecting other people to join, please wait for your group to form before starting the session',

      camera_prompt:
        'Wave your hand to make sure that your camera is working correctly.',
      microphone_prompt:
        'Please say something to make sure your microphone is working correctly.',

      device_not_readable_header: 'Device not readable',
      device_not_readable_description:
        'That most likely means it is in use by another application. Please close the other application and try again.',

      device_denied_header: 'Device not allowed',
      device_denied_description:
        'Please allow access to your camera and microphone to continue.',

      device_not_allowed_header: 'Device not allowed',
      device_not_allowed_description:
        'Please allow access to your camera and microphone to continue.',

      device_overconstrained_header: 'Device cannot be accessed right now',
      device_overconstrained_description:
        'That most likely means it is in use by another application. Please close the other application and try again.',

      in_meeting: 'In Meeting',
      getting_ready: 'Getting Ready',
      not_here_yet: 'Not Here Yet',

      regenerating_token: 'Regenerating Token',

      permissions_initial_step: {
        chrome:
          'Click “Allow” to enable you microphone and camera. You can always turn them off at any time during the meeting',
        generic:
          'Click “Allow” to enable you microphone and camera. You can always turn them off at any time during the meeting',
      },
      permissions_steps: {
        chrome: [
          'Click the <bold>lock</bold> icon in the address bar',
          'Click <bold>Reset Permissions</bold>',
          'Click the Re-run permissions button below',
        ],
        firefox: [
          'Click the settings icons in the address bar',
          'Click on the x icons next to <bold>Blocked Temporarily</bold> to enable camera and microphone',
          'Click the Re-run permissions button below',
        ],
        safari: [
          'Click on Safari next to the Apple icon in the top left bar',
          'Click Settings',
          'Click Websites',
          'Click Camera',
          'Find Breakout Learning and select Allow',
          'Click Microphone',
          'Find Breakout Learning and select Allow',
          'Click the Re-run permissions button below',
        ],
      },
    },
    admin: {
      copy_link: 'Copy Link',
      version: 'Version',
      mean: 'Mean',
      delete_rubric_test: 'Are you sure you want to delete this test?',
      no_test_results: 'No Test Results',
      standard_deviation: 'Std Deviation',
      justification: 'Justification',
      desired_deviation: 'Desired Std Deviation',
      desired_mean: 'Desired Mean',
      add_new_rubric: 'Add New Rubric',
      add_new_rubric_test: 'Add New Rubric Test',
      edit_rubric_test: 'Edit Test',
      rubric_test_score: 'Rubric Test Score',
      rubric_type: 'Rubric Type',
      rubric: 'Rubric',
      rubric_test_name_required: 'Please enter a test name',
      rubric_test_script_required: 'Please enter a test script',
      loading_ai_results: 'Loading AI Results',
      no_rubric_test_results: 'No Rubric Test Results',
      number_of_test_results: 'Number of Test Results',
      rubric_test_score_blooms_error: 'Bloom’s Score must be between 0 and 6',
      rubric_test_score_pass_fail_error: 'Pass/Fail must be either 0 or 1',
      rubric_tests: 'Rubric Tests',
      test_name: 'Rubric Test Name',
      test_script: 'Rubric Test Script',
      update_test: 'Update Test',
      create_test: 'Create Test',
      generate_new_results: 'Generate Results',
      blooms_score: 'Bloom’s Score',
      required: 'required',
      saving_slide_deck: 'Saving Slide Deck…',
      error: 'Error',
      seconds: 'Seconds',
      dashboard: 'Admin Dashboard',
      observe: 'Observe',
      grant_tokens: 'Grant Tokens',
      users: 'Users',
      add_new_course_material: 'Add New Course Material',
      add_new_author: 'Add New Author',
      add_new_reference: 'Add New Reference',
      delete: 'Delete',
      delete_material: 'Are you sure you want to delete this course material?',
      delete_author: 'Are you sure you want to delete this author?',
      delete_reference: 'Are you sure you want to delete this reference?',
      delete_exhibit: 'Are you sure you want to delete this exhibit?',
      delete_rubric: 'Are you sure you want to delete this rubric?',
      author_deleted: 'Author deleted.',
      reference_deleted: 'Reference deleted',
      add_new_question: 'Add New Interactive Element',

      delete_question: 'Are you sure you want to delete this question?',
      question_deleted: 'Question deleted.',
      confirm: 'Confirm',
      course_materials: 'Course Materials',
      authors: 'Authors',
      questions: 'Interactive Element',
      add_to_case: 'Add to Experience',
      save: 'Save',
      slide_details: 'Slide Details',
      add_new_slide: 'Add New Slide',
      delete_slide_title: 'Delete Slide',
      delete_slide: 'Are you sure you want to delete this slide?',
      delete_slide_image_title: 'Delete Slide Image',
      delete_slide_image: 'Are you sure you want to delete the slide image?',
      delete_slide_video_title: 'Delete Slide Video',
      delete_slide_video: 'Are you sure you want to delete the slide video?',
      add_new_exhibit: 'Add New Exhibit',
      exhibits: 'Exhibits',
      demo: {
        details: 'Details',
        delete_meeting: 'Delete Meeting',
        delete_meeting_description:
          'Participants will not be able to join the meeting if you continue.',
        invited_guests: 'Invited Guests',
        add_generic_invitee: 'Add Generic Invitee',
      },
    },
    admin_details: {
      missing_image_alt_text_for_slide:
        'Missing Image Alt text for slide: {{slideName}}',
      missing_image_alt_text_for_exhibit:
        'Missing Image Alt text for exhibit: {{exhibitName}}',
      invalid: 'Fix Errors',
      waiting_for_save: 'Disabled until save is completed',
      demo_slide_deck_tooltip:
        "When slide deck type is set to 'Demo', the slide deck can not be published or added to a catalog.",
      details_invalid: 'Please fix the errors before adding to the Catalog',
      replace: 'Replace',
      save_tags: 'Save Tags',
      tags: 'Tags',
      experience_type: 'Experience Type',
      experience_type_original: 'Breakout Originals',
      experience_type_dialog: 'Dialogs',
      experience_type_simulation: 'Simulations',
      experience_type_negotiation: 'Negotiations',
      experience_type_demo: 'Demos',
      archive: 'Archive',
      removed_from_catalog: 'Removed from Catalog',
      catalog_remove_version_dialog_title:
        'Are you sure you want to remove version from catalog?',
      catalog_remove_version_dialog_subtitle:
        'Once an experience is removed from a catalog, all users who have access to this catalog will not be able to see this experience.',
      catalog_remove_feature_warning:
        'Experience must be un-featured before it is removed from the last catalog',
      proceed_anyways: 'Proceed Anyways',
      cancel: 'Cancel',
      added_to_catalog: 'Added to Catalog',
      catalog_replace_stale_data_warning:
        'Catalog data has updated since you first started the replacement process, please try again',
      publish_error: 'Unexpected Error publishing slide deck',
      slide_deck_publish_dialog_title:
        'This action will mark this experience as published',

      cant_publish_slide_deck_title:
        'Unable to publish slide deck for the following reasons:',
      slide_count_mismatch:
        'Slide count mismatch (if issue continues notify engineering)',
      must_have_one_slide: 'Must have at least one slide',
      must_have_featured_image: 'Must have a featured image',
      must_have_author: 'Must have an author',
      must_have_slide_deck_logo: 'Must have a slide deck logo',
      must_have_title: 'Must have a title',
      must_have_subtitle: 'Must have a subtitle',
      must_have_version: 'Must have a version',
      must_have_summary: 'Must have a summary',
      must_have_discipline: 'Must have at least one discipline',
      must_have_learning_objective: 'Must have at least one learning objective',
      must_have_trailer: 'Must have a trailer',
      must_have_trailer_thumbnail: 'Must have a trailer thumbnail',
      author_without_image: 'Each author must have an image',

      slide_deck_deleted_or_hidden: 'Slide deck is deleted or hidden',

      slide_deck_publish_dialog_subtitle:
        'Once an experience is published, you will not be able to make any changes to it',
      no_catalogs: 'No Catalogs',
      confirm: 'Confirm',
      select_catalog: 'Select Catalog',
      experience_details: 'Experience Details',
      catalogs: 'Catalogs',
      catalog_remove_case_dialog_title:
        'Are you sure you want to remove this experience from the catalog?',
      catalog_remove_case_dialog_subtitle:
        'Once an experience is removed from a catalog, all users who have access to this catalog will not be able to see this experience.',
      catalog_add_case_draft_warning:
        'WARNING: once an experience has been added to a catalog it can no longer be edited.',
      catalog_add_case_type_exists_warning:
        'WARNING: an experience of this type already exists in the catalog.',
      remove: 'Remove',
      remove_from_catalog: 'Remove from Catalog',
      add_to_catalog: 'Add to Catalog',
      add_version_to_catalog: 'Add Version to a Catalog',
      objectives: 'Objectives',
      key_concepts: 'Key Concepts',
      add_new_item: 'Add New Item',
      details_saved: 'Details saved ✅',
      value_objectives: '{{count}} Objectives Added',
      value_single_objective: '1 Objective Added',
      value_single_key_concept: '1 Key Concept Added',
      value_single_tag: '1 Tag Added',
      value_no_objectives: 'No Objectives Added',
      value_key_concepts: '{{count}} Key Concepts Added',
      value_no_key_concepts: 'No Key Concepts Added',
      value_tags: '{{count}} Tags Added',
      value_no_tags: 'No Tags Added',
      required_one_objective: 'At least one objective is required',
      required_description: 'Please add an experience summary',
      disciplines: 'Disciplines',
      description: 'Experience Summary',
      required_discipline: 'Please enter a discipline',
      discipline_already_exists: 'Discipline already exists',
      objective_already_exists: 'Objective already exists',
      objective_invalid: 'Please enter a valid objective',
      tag_invalid: 'Please enter a valid tag',
      key_concept_invalid: 'Please enter a valid key concept',
      objective_placeholder: 'Enter Objective',
      key_concept_placeholder: 'Enter Key Concept',
      key_concept_already_exists: 'Key Concept already exists',
      tag_placeholder: 'Enter Tag',
      tag_already_exists: 'Tag already exists',
      key_concepts_already_exists: 'Key Concept already exists',
      value_no_disciplines: 'No Disciplines',
      industries: 'Industries',
      required_industry: 'Please enter a industry',
      industry_already_exists: 'Industry already exists',
      value_no_industries: 'No Industries',
      save_objectives: 'Save Objectives',
      save_key_concepts: 'Save Key Concepts',
      save_disciplines: 'Save Disciplines',
      save_industries: 'Save Industries',
      version_name: 'Version Name',
      cannot_use_initial_version_name: 'Cannot use 0.0.0 as the version name',
      required_version_name: 'Please enter a version name',
      unique_version_name: 'A version with this name already exists',
      subtitle_name: 'Experience Subtitle',
      required_subtitle_name: 'Please enter an experience subtitle',
      title: 'Experience Title',
      required_title: 'Please enter an experience title',
      back_to_select: 'Back to Select',
      view: 'View',
      edit: 'Edit',
      slide_deck_existing_versions_dialog_replace_button_title: {
        plural: 'Replace Versions', // many
        singular: 'Replace Version', // one
      },
      slide_deck_existing_versions_dialog_publish_button_title: {
        plural: 'Publish All Versions', // many
        singular: 'Publish Both Versions', // one
      },
      slide_deck_existing_versions_dialog_title: {
        plural: 'Existing versions found', // many
        singular: 'Existing version found', // one
      },
      slide_deck_existing_versions_dialog_subtitle: {
        plural:
          'This catalog already has {{count}} versions from this experience, you can publish all versions or replace the existing ones', // many
        singular:
          'This catalog already has a version from this experience, you can publish both versions or replace the existing one', // one
      },
      slide_deck_existing_featured_versions_dialog_replace_button_title: {
        plural: 'Replace Versions', // many
        singular: 'Replace Version', // one
      },
      slide_deck_existing_featured_versions_dialog_publish_button_title: {
        plural: 'Feature All Versions', // many
        singular: 'Feature Both Version', // one
      },
      slide_deck_existing_featured_versions_dialog_title: {
        plural: 'Featured versions found', // many
        singular: 'Featured version found', // one
      },
      slide_deck_existing_featured_versions_dialog_subtitle: {
        plural:
          'This experience already has {{count}} featured versions, you can feature all versions or replace the existing ones', // many
        singular:
          'This experience already has a featured version, you can feature both versions or replace the existing one', // one
      },
      slide_deck_existing_module_versions_dialog_title: {
        plural: 'Replace Module Versions', // many
        singular: 'Replace Module Version', // one
      },
      slide_deck_existing_module_versions_dialog_subtitle: {
        plural:
          'The following slide decks are present within teaching plan modules, you can replace them with the new version or remove them from the module(s)', // many
        singular:
          'The following slide deck is present within teaching plan modules, you can replace them with the new version or remove them from the module(s)', // one
      },
      slide_deck_replace_existing_featured_versions_dialog_title: {
        plural: 'Featured versions', // many
        singular: 'Featured version', // one
      },
      slide_deck_replace_existing_featured_versions_dialog_subtitle: {
        plural: 'Proceeding will un-feature the following versions', // many
        singular: 'Proceeding will un-feature the following version', // one
      },
      slide_deck_remove_from_catalog_modules_title: {
        plural: 'Remove From Modules', // many
        singular: 'Remove From Module', // one
      },
      slide_deck_remove_from_catalog_modules_subtitle: {
        plural:
          'Proceeding will remove the slide deck from the following modules', // many
        singular:
          'Proceeding will remove the slide deck from the following module', // one
      },
      slide_deck_invalid_for_feature:
        'Slide deck can not be featured (requires icon and hero image)',
      saving: 'Saving...',
      saved: 'Saved',
      label_price: 'Token(s) / Student',
      required_price: 'Please enter a token amount',
      min_price: 'Minimum token amount is 0',
      invalid_price: 'Please enter a valid positive integer',
      invalid_google_slide_url: 'Please enter a valid Google Slide URL',
      label_teaching_slides_url: 'Teaching Slides URL',
      label_featured: 'Slide Deck Featured',
      slide_deck_featured_tooltip:
        'Only Slide Decks with a name, logo, and a  large featured image\n (in materials) can be featured. Once these elements are provided, the Slide Deck will be showcased on the login page.',
    },
    admin_demos: {
      headline: 'Sales Demo Dashboard',
      create_demo: 'Create Meeting',
      completed: 'Completed',
      upcoming: 'Upcoming',
      meeting_settings: 'Meeting Settings',
      select_user: 'Select Sales Rep',
      sales_person_required: 'Please select a sales rep',
      select_slide_deck: 'Select Slide Deck',
      slide_deck_required: 'Please select a slide deck',
      select_scheduled_at: 'Select Scheduled Time',
      scheduled_at_required: 'Please select a scheduled time',
      scheduled_at_invalid: 'Scheduled time must be in the future',
      invitation_first_name: 'First Name',
      first_name_required: 'Please enter a first name',
      invitation_last_name: 'Last Name',
      last_name_required: 'Please enter a last name',
      invitation_email: 'Email',
      email_required: 'Please enter an email',
      email_invalid: 'Please enter a valid email',
      first_name: 'First Name',
      last_name: 'Last Name',
      email_address: 'Email Address',
      add_invitee: 'Add Invitee',
      remove: 'Remove',
      student_results: 'Student Results',
      start_meeting: 'Start Meeting',
      join_meeting: 'Join Meeting',
      copy_invite: 'Copy Invite',
      edit: 'Edit',
      join_room_timeout: 'Join Room Timeout: please try again',
    },
    slide: {
      image_dimensions_error: 'Image dimensions must be {{hor}}x{{ver}}',
      slide_type: 'Slide Type',
      slide_description: 'Slide Description',
      slide_description_invalid: 'Slide description required',
      slide_use_default_processing_video: 'Default Processing Experience',
      slide_use_processing_image: 'Show Custom Image',
      slide_processing_type_label: 'View Type',
      slide_details: 'Slide Details',
      slide_duration: 'Duration',
      slide_duration_invalid: 'Integer seconds required (zero is fine)',
      slide_name: 'Slide Title',
      slide_name_invalid: 'Slide title required',
      slide_type_image: 'Slide - Image',
      slide_type_video: 'Slide - Video',
      slide_type_exhibit_grid: 'Slide - Exhibit Grid',
      slide_type_discussion: 'Slide - Discussion',
      slide_type_group_quiz: 'Slide - Group Quiz',
      slide_type_interactive_quiz: 'Slide - Interactive Quiz',
      slide_type_solo_quiz: 'Slide - Solo Quiz',
      slide_type_interactive_poll: 'Slide - Interactive Poll',
      slide_type_professor_feedback: 'Slide - Professor Feedback',
      slide_type_session_processing: 'Slide - Session Processing',
      slide_type_session_results: 'Slide - Session Results',
      slide_image: 'Slide Image',
      slide_image_alt_text: 'Slide Image Alt Text',
      required_image_alt_text: 'Please add alt text',
      required_image: 'Please upload an image',
      slide_video: 'Slide Video',
      show_slide_image: 'slide.jpg',
      show_slide_video: 'slide.webm',
      exhibits: 'Exhibits',
    },
    rubric: {
      select_slide: 'Select Slide',
      all_slides: 'All Slides',
      slide_rubric_prompt_invalid: 'prompt required',
      slide_rubric_prompt: 'Prompt',
      slide_rubric_description_invalid: 'description required',
      slide_rubric_description: 'Description',
      slide_rubric_invalid: 'rubric required',
      slide_rubric: 'Rubric',
      slide_rubric_label_0: 'Label 1',
      slide_rubric_label_0_invalid: 'label required',
      slide_rubric_label_1: 'Label 2',
      slide_rubric_label_1_invalid: 'label required',
      slide_rubric_label_pass: 'Pass Label',
      slide_rubric_label_fail: 'Fail Label',
      rubric: 'Rubric',
      rubric_details: 'Rubric Details',
      rubric_type: 'Rubric Type',
      rubric_type_blooms: "Bloom's Taxonomy",
      rubric_type_pass_fail: 'Pass/Fail',
    },
    reference: {
      reference: 'Reference',
      type: 'Reference Type',
      type_alternative_to: 'Alternative To',
      type_based_on: 'Based On',
      select_type: 'Select Reference Type',
      author_invalid: 'author required',
      author: 'Author',
      title_invalid: 'title required',
      title: 'Title',
      publisher: 'Publisher',
      publisher_invalid: 'publisher required',
      published_date: 'Published Date',
      published_date_invalid: 'date required',
      published_date_future: 'published date can not be in future',
      citation: 'Citation',
      url: 'Reference URL',
      url_invalid: 'valid url required',
    },
    exhibit: {
      label_slide: 'Slide',
      label_name: 'Name',
      label_description: 'Description',
      label_image: 'Image',
      view_image: 'View Image',
      required_slide: 'Please select a slide',
      required_name: 'Please add an exhibit name',
      required_description: 'Please add a description',
      required_alt_text: 'Please add alt text',
    },
    question: {
      label_slide: 'Slide',
      label_question_type: 'Question Type',
      label_question: 'Question',
      label_select_individual_slide: 'Select Individual Quiz Slide',
      label_select_group_slide: 'Select Group Quiz Slide',
      label_answers: 'Answers',
      label_sorting_answers: 'Items (Add in the right order)',
      label_correct_answer: 'Correct Answer',
      required_one_answer: 'Please add at least one answer',
      double_no_slide_error:
        'Either individual or group slide must be selected',
      placeholder_no_answers: 'No answers added',
      required_slide: 'Please select a slide',
      required_question_type: 'Please select a question type',
      required_question: 'Please add a question',
      required_correct_answer: 'Please select a correct answer',
      question_type_poll: 'Poll',
      question_type_custom_poll: 'Custom Poll',
      question_type_multiple_choice: 'Multiple Choice',
      question_type_sorting: 'Sorting Exercise',
      question_type_title: 'Slide Title',
      option_no_slide: 'No Slide',
      option_no_slide_for_group: 'No Slide (Sorting Exercise Only)',
      option_pre_meeting_quiz: '0. Pre-Meeting Quiz',
      individual_quiz_slide: 'Individual Quiz Slide',
      group_quiz_slide: 'Group Quiz Slide',
    },
    material: {
      pre_post_info:
        '"Pre-session" or "Post-session" indicates whether the student is expected to have access to the material before or after the meeting.\nA post-session material will become visible only after the session is over, whereas a pre-session material will appear in the list of Pre-Work materials.',
      see_in_podcast_player: 'See in podcast player',
      see_in_video_player: 'See in video player',
      required_name: 'Please add a material name',
      required_work_type: 'Please select a work type',
      required_description: 'Please add a description',
      required_link: 'Please add a link',
      required_image: 'Please upload an image',
      required_file: 'Please upload a file',
      required_url: 'Please add a url',
      invalid_link: 'Please enter a valid url (ex. https://www.example.com)',
      invalid_image_upload: 'Invalid image, please attempt to upload again',
      label_name: 'Name',
      label_type: 'Type',
      label_work_type: 'Pre or Post-Session Material?',
      label_link: 'Link',
      label_description: 'Description',
      label_viewable_by: 'Viewable By',
      label_viewable_by_student: 'Students',
      label_viewable_by_instructor: 'Instructors',
      label_viewable_by_public: 'Public',
      label_image: 'Upload Image',
      label_audio: 'Upload Audio',
      label_video: 'Upload Video',
      label_pdf: 'Upload PDF',
      view_pdf: 'View PDF',
      view_video: 'View Video',
      view_audio: 'Play Audio',
      view_image: 'View Image',
      view_file: 'View File',
      error_upload_pre_save: 'Something went wrong, please try uploading again',
      // material type:
      option_url: 'URL',
      option_pdf: 'PDF',
      option_mp3: 'MP3',
      option_mp4: 'MP4',
      option_webm: 'WebM',
      option_featuredLarge: 'Featured (Hero) Image',
      option_jpg: 'JPG',
      option_trailer: 'Trailer',
      option_trailer_thumbnail: 'Trailer Thumbnail',

      // work type:
      option_pre_work_type: 'Pre-Session',
      option_post_work_type: 'Post-Session',
      option_neither_work_type: 'Neither',

      aria_label_viewable_by_student: 'Viewable by Students',
      aria_label_viewable_by_instructor: 'Viewable by Instructors',
      aria_label_viewable_by_public: 'Viewable by Public',
    },
    author: {
      label_name: 'Author Name',
      label_title: 'Author Title',
      label_institution: 'Author Institution',
      label_bio: 'Author Bio',
      label_image: 'Author Image',
      required_name: 'Please add an author name',
      required_title: 'Please add an author title',
      required_institution: 'Please add an author institution',
      required_bio: 'Please add an author bio',
      required_image: 'Please upload an image',
      view_image: 'View Image',
      error_upload_pre_save:
        'Something went wrong saving author, please try again',
    },
    videos_preview: {
      loading_video: 'Loading Video',
    },
    dart: {
      company_name: 'Breakout Learning',
      dark_mode: 'Dark Mode',
      english: 'English',

      homepage: 'Homepage',
      slogan: 'Sign In and Begin your Journey',
      get_started: 'Get Started',
      go_route: 'Temporary Navigation',

      instructor_view: 'Instructor View',
      admin_view: 'Admin View',
      admin_dashboard: 'Admin Dashboard',
      view_users: 'View Users',
      assistants: 'Assistants',

      yes: 'Yes',
      no: 'No',
      pending: 'Pending',
      ok: 'Ok',
      na: '—',
      show_low_scores: 'Show Low Scores',
      hide_low_scores: 'Hide Low Scores',
      groups_colon: 'Groups:',
      all_group_average: 'All Group Average',
      video_not_readable_error_popup:
        "Breakout Learning can't access your camera. Please close all applications using your camera, or check your system settings.",
      video_not_allowed_error_popup:
        "Breakout Learning can't access your camera. Please ensure you've given this tab permission to your camera and microphone.",

      error: 'Error',
      error_popup: 'Error: {{errorMessage}}',
      warning_popup: 'Warning: {{errorMessage}}',
      alert_popup: 'Alert: {{errorMessage}}',
      invitation_accepted: 'Invitation Accepted',
      delete: 'Delete',
      cancel: 'Cancel',
      group_leader: 'Group Leader',
      group_leader_promotion: 'Promote to Group Leader',

      users: 'Users',
      tas: 'TAs',
      instructors: 'Instructors',
      admins: 'Admins',
      tokens_granted: 'Tokens Granted',
      grant_tokens_dialog_sub:
        'Choose a token amount to grant to the selected user',
      grant_amount_tokens: 'Grant {{value}} Tokens',

      running_classes: 'Running Classes',
      slide: 'Slide',

      create_assistant_invitation: 'Create Assistant Invitation',

      create_author_invitation: 'Create Author Invitation',
      teachers_assistants_welcome:
        "In Assistants view, instructors can create assistant invitations for TAs. Assistants are authorized to operate the platform on instructors' behalf. They can create classes, assign experiences, invite students and view students' completed session results.",

      save_profile: 'Save Profile',

      library: 'Library',
      admin_library: 'Admin Library',
      upload_logo: 'Upload Logo',

      preview: 'Preview',
      publish: 'Publish',
      archive: 'Archive',
      publish_title: 'Are you sure you want to publish this experience?',
      publish_description:
        'This action will publish the experience to the explore page for professors to add to their classes',
      archive_title: 'Are you sure you want to archive this experience?',
      archive_description:
        'This action will prevent experience from showing up at the explore page for professors',
      case_details: 'Experience Details',
      slides: 'Slides',

      course_materials_description:
        'Course materials help you understand the core concepts of the experience and ready you to join the discussion.',
      interactive_element: 'Interactive Element',
      interactive_elements: 'Interactive Elements',
      rubrics: 'Rubrics',
      exhibits: 'Exhibits',
      active: 'Active',
      archived: 'Archived',
      hidden: 'Hidden',
      hide: 'Hide',
      show: 'Show',
      draft: 'Draft',
      case_study: 'Experience',
      case_title: 'Experience Title',
      case_title_invalid: 'experience title required',
      teaching_slides_url: 'Teaching Slides Url',
      teaching_slides_url_invalid: 'use google slides url from browser',
      case_subtitle: 'Experience Subtitle',
      case_subtitle_invalid: 'experience subtitle required',
      case_summary: 'Experience Summary',
      case_summary_invalid: 'experience summary required',
      case_objectives: 'Experience Objectives',
      case_objective_invalid: 'objective invalid',
      add_objective: 'Add Objective',
      add_new_item: 'Add New Item',
      no_objectives: 'No Objectives Added',
      objective: 'Objective',
      objectives_added: {
        token_number: '{{count}} Objectives Added', // many
        token_number_one: '{{count}} Objective Added', // one
      },
      disciplines: 'Disciplines',
      discipline: 'Discipline',
      no_disciplines: 'No Disciplines',
      industries: 'Industries',
      industry: 'Industry',
      no_industries: 'No Industries',
      price_student: 'Token(s)/Student',
      authors: 'Authors',
      author_image: 'Author Image',
      author_name: 'Author Name',
      author_title: 'Author Title',
      author_institution: 'Author Institution',
      author_bio: 'Author Bio',
      add_new_author: 'Add New Author',
      free: 'Free',
      price_student_invalid: 'price must be an integer',
      add_new_interactive_element: 'Add New Interactive Element',
      no_compatible_slides: 'No Compatible Slides',
      all_slides: 'All Slides',
      add_new_exhibit: 'Add New Exhibit',
      seconds: 'Seconds',
      no_slide_available: 'No slide available.',
      no_questions_available: 'No questions available',
      edit: 'Edit',
      select_instructor: 'Select Instructor',
      slide_deck_create_copy_dialog_title_waiting: 'Creating a new copy...',
      slide_deck_create_copy_dialog_subtitle_waiting:
        'Please do not close this page till the process is completed',
      slide_deck_create_copy_dialog_title_resolved: 'New Version Created',
      slide_deck_create_copy_dialog_subtitle_resolved:
        'A new duplicate for this version has been created and added to your library',
      slide_deck_deep_copy_dialog_button_title: 'Go to New Version',
      slide_deck_deep_copy_input_dialog_title: 'Create a copy',
      slide_deck_deep_copy_input_dialog_subtitle:
        'A new duplicate of this experience will be created and added to your library.',
      version_name: 'Version Name',
      version_invalid: 'Version name invalid',
      version_exists: 'Version exists',
      make_a_copy: 'Make a Copy',
      select_catalog: 'Select Catalog',

      slide_deck_publish_dialog_title:
        'This action will mark this experience as published',

      select_to_add: 'Select {{value}} to add',
      enter_a_new: 'Enter a new {{value}}',

      slide_question_type: 'Question Type',
      slide_question_type_poll: 'Poll',
      slide_question_type_multiple_choice: 'Multiple Choice',
      slide_question_type_title: 'Slide Title',
      slide_question_type_custom_poll: 'Custom Poll',
      slide_question_invalid: 'question required',
      slide_question_sorting_answers_label: 'Items (Add in the right order)',
      slide_answer_invalid: 'answer may not be blank',
      group_slide_id_invalid: 'must have quiz slide or group quiz slide',

      slide_answers: 'Answers',
      select_slide: 'Select Slide',
      no_slide: 'No Slide',
      select_string: 'Select {{value}}',

      rubric_result_name: '{{name}}: {{result}}',
      finished_at: 'Finished At: {{finished_at}}',
      legend: 'Legend',
      blooms_taxonomy: "Bloom's Taxonomy",
      blooms_score: "Bloom's Score",
      bloom_0: 'Did Not Discuss',
      bloom_1: 'Remembering',
      bloom_2: 'Understanding',
      bloom_3: 'Applying',
      bloom_4: 'Analyzing',
      bloom_5: 'Evaluating',
      bloom_6: 'Creating',
      bloom_low: 'Low Understanding',
      bloom_medium: 'Medium Understanding',
      bloom_high: 'High Understanding',
      pass_fail: 'Pass/Fail',
      pass_fail_0: 'Did Not Discuss',
      pass_fail_1: 'Discussed',
      argument: 'Argument',
      justification: 'Justification',
      highlighted_justification: 'Highlighted Justification',
      hide_arguments: 'Hide Arguments',
      show_arguments: 'Show Arguments',

      search_students: 'Search Students',
      no_students_found: 'No students found',

      no_results: 'We did not find any results with these filters.',

      rubric_test: 'Rubric Test',
      rubric_tests: 'Rubric Tests',
      rubric_test_results: 'Rubric Test Results',
      slide_rubric_test_name_invalid: 'name required',
      slide_rubric_test_name: 'Name',
      slide_rubric_test_script_invalid: 'script required',
      slide_rubric_test_script: 'Script',
      slide_rubric_test_score_invalid: 'score required',
      slide_rubric_test_score: 'Score',
      add_to_rubric: 'Add to Rubric',
      run_test: 'Run Test',

      upload_exhibit: 'Upload Exhibit',
      slide_deck_exhibit: 'Exhibit',
      slide_exhibit_name_invalid: 'exhibit name required',
      slide_exhibit_name: 'Exhibit Name',
      slide_exhibit_description_invalid: 'exhibit description required',
      slide_exhibit_description: 'Exhibit Description',

      material_name_invalid: 'name required',
      material_name: 'Name',
      material_type: 'Type',
      material_description_invalid: 'description required',
      material_description: 'Description',
      material_link_invalid: 'link required',
      material_link: 'Link',

      catalog_title: 'Catalog Title',
      exit_portal: 'Exit to Portal',
      catalog_title_hint: 'Enter a title for this catalog',
      catalog_description: 'Catalog Description',
      catalog_description_hint: 'Enter a description for this catalog',
      catalog_title_required: 'catalog title required',
      catalog_description_required: 'catalog description required',
      catalog_details: 'Catalog Details',

      delete_catalog: 'Delete Catalog',
      delete_catalog_protected: 'Catalog is protected and can not be deleted',
      save_edits: 'Save Edits',
      save: 'Save',
      details: 'Details',
      invite_users: 'Invite Users',
      version_label: 'Version {{version}}',
      version: 'Version',
      versions: 'Versions',

      published: 'Published',

      next_question: 'Next Question',
      advance_to_next_slide: 'Advance to the next slide',
      advance_to_next_question: 'Advance to the next question',
      when_ready: 'When your group is ready!',
      waiting_for_next_slide:
        'Waiting for the group leader to advance to the next slide',
      end_timer: 'End Timer',
      time_is_up: 'Time is up',
      time_left: 'Time left',

      reconnect: 'Reconnect',
      reconnect_prompt: 'This will force a reconnection',
      reconnect_success: 'Reconnection was successful.',
      hide_captions: 'Hide Captions',
      show_captions: 'Show Captions',
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      subscribe_menu: 'Subscribe Menu',
      discussion_index: 'Discussion {{index}} of {{count}}',

      processing_session: 'Processing Session Data... Please Wait',
      exhibit_grid: 'Exhibit Grid',

      great_session: 'You did great on this session. Thank you!',
      engagement_scores: 'Engagement Scores',
      engagement_scores_hint:
        'Engagement levels for all participants in session',

      no_data_available_yet: 'No data available yet',
      instructor_question: 'Highlighted Question for Professor',
      rubric_arguments: 'Highlighted Comment in Session',
      keep_watching: 'Keep Watching to Progress',
      keep_watching_reason:
        'Please ensure you watch the entire video to advance to the next section. Your progress depends on it!',
      play_video: 'Play Video',
      join_room_audio: 'Welcome back!',
      join_room_audio_explanation: 'Click the button below to join the meeting',
      join_room_button: 'Join Experience',

      submit: 'Submit',
      next: 'Next',
      previous: 'Previous',
      loading_slide: 'Loading slide…',
      stop_video: 'Stop Video',
      stop_video_question:
        'A video is currently playing. Do you want to stop it? It cannot be restarted.',
      take_poll:
        'First, take the poll below, then advance to the next slide to begin your discussion.',
      waiting_poll: 'Waiting for your peers to complete the poll',
      take_quiz: 'Please take the following quiz',
      take_group_quiz: {
        token_number:
          'Group Leader, please answer these questions with input from your group.', // many
        token_number_one:
          'Group Leader, please answer the following question with input from your group.', // one
      },
      waiting_quiz: 'Waiting for your peers to complete the quiz',
      confirm_exhibit_action_title:
        'Are you sure your want to share this exhibit?',
      confirm_exhibit_action:
        'This action will present this exhibit file to all the members in this room.',
      stop_broadcasting: 'Stop Sharing',
      broadcast: 'Share',
      view: 'View',
      observe: 'Observe',
      subscribe_permissions_title: 'Allow subscription',
      subscribe_permissions_content:
        'Allow all participants to subscribe tracks published by local participant?',

      peers: 'Peers',
      chat: 'Chat',
      agenda: 'Agenda',
      slide_mins: '{{minutes}} Mins',
      time_left_in_session: 'Time Left in Session',
      slide_minutes: '{{minutes}} Minutes',
      speaking: 'Speaking:',
      transcript_name: '{{name}}: ',

      overshare: "We value your input, but let's hear others too.",
      speakers: 'Speakers',
      microphone: 'Microphone',
      completed_quiz: 'Completed Quiz',
      assert_group_leadership: 'Assert Group Leadership',
      group_leader_description:
        'Group leader is the participant who is responsible for advancing the group through each slide of experience.',
      assume_leadership_confirmation:
        'Are you sure you want to assume the leadership role?',
      assume_leadership_confirmation_description:
        'You will be required to advance the group through each slide and follow the instructions given to you by the platform throughout the experience.',

      login: 'Login',
      logout: 'Logout',
      log_out: 'Log Out',
      user_profile: 'User Profile',
      saving_user_profile: 'Saving User Profile…',
      first_name_invalid: 'first name required',
      first_name: 'First Name',
      last_name_invalid: 'last name required',
      last_name: 'Last Name',
      email_address_invalid: 'email address required',
      personal_details: 'Personal Details',

      login_email: 'Enter your email',
      login_password: 'Enter your password',
      invalid_email: 'Invalid email',
      invalid_password: 'Invalid password',
      continue_label: 'Continue',

      verify_email: 'Email Verification',
      check_your_email: 'Check your inbox',
      email_warning_prefix: 'We just sent an email to you at ',
      email_warning_suffix:
        ', it contains a link that will sign you in super quick!',
      back_to_login: 'Back to login',

      max_filter_applied: 'Max Filters Applied',
      email_address: 'Email Address',
      no_users_found: 'No users found',

      library_empty:
        'Assignments will appear here when you are invited by your instructor',
      support_email: 'support@breakoutlearning.com',

      just_class: 'Class',
      classes: 'Classes',
      classes_impersonated: 'Classes ({{name}})',
      students: 'Students',
      n_students: '{{count}} Students',

      show_completed: 'Show Completed',
      hide_completed: 'Hide Completed',
      show_archived: 'Show Archived',
      hide_archived: 'Hide Archived',
      show_hidden: 'Show Hidden',
      hide_hidden: 'Hide Hidden',
      cases: 'Experiences',
      add_class_for: 'Add Class for {{name}}',

      enroll: {
        token_number: 'Enroll ({{count}} Tokens)', // many
        token_number_one: 'Enroll ({{count}} Token)', // one
      },

      print_in_tokens: {
        token_number: 'Enroll ({{count}} Tokens)', // many
        token_number_one: 'Enroll ({{count}} Token)', // one
      },

      deadline: 'Deadline: {{date}}',
      view_session: 'View Session',
      start_session_confirmation:
        'Are you sure you want to start the session?\n\nYou will be responsible for controlling the slides throughout the session.\n\nYou should only do this when the group is expecting to meet because each meeting can only run once.',

      group_locked: 'Group Locked',
      group_locked_description:
        'This group is locked. The session may be complete or the group is full.\nPlease join another group.',
      join_session_description:
        'Please join the experience on time to complete this assignment.',
      experience: 'Experience',
      completed: 'Completed',
      prework: 'Pre-Work',
      prework_description: 'Complete the Pre-Work by:',
      complete_prework: 'Complete Pre-Work',
      complete_prework_description:
        'Please complete the pre-work for this assignment before completing the experience.',
      complete_prework_take_quiz: 'Complete Pre-Work and take quiz',
      complete_prework_take_quiz_description:
        'Please complete the Pre-Work for this assignment, then take the quiz.',
      take_quiz_description:
        'Please take this quiz before completing the experience.',
      summary: 'Summary',
      breakout_learning_tokens: 'Breakout Learning Tokens',
      test_audio_video: 'Test Audio/Video',
      setup_camera_and_microphone: 'Setup your camera and microphone',
      setup_camera_and_microphone_description:
        'Breakout Learning is a discussion-based platform that requires your camera and microphone to be enabled.',
      setup_camera_and_microphone_mobile_warning:
        'You will need to set up your camera and microphone on your desktop',
      enable_animations: 'Enable Animations',
      disable_animations: 'Disable Animations',

      single: 'Single',
      best_choice_for: 'Best Choice for ',
      single_case_experience: 'single experience',
      single_class: 'single class ',
      just_with: ' with ',
      triplet: 'Triplet',
      multiple_cases: 'multiple experiences ',
      in_syllabus: 'in syllabus',
      bulk: 'Bulk',
      multiple_classes: 'multiple classes ',
      most_popular: 'Most Popular',

      total_price: 'Total Price: ${{price}}',
      enroll_in_case: 'Enroll in Experience',
      enroll_outcome: {
        token_number:
          'Breakout works with a Token based system. You need to buy {{count}} Tokens to access this experience.', // many
        token_number_one:
          'Breakout Learning works with a Token based system. You need to buy {{count}} Token to access this experience.', // one
        token_number_zero:
          'Breakout Learning works with a Token based system. You have enough tokens to access this experience.', // one
      },
      loading_stripe: 'Loading Stripe…',
      please_wait: 'Please wait…',
      no_group_leader: 'Group has no leader.',

      group_name_exists: 'Group name already exists.',

      leave_group: 'Leave Group',
      schedule_meeting: 'Schedule Meeting',

      scheduled: 'Scheduled',
      not: 'Not',
      date: 'Date',
      time: 'Time',
      group_details: 'Group Details',
      view_case_study: 'View Experience',

      pre_session_quiz: 'Pre-session Quiz',
      tile_number: '# {{number}}',
      talk_time: 'Talk Time (m): {{timeInMinutes}}',
      camera_off_time: 'Camera Off Time (m): {{timeInMinutes}}',

      welcome_to_breakout: 'Welcome to Breakout Learning',
      welcome_to_breakout_details:
        'Start your experience by creating your first class and adding assignments to it',
      class_name: 'Class Name',
      class_name_invalid: 'Class name is invalid',
      section_name: 'Section Name',
      section_name_invalid: 'Section name is invalid',

      students_view: 'Students View ({{count}})',
      cases_view: 'Experiences View ({{count}})',
      students_count: 'Students ({{count}})',
      cases_count: 'Experiences ({{count}})',
      invite_student_tooltip:
        'Create an invitation link to share with your students',
      invitation_created: 'Invitation list copied to your clipboard. {{link}}',
      invitation_create_error: 'Error creating invitation.',
      one_time_link: 'Create One Time Link',
      one_month_link: 'Create One Month Link',
      set_section_invoiced: 'Make Section Invoiced',
      set_section_invoiced_confirmation:
        'Are you sure you want to set this section as invoiced?',
      section_invoiced: 'Section Is Invoiced',
      invite_students: 'Invite Students',
      discussion_mode: 'Discussion Mode',
      discussion_mode_sub:
        'Peer video cards are now available on the main panel for discussion.',

      class_invitation: 'Class Invitation',
      class_joined_successfully: 'Class Joined Successfully!',
      class_joined_successfully_description:
        'New assignments added by your professor will now be available in your library.',
      enrolled: 'Enrolled',
      go_to_library: 'Go to Library',

      results: 'Results',

      group: 'Group',

      remove_user_from_group:
        'Are you sure you want to remove this student from group?',
      remove_from_group: 'Remove from Group',
      student_name: 'Student Name',
      add_member: 'Add Member',
      add_student_to_group: 'Add Student to Group',
      search_by_name: 'Search by Name',
      assigned_to_multiple_groups: 'Assigned to Multiple Groups',
      assigned_to_group: 'Assigned to: {{groupName}}',

      action: 'Action',

      av_test: 'AV Test',
      config: 'Config',
      home: 'Home',
      welcome: 'Welcome',
      loading: 'Loading',
      meeting: 'Meeting',
      video: 'Video',
      audio: 'Audio',
      purchase: 'Purchase',
      instructor: 'Instructor',

      room_state_status_abandoned: 'Abandoned',
      room_state_status_must_schedule: 'Not Scheduled',
      section_state_not_started: 'Not Started',
      section_state_completed: 'Completed',
      in_progress: 'In Progress',

      upload_image: 'Upload Image',
      upload_audio: 'Upload Audio',

      google_podcasts: 'Google Podcasts',
      apple_podcasts: 'Apple Podcasts',
      podcast_addict: 'Podcast Addict',
      pocket_casts: 'Pocket Casts',
      downcast: 'Downcast',
      overcast: 'Overcast',
      copy_rss_feed: 'Copy RSS Feed',
      podcast_links: 'Podcast Links',
      podcast_links_sub:
        'Listen to your private podcast using one of these apps, or copy the RSS Feed into an app of your choice.',
      image_aspect_ratio_error: 'Image must be {{hor}}:{{ver}} aspect ratio',
      image_dimensions_error_range:
        'Image dimensions must be between {{hor_min}}x{{ver_min}} and {{hor_max}}x{{ver_max}} pixels',
      image_maximum_dimension_error:
        'Image height and width must not exceed {{pixels}}px',

      warning_exclamation: 'Warning!',
      browser_unsupported_message:
        'Breakout Learning is not officially supported on this browser',
      browser_chrome_recommendation:
        'You may experience audio and video issues. Using Google Chrome is recommended for a stable experience.',
      proceed_anyways: 'Proceed Anyways',
      proceed: 'Proceed',

      url: 'URL',
      pdf: 'PDF',
      mp3: 'MP3',
      jpg: 'JPG',
      mp4: 'MP4',
      webm: 'WEBM',
      upload_pdf: 'Upload PDF',
      viewable_by: 'Viewable By',
      public: 'Public',
      invalid_material_type: 'type is required',
      invalid_material_permissions: 'viewable by is required',
      pre_session: 'Pre-session',
      post_session: 'Post-session',
      material_work_type_label: 'Pre or post-session material?',
      material_work_type_tooltip:
        '"Pre-session" or "Post-session" indicates whether the student is expected to have access to the material before or after the meeting.\nA post-session material will become visible only after the session is over, whereas a pre-session material will appear in the list of Pre-Work materials.',
      neither: 'Neither',
      upload_video: 'Upload Video',
      delete_file: 'Are you sure you want to delete this file?',
      delete_author: 'Are you sure you want to delete this author record?',

      featured_slide_deck_image: 'Featured (Hero) Image',
      slide_deck_featured: 'Slide Deck Featured',
      slide_deck_featured_tooltip:
        'Only Slide Decks with a name, logo, and a  large featured image (in materials) can be featured. Once these elements are provided, the Slide Deck will be showcased on the login page.',
      back: 'Back',

      av_test_continue: 'Looks Good! Continue',
      av_test_skip: 'Skip Walkthrough',
      av_test_next: 'Next',
      av_test_next_login_desktop: 'Next time you login on your desktop',

      stream_pause: 'Pause Everyone',
      stream_muted_notification: 'When the video is playing, you are muted.',
    },
    lti: {
      configuration: {
        confirm: 'Confirm',
        continue: 'Continue',

        section_settings: 'Class Settings',
        complete_section_settings: 'Complete Class Configuration',
        section_settings_description:
          'You will only configure class settings once for the course. Experience settings will be defined for each assignment.',
        section_class_name: 'Class Title',
        section_section_name: 'Section Title',

        class_pending: 'Class Pending',
        class_pending_description:
          'Your experience has not been activated by your professor. Please reach out to them to make sure the experience setup is complete.',

        section_class_name_empty: 'Class Title required',
        section_section_name_empty: 'Section Title required',

        wants_invoice_class: 'Invoice Class?',
        wants_invoice_class_description:
          'You are part of an organization that supports invoicing for classes. To request that your class be invoiced, please fill in the details below.',
        invoicing_estimated_number_of_users: 'Estimated Number of Students',
        invoicing_estimated_number_of_experiences:
          'Estimated Number of Experiences',
        dont_want_invoice: 'Don’t Invoice This Class',
        invoicing_submit_request: 'Submit Request',

        invoice_request: 'Invoice Request',
        invoice_request_status_pending: 'Pending',
        invoice_request_status_rejected: 'Rejected',

        change_to_student_pay: 'Change to Student Pay',
        create_new_request: 'Create New Request',

        invoice_request_submitted_on: 'Submitted On',
        invoice_request_number_of_students: 'Number of Students',
        invoice_request_number_of_experiences: 'Number of Experiences',
        invoice_request_estimated_cost: 'Estimated Cost',
        invoice_request_status: 'Status',

        invoice_request_submitted: 'Invoice Request Submitted',
        invoice_request_submitted_description:
          'The organization admin will need to approve this request. Until then students won’t be able to access this assignment.',

        invoice_request_pending: 'Class Pending',
        invoice_request_pending_description:
          "Your request for this class to be invoiced has been rejected by your organization's administrators. Please reach out to your organization's administrators to resolve this issue.",

        assigment_settings: 'Assignment Settings',
        complete_assignment_configuration: 'Complete Experience Configuration',
        complete_assignment_description:
          'Breakout experiences happen in groups. Please select your preferred grouping method to proceed.',

        grouping_method: 'Grouping Method',
        grouping_method_self_grouping: 'Student Self Grouping',
        grouping_method_automatic_randomized_grouping:
          'Automatic Randomized Grouping',

        grouping_size: 'Desired Group Size',

        grouping_method_description:
          'Students will be responsible for creating and joining groups. The software allows a maximum of eight students per group. You can also manually assign students to groups from the Students tab in your professor view.',

        assignment_ready: 'Assignment Ready',
        your_assignment_is_ready: 'Your experience is ready!',
        assignment_ready_description:
          'Students can now use the link added to their Canvas account to access the Breakout experience and complete the assignment. ',

        go_to_assignment: 'Go to Assignment',
      },
    },
  },
}

// eslint-disable-next-line import/no-default-export
export default language
