import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Search } from 'components/icons/Search'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const TeachingPlansSearchResultsEmptyView = observer(
  function CatalogTeachingPlansView({
    onClearClick,
  }: {
    onClearClick?: () => void
  }) {
    const { t } = useTranslation()
    return (
      <div className="flex min-h-full w-full flex-1 flex-col items-center justify-center rounded-2xl bg-surface p-7">
        <Search className="stroke-fixed-accent-color" size={50} />
        <div className="text-title-large my-1 text-on-surface">
          {t('instructor_library.no_results_found')}
        </div>
        <div className="text-body-large text-center text-on-surface-var">
          {t('instructor_library.no_results_found_description_1')}
          <br />
          {t('instructor_library.no_results_found_description_2')}
        </div>
        {!!onClearClick && (
          <BreakoutButton onClick={onClearClick} className="mt-3">
            {t('instructor_library.clear_search')}
          </BreakoutButton>
        )}
      </div>
    )
  }
)
