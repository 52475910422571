import { InstructorTeachingPlanCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorTeachingPlanCubit'
import type { TeachingPlanModule } from '@breakoutlearning/firebase-repository/models/TeachingPlanModule'
import { GoogleStorageImage } from 'components/GoogleStorageImage'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { LazyMarkdown } from 'components/LazyMarkdown'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { Search } from 'components/icons/Search'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import {
  InstructorTeachingPlanCubitProvider,
  useInstructorTeachingPlanCubit,
} from 'hooks/cubits/instructorTeachingPlan'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { useTranslation } from 'react-i18next'
import { RevampedSlideDeckCard } from '../shared/RevampedSlideDeckCard'
import { TeachingPlansSearchResultsEmptyView } from '../shared/TeachingPlansSearchResultsEmptyView'
import { useState } from 'react'

export const InstructorTeachingPlanPage = observer(
  function InstructorTeachingPlanPage({
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
  }) {
    const store = useRootStore()
    const repository = useRepository()
    const cubit = useCubitBuilder(
      () =>
        new InstructorTeachingPlanCubit(repository, catalogId, teachingPlanId),
      [repository, teachingPlanId]
    )
    const { t } = useTranslation()

    const queryParams = store.router.queryParams

    return (
      <InstructorTeachingPlanCubitProvider value={cubit}>
        <MainPane>
          <Header>
            <Headline
              className="text-display-medium font-display min-w-[35%] cursor-pointer truncate"
              aria-label={[
                cubit.teachingPlan.data.teachingPlanName,
                t('instructor_library.back_to_library_page'),
              ].join(', ')}
              onClick={() =>
                store.navigateTo('instructorLibrary', undefined, {
                  catalogId: catalogId,
                  sectionId: queryParams?.sectionId,
                })
              }
            >
              <div className="flex flex-row items-center">
                <div className="pr-1">
                  <ChevronLeft size={24} />
                </div>
                {cubit.teachingPlan.data.teachingPlanName}
                <ImpersonationInfo />
              </div>
            </Headline>
            <Actions>
              <SearchBox />
            </Actions>
          </Header>

          <Contents className="h-full md:overflow-auto">
            <PageContents />
          </Contents>
        </MainPane>
      </InstructorTeachingPlanCubitProvider>
    )
  }
)

const SearchBox = observer(function SearchBox() {
  const { t } = useTranslation()
  const cubit = useInstructorTeachingPlanCubit()
  return (
    <BreakoutTextInput
      type="text"
      clearIcon
      onChange={(e) => {
        const value = e.currentTarget.value
        cubit.setSearchFilter(value)
      }}
      value={cubit.searchFilter}
      LeadingIcon={Search}
      kind="secondary"
      placeholder={t('instructor_library.search_experiences')}
      className="lg:w-[220px]"
    />
  )
})

const PageContents = observer(function PageContents() {
  const cubit = useInstructorTeachingPlanCubit()
  return (
    <div className="flex min-h-full flex-col gap-4">
      {cubit.hasSearchFilter && <SearchResultsView />}
      {!cubit.hasSearchFilter && (
        <>
          <AboutView />
          <ModulesView />
        </>
      )}
    </div>
  )
})

const AboutView = observer(function PageContents() {
  const { t } = useTranslation()
  const cubit = useInstructorTeachingPlanCubit()
  const description = cubit.teachingPlan.data.teachingPlanDescription
  const authors = cubit.teachingPlanAuthors
  return (
    <div className="flex flex-col gap-4 rounded-2.5xl bg-surface p-7">
      {description && (
        <div>
          <div className="text-headline-medium text-core-primary" tabIndex={0}>
            {t('instructor_teaching_plan.about')}
          </div>
          <div
            className="text-body-large mt-4 text-on-surface-var"
            tabIndex={0}
          >
            <LazyMarkdown>{description}</LazyMarkdown>
          </div>
        </div>
      )}
      {authors.length > 0 && (
        <div className="flex flex-col gap-4" tabIndex={0}>
          {authors.models.map((author) => (
            <div key={author.id} className="flex flex-row items-center gap-3">
              {author.data.authorImageURL && (
                <div>
                  <GoogleStorageImage
                    src={author.data.authorImageURL}
                    className="aspect-square h-[40px] w-[40px] rounded-full object-cover"
                    namespace="instructor-library"
                    alt={author.data.authorName}
                  />
                </div>
              )}
              <div className="">
                <div className="text-title-medium line-clamp-1 text-on-surface">
                  {author.data.authorName}
                </div>
                <div className="text-body-large line-clamp-1 text-on-surface-var">
                  {author.data.authorTitle}, {author.data.authorInstitution}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
})

const ModulesView = observer(function ModulesView() {
  const cubit = useInstructorTeachingPlanCubit()

  return (
    <>
      {cubit.teachingPlanModules.models.map((module) => (
        <ModuleView key={module.id} module={module} />
      ))}
    </>
  )
})

const ModuleView = observer(function ModulesView({
  module,
}: {
  module: TeachingPlanModule
}) {
  const [isOpen, setIsOpen] = useState(true)
  const cubit = useInstructorTeachingPlanCubit()
  const pack = cubit.slideDeckPacks.get(module.id)
  const rootStore = useRootStore()
  const queryParams = rootStore.router.queryParams
  const { t } = useTranslation()

  if (!pack) return null

  return (
    <div className="flex flex-col gap-4 rounded-2.5xl bg-surface p-7">
      <div>
        <div
          className="text-headline-medium flex cursor-pointer flex-row justify-between text-core-primary"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div tabIndex={0}>{module.data.moduleName}</div>
          <div>
            <ChevronLeft
              onClick={() => setIsOpen(!isOpen)}
              tabIndex={0}
              role="button"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  setIsOpen(!isOpen)
                }
              }}
              aria-label={
                isOpen
                  ? t('instructor_library.expanded')
                  : t('instructor_library.collapsed')
              }
              className={isOpen ? 'rotate-270' : 'rotate-90'}
            />
          </div>
        </div>
        <div className="text-body-large text-on-surface-var" tabIndex={0}>
          <LazyMarkdown>{module.data.moduleDescription}</LazyMarkdown>
        </div>
      </div>
      {isOpen && (
        <>
          {pack.map(({ slideDeck, catalogId }) => {
            const materials =
              cubit.slideDeckMaterialsBySlideDeckId.get(slideDeck.id) || []
            const references =
              cubit.slideDeckReferencesBySlideId.get(slideDeck.id) || []
            const authors = cubit.slideDeckAuthorsBySlideDeckId.get(
              slideDeck.id
            )

            return (
              <RevampedSlideDeckCard
                href={rootStore.getSlideDeckPageUrl(slideDeck.id, {
                  ...queryParams,
                  catalogId,
                })}
                disableThumbnailKeyboardNavigation
                onClick={() => {
                  rootStore.navigateToSlideDeckPage(slideDeck.id, {
                    ...queryParams,
                    teachingPlanId: module.data.teachingPlanId,
                    catalogId,
                  })
                }}
                key={slideDeck.id}
                slideDeck={slideDeck}
                slideDeckMaterials={materials}
                slideDeckAuthors={authors}
                references={references}
              />
            )
          })}
        </>
      )}
    </div>
  )
})

const SearchResultsView = observer(function CatalogTeachingPlansView() {
  const cubit = useInstructorTeachingPlanCubit()
  const rootStore = useRootStore()
  const queryParams = rootStore.router.queryParams

  if (cubit.filteredSlideDecks.length === 0) {
    return (
      <TeachingPlansSearchResultsEmptyView
        onClearClick={() => {
          cubit.setSearchFilter('')
        }}
      />
    )
  }

  return (
    <div className="min-h-full w-full flex-1 rounded-2xl bg-surface p-7">
      <div className="flex flex-col gap-2">
        {cubit.filteredSlideDecks.map(({ slideDeck, catalogId }) => {
          const materials =
            cubit.slideDeckMaterialsBySlideDeckId.get(slideDeck.id) || []
          const authors = cubit.slideDeckAuthorsBySlideDeckId.get(slideDeck.id)
          const references =
            cubit.slideDeckReferencesBySlideId.get(slideDeck.id) || []

          return (
            <RevampedSlideDeckCard
              href={rootStore.getSlideDeckPageUrl(slideDeck.id, {
                ...queryParams,
                catalogId,
              })}
              onClick={() => {
                rootStore.navigateToSlideDeckPage(slideDeck.id, {
                  ...queryParams,
                  catalogId,
                })
              }}
              key={slideDeck.id}
              slideDeck={slideDeck}
              slideDeckMaterials={materials}
              slideDeckAuthors={authors}
              references={references}
            />
          )
        })}
      </div>
    </div>
  )
})
