import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { useDialogs } from 'hooks/dialogs'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import { dateSchema } from 'util/schema-date'
import { z } from 'zod'
import { ErrorIcon } from 'components/icons/Error'
import { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'

const getSchema = (
  t: ReturnType<typeof useTranslation>['t'],
  minDateOverride?: DateTime
) => {
  // When the assignment is scheduled, the deadline should be at least one day after that date.
  const minDate = (minDateOverride ? minDateOverride : DateTime.now()).minus({
    minutes: 1,
  })
  const tScoped = (key: string) => t(`instructor_assignment.${key}`)
  return z.object({
    assignedAt: dateSchema({
      required: t('instructor_assignment.start_date_required'),
    }),
    expiresAt: dateSchema({
      required: tScoped('deadline_required'),
      min: {
        date: minDate,
        message: minDateOverride
          ? tScoped('can_only_be_moved_forward')
          : tScoped('deadline_below_min'),
      },
    }),
    groupingType: z.preprocess((val) => {
      return Number(val)
    }, z.nativeEnum(AssignmentGroupingType)),
    groupingSize: z.coerce
      .number()
      .min(2, { message: t('instructor_library.grouping_size_invalid') })
      .max(RoomState.maxAllowedUsers, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .optional(),
  })
}

export const EditAssignmentDialog = observer(function EditAssignmentDialog({
  expiresAt: initialExpiresAt,
  assignedAt: initialAssignedAt,
  groupingSize,
  groupingType: initialGroupingType,
  hasBeenStarted,
  sectionHasStudents,
  sectionClassName,
  sectionName,
  slideDeckName,
  deleteAssignment,
  onSave,
}: {
  expiresAt?: DateTime
  assignedAt?: DateTime
  groupingType?: AssignmentGroupingType
  groupingSize?: number
  hasBeenStarted: boolean
  sectionHasStudents: boolean
  slideDeckName: string
  sectionName: string
  sectionClassName: string
  deleteAssignment: () => Promise<void>
  onSave: (params: {
    assignedAt: DateTime
    expiresAt: DateTime
    groupingType?: AssignmentGroupingType
    groupingSize?: number
  }) => Promise<void>
}) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { popDialog } = useDialogs()
  const { t } = useTranslation()
  const schema = useMemo(
    () => getSchema(t, hasBeenStarted ? initialExpiresAt : undefined),
    [t, hasBeenStarted, initialExpiresAt]
  )
  type FormValues = Omit<z.infer<typeof schema>, 'expiresAt'> & {
    expiresAt: DateTime | null
  }

  const defaultExpiresAt =
    initialExpiresAt || DateTime.now().plus({ minutes: 5 })
  const defaultAssignedAt = initialAssignedAt || DateTime.now()
  const defaultGroupingType =
    initialGroupingType || AssignmentGroupingType.manual
  const defaultGroupingSize = groupingSize || 4

  const {
    control,
    formState: { isSubmitting },
    setError,
    setValue,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      assignedAt: defaultAssignedAt,
      expiresAt: defaultExpiresAt,
      groupingType: defaultGroupingType,
      groupingSize: defaultGroupingSize,
    },
  })

  const groupingType = useWatch({
    control,
    name: 'groupingType',
  })

  const onSubmit = useCallback(
    async ({
      assignedAt,
      expiresAt,
      groupingType,
      groupingSize,
    }: FormValues) => {
      // Validate expiresAt
      if (!expiresAt) {
        setError('expiresAt', {
          message: t('instructor_assignment.deadline_required'),
        })
        return false
      }

      // For started assignments, can only move forward in time
      if (hasBeenStarted && initialExpiresAt && expiresAt < initialExpiresAt) {
        setError('expiresAt', {
          message: t('instructor_assignment.can_only_be_moved_forward'),
        })
        return false
      }

      // For non-started assignments, must be one day after assigned date
      if (
        !hasBeenStarted &&
        assignedAt &&
        expiresAt < assignedAt.plus({ days: 1 })
      ) {
        setError('expiresAt', {
          message: t(
            'instructor_assignment.deadline_must_be_one_day_after_start_date'
          ),
        })
        return false
      }

      const payload: Parameters<typeof onSave>[0] = {
        assignedAt,
        expiresAt,
        ...(groupingType !== initialGroupingType && { groupingType }),
        ...(groupingType === AssignmentGroupingType.automaticRandom &&
          groupingSize !== defaultGroupingSize && { groupingSize }),
      }

      const [, err] = await noTryAsync(() => onSave(payload))
      if (err) {
        console.error(err)
        toast.error(t('instructor_assignment.save_failed'))
        return
      }
      popDialog()
    },
    [
      defaultGroupingSize,
      hasBeenStarted,
      initialExpiresAt,
      initialGroupingType,
      onSave,
      popDialog,
      setError,
      t,
    ]
  )

  const assignedAt = useWatch({
    control,
    name: 'assignedAt',
  })
  const expiresAt = useWatch({
    control,
    name: 'expiresAt',
  })

  const now = DateTime.now()

  return (
    <Dialog
      size="md"
      innerClassName="flex flex-col"
      ignoreBackdropClick
      ignoreEscapeKey
    >
      <DialogCloseButton />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-grow flex-col gap-3 rounded-lg"
        data-testid="schedule-assignment-form"
      >
        <h2 className="text-headline-large">
          {t('instructor_assignment.experience_settings')}
        </h2>
        <BreakoutTooltip
          enabled={hasBeenStarted}
          content={t('instructor_assignment.disabled_because_its_started')}
        >
          <div>
            <Controller
              control={control}
              name="assignedAt"
              render={({ field, fieldState }) => (
                <BreakoutDateTimeInput
                  {...field}
                  error={fieldState.error}
                  value={field.value}
                  kind="secondary"
                  label={t('instructor_assignment.start_date')}
                  min={now}
                  onChange={(newAssignedAt) => {
                    field.onChange(newAssignedAt)

                    // If the deadline is now before the start date, clear out the deadline.
                    if (
                      newAssignedAt &&
                      expiresAt &&
                      newAssignedAt > expiresAt
                    ) {
                      setValue('expiresAt', null)
                    }
                  }}
                  disabled={isSubmitting || hasBeenStarted}
                  name="assignedAt"
                  data-testid="datetime-input-assignedAt"
                />
              )}
            />
          </div>
        </BreakoutTooltip>
        <Controller
          control={control}
          name="expiresAt"
          render={({ field, fieldState }) => (
            <BreakoutDateTimeInput
              {...field}
              error={fieldState.error}
              value={field.value}
              kind="secondary"
              label={t('instructor_assignment.deadline')}
              // If the assignment has been scheduled, the deadline can't be moved to before the current expiresAt.
              min={
                hasBeenStarted
                  ? initialExpiresAt
                  : assignedAt?.plus({ days: 1 })
              }
              hideNowButton
              max={assignedAt?.plus({ years: 1 })}
              initialView={assignedAt?.plus({ days: 1 })}
              disabled={isSubmitting}
              name="expiresAt"
              data-testid="datetime-input-expiresAt"
            />
          )}
        />

        <BreakoutTooltip
          enabled={sectionHasStudents}
          content={t(
            'instructor_assignment.disabled_because_it_has_students_in_section'
          )}
        >
          <div>
            <Controller
              control={control}
              name="groupingType"
              render={({ field }) => (
                <BreakoutSelect
                  {...field}
                  label={t('instructor_library.student_grouping')}
                  name="groupingType"
                  kind="secondary"
                  disabled={isSubmitting || sectionHasStudents}
                  onChange={(value) => {
                    field.onChange(value)
                    if (value === AssignmentGroupingType.manual) {
                      setValue('groupingSize', undefined)
                    } else {
                      setValue('groupingSize', 4)
                    }
                  }}
                  options={[
                    {
                      value: AssignmentGroupingType.manual,
                      label: t('instructor_library.students_self_grouping'),
                    },
                    {
                      value: AssignmentGroupingType.automaticRandom,
                      label: t(
                        'instructor_library.automatic_randomized_grouping'
                      ),
                    },
                  ]}
                />
              )}
            />
          </div>
        </BreakoutTooltip>

        {groupingType === AssignmentGroupingType.automaticRandom && (
          <BreakoutTooltip
            enabled={sectionHasStudents}
            content={t(
              'instructor_assignment.disabled_because_it_has_students_in_section'
            )}
          >
            <Controller
              control={control}
              name="groupingSize"
              render={({ field, fieldState }) => (
                <BreakoutTextInput
                  {...field}
                  disabled={sectionHasStudents || isSubmitting}
                  kind="secondary"
                  error={fieldState.error}
                  type="number"
                  label={t('instructor_library.desired_group_size')}
                  name="groupingSize"
                  min="2"
                  max={RoomState.maxAllowedUsers}
                />
              )}
            />
          </BreakoutTooltip>
        )}
        <div className="flex-auto" />
        <BreakoutButton
          type="submit"
          size="large"
          className="mt-2 w-full"
          data-testid="submit-button"
        >
          {t('instructor_assignment.save_settings')}
        </BreakoutButton>
        {!sectionHasStudents && (
          <button
            type="button"
            className="mx-auto mt-2"
            data-testid="delete-button"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <div className="text-label-medium flex items-center justify-center gap-1 text-core-error">
              <TrashCanIcon size={15} />
              {t('instructor_assignment.delete_experience')}
            </div>
          </button>
        )}
        <ConfirmationDialog
          btnTestId={'delete-button-confirm'}
          open={showDeleteConfirmation}
          buttonKind="error"
          topIcon={<ErrorIcon size={32} className="text-core-error" />}
          dismiss={() => {
            setShowDeleteConfirmation(false)
          }}
          text={t('instructor_assignment.delete_experience_confirmation', {
            slideDeckName: slideDeckName,
            sectionClassName: sectionClassName,
            sectionName: sectionName,
          })}
          subtitle={t('instructor_assignment.delete_experience_subtitle')}
          inline={true}
          onConfirm={async () => {
            await deleteAssignment()
          }}
        />
      </form>
    </Dialog>
  )
})
