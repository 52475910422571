import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { useCallback } from 'react'
import { WalkthroughDialog } from './WalkthroughDialog'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'
import { useBreakoutUser } from 'hooks/profile'

export function useWalkthroughDialog({
  roomId,
  roomStateStatus,
}: {
  roomId: string | undefined | null
  roomStateStatus: RoomStateStatus
}) {
  const { showDialog } = useDialogs()
  const store = useRootStore()
  const user = useBreakoutUser()

  const showWalkthroughDialogIfNecessary = useCallback(
    (onJoin: VoidFunction) => {
      if (!roomId) return

      if (
        user.profile.data.walkthroughComplete ||
        roomStateStatus === RoomStateStatus.suspended
      ) {
        onJoin()
        return store.navigateTo('meeting', { roomId })
      }

      showDialog(
        ({ remove }) => (
          <WalkthroughDialog
            joinSession={() => {
              onJoin()
              remove()
              user.setWalkthroughComplete()
              store.navigateTo('meeting', { roomId })
            }}
          />
        ),
        {
          namespace: 'join-session',
        }
      )
    },

    [roomId, roomStateStatus, user, showDialog, store]
  )

  return {
    showWalkthroughDialogIfNecessary,
  }
}
