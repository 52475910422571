import { createContext, useContext } from 'react'
import type { FirebaseRepository } from '@breakoutlearning/firebase-repository/models/FirebaseRepository'

// type UserRole = 'student' | 'instructor' | 'admin'

type AuthContextType = {
  repository: FirebaseRepository
  initialized: boolean
  usingLocalEmulator: boolean
  authenticated: boolean
  setAuthenticated: (value: boolean) => void
}

export const AuthenticationContext = createContext<AuthContextType | null>(null)

export function useAuthContext() {
  const context = useContext(AuthenticationContext)
  if (!context) {
    throw new Error(
      'useAuth must be used within an AuthenticationContextProvider'
    )
  }
  return context
}

export function useAuth() {
  const auth = useAuthContext()
  return auth.repository.auth
}

export function useCurrentUser() {
  const auth = useAuth()
  if (!auth.currentUser) {
    throw new Error(
      'No user found - this hook should only be called after the user is authenticated.'
    )
  }
  return auth.currentUser
}

export function useRepository() {
  return useAuthContext().repository
}

export function useStorage() {
  return useAuthContext().repository.storage
}

export function useFunctions() {
  return useAuthContext().repository.functions
}
