export const CalendarClose = ({
  color = 'currentColor',
  size = '24',
  onClick,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill={'none'}
      {...otherProps}
    >
      <path
        fill={color}
        d="M23.75 23.75H6.25V10H23.75M23.75 3.75H22.5V1.25H20V3.75H10V1.25H7.5V3.75H6.25C5.58696 3.75 4.95107 4.01339 4.48223 4.48223C4.01339 4.95107 3.75 5.58696 3.75 6.25V23.75C3.75 24.413 4.01339 25.0489 4.48223 25.5178C4.95107 25.9866 5.58696 26.25 6.25 26.25H23.75C24.413 26.25 25.0489 25.9866 25.5178 25.5178C25.9866 25.0489 26.25 24.413 26.25 23.75V6.25C26.25 5.58696 25.9866 4.95107 25.5178 4.48223C25.0489 4.01339 24.413 3.75 23.75 3.75ZM11.6375 21.25L14.6875 18.2L17.7375 21.25L19.0625 19.925L16.0125 16.875L19.0625 13.825L17.7375 12.5L14.6875 15.55L11.6375 12.5L10.3125 13.825L13.3625 16.875L10.3125 19.925L11.6375 21.25Z"
      />
    </svg>
  )
}
