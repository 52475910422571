import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useRepository } from 'hooks/auth'
import { useEffect, useMemo } from 'react'

type FormData = {
  firstName: string
  lastName: string
  emailAddress: string
  company: string | undefined
}
export const ProfileInfoSection = observer(function ProfileInfoSection() {
  const { t } = useTranslation()
  const breakoutUser = useBreakoutUser()
  const { featureFlags } = useRepository()

  const allowEmailUpdate = featureFlags.data.allowEmailUpdate

  const showCompanyInput = useMemo(() => {
    return breakoutUser.isInstructor || breakoutUser.isCorre
  }, [breakoutUser.isInstructor, breakoutUser.isCorre])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      firstName: breakoutUser.firstName,
      lastName: breakoutUser.lastName,
      emailAddress: breakoutUser.emailAddress,
      company: breakoutUser.profile.data.company || undefined,
    },
  })

  useEffect(() => {
    // Reset the form emailAddress form field when the user email changes.
    reset({
      emailAddress: breakoutUser.emailAddress,
    })
  }, [breakoutUser.emailAddress, reset])

  const onSubmit: SubmitHandler<FormData> = async ({
    firstName,
    lastName,
    emailAddress,
    company,
  }) => {
    const updateNameAndCompanyIfChanged = async () => {
      const promises: Promise<unknown>[] = []

      // did name update
      if (
        firstName !== breakoutUser.firstName ||
        lastName !== breakoutUser.lastName
      ) {
        promises.push(
          breakoutUser.updateName(firstName.trim(), lastName.trim())
        )
      }

      // did hubspot company update
      if (company && company !== breakoutUser.profile.data.company) {
        promises.push(breakoutUser.updateCompany(company.trim()))
      }

      await Promise.all(promises)

      // display success message if any updates were made
      if (promises.length)
        toast.success(t('profile.profile_updated'), {
          id: 'profile-updated',
        })
    }

    // if email did not change, update the name only
    if (!allowEmailUpdate || emailAddress === breakoutUser.emailAddress) {
      return await updateNameAndCompanyIfChanged()
    }

    const [verified, errKey] =
      await breakoutUser.profile.startEmailUpdateVerification(emailAddress)

    if (!verified) {
      toast.error(t(`profile.${errKey}`))
      return
    }

    await breakoutUser.updateName(firstName, lastName)

    toast.success(t('profile.email_verification_sent'), {
      duration: 12000,
    })
    return
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full flex-col justify-between"
    >
      <div>
        <h2 className="text-headline-large text-core-on-tertiary">
          {t('profile.details_headline')}
        </h2>

        <div className="mt-6 flex flex-col gap-3">
          <Controller
            control={control}
            name="firstName"
            rules={{
              required: t('profile.first_name_required'),
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                kind="secondary"
                label={t('profile.first_name')}
                data-testid="firstNameInput"
                error={fieldState.error}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{
              required: t('profile.last_name_required'),
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                kind="secondary"
                label={t('profile.last_name')}
                data-testid="lastNameInput"
                error={fieldState.error}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="emailAddress"
            rules={{
              required: t('profile.email_required'),
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                disabled={!allowEmailUpdate}
                kind="secondary"
                label={t('profile.email')}
                data-testid="emailInput"
                error={fieldState.error}
                {...field}
              />
            )}
          />
          {showCompanyInput && (
            <Controller
              control={control}
              name="company"
              render={({ field, fieldState }) => (
                <BreakoutTextInput
                  disabled={!showCompanyInput}
                  kind="secondary"
                  label={t('profile.institution')}
                  data-testid="companyInput"
                  error={fieldState.error}
                  required={false}
                  {...field}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <BreakoutButton
          data-testid="save-profile"
          type="submit"
          size="large"
          kind="primary"
          className="mt-3"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t('profile.save')}
        </BreakoutButton>
      </div>
    </form>
  )
})
