import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PollDetailRow } from './PollDetailRow'
import { observer } from 'mobx-react-lite'

export const SimplePoll = observer(function SimplePoll({
  answers,
  index,
  question,
}: {
  answers: RoomStateAnswer[]
  index: number
  question: SlideQuestion
}) {
  const { t } = useTranslation()

  const yes = answers.filter((answer) => answer.data.answer === 1).length
  const yesPercentage = (yes / answers.length) * 100
  const noPercentage = 100 - yesPercentage

  const [yesStr, noStr] = useMemo(() => {
    return [t('instructor_assignment.yes'), t('instructor_assignment.no')]
  }, [t])

  return (
    <div
      tabIndex={0}
      data-testid={`instructor-poll-row-${question.id}`}
      className="mt-3 flex h-[60px] flex-row items-center rounded-2xl bg-core-tertiary px-5 py-3"
    >
      <strong className="text-title-medium mr-3">{index + 1}</strong>
      <div className="flex-grow">
        <strong className="text-body-medium line-clamp-3">
          {question.data.question}
        </strong>
      </div>
      <div className="flex w-[250px] min-w-[250px] flex-col">
        <PollDetailRow
          answer={yesStr}
          title={yesStr}
          selectedPercentage={yesPercentage}
          testId={`instructor-poll-row-${question.id}-yes`}
        />
        <PollDetailRow
          answer={noStr}
          title={noStr}
          selectedPercentage={noPercentage}
          testId={`instructor-poll-row-${question.id}-no`}
        />
      </div>
    </div>
  )
})
