import classNames from 'classnames'

export const LinearProgressIndicator = ({
  percentage,
  className,
  color = 'green',
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  percentage: number
  color?: 'green' | 'red'
}) => (
  <div
    className={classNames(
      'h-1.5 min-h-1.5 rounded-[4px] bg-light-grey',
      className
    )}
  >
    <div
      className={classNames('h-1.5 rounded-[4px]', {
        'bg-breakout-dark-green': color === 'green',
        'bg-breakout-red': color === 'red',
      })}
      style={{ width: `${percentage}%` }}
      {...rest}
    ></div>
  </div>
)
