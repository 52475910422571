const language = {
  translation: {
    navigation: {
      home: 'Strona Główna',
      profile: 'Profil',
      logout: 'Wyloguj',
      instructor_classes: 'Zajęcia',
      instructor_library: "Przeglądaj Case'y",
      instructor_assistants: 'Asystenci',
      admin_dashboard: 'Panel Administracyjny',
      admin_library: 'Biblioteka Admina',
      admin_catalogs: 'Katalogi',
      admin_users: 'Użytkownicy',
    },
    buttons: {
      hide_completed: 'Ukryj Zakończone',
      show_completed: 'Pokaż Zakończone',
    },
    library: {
      headline: 'Biblioteka',
    },
    instructor_class: {
      cases: "Case'y",
      students: 'Studenci',
      explore_catalog: 'Przeglądaj Katalog',
      invite_students: 'Zaproś Studentów',
      invite_students_tooltip: 'Zaproś Studentów',
    },
    instructor_classes: {
      headline: 'Zajęcia',
    },
    instructor_library: {
      headline: 'Biblioteka Case Study',
      deadline_missing: 'Proszę ustawić termin',
    },
    instructor_assistants: {
      headline: 'Asystenci',
    },
    admin_dashboard: {
      headline: 'Panel Administracyjny',
    },
    admin_library: {
      headline: 'Biblioteka Admina',
    },
    admin_catalogs: {
      headline: 'Katalogi',
    },
    admin_users: {
      headline: 'Użytkownicy',
    },
    profile: {
      headline: 'Profil Użytkownika',
      tokens_headline: 'Tokeny Breakout Learning',
      tokens_explanation:
        'Breakout Learning używa systemu tokenów. Aktualnie masz <bold>{{tokensAvailable}}</bold> tokenów. Aby dołączyć do experience, które Twoi profesorowie przypisują do Twojej klasy, musisz zakupić tokeny.',
      tokens_available: 'Dostępne Tokeny',
      tokens_consumed: 'Zużyte Tokeny',

      first_name: 'Imię',
      last_name: 'Nazwisko',
      email_address: 'Adres e-mail',

      details_headline: 'Dane Osobowe',

      select_language: 'Wybierz Język',

      save_profile: 'Zapisz Profil',

      other_resources: 'Other Resources',
      test_av: 'Testuj Audio-Video',
      privacy_policy: 'Polityka Prywatności',
      disable_animations: 'Wyłącz Animacje',
      enable_animations: 'Włącz Animacje',
    },
    meeting: {
      progress_time_left: 'Pozostało',
      progress_loading: 'Ładowanie',
      progress_time_up: 'Czas minął',
      time_left_zero: '<1 min',
      time_left_one: '1 min',
      time_left_other: '{{count}} min',
    },
    student_library: {
      // And now Polish with its own insanity
      // I wonder if that's why I understand Javascript so well
      // token_number: '{{count}} Tokens', -> this one will never be used in Polish
      token_number_one: '{{count}} Token', // 1
      token_number_zero: 'Brak Tokenów', // 0 - doing this like that just to show you how it works, normally `token_number_many` will kick in
      token_number_few: '{{count}} Tokeny', // 2-4, 22-24, 32-34, etc.
      token_number_many: '{{count}} Tokenów', // 4-21, 25-31, 35-41, etc.
      token_number_other: '{{count}} Tokenów', // I'm not sure when this would be used, but it's here just in case
    },
  },
}

// eslint-disable-next-line import/no-default-export
export default language
