import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const getSchema = () =>
  z.object({
    groupingType: z.nativeEnum(AssignmentGroupingType),
    groupingSize: z.number().optional(),
  })

type FormValues = z.infer<ReturnType<typeof getSchema>>

export const AssignmentConfiguration = observer(
  function AssignmentConfiguration() {
    const { t } = useTranslation()
    const assignmentCubit = useInstructorAssignmentCubit()
    const slideDeck = assignmentCubit.slideDeck

    const onSubmit = useCallback(
      async (data: FormValues) => {
        await assignmentCubit.updateAssignmentGrouping({
          groupingType: data.groupingType,
          groupingSize:
            data.groupingType === AssignmentGroupingType.automaticRandom
              ? data.groupingSize
              : undefined,
        })
        await assignmentCubit.markAsActive()
      },
      [assignmentCubit]
    )

    const schema = useMemo(() => getSchema(), [])

    const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
      resolver: zodResolver(schema),
      mode: 'onChange',
      defaultValues: {
        groupingType: AssignmentGroupingType.manual,
        groupingSize: 4,
      },
    })

    const { groupingType } = watch()

    return (
      <div className="assignment flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <div className="mb-5">
            <h2 className="text-headline-small">
              {t('lti.configuration.assigment_settings')}
            </h2>
            <h1 className="text-headline-large">
              {t('lti.configuration.complete_assignment_configuration')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.complete_assignment_description')}
            </div>
          </div>
          <div className="mb-3 flex flex-row items-center gap-3 rounded-2xl bg-core-secondary px-2 py-3">
            <div className="pl-3">
              <img
                className="max-h-5 max-w-5"
                src={slideDeck.data.slideDeckImageURL}
                alt={slideDeck.data.slideDeckName}
              />
            </div>
            <div>
              <div className="text-body-medium text-on-surface-var">
                {slideDeck.data.slideDeckName}
              </div>
              <div className="text-label-large">
                {slideDeck.data.slideDeckTeaser}
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <Controller
              control={control}
              name="groupingType"
              render={({ field, fieldState }) => {
                return (
                  <BreakoutSelect
                    {...field}
                    onChange={(value) => {
                      setValue('groupingType', value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                    label={t('lti.configuration.grouping_method')}
                    kind="secondary"
                    options={[
                      {
                        value: AssignmentGroupingType.manual,
                        label: t(
                          'lti.configuration.grouping_method_self_grouping'
                        ),
                      },
                      {
                        value: AssignmentGroupingType.automaticRandom,
                        label: t(
                          'lti.configuration.grouping_method_automatic_randomized_grouping'
                        ),
                      },
                    ]}
                    error={fieldState.error}
                  />
                )
              }}
            />

            {groupingType === AssignmentGroupingType.automaticRandom && (
              <Controller
                control={control}
                name="groupingSize"
                render={({ field, fieldState }) => {
                  return (
                    <BreakoutTextInput
                      {...field}
                      error={fieldState.error}
                      label={t('lti.configuration.grouping_size')}
                      type="number"
                    />
                  )
                }}
              />
            )}
            <div className="text-body-medium px-4 py-2 text-on-surface-var">
              {t('lti.configuration.grouping_method_description')}
            </div>

            <BreakoutButton
              kind="accent"
              type="submit"
              size="medium"
              className="bg-fixed-accent-color"
            >
              {t('lti.configuration.confirm')}
            </BreakoutButton>
          </form>
        </div>
      </div>
    )
  }
)
