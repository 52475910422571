import type { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { createContext, useContext } from 'react'

const InstructorSlideDeckCubitContext = createContext<
  InstructorSlideDeckCubit | undefined
>(undefined)

export const InstructorSlideDeckCubitProvider =
  InstructorSlideDeckCubitContext.Provider

export function useInstructorSlideDeckCubit() {
  const cubit = useContext(InstructorSlideDeckCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useInstructorSlideDeckCubit must be used within a CubitProvider'
    )
  }

  return cubit
}
