import { FullPageSpinner } from 'components/Spinner'
import { signInWithCustomToken } from 'firebase/auth'
import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import { useEffect } from 'react'
import { useRepository } from '../../hooks/auth'
import { useCanvasMode } from 'hooks/use-canvas-mode'

function TokenLogin() {
  // const [loggingIn, setLoggingIn] = useState(false)
  const rootStore = useRootStore()
  const repository = useRepository()

  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')
  const redirectTo = queryParams.get('redirectTo')
  const landingPage = queryParams.get('landingPage')
  const canvasMode = queryParams.get('canvasMode')
  const { setCanvasMode } = useCanvasMode()

  useEffect(() => {
    return reaction(
      () => repository.breakoutUser?.isLoaded,
      (isLoaded) => {
        if (isLoaded) {
          if (canvasMode) setCanvasMode(true)
          rootStore.navigateTo('instructorLibrary')
        }
      }
    )
  }, [repository, rootStore, canvasMode, setCanvasMode])

  useEffect(() => {
    if (!token) {
      alert('bad token')
      return
    }
    if (canvasMode) setCanvasMode(true)
    signInWithCustomToken(repository.auth, token).then(() => {
      if (landingPage) {
        window.location.href = landingPage
        return
      }

      rootStore.navigateTo(
        'instructorLibrary',
        undefined,
        redirectTo
          ? {
              redirectTo: redirectTo,
            }
          : undefined
      )
    })
  }, [
    token,
    rootStore,
    repository.auth,
    redirectTo,
    landingPage,
    canvasMode,
    setCanvasMode,
  ])

  return <FullPageSpinner />
}

export function LoginWithToken() {
  return (
    <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
      <TokenLogin />
    </div>
  )
}
