import { DateTime } from 'luxon'

/**
 * Calculates the grouping date based on assignment start and expiry times.
 * Returns a date that is 20% of the total duration before the expiry date.
 *
 * @param expiresAt - ISO string representing the expiry date
 * @param assignedAt - ISO string representing the assignment start date
 * @returns DateTime object representing the grouping date, or undefined if inputs are invalid
 */
export function calculateGroupingDate(
  expiresAt: string | null | undefined,
  assignedAt: string | null | undefined
): DateTime | undefined {
  if (!expiresAt || !assignedAt) {
    return undefined
  }

  const expiryTime = DateTime.fromISO(expiresAt)
  const startTime = DateTime.fromISO(assignedAt)

  if (!expiryTime.isValid || !startTime.isValid) {
    return undefined
  }

  // Calculate duration and ensure it's positive
  const duration = expiryTime.diff(startTime, 'milliseconds').milliseconds
  if (duration <= 0) {
    return undefined
  }

  const timeBeforeExpiry = duration * 0.2
  return expiryTime.minus({ milliseconds: timeBeforeExpiry })
}
