import classNames from 'classnames'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { LightBulbIcon } from 'components/icons/LightBulb'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const Header = observer(function Header() {
  const { t } = useTranslation()
  const cubit = useMeetingResultsCubit()

  const {
    sessionResults: {
      quizPerformance,
      userQuizPerformance,
      talkTimeMinutes,
      groupAverageTalkTimeMinutes,
      cameraOnPercent,
      groupAverageCameraOnPercent,
    },
  } = cubit

  return (
    <div
      data-testid="meeting-results-header"
      className="flex flex-col gap-1 p-1 md:flex-row"
    >
      <div className="flex flex-1 flex-col gap-1 py-3">
        <HeaderTitle />
        <div className="flex flex-row gap-1">
          <LightBulbIcon
            size={15}
            className="min-w-[15px] stroke-breakout-yellow"
          />
          <p className="text-body-medium">
            {t('meeting.results.breakout_session_results_description')}
          </p>
        </div>
      </div>
      {/* metrics */}
      <div className="flex flex-1 flex-wrap">
        {!isNaN(groupAverageTalkTimeMinutes) && (
          <SessionResultMetric
            label={t('meeting.results.talk_time')}
            userMetric={`${talkTimeMinutes.toFixed(1)} ${t('meeting.results.mins')}`}
            groupAverageMetric={`${groupAverageTalkTimeMinutes.toFixed(1)} ${t('meeting.results.mins')}`}
            borderRight={true}
          />
        )}
        {userQuizPerformance !== null && (
          <SessionResultMetric
            label={t('meeting.results.quiz_score')}
            userMetric={`${(userQuizPerformance * 100).toFixed(0)}%`}
            groupAverageMetric={`${(quizPerformance * 100).toFixed(0)}%`}
            borderRight={!isNaN(groupAverageCameraOnPercent)}
          />
        )}
        {!isNaN(groupAverageCameraOnPercent) && (
          <SessionResultMetric
            label={t('meeting.results.camera_on')}
            userMetric={`${cameraOnPercent.toFixed(0)}%`}
            groupAverageMetric={`${groupAverageCameraOnPercent.toFixed(0)}%`}
            borderRight={false}
          />
        )}
      </div>
    </div>
  )
})

const HeaderTitle = observer(function HeaderTitle() {
  const cubit = useMeetingResultsCubit()
  const { t } = useTranslation()
  const selectedUser = useMemo(
    () => cubit.meetingCubit.users.find((u) => u.id === cubit.userId),
    [cubit.meetingCubit.users, cubit.userId]
  )

  if (!selectedUser || selectedUser.isEmpty || !selectedUser.data.firstName) {
    return (
      <h2 className="text-headline-large">
        {t('meeting.results.breakout_session_results')}
      </h2>
    )
  }

  const {
    data: { imageUrl, firstName },
  } = selectedUser

  return (
    <div className="flex flex-row items-center gap-1">
      {imageUrl && (
        <div className="aspect-square min-w-[30px]">
          <BreakoutUserAvatar radius={15} user={selectedUser} />
        </div>
      )}
      <h2 className="text-headline-large">
        {t('meeting.results.breakout_session_results_per_user', {
          name: firstName,
        })}
      </h2>
    </div>
  )
})

const SessionResultMetric = observer(function SessionResultMetric({
  label,
  userMetric,
  groupAverageMetric,
  borderRight,
}: {
  label: string
  userMetric: string
  groupAverageMetric: string
  borderRight: boolean
}) {
  const { t } = useTranslation()
  const groupAvgText = t('meeting.results.group_avg')

  return (
    <div
      className={classNames(
        'flex w-[140px] min-w-[140px] flex-grow flex-col justify-center gap-1 p-3 text-center',
        {
          'border-r border-outline-variant': borderRight,
        }
      )}
    >
      <strong className="text-body-large text-on-surface-var">{label}</strong>{' '}
      <strong className="text-headline-medium">{userMetric}</strong>{' '}
      <strong className="text-body-medium">{`${groupAvgText} (${groupAverageMetric})`}</strong>{' '}
    </div>
  )
})
