import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { Spinner } from 'components/Spinner'
import { useOrganizationCubit } from 'hooks/cubits/organization'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRootStore } from 'hooks/rootStore'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import type { SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { BreakoutButton } from 'components/design-system/BreakoutButton'

export const OrganizationClassesView = observer(
  function OrganizationClassesView() {
    const cubit = useOrganizationCubit()
    const { t } = useTranslation()

    const [filters, setFilters] = useState<string[]>([])

    const filteredSections = useMemo(() => {
      if (filters.length === 0) {
        return cubit.sectionsForOrganization
      }
      return cubit.sectionsForOrganization.filter((section) => {
        return filters.some((filter) => {
          return (
            section.data.className
              .toLowerCase()
              .includes(filter.toLowerCase()) ||
            section.data.sectionName
              .toLowerCase()
              .includes(filter.toLowerCase()) ||
            section.instructor.fullName
              .toLowerCase()
              .includes(filter.toLowerCase())
          )
        })
      })
    }, [cubit.sectionsForOrganization, filters])

    if (!cubit.sectionsForOrganization) {
      return (
        <div className="flex h-full -translate-y-20 items-center justify-center">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="h-full min-h-0 rounded-3xl bg-surface p-6">
        <div className="h-full overflow-auto rounded-3xl bg-core-tertiary">
          <BreakoutTable className="white max-h-full">
            <thead>
              <tr className="sticky top-0 z-[10] !px-5">
                <th>{t('organization.class_title')}</th>
                <th>{t('organization.professor')}</th>
                <th>{t('organization.students')}</th>
                <th>{t('organization.experiences')}</th>
                <th>{t('organization.units')}</th>
                <th>{t('organization.invoiced')}</th>

                <th>
                  <BreakoutTextInput
                    kind="secondary"
                    className="float-right !min-w-[230px] max-w-[300px]"
                    placeholder={t('organization.filter_by_class_instructor')}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'Enter' &&
                        e.currentTarget.value.trim() !== ''
                      ) {
                        e.preventDefault()
                        setFilters([...filters, e.currentTarget.value])
                        e.currentTarget.value = ''
                      }
                    }}
                    LeadingIcon={Search}
                  />
                </th>
              </tr>
              {filters.length > 0 && (
                <tr>
                  <td colSpan={3}>
                    <div className="py-3">
                      <BreakoutPill.Chips
                        labels={filters}
                        onClick={(label) =>
                          setFilters((oldFilters) => {
                            const filtersCopy = [...oldFilters]
                            return filtersCopy.filter((item) => item !== label)
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
              )}
            </thead>
            <tbody className="max-h-full overflow-auto">
              {filteredSections.length === 0 && (
                <tr>
                  <td className="text-center" colSpan={7}>
                    <div className="p-5">
                      {t('organization.no_classes_found')}
                    </div>
                  </td>
                </tr>
              )}
              {filteredSections.map((section) => (
                <Row
                  key={section.id}
                  section={section}
                  assignments={cubit.assignmentsForOrganizationBySectionId.get(
                    section.id
                  )}
                />
              ))}
            </tbody>
          </BreakoutTable>
        </div>
      </div>
    )
  }
)

const Row = observer(function ClassRow({
  section,
  assignments = [],
}: {
  section: Section
  assignments?: SectionAssignment[]
}) {
  const { t } = useTranslation()
  const store = useRootStore()

  return (
    <tr>
      <td>
        <div className="text-label-medium text-nowrap">
          {section.data.className}
        </div>
        <div className="text-body-medium text-nowrap text-on-surface-var">
          {section.data.sectionName}
        </div>
      </td>

      <td>
        <div className="flex h-full shrink-0 items-center justify-start gap-1 border-none">
          <BreakoutUserAvatar
            className="shrink-0"
            user={section.instructor}
            radius={13}
          />
          <strong className="text-label-medium shrink-0">
            {section.instructor.fullName}
          </strong>
        </div>
      </td>
      <td>
        <div className="text-label-medium text-nowrap">
          {section.students.length}
        </div>
      </td>

      <td>
        <div className="text-label-medium text-nowrap">
          {assignments.length}
        </div>
      </td>
      <td>{section.students.length * assignments.length}</td>
      <td>
        <strong className="text-label-medium">
          {(section.data.invoiced ?? false)
            ? t('organization.yes')
            : t('organization.no')}
        </strong>
      </td>

      <td>
        <div className="flex   justify-end gap-2">
          <BreakoutButton
            kind="secondary"
            className="!min-w-[62px]"
            onClick={() => {
              store.navigateTo('instructorClass', {
                id: section.id,
              })
            }}
            size="medium"
          >
            {t('organization.view_class')}
          </BreakoutButton>
        </div>
      </td>
    </tr>
  )
})
