import { SlideProjector } from 'components/SlideProjector'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InstructorAssignmentResultsQuestions } from './InstructorAssignmentsResultsQuestions'
import { Enlarge } from 'components/icons/Enlarge'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { InlineDialog } from 'components/dialogs/Dialog'
import { Close } from 'components/icons/Close'
import { InstructorAssignmentResultsRubric } from './InstructorAssignmentsResultsRubric'
import { InstructorAssignmentResultsPolls } from './InstructorAssignmentResultsPolls'
import { InstructorAssignmentResultsQuiz } from './InstructorAssignmentResultsQuiz'
import { type FunctionComponent } from 'react'
import { createPortal } from 'react-dom'

export const InstructorAssignmentResults = observer(
  function InstructorAssignmentResults({
    removeSelfDialog = () => {},
    inDialog = false,
  }: {
    removeSelfDialog?: () => void
    inDialog?: boolean
  }) {
    const { t } = useTranslation()
    const cubit = useInstructorAssignmentCubit()
    const [showDialog, setShowDialog] = useState(false)

    // we need both readable string keys for changing
    // tabs intuitively via the cubit and the current index for
    // the SlideProjector
    const tabs: {
      key: (typeof cubit)['resultsTab']
      label: string
      component: FunctionComponent<object>
    }[] = useMemo(
      () => [
        {
          key: 'questions',
          label: t('instructor_assignment.questions'),
          component: InstructorAssignmentResultsQuestions,
        },
        {
          key: 'rubric',
          label: t('instructor_assignment.rubric'),
          component: InstructorAssignmentResultsRubric,
        },
        {
          key: 'polls',
          label: t('instructor_assignment.polls'),
          component: InstructorAssignmentResultsPolls,
        },
        {
          key: 'quiz',
          label: t('instructor_assignment.quiz'),
          component: InstructorAssignmentResultsQuiz,
        },
      ],
      [t]
    )

    const getTab = useCallback(
      (key: (typeof cubit)['resultsTab']) => {
        switch (key) {
          case 'questions':
            return { ...tabs[0], i: 0 }
          case 'rubric':
            return { ...tabs[1], i: 1 }
          case 'polls':
            return { ...tabs[2], i: 2 }
          case 'quiz':
            return { ...tabs[3], i: 3 }
        }
      },
      [tabs]
    )

    const tabResult = getTab(cubit.resultsTab)
    const Tab = tabResult.component

    return (
      <>
        <div className="flex h-full w-full flex-col bg-light-grey px-10 pb-10 pt-8">
          <div className="mb-5 flex flex-row gap-5">
            {tabs.map((tab, i) => (
              <BreakoutButton
                data-testid={`results-tab-button-${tab.key}`}
                key={tab.label}
                size="medium"
                kind={i === tabResult.i ? 'tertiary' : 'secondary'}
                onClick={() => cubit.changeResultsTab(tab.key)}
              >
                {tab.label}
              </BreakoutButton>
            ))}
            <button
              title={
                inDialog
                  ? t('instructor_assignment.close_summary')
                  : t('instructor_assignment.expand_summary')
              }
              aria-label={
                inDialog
                  ? t('instructor_assignment.close_summary')
                  : t('instructor_assignment.expand_summary')
              }
              className="relative ml-auto"
              onClick={inDialog ? removeSelfDialog : () => setShowDialog(true)}
            >
              {!inDialog ? <Enlarge size={24} /> : <Close size={24} />}
            </button>
          </div>
          <SlideProjector
            index={tabResult.i}
            className="rounded-2xl bg-core-tertiary"
          >
            <Tab />
          </SlideProjector>
        </div>
        {showDialog && (
          <Portal>
            <InlineDialog
              open={showDialog}
              zIndex={2}
              size="custom"
              className="!fixed !h-[95vh] !w-[95vw] overflow-hidden !rounded-2xl"
            >
              <div className="h-full w-full">
                <InstructorAssignmentResults
                  removeSelfDialog={() => setShowDialog(false)}
                  inDialog={true}
                />
              </div>
            </InlineDialog>
          </Portal>
        )}
      </>
    )
  }
)

const Portal = ({ children }: { children: React.ReactNode }) => {
  return createPortal(children, document.body)
}
