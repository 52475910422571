export function ErrorIcon({
  color = 'currentColor',
  className,
  size = 24,
  strokeWidth = 1.1,
  ...otherProps
}: {
  className?: string
  color?: string
  size?: number | string
  strokeWidth?: number
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      stroke={color}
    >
      <path
        d="M4.9987 9.66537C7.29988 9.66537 9.16537 7.79988 9.16537 5.4987C9.16537 3.19751 7.29988 1.33203 4.9987 1.33203C2.69751 1.33203 0.832031 3.19751 0.832031 5.4987C0.832031 7.79988 2.69751 9.66537 4.9987 9.66537Z"
        stroke={color}
        strokeWidth={strokeWidth}
        {...otherProps}
      />
      <path
        d="M4.99805 3.41602V5.91602M4.99805 7.37435V7.58268"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}
