import type { AnimationController } from 'pages/layout/TwoColumn'
import { TwoColumnAnimatedPane } from 'pages/layout/TwoColumn'
import type { RouteType } from 'config/routes'
import type { QueryParams } from 'mobx-router'
import { InstructorClassPage } from './class/InstructorClassPage'
import { InstructorClassesPage } from './classes/InstructorClassesPage'
import { InstructorAssignmentPage } from './assignment/InstructorAssignmentPage'

// This function controls animated transitions between subpages
function calculateAnimationController(
  params: QueryParams,
  route: RouteType | undefined,
  previousController?: AnimationController
) {
  const routeTitle = route?.title
  let key: string | null = null
  let component: JSX.Element | null = null
  let mode = 'stack'
  const id = params?.id
  const assignmentId = params?.assignmentId
  if (
    routeTitle === 'instructorClassAssignment' &&
    typeof id === 'string' &&
    typeof assignmentId === 'string'
  ) {
    key = `instructorClassAssignment`
    component = (
      <InstructorAssignmentPage sectionId={id} assignmentId={assignmentId} />
    )
    mode = 'slideIn'
  } else if (routeTitle === 'instructorClass' && typeof id === 'string') {
    key = `instructorClass`
    component = <InstructorClassPage id={id} />
    if (previousController?.key === 'instructorClassAssignment') {
      mode = 'slideOut'
    } else {
      mode = 'slideIn'
    }
  } else if (routeTitle === 'instructorClasses' || routeTitle === 'home') {
    key = 'instructorClasses'
    component = <InstructorClassesPage />
    mode = 'slideOut'
  } else {
    mode = 'leave'
    component = null
  }

  return {
    key,
    component,
    mode,
  }
}

export const InstructorClassesEntryPage = () => {
  return (
    <TwoColumnAnimatedPane calculateController={calculateAnimationController} />
  )
}
