export function DoubleCheckIcon({
  color = 'currentColor',
  className,
  size = 24,
  strokeWidth = 2,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M2 12L7.25 17C7.25 17 8.66939 15.3778 9.875 14" />
      <path d="M8 12L13.25 17L22 7" />
      <path d="M16 7L12.5 11" />
    </svg>
  )
}
