import { Navigation } from 'pages/layout/Navigation'
import { getRouteName, getSubRouteComponent } from 'config/routes'
import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import React, { useEffect, useState } from 'react'
import { AnimatePresence, m } from 'framer-motion'
import sha1 from 'sha1'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import classNames from 'classnames'

const SubRouter = () => {
  return <SubRouterWithAnimation />
}

const SubRouterWithAnimation = () => {
  const rootStore = useRootStore()
  const router = rootStore.router
  const name = getRouteName(router.currentRoute)
  const initialComponent = getSubRouteComponent(name)
  const [component, setComponent] = useState<JSX.Element>(initialComponent)

  useEffect(() => {
    return reaction(
      () => router.currentRoute,
      () => {
        const name = getRouteName(router.currentRoute)
        const component = getSubRouteComponent(name)

        setComponent((current) => {
          if (current === component) return current
          return component
        })
      },
      { fireImmediately: true }
    )
  }, [router])
  const { canvasMode } = useCanvasMode()

  const typehash = sha1(component?.type || 'none')
  return (
    <main
      id="main"
      className={classNames('h-full w-full rounded-3xl bg-core-tertiary', {
        'md:ml-5': !canvasMode,
      })}
    >
      <AnimatePresence mode="wait" initial={false}>
        <m.div
          className="h-full w-full"
          key={`subroute-${typehash}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <React.Fragment key={typehash}>{component}</React.Fragment>
        </m.div>
      </AnimatePresence>
    </main>
  )
}

export function TwoColumnSubRouter() {
  const { canvasMode } = useCanvasMode()
  return (
    <div className="box-border h-full w-full">
      <div className="items-left flex h-full flex-col md:flex-row md:justify-between md:overflow-hidden">
        {!canvasMode && <Navigation />}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SubRouter />
        </ErrorBoundary>
      </div>
    </div>
  )
}

function ErrorFallback({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void
}) {
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const router = rootStore.router

  useEffect(() => {
    return reaction(
      () => router.currentRoute,
      () => {
        resetErrorBoundary()
      }
    )
  }, [resetErrorBoundary, router])

  return (
    <div className="flex h-full w-full items-center justify-center rounded-3xl bg-core-tertiary md:ml-5">
      <div className="text-center">
        <div className="text-headline-large mb-5">
          {t('components.something_went_wrong')}
        </div>
        <BreakoutButton
          size="large"
          className="bg-core-primary font-semibold text-core-on-primary"
          onClick={resetErrorBoundary}
        >
          Try again
        </BreakoutButton>
      </div>
    </div>
  )
}
