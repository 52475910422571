import classNames from 'classnames'

export function BreakoutTable({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) {
  return (
    <table className={classNames('breakout-table', className)}>
      {children}
    </table>
  )
}
