import type { UserProfileRole } from '@breakoutlearning/firebase-repository/types'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import type { ReactNode } from 'react'
import { useEffect, useMemo } from 'react'

export const RoleGuard = ({
  roles,
  children,
}: {
  roles: UserProfileRole[]
  children: ReactNode
}) => {
  const { navigateTo } = useRootStore()
  const { role } = useBreakoutUser()

  // Ensure roles are unique by converting to a Set and then back to an array
  const uniqueRoles = useMemo(() => [...new Set(roles)], [roles])

  useEffect(() => {
    if (!role || uniqueRoles.includes(role)) {
      return
    }

    // The user is not authorized to view this page, redirect them to the home page.
    navigateTo('home')
  }, [navigateTo, role, uniqueRoles])

  if (!role || !uniqueRoles.includes(role)) {
    // Show nothing while we're waiting for the user to redirected to home.
    return null
  }

  return children
}
