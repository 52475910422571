import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'

export const BloomsLabel = observer(function Blooms({
  level,
  width = 170,
  className,
}: {
  level: number
  width?: number
  className?: string
}) {
  const { t } = useTranslation()
  const tScoped = (s: string) => t(`instructor_assignment.${s}`)

  const label = [
    tScoped('bloom_0'),
    tScoped('bloom_low'),
    tScoped('bloom_medium'),
    tScoped('bloom_high'),
  ][level]
  return (
    <div
      className="flex flex-row items-center gap-1.5"
      style={{
        maxWidth: width,
        width: width,
      }}
    >
      <div
        className={classNames(
          'h-[10px] min-h-[10px] w-[10px] min-w-[10px] rounded-full',
          {
            'bg-breakout-color-scale-condensed-0': level === 0,
            'bg-breakout-color-scale-condensed-1': level === 1,
            'bg-breakout-color-scale-condensed-2': level === 2,
            'bg-breakout-color-scale-condensed-3': level === 3,
          }
        )}
      ></div>
      <BreakoutTooltip content={label}>
        <span className={`${className} text-nowrap`}>{label}</span>
      </BreakoutTooltip>
    </div>
  )
})
