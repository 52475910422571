import type { SectionRequest } from '@breakoutlearning/firebase-repository/models/SectionRequest'
import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { CircleX } from 'components/icons/CircleX'
import { ClockIcon } from 'components/icons/Clock'
import { FullPageSpinner } from 'components/Spinner'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const getSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({
    estimatedNumberOfUsers: z.coerce.number().min(1, {
      message: t('lti.configuration.estimated_number_of_users_empty'),
    }),

    estimatedNumberOfExperiences: z.coerce.number().min(1, {
      message: t('lti.configuration.estimated_number_of_experiences_empty'),
    }),
  })

type FormValues = z.infer<ReturnType<typeof getSchema>>

export const InvoicingConfiguration = observer(function InvoicingConfiguration({
  sectionRequest,
  setWantsInvoicing,
}: {
  sectionRequest: SectionRequest | null
  setWantsInvoicing: (value: boolean) => void
}) {
  const [requestSubmitted, setRequestSubmitted] = useState(false)

  const sectionCubit = useInstructorSectionCubit()
  // const user = useBreakoutUser()
  const { t } = useTranslation()

  const schema = useMemo(() => getSchema(t), [t])

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      estimatedNumberOfExperiences: 4,
      estimatedNumberOfUsers: 200,
    },
  })

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const section = sectionCubit.section
      const organizationId = section.data.organizationId
      if (!organizationId) {
        alert('Organization ID not found')
        return
      }
      await sectionCubit.createSectionInvoiceRequest({
        sectionId: sectionCubit.sectionId,
        sectionRequest: {
          sectionRequestAssignmentCount: data.estimatedNumberOfExperiences,
          sectionRequestStudentCount: data.estimatedNumberOfUsers,
          organizationId: organizationId,
          requestedAt: new Date(),
          sectionId: sectionCubit.sectionId,
        },
      })
      setRequestSubmitted(true)
    },
    [sectionCubit]
  )

  const createNewRequest = useCallback(async () => {
    setRequestSubmitted(false)
    setWantsInvoicing(true)

    if (sectionRequest) {
      await sectionCubit.withdrawSectionRequest(sectionRequest.id)
    }
  }, [sectionCubit, sectionRequest, setWantsInvoicing])

  const setToStudentPay = useCallback(async () => {
    setRequestSubmitted(false)
    setWantsInvoicing(false)

    if (sectionRequest) {
      await sectionCubit.setToStudentPay()
    }
  }, [sectionCubit, sectionRequest, setWantsInvoicing])

  const requestIsPending =
    !!sectionRequest?.isPending || !!sectionRequest?.isRejected

  if (sectionCubit.section.isLoading) {
    return <FullPageSpinner />
  }

  if (requestIsPending) {
    return (
      <div className="invoicing-submitted flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <div className="mb-5">
            <h1 className="text-headline-large">
              {t('lti.configuration.invoice_request_pending')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.invoice_request_pending_description')}
            </div>
          </div>
          <div className="rounded-xl bg-core-secondary p-4">
            <div className="text-title-large mb-5 mt-1 flex flex-row items-center gap-1">
              {sectionRequest.isPending && (
                <>
                  <ClockIcon className="stroke-fixed-accent-color" size="20" />
                  {t('lti.configuration.invoice_request')}:{' '}
                  {t('lti.configuration.invoice_request_status_pending')}
                </>
              )}
              {sectionRequest.isRejected && (
                <>
                  <CircleX
                    className="rounded-full bg-core-error stroke-core-on-error "
                    size="20"
                  />
                  {t('lti.configuration.invoice_request')}:{' '}
                  {t('lti.configuration.invoice_request_status_rejected')}
                </>
              )}
            </div>
            <div className="rounded-xl bg-core-tertiary p-4">
              <table role="presentation" className="w-full">
                <tr>
                  <td className="text-body-medium">
                    {t('lti.configuration.invoice_request_submitted_on')}
                  </td>
                  <td className="text-label-medium">
                    {sectionRequest.data.requestedAt.toDateString()}
                  </td>
                </tr>
                <tr>
                  <td className="text-body-medium">
                    {t('lti.configuration.invoice_request_number_of_students')}
                  </td>
                  <td className="text-label-medium">
                    {sectionRequest.data.sectionRequestStudentCount}
                  </td>
                </tr>
                <tr>
                  <td className="text-body-medium">
                    {t(
                      'lti.configuration.invoice_request_number_of_experiences'
                    )}
                  </td>
                  <td className="text-label-medium">
                    {sectionRequest.data.sectionRequestAssignmentCount}
                  </td>
                </tr>
                <tr>
                  <td className="text-body-medium">
                    {t('lti.configuration.invoice_request_estimated_cost')}
                  </td>
                  <td className="text-label-medium">
                    {sectionRequest.estimatedCost}
                  </td>
                </tr>
                <tr>
                  <td className="text-body-medium">
                    {t('lti.configuration.invoice_request_status')}
                  </td>
                  <td className="text-label-medium">
                    {sectionRequest.isPending &&
                      t('lti.configuration.invoice_request_status_pending')}
                    {sectionRequest.isRejected &&
                      t('lti.configuration.invoice_request_status_rejected')}
                  </td>
                </tr>
              </table>
            </div>
            <div className="mt-4 flex flex-row justify-between">
              <BreakoutButton
                onClick={setToStudentPay}
                kind="tertiary"
                type="submit"
                size="small"
              >
                {t('lti.configuration.change_to_student_pay')}
              </BreakoutButton>
              {sectionRequest.isRejected && (
                <BreakoutButton
                  onClick={createNewRequest}
                  kind="accent"
                  type="submit"
                  size="small"
                >
                  {t('lti.configuration.create_new_request')}
                </BreakoutButton>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (requestSubmitted) {
    return (
      <div className="invoicing-submitted flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <div className="mb-5">
            <h1 className="text-headline-large">
              {t('lti.configuration.invoice_request_submitted')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.wants_invoice_class_description')}
            </div>
          </div>
          <BreakoutButton
            onClick={() => setWantsInvoicing(false)}
            kind="accent"
            type="submit"
            size="medium"
          >
            {t('lti.configuration.continue')}
          </BreakoutButton>
        </div>
      </div>
    )
  }

  return (
    <div className="invoicing flex min-h-screen w-full flex-col items-start justify-center">
      <div className="ml-[5vw] max-w-[400px]">
        <div className="mb-5">
          <h1 className="text-headline-large">
            {t('lti.configuration.wants_invoice_class')}
          </h1>
          <div className="text-body-large">
            {t('lti.configuration.wants_invoice_class_description')}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
          <Controller
            control={control}
            name="estimatedNumberOfUsers"
            render={({ field, fieldState }) => {
              return (
                <BreakoutTextInput
                  label={t(
                    'lti.configuration.invoicing_estimated_number_of_users'
                  )}
                  {...field}
                  type="number"
                  error={fieldState.error}
                />
              )
            }}
          />

          <Controller
            control={control}
            name="estimatedNumberOfExperiences"
            render={({ field, fieldState }) => {
              return (
                <BreakoutTextInput
                  label={t(
                    'lti.configuration.invoicing_estimated_number_of_experiences'
                  )}
                  {...field}
                  type="number"
                  error={fieldState.error}
                />
              )
            }}
          />

          <div className="flex justify-between">
            <BreakoutButton
              onClick={() => setWantsInvoicing(false)}
              kind="tertiary"
              type="submit"
              size="medium"
            >
              {t('lti.configuration.dont_want_invoice')}
            </BreakoutButton>
            <BreakoutButton
              kind="accent"
              type="submit"
              size="medium"
              className="bg-fixed-accent-color"
            >
              {t('lti.configuration.invoicing_submit_request')}
            </BreakoutButton>
          </div>
        </form>
      </div>
    </div>
  )
})
