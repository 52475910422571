import * as i18n from 'i18next'
import { useMemo } from 'react'
import { initReactI18next } from 'react-i18next'

import en from './en'
import pl from './pl'

// eslint-disable-next-line react-refresh/only-export-components
export function setLanguage(lang: string) {
  localStorage.setItem('language', lang)
  i18n.changeLanguage(lang)
}

// Right now we load the languages directly, down the line we can do this dynamically
const i18nResources = {
  en: en,
  pl: pl,
}

function initI18n(lang: string) {
  i18n.use(initReactI18next)
  i18n.init({
    resources: i18nResources,
    lng: lang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
}

export type Translation = i18n.TFunction<'translation', undefined>

export function I18nWrapper({ children }: { children: JSX.Element }) {
  useMemo(() => {
    const lang = localStorage.getItem('language') || 'en'

    initI18n(lang)
  }, [])

  return <>{children}</>
}

export function I18nTestWrapper({ children }: { children: JSX.Element }) {
  useMemo(() => {
    initI18n('en')
  }, [])

  return <>{children}</>
}
