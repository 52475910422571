import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useTranslation } from 'react-i18next'

export const DemoMeetingEndedPage = () => {
  const { t } = useTranslation()
  const { profile, role } = useBreakoutUser()
  const rootStore = useRootStore()

  const isAnonymousDemoUser = profile.isAnonymous
  return (
    <div
      className="flex h-full w-full flex-col overflow-hidden bg-surface-dim lg:items-center lg:justify-center"
      data-testid="demo-meeting-ended-page"
    >
      <div className="mx-auto block h-full w-full max-w-[1280px] grid-cols-2 gap-3 space-y-4 overflow-y-auto p-7 lg:grid lg:space-y-0">
        <div className="flex flex-col justify-between gap-5 rounded-3xl bg-surface-bright px-14 py-10">
          <div>
            <div>
              <img src="/assets/images/logo_large_trimmed.png" width={160} />
            </div>
          </div>
          <div>
            <div className="text-headline-small text-on-surface">
              {t('demo.demo_complete')}
            </div>
            <div className="text-display-medium mt-3 text-on-surface">
              {t('demo.thank_you')}
            </div>

            <div className="text-label-large mt-6 text-on-surface">
              {t('demo.thank_you_long')}
            </div>
            <div className="mt-3 space-y-2">{/*  */}</div>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <div className="text-body-large flex-grow pr-4 text-on-surface">
                {t('demo.see_more')}
              </div>
              <div className="flex-shrink-0">
                <BreakoutButton
                  // disabled={!canJoinMeeting}
                  kind="primary"
                  size="large"
                  onClick={() => {
                    if (
                      isAnonymousDemoUser ||
                      role === UserProfileRole.student
                    ) {
                      window.location.href =
                        'https://www.breakoutlearning.com/catalog'
                      return
                    }

                    rootStore.navigateTo('instructorLibrary')
                  }}
                >
                  {t('demo.explore')}
                </BreakoutButton>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-center">
              <div className="flex-shrink-0">
                <BreakoutButton
                  kind="primary"
                  size="large"
                  onClick={() => {
                    rootStore.navigateTo('logout')
                  }}
                >
                  {t('demo.become_a_customer')}
                </BreakoutButton>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden overflow-hidden rounded-3xl bg-black lg:block">
          <img
            className="absolute left-1/2 top-0 h-full -translate-x-1/2 transform bg-left object-cover"
            src="/assets/images/breakout-face.png"
          />
        </div>
      </div>
    </div>
  )
}
