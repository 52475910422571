import { Suspense, lazy } from 'react'
const Markdown = lazy(() => import('react-markdown'))

export function LazyMarkdown({ children }: { children: string }) {
  return (
    <Suspense fallback={<></>}>
      <Markdown>{children}</Markdown>
    </Suspense>
  )
}
