import type { FirestoreOrganization } from '@breakoutlearning/firebase-repository/types'
import { useEffect, useMemo, useState } from 'react'
import { useRepository } from './auth'
import { useBreakoutUser } from './profile'
import { useRootStore } from './rootStore'

type OrganizationWithId = FirestoreOrganization & { id: string }

export const useFetchOrganizations = (): [boolean, OrganizationWithId[]] => {
  const { fetchMyOrganizations } = useBreakoutUser()
  const { impersonationInfo } = useRootStore()

  const [loadingOrgs, setLoadingOrgs] = useState(false)
  const repository = useRepository()
  const [organizations, setOrganizations] = useState<OrganizationWithId[]>([])

  useEffect(() => {
    fetchMyOrganizations(impersonationInfo)
      .then((orgs) => {
        setOrganizations(
          orgs.map((org) => ({
            id: org!.id,
            ...org!.data()!,
          }))
        )
      })
      .finally(() => {
        setLoadingOrgs(false)
      })
  }, [fetchMyOrganizations, impersonationInfo, repository])

  return useMemo(
    () => [loadingOrgs, organizations],
    [loadingOrgs, organizations]
  )
}
