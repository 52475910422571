import type { ZodType } from 'zod'
import { z } from 'zod'
import { DateTime } from 'luxon'

type DateConfig = {
  required?: string
  invalid?: string
  min?: {
    date: DateTime
    message: string | ((date: DateTime) => string)
  }
  max?: {
    date: DateTime
    message: string | ((date: DateTime) => string)
  }
}

const isDateTimeOrFalsy = (value: unknown): value is DateTime => {
  if (!value) return true
  return value instanceof DateTime && value.isValid
}

export const dateSchema = (config: DateConfig): ZodType<DateTime> => {
  let schema = z.custom<DateTime>(isDateTimeOrFalsy, {
    message: config.invalid || 'Invalid date',
  })

  if (config.required) {
    schema = schema.refine(
      (dateTime) => dateTime !== null && dateTime !== undefined,
      {
        message: config.required,
      }
    )
  }

  if (config.min) {
    schema = schema.refine(
      (dateTime) => !dateTime || dateTime >= config.min!.date,
      {
        message:
          typeof config.min!.message === 'function'
            ? config.min!.message(config.min!.date)
            : config.min!.message,
      }
    )
  }

  if (config.max) {
    schema = schema.refine(
      (dateTime) => !dateTime || dateTime <= config.max!.date,
      {
        message:
          typeof config.max!.message === 'function'
            ? config.max!.message(config.max!.date)
            : config.max!.message,
      }
    )
  }

  return schema
}
