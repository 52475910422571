import { useEffect, useState } from 'react'

export const useWaitFor = (timeInMinutes: number) => {
  const [elapsed, setElapsed] = useState(false)

  useEffect(() => {
    const interval = setInterval(
      () => {
        setElapsed(true)
      },
      timeInMinutes * 60 * 1000
    )

    return () => clearInterval(interval)
  }, [timeInMinutes])

  return elapsed
}
