import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { createContext, useContext } from 'react'

const StudentAssignmentCubitContext = createContext<
  StudentAssignmentCubit | undefined
>(undefined)

export const StudentAssignmentCubitProvider =
  StudentAssignmentCubitContext.Provider

export function useStudentAssignmentCubit() {
  const cubit = useContext(StudentAssignmentCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useStudentAssignmentCubit must be used within a CubitProvider'
    )
  }

  return cubit
}
