import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function PageTitle({ title }: { title: string }) {
  const { t } = useTranslation()
  const breakoutLearningName = t('breakout_learning')
  useEffect(() => {
    const container = document.getElementById('page-title')
    if (container) {
      container.innerHTML = [title, breakoutLearningName].join(' | ')
    }
    document.title = [title, breakoutLearningName].join(' | ')
    return () => {
      document.title = breakoutLearningName
      if (container) {
        container.innerHTML = breakoutLearningName
      }
    }
  }, [title, breakoutLearningName])

  return null
}
