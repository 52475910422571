import type { MeetingResultsCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingResultsCubit'
import { createContext, useContext } from 'react'

const MeetingResultsCubitContext = createContext<
  MeetingResultsCubit | undefined
>(undefined)

export const MeetingResultsCubitProvider = MeetingResultsCubitContext.Provider

export function useMeetingResultsCubit() {
  const cubit = useContext(MeetingResultsCubitContext)

  if (cubit === undefined) {
    throw new Error(
      'useMeetingResultsCubit must be used within a MeetingResultsCubitProvider'
    )
  }

  return cubit
}
