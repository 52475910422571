export const capitalize = (input: string | null | undefined): string => {
  if (!input) {
    return ''
  }
  return input
    .split('.')
    .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
    .join('.')
}

const sanitizeFilename = (filename: string): string => {
  // Convert to lowercase
  filename = filename.toLowerCase()

  // Replace spaces with underscores and replace non-alphanumeric characters (except dashes) with underscores
  filename = filename
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/[^a-z0-9-]/g, '_') // Replace all non-alphanumeric characters except dashes with underscores
    .replace(/_+/g, '_') // Replace multiple underscores with a single underscore

  return filename
}

export const formatFilename = ({
  extension,
  nameParts,
}: {
  extension: string
  nameParts: string[]
}) => {
  // Cap each part in the array at 16 characters
  const sanitizedParts = nameParts.map((part) => part.substring(0, 16))

  // Join the parts with underscores
  const fileName = sanitizedParts.join('_')

  // Sanitize the final file name and append the extension
  return `${sanitizeFilename(fileName)}.${extension}`
}
